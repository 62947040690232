import { icon1, icon2, icon3, icon4, icon5 } from "../icons";

export function getIndexSvgIcon(index) {
  switch (index) {
    case 1:
      return <>{icon1}</>;
    case 2:
      return <>{icon2}</>;
    case 3:
      return <>{icon3}</>;
    case 4:
      return <>{icon4}</>;
    case 5:
      return <>{icon5}</>;
  }
}

import { useState, useEffect } from "react";
import { Button } from "../../components/CopiedFromRefactor/Button";
import { QuestionWrapper } from "../../components/QuestionWrapper";
import { Input } from "../../components/Input";
import { SectionButtons } from "../../components/SectionButtons";
import { usePage } from "../../contexts/PageProvider";
import { useSave } from "../../hooks/useSave";
import { SectionMode } from "../../helpers/enums";
import { strategyManagementTeamObjectToAiGeneratedContentDto } from "../../models/aiGeneratedContent/transformer";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import { useNavigate } from "react-router-dom";
import { iconClose } from "../../icons";
import { FormNavigation } from "../../components/FormNavigation";

export function ManagementTeam() {
  const navigate = useNavigate();
  const {
    section,
    strategyManagementTeam,
    setStrategyManagementTeam,
    getGeneratedData,
    isLoading,
    setLoading,
    hasFetchedData,
  } = usePage();

  const [sectionName, setSectionName] = useState({ sectionName: "" });
  const { nextSection, generateSection, saveSectionInputs } = useSave(sectionName);
  const [isLoadingTeam, setIsloadingTeam] = useState(true);

  const [teamMembers, setTeamMembers] = useState([
    {
      managementTeamNameSurname: "",
      managementTeamPosition: "",
      managementTeamShortDescription: "",
      managementTeamExperience: "",
      error: false,
    },
  ]);

  useEffect(() => {
    if (section) {
      setSectionName({ sectionName: section.name });
    }
  }, [section]);

  useEffect(() => {
    if (strategyManagementTeam?.topManagers && strategyManagementTeam.topManagers.length > 0) {
      setTeamMembers(strategyManagementTeam.topManagers);
      setIsloadingTeam(false);
    }
    if (hasFetchedData) setIsloadingTeam(false);
  }, [strategyManagementTeam, hasFetchedData]);

  const handleInputChange = (index, field, value) => {
    const updatedMembers = [...teamMembers];
    updatedMembers[index][field] = value;
    setTeamMembers(updatedMembers);
  };

  const addTeamMember = () => {
    setTeamMembers((prevMembers) => [
      ...prevMembers,
      {
        managementTeamNameSurname: "",
        managementTeamPosition: "",
        managementTeamShortDescription: "",
        managementTeamExperience: "",
        error: false,
      },
    ]);
  };

  const deleteTeamMember = (index) => {
    setTeamMembers((prevMembers) => prevMembers.filter((_, i) => i !== index));
  };

  const checkErrors = () => {
    let hasErrors = false;
    const updatedMembers = teamMembers.map((member) => {
      if (
        !member.managementTeamNameSurname ||
        !member.managementTeamPosition ||
        !member.managementTeamShortDescription ||
        !member.managementTeamExperience
      ) {
        hasErrors = true;
        return { ...member, error: true };
      }
      return { ...member, error: false };
    });

    if (hasErrors) {
      setTeamMembers(updatedMembers);
    }

    return !hasErrors;
  };

  const validate = () => {
    return teamMembers.every(
      (member) =>
        member.managementTeamNameSurname &&
        member.managementTeamPosition &&
        member.managementTeamShortDescription &&
        member.managementTeamExperience,
    );
  };

  async function onNextHandle() {
    if (!validate()) {
      return "strategy/personnel_team";
    }
    await setStrategyManagementTeam({
      topManagers: teamMembers.map(({ error, ...rest }) => rest),
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await nextSection(
            strategyManagementTeamObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });

    return "strategy/personnel_team";
  }

  async function onGenerateHandle() {
    checkErrors();
    if (!validate()) return;

    await setStrategyManagementTeam({
      topManagers: teamMembers.map(({ error, ...rest }) => rest),
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await generateSection(
            strategyManagementTeamObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });

    return sectionName;
  }

  async function onSave() {
    await setStrategyManagementTeam({
      topManagers: teamMembers.map(({ error, ...rest }) => rest),
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await saveSectionInputs(
            strategyManagementTeamObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });
  }

  return (
    <>
      <QuestionWrapper
        isAnswered={validate()}
        question={"List your management team members."}
        description={
          "Add the name, position, short description, and experience of each team member."
        }
      >
        {isLoadingTeam ? (
          <CircleLoader />
        ) : (
          <div className="flex flex-col gap-2">
            <div className="grid grid-cols-4 gap-3 auto-rows-fr pr-4">
              <span className="text-18 font-bold">Name & Surname</span>
              <span className="text-18 font-bold">Position</span>
              <span className="text-18 font-bold">Short description</span>
              <span className="text-18 font-bold">Experience (years)</span>
            </div>
            {teamMembers.map((member, index) => (
              <div
                className="grid grid-cols-4 gap-3 auto-rows-fr min-h-16 relative pr-4"
                key={`member-${index}`}
              >
                <Input
                  placeholder="Type here"
                  error={member.error && !member.managementTeamNameSurname}
                  className={`h-full`}
                  value={member.managementTeamNameSurname}
                  onChange={(e) =>
                    handleInputChange(index, "managementTeamNameSurname", e.target.value)
                  }
                />
                <Input
                  placeholder="Type here"
                  error={member.error && !member.managementTeamPosition}
                  className={`h-full`}
                  value={member.managementTeamPosition}
                  onChange={(e) =>
                    handleInputChange(index, "managementTeamPosition", e.target.value)
                  }
                />
                <Input
                  placeholder="Type here"
                  error={member.error && !member.managementTeamShortDescription}
                  className={`h-full`}
                  value={member.managementTeamShortDescription}
                  onChange={(e) =>
                    handleInputChange(index, "managementTeamShortDescription", e.target.value)
                  }
                />
                <Input
                  placeholder="Type here"
                  error={member.error && !member.managementTeamExperience}
                  className={`h-full`}
                  value={member.managementTeamExperience}
                  type={"number"}
                  onChange={(e) =>
                    handleInputChange(index, "managementTeamExperience", e.target.value)
                  }
                />
                {teamMembers.length > 1 && (
                  <div
                    className="absolute top-1 right-0 cursor-pointer"
                    onClick={() => deleteTeamMember(index)}
                  >
                    {iconClose}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        <Button className={"w-38"} rounded outline onClick={addTeamMember}>
          Add More
        </Button>
      </QuestionWrapper>
      <SectionButtons onNext={onNextHandle} onGenerate={onGenerateHandle} />

      <FormNavigation onSave={onSave} />
    </>
  );
}

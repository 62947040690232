import React from "react";
import NavigationActions from "../../../../components/containers/NavigationActions";
import { FinancialBlank } from "../../../../components/containers/FinancialBlank";
import RevenueDetails from "./RevenueDetails";
import FinanceTableMain from "../../../../components/tables/FinanceTable/FinanceTableMain";
import { greenPallete, bluePallete } from "./components/ColorPalette";
import FinancialMainCharts from "../components/Charts/FinancialMainCharts";
import { PermissionTypes } from "../../../../components/constants/permissions";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import CollapseTableComponent from "../../../../components/tables/FinanceTable/components/CollapseTableComponent";
import { ExpandCollapseTablesID } from "../../../../data/Finance/constants";
import ReportExport from "../../../../helpers/ReportExport";

class RevenueComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      addNew: false,
      editRevenue: null,
      lines: [],
      rows: [],
      columns: [],
      periodHeaders: [],
      monthDatasets: [],
      yearDatasets: [],
      monthValues: [],
      yearValues: [],
      chartKey: "chart_123421",
      showMonthly: true,
      step: null,
    };

    this.toggleActualsObj = {
      toggled: false,
      callBack: this.updateCollapsedValue,
    };

    this.EditPermission = global.Modeliks.UserPermissions[PermissionTypes.Financials] == 0;

    this.revenues = [];
    this.totalsRows = [];
    this.colors = bluePallete;
    this.colorsYear = greenPallete;
    this.title = "Revenue";
  }
  updateCollapsedValue = () => {
    this.toggleActualsObj.toggled = !this.toggleActualsObj.toggled;
    this.setState({ key: new Date().getTime() });
  };

  componentDidMount() {
    this.setData();
  }

  setData = (revenues) => {
    this.revenues = global.Modeliks.RevenuesStore;
    this.revenueTotals = global.Modeliks.RevenuesStore.dataGridRows.revenueTotals;
    this.revenueTotalsGrowth = global.Modeliks.RevenuesStore.dataGridRows.revenueTotalsGrowth;
    this.totalsRows = global.Modeliks.RevenuesStore.dataGridRows.totalsRows;
    this.gridRows = global.Modeliks.RevenuesStore.dataGridRows.gridRows;

    setTimeout(() => {
      this.props.handleStreamLoad && this.props.handleStreamLoad(false);
      this.setState({
        loading: false,
      });
    }, this.props.time);
  };

  handleOnEditRevenue = (row) => {
    this.setState({ editRevenue: row });
  };

  handleOnEditRevenueChildren = (revenue, step) => {
    this.setState({ editRevenue: revenue, step });
  };

  handleOnDeleteRevenue = (row) => {};

  handleAddNewRevenue = () => {
    this.setState({ addNew: true });
  };

  handleCloseAddNewRevenue = (
    clickEvent = false,
    backDropClick = false,
    shouldRefreshData = false,
  ) => {
    this.state.addNew = false;
    this.state.editRevenue = false;
    this.state.step = null;

    if (shouldRefreshData) {
      this.revenues = [];
      this.setState({ loading: true });
      this.props.handleStreamLoad && this.props.handleStreamLoad(true);
      global.Modeliks.LoadDrivers(this.setData);
    } else {
      this.setState({ loading: false });
    }
  };
  handleGridDataChanged = (items) => {
    this.forceUpdate();

    items.forEach((item) => {
      setTimeout(() => {
        if (item.Save) {
          setTimeout(item.Save, 100);
        }
      }, 1);
    });
    this.forceUpdate();
  };
  handeMoveRow = (rows) => {
    this.gridRows = rows;
    this.forceUpdate();
  };
  makeDuplicate = (row) => {
    const revenue = global.Modeliks.RevenuesStore.getItem(row.data.Ref_ID);
    if (!revenue) {
      return null;
    }
  };
  showLoading = (callback) => {
    this.props.handleStreamLoad && this.props.handleStreamLoad(true);
    this.setState({ loading: true }, callback());
  };
  exportSingleReport = (title, headers) => {
    return ReportExport.singleSectionExport(
      [...this.gridRows, ...this.totalsRows],
      title,
      headers,
      [...this.gridRows.map((row) => row.header)],
      ["Total Revenue"],
    );
  };

  exportAllReports = (headers) => {
    return ReportExport.AllReports(headers);
  };

  render() {
    if (this.state.loading) {
      return (
        <div className="loader_chart_container">
          <CircleLoader />
        </div>
      );
    }

    if (this.revenues.length == 0) {
      return (
        <React.Fragment>
          <NavigationActions
            label="Revenue"
            nextLink="/forecast/costofsales"
            nextLinkText={"Cost of Goods Sold"}
            disablePrev={true}
            showStreamNavigation={true}
            disabled={this.EditPermission}
            buttonLabel="Add Revenue Stream"
            className={"blank"}
            onClick={this.handleAddNewRevenue}
          />
          <FinancialBlank
            onAdd={this.handleAddNewRevenue}
            headerText="Add your first Revenue Stream"
            buttonLabel="Add Revenue Stream"
            disabled={this.EditPermission}
          />
          {(this.state.addNew || this.state.editRevenue) && (
            <RevenueDetails
              totalDriver={this.revenueTotals}
              step={this.state.step}
              onClose={this.handleCloseAddNewRevenue}
              showLoading={this.showLoading}
            />
          )}
          {/*{this.state.addNew && <RevenueDetails  showLoading={this.showLoading} step={this.state.step} onClose={this.handleCloseAddNewRevenue}/>}*/}
        </React.Fragment>
      );
    } else {
      const headers = this.state.showMonthly
        ? this.props.financialProps.gridPeriodHeaders
        : this.props.financialProps.gridPeriodHeaders_Years;

      return (
        <div className="pfr_main_revenue_content streams forecast_streams">
          <NavigationActions
            label="Revenue"
            nextLink="/forecast/costofsales"
            nextLinkText={"Cost of Goods Sold"}
            disablePrev={true}
            showStreamNavigation={true}
            disabled={this.EditPermission}
            buttonLabel="Add Revenue Stream"
            onClick={this.handleAddNewRevenue}
          />
          <div className={"pfr_main_cont revenue"}>
            <FinancialMainCharts
              totals={this.revenueTotals}
              periodHeaders={this.props.financialProps.chartPeriodHeaders}
              revenues={this.revenues.map((revenue) => revenue.Totals)}
              breakdownName="RevenueName"
              useShowHideCurrency={true}
              updateTable={() => this.forceUpdate()}
              headers={this.props.financialProps.gridPeriodHeaders}
              growthDriver={this.revenueTotalsGrowth}
            />

            <div className="main_table_container psf_upper_table_cont ">
              {this.gridRows && (
                <div className="psf_reports_export_pdf " id="report_table">
                  <CollapseTableComponent
                    toggleActuals={
                      global.Modeliks.PERMISSIONS.Dashboards.restrictions.overAll.active
                        ? this.toggleActualsObj
                        : false
                    }
                    title="Forecast"
                    showMonthly={this.state.showMonthly}
                    showMonthlyOnChange={() =>
                      this.setState({ showMonthly: !this.state.showMonthly })
                    }
                    useCustomizableTable={true}
                    showHideCurrency={true}
                    updateTable={() => this.forceUpdate()}
                    disabledEdit={this.EditPermission}
                    showSectionExcelExport
                    exportSectionReport={() => this.exportSingleReport("Revenues", headers)}
                  >
                    <FinanceTableMain
                      rows={this.gridRows}
                      totalsRows={this.totalsRows}
                      headers={headers}
                      indexTab={0}
                      showRowHeaders={true}
                      disabledEdit={this.EditPermission}
                      useDateIndexes={true}
                      hasRenameDriverName={true}
                      useCustomizableTable={true}
                      useShowHideCurrency={true}
                      useExpandCollapseStore={true}
                      ExpandCollapseID={ExpandCollapseTablesID.Revenue}
                      changeDriverName={true}
                      handleReload={() => this.handleCloseAddNewRevenue(false, false, true)}
                      handleOnEdit={this.handleOnEditRevenue}
                      unitDisplay={true}
                      wrapperClassName={"psf_main_streams_wrapper_div"}
                      makeDuplicate={() => console.log("this.makeDuplicate")}
                      checkIsDividedWithZero={true}
                      // handeMoveRow={this.handeMoveRow}
                      // useDrag={true}
                      disabledMove={true}
                      title="Revenues"
                      onDataChanged={this.handleGridDataChanged}
                    />
                  </CollapseTableComponent>
                </div>
              )}
            </div>

            {global.Modeliks.PERMISSIONS.Dashboards.restrictions.overAll.active && (
              <div className="main_table_container psf_upper_table_cont ">
                {this.gridRows && (
                  <div className="psf_reports_export_pdf " id="report_table">
                    <CollapseTableComponent
                      title="Actuals"
                      showActualTitle={true}
                      showMonthlyOnChange={() =>
                        this.setState({ showMonthly: !this.state.showMonthly })
                      }
                      showMonthly={this.state.showMonthly}
                      useCustomizableTable={true}
                      updateTable={() => this.forceUpdate()}
                      disabledEdit={this.EditPermission}
                      showHideCurrency={true}
                      toggleActualsObj={this.toggleActualsObj}
                      collapsed={!this.toggleActualsObj.toggled}
                    >
                      <FinanceTableMain
                        rows={this.gridRows}
                        totalsRows={this.totalsRows}
                        headers={
                          this.state.showMonthly
                            ? this.props.financialProps.gridPeriodHeaders_Actual
                            : this.props.financialProps.gridPeriodHeaders_Years_Actual
                        }
                        indexTab={0}
                        showRowHeaders={true}
                        disabledEdit={true}
                        useDateIndexes={true}
                        useActual={true}
                        useExpandCollapseStore={true}
                        ExpandCollapseID={`actuals_${ExpandCollapseTablesID.Revenue}`}
                        useDashboards={true}
                        useShowHideCurrency={true}
                        useCustomizableTable={true}
                        wrapperClassName={"psf_main_streams_wrapper_div"}
                        disabledMove={true}
                        // wrapperClassName={'max_height'}
                        title="Actual"
                        unitDisplay={true}
                        makeDuplicate={() => console.log("this.makeDuplicate")}
                        // handeMoveRow={this.handeMoveRow}
                        // useDrag={true}
                      />
                    </CollapseTableComponent>
                  </div>
                )}
              </div>
            )}
          </div>
          {(this.state.addNew || this.state.editRevenue) && (
            <RevenueDetails
              totalDriver={this.revenueTotals}
              step={this.state.step}
              revenue={this.state.editRevenue}
              onClose={this.handleCloseAddNewRevenue}
              showLoading={this.showLoading}
            />
          )}
        </div>
      );
    }
  }
}

export default RevenueComponent;

import React from "react";
import Button from "../../../../../components/actions/Button";
import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { default as MuiButton } from "@mui/material/Button";
import "../../../../Admin/Dashboards/components/dashboardsBlankAdmin.scss";
import { ArrowDown, ExportIcon } from "../../../../../components/icons/svgIcons";
import CircleLoader from "../../../../../components/Loaders/CircleLoader/CircleLoader";
import { Label } from "../../../../../components/typography/label/Label";
import DatePickerYM from "../../../../../components/actions/DatePickers/DatePickerYM";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import "../index.scss";
import ShowHideMenu from "./SowHideMenu";
import ExportPortal from "./ExportPortal";
import ExportReport from "../../../Financials/components/Export/ExportReport";
import DropDownViewMenu from "../../components/DropDownViewMenu";
import { NumberFormatTypes } from "../../../../../components/constants/charts";
import MaterialSelect from "../../../../../components/actions/SelectM";
import { AccessControl } from "../../../../../data/Permissions/AccessControl";
import { DateGetMonth, DateGetYear, DateMonthChange } from "../../../../../helpers/DateSettings";
import ButtonMui from "../../../../../components/buttons/buttonMui/buttonMui";
import UpdatePlan from "../../../../../components/dialogs/LimitDialogs/UpdatePlan";
import {
  tableConfig,
  tableConfigTypes,
  tableObject,
} from "../../../../../components/tables/FinanceTable/components/constants";
import LocalStorage from "../../../../../helpers/LocalStorage";

export default class FinancialNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: new Date().getTime(),
      loading: false,
      minDate: props.financialMonths.minDate,
      maxDate: props.financialMonths.maxDate,
      from: props.financialMonths.startMonth,
      to: props.financialMonths.endMonth,
      startMonth: props.financialMonths.startMonth,
      endMonth: props.financialMonths.endMonth,
      startYear: props.financialMonths.startYear,
      endYear: props.financialMonths.endYear,
      maxYear: props.financialMonths.maxYear,
      minYear: props.financialMonths.minYear,
      view: 1,
    };
  }

  getMonth = (Year, Month) => DateGetMonth(this.props.months, Month, Year);

  getYear = (Header) => DateGetYear(this.props.years, Header);

  changeFromDate = (e) => {
    const Year = e.getFullYear();
    const Month = e.getMonth();
    const MonthParsed = Month + 1;
    const startMonthOrder = this.getMonth(Year, Month);
    if (startMonthOrder) {
      this.props.financialMonths.startMonth = `${Year}-${MonthParsed}`;
      this.props.financialMonths.startMonthOrder = startMonthOrder.Order;
      this.state.startMonth = `${Year}-${MonthParsed}`;
      this.props.getMonths(true);
      this.forceUpdate();
    }
  };

  changeToDate = (e) => {
    const Year = e.getFullYear();
    const Month = e.getMonth();
    const MonthParsed = Month + 1;
    const endMonthOrder = this.getMonth(Year, Month);

    if (endMonthOrder) {
      this.props.financialMonths.endMonth = `${Year}-${MonthParsed}`;
      this.props.financialMonths.endMonthOrder = endMonthOrder.Order;
      this.state.endMonth = `${Year}-${MonthParsed}`;
      this.props.getMonths(true);
      this.forceUpdate();
    }
  };

  changeFromDateYear = (e) => {
    const Year = e.getFullYear();
    const startYearOrder = this.getYear(Year);
    if (startYearOrder) {
      this.props.financialMonths.startYear = `${startYearOrder.Header}`;
      this.props.financialMonths.startYearOrder = startYearOrder.Order;
      this.state.startYear = `${startYearOrder.Header}`;
      this.props.getYears(true);
      this.forceUpdate();
    }
  };

  changeToDateYear = (e) => {
    const Year = e.getFullYear();
    const endYearOrder = this.getYear(Year);
    if (endYearOrder) {
      this.props.financialMonths.endYear = `${endYearOrder.Header}`;
      this.props.financialMonths.endYearOrder = endYearOrder.Order;
      this.state.endYear = `${endYearOrder.Header}`;
      this.props.getYears(true);
      this.forceUpdate();
    }
  };

  getReportName = () => {
    const { selectedSubsection } = this.props;
    if (selectedSubsection.name) {
      return selectedSubsection.name;
    }
    if (selectedSubsection.Name) {
      return selectedSubsection.Name;
    }
    return null;
  };

  getPDFReportType = () => {
    if (this.props.pdfReportType) {
      return this.props.pdfReportType;
    }
  };

  getTypeName = () => {
    let index = Object.values(this.props.types).findIndex((c) => c == this.props.selectedType);
    if (index !== -1) {
      if (this.props.types["Comparative"] === this.props.selectedType) {
        return "variance analysis";
      }
      return Object.keys(this.props.types)[index].toLowerCase();
    }
    return "";
  };

  changeUnitDisplay = (e) => {
    global.Modeliks.DashboardSettings.Value.financialDates.numberFormatType = e.target.value;
    this.props.financialMonths.numberFormatType = e.target.value;
    LocalStorage.updateValueFromObject(
      tableObject,
      tableConfig,
      tableConfigTypes.displayOfDecimals,
      NumberFormatTypes[e.target.value].numDecimals,
    );
    this.props.saveSettings("numberFormatType");
  };

  toggleUpdatePlan = () => {
    this.setState({ updatePlan: !this.state.updatePlan });
  };

  formatDate = (date, before = false) => {
    if (before) {
      let formattedDate;

      if (date.split("-").length === 2) {
        let [year, month] = date.split("-");
        let fullDate = new Date(`${year}-${month}-01`); // Start from the 1st day of the month
        fullDate.setMonth(fullDate.getMonth() - 1); // Subtract one month

        year = fullDate.getFullYear();
        month = String(fullDate.getMonth() + 1).padStart(2, "0"); // Adjust the month

        formattedDate = `${year}-${month}-29 00:00:00`; // Keep the day as 29
      } else {
        let fullDate = new Date(date);
        fullDate.setMonth(fullDate.getMonth() - 1); // Subtract one month

        let year = fullDate.getFullYear();
        let month = String(fullDate.getMonth() + 1).padStart(2, "0");
        let day = String(fullDate.getDate()).padStart(2, "0");

        formattedDate = `${year}-${month}-${day} 00:00:00`;
      }

      return formattedDate;
    }

    if (date.split("-").length === 2) {
      let month = `0${date.split("-")[1]}`;
      return `${date.split("-")[0]}-${month}-29 00:00:00`;
    }
    return date + ` 00:00:00`;
  };

  render() {
    const {
      minDate,
      maxDate,
      from,
      to,
      startMonth,
      endMonth,
      startYear,
      endYear,
      maxYear,
      minYear,
    } = this.state;
    const {
      selectedType,
      types,
      changeType,
      changeColumns,
      getHeaders,
      selectedSubsection,
      exportReport,
      title,
      periods,
      isTmpData,
      exportAllReports,
      rows,
      useActual,
      pdfReportType,
    } = this.props;
    const isAnnual = selectedType === types.Annual;

    const minDateForMozilla = this.formatDate(minDate);
    const maxDateForMozilla = this.formatDate(maxDate);
    const startMonthDateForMozillaValue = this.formatDate(startMonth);
    const startMonthDateBeforeForMozillaValue = this.formatDate(startMonth, true);
    const endMonthDateForMozillaValue = this.formatDate(endMonth);

    return (
      <>
        {this.state.updatePlan && (
          <UpdatePlan
            open={true}
            handleClose={this.toggleUpdatePlan}
            onCancel={this.toggleUpdatePlan}
          />
        )}
        <ExportPortal>
          <AccessControl
            allowedPermissions={[
              {
                [global.Modeliks.PERMISSIONS.Dashboards.key]:
                  global.Modeliks.PERMISSIONS.Dashboards.restrictions.ExportExcel.key,
              },
              {
                [global.Modeliks.PERMISSIONS.Dashboards.key]:
                  global.Modeliks.PERMISSIONS.Dashboards.restrictions.ExportPDF.key,
              },
            ]}
            renderNoAccess={
              <ButtonMui
                onClick={this.toggleUpdatePlan}
                label={"Export"}
                color={"grey"}
                variant={"text"}
                style={{ fontWeight: 600 }}
                width={100}
                endIcon={<ExportIcon />}
              />
            }
          >
            <ExportReport
              isDashboards
              IsExportAllReports={exportAllReports}
              disabled={!global.Modeliks.PERMISSIONS.Dashboards.restrictions.edit.active}
              useActual={useActual}
              pdfSuffix={this.getTypeName()}
              pdfReportType={this.getPDFReportType()}
              reportName={title}
              rows={rows}
              periods={periods}
              showExcelExport={true}
              showExcelExportAllReports={true}
              exportAllReports={(title) => exportAllReports(periods, isTmpData, title)}
              exportReport={(title) =>
                exportReport(selectedSubsection.key, periods, isTmpData, title)
              }
            />
          </AccessControl>
        </ExportPortal>

        <div
          key={"frm_" + startYear + startMonth + startYear}
          className={`psfac_actuals_single_filter vertical align_label`}
        >
          <DatePickerYM
            label="From"
            minDate={isAnnual ? new Date(minYear) : new Date(minDateForMozilla)}
            maxDate={isAnnual ? new Date(maxYear) : new Date(maxDateForMozilla)}
            views={isAnnual ? ["year"] : ["year", "month"]}
            value={isAnnual ? startYear.toString().padStart(2, "0") : startMonthDateForMozillaValue}
            endDateValue={isAnnual ? endYear : endMonthDateForMozillaValue}
            doMultipleDateValidation={true}
            monthValidation={!isAnnual}
            onChange={isAnnual ? this.changeFromDateYear : this.changeFromDate}
          />
        </div>
        <div
          key={"to_" + endMonth + endYear}
          className={`psfac_actuals_single_filter vertical align_label`}
        >
          <DatePickerYM
            label="To"
            minDate={isAnnual ? new Date(minYear) : new Date(minDateForMozilla)}
            maxDate={isAnnual ? new Date(maxYear) : new Date(maxDateForMozilla)}
            views={isAnnual ? ["year"] : ["year", "month"]}
            value={isAnnual ? endYear.toString().padStart(2, "0") : endMonthDateForMozillaValue}
            startDateValue={isAnnual ? startYear : startMonthDateBeforeForMozillaValue}
            doMultipleDateValidation={true}
            monthValidation={!isAnnual}
            onChange={isAnnual ? this.changeToDateYear : this.changeToDate}
          />
        </div>
        <div
          key={"rep" + endMonth + endYear}
          className={`psfac_actuals_single_filter vertical align_label apply_hidden`}
        >
          <Label color={"black"} size="label3" label={"Report Frequency"} />
          <Tabs
            value={selectedType}
            textColor="secondary"
            className="psdf_navigation_menu_buttons apply_color apply_hidden"
            onChange={changeType}
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            {Object.entries(types).map(([key, value]) => {
              return (
                <Tab
                  key={`${key} ${value}`}
                  label={key.replace(/([A-Z])/g, " $1").trim()}
                  value={value}
                  className="psdf_navigation_menu_tab"
                />
              );
            })}
          </Tabs>
        </div>
        <div className="psfac_actuals_single_filter vertical align_label">
          <Label
            style={{ whiteSpace: "nowrap !important" }}
            color={"black"}
            size="label3"
            label={"Unit display"}
          />
          <MaterialSelect
            name="default_level"
            value={global.Modeliks.DashboardSettings.Value.financialDates.numberFormatType}
            onChange={this.changeUnitDisplay}
            width="150px"
            options={Object.values(NumberFormatTypes).map((c) => {
              return {
                description: `${c.name.length < 2 ? global.Modeliks.CompanyInfo.Currency.value : ""} ${c.name}`,
                value: c.key,
              };
            })}
          />
        </div>
        <DropDownViewMenu
          types={types}
          className="financials_navigation_dropdown"
          value={selectedType}
          onChange={changeType}
        />
        {selectedType === types.Comparative && (
          <ShowHideMenu
            isValuation={false}
            isCompany={false}
            changeColumns={changeColumns}
            getHeaders={getHeaders}
          />
        )}
      </>
    );
  }
}

import React, { Component, useContext } from "react";
import PropTypes from "prop-types";
import {
  ChartDataTypes,
  ChartTypes,
  ComparisonPeriod,
  ReportFrequency,
  StreamTypes,
  ChartWidth,
  NumberFormatTypes,
  ChangeableChartTypes,
  NumberOfMonths,
  ChartDescriptions,
} from "../../../components/constants/charts";
import request from "superagent";
import MxMath from "../../../data/MxMath";
import { DriverCategories, UnitTypes } from "../../../data/Finance/CalculatedDriver/constants";
import "./chart.scss";
import ChartActual from "./ChartActual";
import { Theme } from "../../../theme/styles/theme";
import Button from "@mui/material/Button";
import {
  ReusableMenu,
  ReusableMenuItem,
} from "../../../components/menus/ReusableMenu/ReusableMenu";
import CalculatedDriver from "../../../data/Finance/CalculatedDriver";
import { SettingsIcon, StarChartIcon } from "../../../components/icons/svgIcons";
import IconButton from "@mui/material/IconButton";
import { Menu, MenuItem, FocusableItem } from "@szhsin/react-menu";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Assets from "../../../data/Finance/Assets";
import Financing from "../../../data/Finance/Financing";
import { ReportsTypes } from "../../../data/Finance/Reports/constants";
import { MyContext } from "../../../pages/Secure/Dashboards/ManagementReports";
import Mx_Tooltip_Hover from "../../../components/icons/Mx_Tooltip_Hover";
import {stringFormatter} from "./utils/stringFormatter";

const WaterfallChartTypes = {
  SingleComparisonPeriod: "SingleComparisonPeriod",
  DeltaWaterfall: "DeltaWaterfall",
};

window.rgbToRgba = (event) => {
  return "rgba(" + event.rgb.r + "," + event.rgb.g + "," + event.rgb.b + "," + event.rgb.a + ")";
};

export default class Chart extends Component {
  subtotalIndexes = [];
  comparisonPeriodTotals = {};
  seriesTotals = {};
  chartValueSums = [];
  chartClassName = null;
  chartType = null;
  currentDateRange = null;
  Name = null;
  ID = null;
  chartContainerRef = null;
  UnitType = null;
  chartOptions = {};
  chartData = {};
  chartConfig = {};
  chartRef = null;
  monthDataByDates = {};
  yearDataByDates = {};
  chartDatasets = [];
  imgSrc = null;

  firstDate = null;
  lastDate = null;

  chartTitleRef = null;
  currentColorIndex = 0;
  ChartRightPadding = 0;

  lastSavedBenchmarkValue = 0;
  lastSavedDetailedNumbers = false;
  lastSavedContribution = false;
  lastSavedBenchmark = false;
  lastSavedNumberOfMonths = 12;
  lastSavedBackgroundChartColor = false;
  lastSavedDatalabelsDecimalPoints = 0;

  constructor(props) {
    super(props);

    this.Name = props.Name ? props.Name : Chart.defaultProps.Name;
    this.ID = props.ID ? props.ID : null;

    this.setConfigs("chartOptions");
    this.setConfigs("chartData");
    this.setConfigs("chartConfig");

    if (props && props.chartType) {
      this.chartType = props.chartType;
    } else {
      this.chartType = Chart.defaultProps.chartType;
    }

    if (global.Modeliks.CompanyInfo) {
      this.chartDrivers = this.getChartDrivers();

      this.currentDateRange = this.getCurrentDateRange();

      this.indexData();
    }
    if (props.chartFunctionsObj) {
      props.chartFunctionsObj.changeCategories = this.changeCategories;
      props.chartFunctionsObj.changeSeries = this.changeSeries;
      props.chartFunctionsObj.changeReportFrequency = this.changeReportFrequency;
      props.chartFunctionsObj.changeGrowth = this.changeGrowth;
      props.chartFunctionsObj.rebuildChartData = this.rebuildChartData;
      props.chartFunctionsObj.setChartWidth = this.setChartWidth;
      props.chartFunctionsObj.changeChartWidth = this.changeChartWidth;
      props.chartFunctionsObj.getWaterFallType = this.getWaterFallType;
      props.chartFunctionsObj.changeComparisonPeriod = this.changeComparisonPeriod;
      props.chartFunctionsObj.handleColorChange = this.handleColorChange;
      props.chartFunctionsObj.handleSingleColorChange = this.handleSingleColorChange;
      props.chartFunctionsObj.handleChartDataOptionsChange = this.handleChartDataOptionsChange;
      props.chartFunctionsObj.handleChartOptionsChange = this.handleChartOptionsChange;
      props.chartFunctionsObj.getDataFromChart = this.getDataFromChart;
      props.chartFunctionsObj.SaveChart = this.SaveChart;
      props.chartFunctionsObj.getDataForObjSaving = this.getDataForObjSaving;
      props.chartFunctionsObj.getChartDatasets = this.getChartDatasets;
      props.chartFunctionsObj.getChartColorDatasets = this.getChartColorDatasets;
      props.chartFunctionsObj.onWeightedAverageChange = this.onWeightedAverageChange;
    }
    if (props.chartOptionsObj) {
      props.chartOptionsObj.chartOptions = this.chartOptions;
      props.chartOptionsObj.chartData = this.chartData;
      props.chartOptionsObj.chartConfig = this.chartConfig;
    }
    if (props.hasOwnProperty("chartConfigObj")) {
      props.chartConfigObj.width = this.chartConfig.width;
      props.chartConfigObj.name = this.chartConfig.name;
    }

    this.state = {
      key: "chart_dashboard_" + new Date().getTime(),
    };

    if (props.handleChartMount) {
      props.handleChartMount();
    }

    this.setChartRightPadding();
    this.lastSavedBenchmarkValue = this.chartOptions.benchmarkValue;
    this.lastSavedBenchmark = this.chartOptions.benchmark;
    this.lastSavedDatalabelsDecimalPoints = this.chartOptions.datalabelsDecimalPoints;
    this.lastSavedDetailedNumbers = this.chartOptions.showDetailedNumbers;
    this.lastSavedContribution = this.chartOptions.showContribution;
    this.lastSavedNumberOfMonths = this.chartOptions.numberOfMonths;
  }

  setDataManager = (DataManager) => {
    this.DataManager = DataManager;
  };

  setChartRightPadding() {
    if (this.chartOptions.benchmark) {
      const text = this.DataLabelFormat(this.chartOptions.benchmarkValue);
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      let textInfo = ctx.measureText(text);
      this.ChartRightPadding = textInfo.width * 3;
    } else {
      this.ChartRightPadding = 0;
    }
  }

  getChartRightPadding = () => {
    return this.ChartRightPadding;
  };

  setClassName() {
    if (this.props.hasOwnProperty("chartClassName")) {
      this.props.chartClassName.name = this.chartClassName;
    }
  }

  getChartLabels() {
    // if(this.chartOptions.benchmark){
    //     return [...this.chartLabels, ''];
    // }
    if (!global.Modeliks.CompanyInfo && this.props.snapShotData) {
      return this.props.snapShotData.labels;
    }

    return this.chartLabels;
  }

  horizontalDottedLine = {
    id: "horizontalDottedLine",
    beforeDatasetsDraw: (chart, args, options) => {
      const {
        ctx,
        chartArea: { top, right, bottom, left, width, height },
        scales: { x, y },
      } = chart;
      ctx.save();
      // draw line

      ctx.beginPath();
      ctx.setLineDash([10, 10]);
      ctx.moveTo(left, y.getPixelForValue(this.chartOptions.benchmarkValue));
      ctx.lineTo(
        width + this.getChartRightPadding(),
        y.getPixelForValue(this.chartOptions.benchmarkValue),
      );
      ctx.strokeStyle = "grey";
      ctx.stroke();
      // ctx.setLineDash([10, 5]);
      // ctx.strokeRect(left, y.getPixelForValue(50000), width, 0);
      ctx.restore();

      ctx.font = "12px Inter";
      ctx.fillStyle = "black";
      ctx.fillText(
        this.DataLabelFormat(this.chartOptions.benchmarkValue),
        width + this.getChartRightPadding(),
        y.getPixelForValue(this.chartOptions.benchmarkValue) - 8,
      );
      ctx.textAlign = "right";
      this.setChartRightPadding();
    },
  };

  startXLine = {
    id: "startXLine",
    beforeDatasetsDraw: (chart, args, options) => {
      const {
        ctx,
        chartArea: { top, right, bottom, left, width, height },
        scales: { x, y },
      } = chart;
      ctx.save();

      ctx.beginPath();
      ctx.moveTo(left, y.getPixelForValue(0));
      ctx.lineTo(width + this.getChartRightPadding(), y.getPixelForValue(0));
      ctx.strokeStyle = "#B9BFC3";
      ctx.stroke();
      ctx.restore();
    },
  };

  startYLine = {
    id: "startYLine",
    beforeDatasetsDraw: (chart, args, options) => {
      const {
        ctx,
        chartArea: { top, right, bottom, left, width, height },
        scales: { x, y },
      } = chart;
      ctx.save();

      ctx.beginPath();
      ctx.moveTo(x.getPixelForValue(0), top);
      ctx.lineTo(x.getPixelForValue(0), height + this.getTopPadding());
      ctx.strokeStyle = "#B9BFC3";
      ctx.stroke();
      ctx.restore();
    },
  };

  getWaterFallType = (props) => {
    if (props.chartConfig.waterFallType) {
      return props.chartConfig.waterFallType;
    }
    return WaterfallChartTypes.DeltaWaterfall;
  };

  getWeightedAverageDrivers = () => {
    let firstArr = this.chartData.mainDriverKeys
      .flatMap((key, index) => this.getRevenueFunc(key, index))
      .filter((c) => c != null);
    let secondArr = this.chartData.weightingDriverKeys
      .flatMap((key, index) => this.getRevenueFunc(key, index))
      .filter((c) => c != null);

    let finalArr = [];

    firstArr.map((driver1, index) => {
      if (driver1 && secondArr[index]) {
        finalArr.push(driver1);
        finalArr.push(secondArr[index]);
      }
    });

    return finalArr;
  };

  handleColorSet() {
    if (
      this.chartDatasets &&
      this.chartDatasets.length > 0 &&
      this.props.chartOptions &&
      this.props.chartOptions.hasOwnProperty("chartDatasetsColors")
    ) {
      this.chartDatasets[0].forEach((chartdataset, index) => {
        chartdataset.backgroundColor = this.props.chartOptions.chartDatasetsColors[index];
      });
    }
  }

  getChartDrivers = () => {
    if (
      this.chartOptions.isWeightedAverage &&
      ((this.chartData.series === ChartDataTypes.comparisonPeriod &&
        this.chartData.categories === ChartDataTypes.driver) ||
        (this.chartData.series === ChartDataTypes.driver &&
          this.chartData.categories === ChartDataTypes.comparisonPeriod))
    ) {
      // return [...this.chartData.mainDriverKeys.flatMap((key, index) => this.getRevenueFunc(key, index)), ...this.chartData.weightingDriverKeys.flatMap((key, index) => this.getRevenueFunc(key, index))].filter(c => c != null);
      return this.getWeightedAverageDrivers();
    }
    if (this.chartOptions.isWeightedAverage) {
      let Obj = {};

      Object.keys({ mainDriverKeys: "", weightingDriverKeys: "" }).forEach((driverLocationKey) => {
        Object.assign(Obj, {
          [driverLocationKey]: this.chartData[driverLocationKey]
            .flatMap((key, index) => this.getRevenueFunc(key, index))
            .filter((c) => c != null),
        });
      });

      return Object.values(Obj)[0]
        .map((driver, index) => {
          let driver2 = Object.values(Obj)[1][index];
          if (driver && driver2) {
            let newDriver = CalculatedDriver.createDriver(
              "TempDriver",
              null,
              "tmp_field",
              undefined,
              DriverCategories.Average,
              `${driver.DriverName} / ${driver2.DriverName}`,
              true,
            );
            newDriver.setFormula(`${driver.ID_f}/${driver2.ID_f}`);
            return newDriver;
          }
        })
        .filter((c) => !!c);
    }
    this.addTotalDrivers();
    return this.chartData.driverKeys
      .flatMap((key, index) => this.getRevenueFunc(key, index))
      .filter((c) => c != null);
  };

  setChartContainerRef = (ref) => {
    this.chartContainerRef = ref;
  };

  addTotalDrivers() {}

  setConfigs = (config, defaultProps = Chart.defaultProps) => {
    if (this[config]) {
      Object.keys(defaultProps[config]).forEach((key) => {
        if (this.props && this.props[config] && this.props[config].hasOwnProperty(key)) {
          this[config][key] = this.props[config][key];
        } else {
          this[config][key] = defaultProps[config][key];
        }
      });
    }
  };

  handleColorChange = (driverKey, colorKeys = [], callBack) => {
    if (colorKeys.length) {
      this.chartOptions.barColorKeys[driverKey] = colorKeys;
      this.buildData();
      this.chartRef.data.datasets = this.getData();
      this.chartRef.update();
      this.updateChart();
      callBack && callBack({ barColorKeys: this.chartOptions.barColorKeys });
    }
  };

  handleSingleColorChange = (color, callBack) => {
    this.chartOptions.singleColor = color;
    this.buildData();
    this.chartRef.data.datasets = this.getData();
    this.chartRef.update();
    this.updateChart();
    callBack && callBack({ singleColor: this.chartOptions.singleColor });
  };

  handleUpdateColor = (color, index, callBack) => {
    this.lastSavedBackgroundChartColor = true;
    this.chartDatasets[0][index].backgroundColor = this.chartDatasets[0][index].backgroundColor.map(
      (oldColor) => color,
    );
    this.saveCurrentSettings();
    this.updateChart();
    this.lastSavedBackgroundChartColor = false;
    callBack && callBack({ chartDatasets: this.chartDatasets });
  };

  handleResetColor = (callBack) => {
    this.lastSavedBackgroundChartColor = true;
    this.chartDatasets[0].forEach(
      (dataset, index) => (dataset.backgroundColor = this.getRandomColor(index)),
    );
    this.saveCurrentSettings();
    this.updateChart();
    this.lastSavedBackgroundChartColor = false;
    callBack && callBack({ chartDatasets: this.chartDatasets });
  };

  compareArraysForSameObj = (key, c) => {
    let isSame = true;

    StreamTypes[key].except.forEach((except) => {
      StreamTypes[key].exceptionKeys.forEach((exceptionKey) => {
        if (c[exceptionKey]) {
          if (c[exceptionKey] === except) {
            isSame = false;
          }
        }
      });
    });

    return isSame;
  };

  getRevenueFunc(key, index) {
    //calling Revenue.getRevenueTotals()
    if (StreamTypes[key]) {
      if (StreamTypes[key].hasOwnProperty("isCashFlow")) {
        let store = global.Modeliks.ReportsStore.find(
          (d) => d.ReportType === ReportsTypes.CashFlow,
        );
        if (store) {
          return store[StreamTypes[key].containingDriver]
            .getChildDrivers()
            .filter((c) => c.isValid);
        }
      }
      if (
        StreamTypes[key].hasOwnProperty("getData") &&
        StreamTypes[key].data.hasOwnProperty(StreamTypes[key].getData)
      ) {
        return StreamTypes[key].data[StreamTypes[key].getData].call();
      } else if (StreamTypes[key].hasOwnProperty("function")) {
        return this[StreamTypes[key].function].call();
      } else if (StreamTypes[key].hasOwnProperty("driversStore")) {
        return StreamTypes[key]["driversStore"].flatMap((store) =>
          global.Modeliks[store]
            .filter((c) => !StreamTypes[key].except || this.compareArraysForSameObj(key, c))
            .map((c) => c.Totals),
        );
      }
    } else {
      try {
        let obj = JSON.parse(key);
        let driver = null;
        if (obj.Ref_ID && obj.Ref_ID.toString().includes("CompanyScenarios")) {
          obj.Ref_ID = obj.Ref_ID.replace(
            "CompanyScenarios-$CS_ID-",
            `CompanyScenarios-${global.Modeliks.CompanyScenarioInfo.ID}-`,
          );
        }
        if (obj.Ref_Table) {
          driver = global.Modeliks.DriversStore.find(
            (d) =>
              d.Ref_Field == obj.Ref_Field &&
              d.Ref_ID == obj.Ref_ID &&
              d.Ref_Table == obj.Ref_Table,
          );
        } else if (obj.Ref_ID) {
          driver = global.Modeliks.DriversStore.find(
            (d) => d.Ref_Field == obj.Ref_Field && d.Ref_ID == obj.Ref_ID,
          );
        } else {
          driver = global.Modeliks.DriversStore.find((d) => d.Ref_Field == obj.Ref_Field);
        }
        return driver;
      } catch (e) {
        return global.Modeliks.DriversStore.find((d) => d.DriverName === key);
      }
    }
  }

  deleteUnusedDriverKey = (index) => {
    this.chartData.driverKeys.splice(index, 1);
  };

  getCurrentDateRange() {
    if (global.Modeliks.CompanyInfo) {
      if (this.props.dateRange) {
        return { ...this.props.dateRange, validDateFrom: this.props.dateRange.dateFrom };
      }
      if (this.chartData) {
        let date = {
          validDateFrom: this.getDateFrom(),
          dateFrom: this.getDateFrom(),
          dateTo: {
            month: this.getMonthDatesAll().find(
              (c) => c.Month == this.chartData.dateTo.month && c.Year == this.chartData.dateTo.year,
            )
              ? this.getMonthDatesAll().find(
                  (c) =>
                    c.Month == this.chartData.dateTo.month && c.Year == this.chartData.dateTo.year,
                )
              : this.currentDateRange.dateTo.month,
            year: this.getYearDatesAll().find((c) => c.Year == this.chartData.dateTo.year)
              ? this.getYearDatesAll().find((c) => c.Year == this.chartData.dateTo.year)
              : this.currentDateRange.dateTo.year,
          },
        };
        return date;
      }
    }
    return null;
  }

  getDateFrom = () => {
    if (this.chartData.dateFrom.month && this.chartData.dateFrom.year) {
      return {
        month: this.getMonthDatesAll().find(
          (c) => c.Month == this.chartData.dateFrom.month && c.Year == this.chartData.dateFrom.year,
        )
          ? this.getMonthDatesAll().find(
              (c) =>
                c.Month == this.chartData.dateFrom.month && c.Year == this.chartData.dateFrom.year,
            )
          : this.getCompanyMonth(),
        year: this.getYearDatesAll().find((c) => c.Year == this.chartData.dateFrom.year)
          ? this.getYearDatesAll().find((c) => c.Year == this.chartData.dateFrom.year)
          : this.getCompanyYear(),
      };
    } else {
      return {
        month: this.getCompanyMonth(),
        year: this.getCompanyYear(),
      };
    }
  };

  getCompanyMonth = (
    datesArray = this.getMonthDatesAll(),
    dateKey = "Month",
    companyDateKey = "StartMonth",
  ) => {
    return datesArray.find(
      (c) =>
        c.Month == global.Modeliks.CompanyInfo.StartMonth &&
        c.Year == global.Modeliks.CompanyInfo.StartYear,
    );
  };

  getCompanyYear = (
    datesArray = this.getYearDatesAll(),
    dateKey = "Year",
    companyDateKey = "StartYear",
  ) => {
    return datesArray.find((c) => c.Year == global.Modeliks.CompanyInfo.StartYear);
  };

  getMonthDatesAll = () => {
    return this.props.months;
  };

  getYearDatesAll = () => {
    return this.props.years;
  };

  changeCategories = (category) => {
    this.chartData.categories = category;
    if (this.chartData.series != this.chartData.categories) {
      this.chartDrivers = this.getChartDrivers();
      this.indexData();
      this.buildData();
      this.chartRef.data.datasets = this.getData();
      this.chartRef.data.labels = this.chartLabels;
      this.chartRef.update();
    }
  };

  getPlugins() {
    return [this.horizontalDottedLine, this.startXLine];
  }

  changeSeries = (series) => {
    this.chartData.series = series;
    if (this.chartData.series != this.chartData.categories) {
      this.chartDrivers = this.getChartDrivers();
      this.indexData();
      this.buildData();
      this.chartRef.data.datasets = this.getData();
      this.chartRef.data.labels = this.chartLabels;
      this.chartRef.update();
    }
  };

  changeReportFrequency = (reportFrequency) => {
    this.chartData.reportFrequency = reportFrequency;
    this.buildData();
    this.chartRef.data.datasets = this.getData();
    this.chartRef.data.labels = this.chartLabels;
    this.chartRef.update();
  };

  initialiseDateIndex = (chartDriverType, date, obj, isQuarterly = false) => {
    if (!obj[chartDriverType]) {
      obj[chartDriverType] = {};
    }
    Object.assign(obj[chartDriverType], {
      [date.dateIndex]: {
        Drivers: [],
        Date: date,
      },
    });
  };

  getValue(item, sufix, key = "Value") {
    if (item.isChartSubtotal) {
      return null;
    }
    let newItem = item.getItemByDateSufix(sufix);
    if (newItem) {
      if (isNaN(newItem[key])) {
        return 0;
      }
      if (
        this.chartOptions.isWeightedAverage &&
        ((this.chartData.series === ChartDataTypes.comparisonPeriod &&
          this.chartData.categories === ChartDataTypes.driver) ||
          (this.chartData.series === ChartDataTypes.driver &&
            this.chartData.categories === ChartDataTypes.comparisonPeriod))
      ) {
        return newItem[key];
      }
      if (
        this.chartOptions.isWeightedAverage &&
        this.chartOptions.unitType === UnitTypes.Percentage
      ) {
        return newItem[key] * 100;
      }
      return newItem[key];
    }
    return null;
  }

  indexData = () => {
    this.monthDataByDates = {};
    this.yearDataByDates = {};
    if (this.chartDrivers.length) {
      this.UnitType = this.chartDrivers[0].UnitType;
    }

    const UnitType = this.getUnitType();

    Object.values(ComparisonPeriod).forEach((chartDriverType) => {
      this.getMonthDatesAll().forEach((date) => {
        this.initialiseDateIndex(chartDriverType, date, this.monthDataByDates);
      });
      this.getYearDatesAll().forEach((date) =>
        this.initialiseDateIndex(chartDriverType, date, this.yearDataByDates),
      );
    });

    this.chartDrivers.forEach((driver) => {
      if (driver) {
        if (driver.UnitType && driver.UnitType != this.UnitType && !driver.isChartSubtotal) {
          console.log(
            "unit type is different between drivers",
            driver.UnitType,
            this.UnitType,
            driver.isChartSubtotal,
          );
        }
      }
      this.getMonthDatesAll().forEach((date) => {
        let tempDriver = {
          DriverValue: this.getValue(driver, date.sufix, "Actual"),
          DriverName: driver.DriverName,
          isChartSubtotal: driver.isChartSubtotal,
          isExpense: driver.isExpense,
          LastPeriodOnly: driver.LastPeriodOnly,
        };
        this.monthDataByDates[ComparisonPeriod.actual][date.dateIndex].Drivers.push(tempDriver);
        this.monthDataByDates[ComparisonPeriod.previousPeriod][date.dateIndex].Drivers.push(
          tempDriver,
        );
        this.monthDataByDates[ComparisonPeriod.previousYear][date.dateIndex].Drivers.push(
          tempDriver,
        );
        if (
          date.Order <=
          global.Modeliks.DateHelper.months_before_actual[
            global.Modeliks.DateHelper.months_before_actual.length - 1
          ].Order
        ) {
          this.monthDataByDates[ComparisonPeriod.actualForecast][date.dateIndex].Drivers.push(
            tempDriver,
          );
        }
      });
      this.getYearDatesAll().forEach((date) => {
        let tempDriver = {
          DriverValue: this.getValue(driver, date.sufix, "Actual"),
          DriverName: driver.DriverName,
          isChartSubtotal: driver.isChartSubtotal,
          LastPeriodOnly: driver.LastPeriodOnly,
        };
        this.yearDataByDates[ComparisonPeriod.actual][date.dateIndex].Drivers.push(tempDriver);
        if (
          date.Order <=
          global.Modeliks.DateHelper.years_before_actual[
            global.Modeliks.DateHelper.years_before_actual.length - 1
          ].Order
        ) {
          this.yearDataByDates[ComparisonPeriod.actualForecast][date.dateIndex].Drivers.push(
            tempDriver,
          );
        }
      });
    });

    this.chartDrivers.forEach((driver) => {
      this.getMonthDatesAll().forEach((date) => {
        let tempDriver = {
          DriverValue: this.getValue(driver, date.sufix),
          DriverName: driver.DriverName,
          isChartSubtotal: driver.isChartSubtotal,
          isExpense: driver.isExpense,
          LastPeriodOnly: driver.LastPeriodOnly,
        };
        this.monthDataByDates[ComparisonPeriod.forecast][date.dateIndex].Drivers.push(tempDriver);
        if (
          date.Order >
          global.Modeliks.DateHelper.months_before_actual[
            global.Modeliks.DateHelper.months_before_actual.length - 1
          ].Order
        ) {
          this.monthDataByDates[ComparisonPeriod.actualForecast][date.dateIndex].Drivers.push(
            tempDriver,
          );
        }
      });
      this.getYearDatesAll().forEach((date) => {
        let tempDriver = {
          DriverValue: this.getValue(driver, date.sufix),
          DriverName: driver.DriverName,
          isChartSubtotal: driver.isChartSubtotal,
          LastPeriodOnly: driver.LastPeriodOnly,
        };
        this.yearDataByDates[ComparisonPeriod.forecast][date.dateIndex].Drivers.push(tempDriver);
        if (
          date.Order >
          global.Modeliks.DateHelper.years_before_actual[
            global.Modeliks.DateHelper.years_before_actual.length - 1
          ].Order
        ) {
          this.yearDataByDates[ComparisonPeriod.actualForecast][date.dateIndex].Drivers.push(
            tempDriver,
          );
        }
      });
    });
  };

  buildLeanDataPreviousPeriod = (obj, startDateInfo, endDateInfo = null, diff = 0) => {
    let startDateInfoPreviousPeriod = this.getDateFromDifference(
      startDateInfo,
      -Math.abs(endDateInfo.Order - startDateInfo.Order) - 1,
      this.getMonthDatesAll(),
    );
    let endDateInfoPreviousPeriod = this.getDateFromDifference(
      startDateInfo,
      -1,
      this.getMonthDatesAll(),
    );
    if (Object.keys(obj).length && startDateInfoPreviousPeriod) {
      let dateObjects = Object.keys(obj)
        .filter(
          (c) =>
            obj[c].Date.Order >= startDateInfo.Order &&
            (!endDateInfo || obj[c].Date.Order <= endDateInfo.Order),
        )
        .map((key) => {
          return {
            ...obj[key],
          };
        });
      let driverObjects = Object.keys(obj)
        .filter(
          (c) =>
            obj[c].Date.Order >= startDateInfoPreviousPeriod.Order &&
            (!endDateInfoPreviousPeriod || obj[c].Date.Order <= endDateInfoPreviousPeriod.Order),
        )
        .map((key) => {
          return {
            ...obj[key],
            Drivers: Object.keys(obj[key].Drivers).map((driverKey) => {
              let driver = obj[key].Drivers[driverKey];
              return {
                DriverValue: driver.DriverValue,
                DriverName: driver.DriverName,
                isChartSubtotal: driver.isChartSubtotal,
                isExpense: driver.isExpense,
              };
            }),
          };
        });
    } else {
      return [];
    }
  };

  buildLeanDataPreviousYear = (obj, startDateInfo, endDateInfo = null, diff = 0) => {
    let startDateInfoPreviousPeriod = this.getDateFromDifference(
      this.currentDateRange.dateFrom.month,
      -12,
      this.getMonthDatesAll(),
    );
    let endDateInfoPreviousPeriod = this.getDateFromDifference(
      this.currentDateRange.dateTo.month,
      -12,
      this.getMonthDatesAll(),
    );
    if (Object.keys(obj).length && startDateInfoPreviousPeriod && startDateInfo) {
      let dateObjects = Object.keys(obj)
        .filter(
          (c) =>
            obj[c].Date.Order >= startDateInfo.Order &&
            (!endDateInfo || obj[c].Date.Order <= endDateInfo.Order),
        )
        .map((key) => {
          return {
            Date: { ...obj[key] }.Date,
          };
        });
      let driverObjects = Object.keys(obj)
        .filter(
          (c) =>
            obj[c].Date.Order >= startDateInfoPreviousPeriod.Order &&
            (!endDateInfoPreviousPeriod || obj[c].Date.Order <= endDateInfoPreviousPeriod.Order),
        )
        .map((key) => {
          return {
            Drivers: Object.keys(obj[key].Drivers).map((driverKey) => {
              let driver = obj[key].Drivers[driverKey];
              return {
                DriverValue: driver.DriverValue,
                DriverName: driver.DriverName,
                isChartSubtotal: driver.isChartSubtotal,
                isExpense: driver.isExpense,
              };
            }),
          };
        });

      driverObjects.forEach((c, index) => {
        Object.assign(c, { Date: Object.values(dateObjects)[index].Date });
      });

      return driverObjects;
    } else {
      return [];
    }
  };

  buildLeanData = (obj, startDateInfo, endDateInfo = null) => {
    if (Object.keys(obj).length && startDateInfo) {
      return Object.keys(obj)
        .filter(
          (c) =>
            obj[c].Date.Order >= startDateInfo.Order &&
            (!endDateInfo || obj[c].Date.Order <= endDateInfo.Order),
        )
        .map((key) => {
          return {
            ...obj[key],
            Drivers: Object.keys(obj[key].Drivers).map((driverKey) => {
              let driver = obj[key].Drivers[driverKey];
              return {
                DriverValue: driver.DriverValue,
                DriverName: driver.DriverName,
                isChartSubtotal: driver.isChartSubtotal,
                isExpense: driver.isExpense,
                LastPeriodOnly: driver.LastPeriodOnly,
              };
            }),
          };
        });
    } else {
      return [];
    }
  };

  getDateFromDifference = (referenceMonth, difference, datesArray) => {
    let newMonth = datesArray.find((c) => referenceMonth.Order + difference == c.Order);
    if (newMonth) {
      return newMonth;
    } else if (difference < 0) {
      return datesArray.find((c) => c.Order == Math.min(...datesArray.map((o) => o.Order))); //datesArray.reduce((a, b) => Math.min(a.Order, b.Order));
    } else if (difference > 0) {
      return datesArray.find((c) => c.Order == Math.max(...datesArray.map((o) => o.Order))); //datesArray.reduce((a, b) => Math.max(a.Order, b.Order));
    }
  };

  buildReportFrequency = () => {
    switch (this.chartData.reportFrequency) {
      case "monthly":
        this.chartDates = {
          [ComparisonPeriod.previousPeriod]: this.buildLeanData(
            this.monthDataByDates[ComparisonPeriod.actual],
            this.getDateFromDifference(
              this.currentDateRange.dateFrom.month,
              -Math.abs(
                this.currentDateRange.dateTo.month.Order -
                  this.currentDateRange.dateFrom.month.Order,
              ) - 1,
              this.getMonthDatesAll(),
            ),
            this.getDateFromDifference(
              this.currentDateRange.dateFrom.month,
              -1,
              this.getMonthDatesAll(),
            ),
          ),
          [ComparisonPeriod.previousYear]: this.buildLeanDataPreviousYear(
            this.monthDataByDates[ComparisonPeriod.actual],
            this.currentDateRange.dateFrom.month,
            this.currentDateRange.dateTo.month,
          ),
          [ComparisonPeriod.forecast]: this.buildLeanData(
            this.monthDataByDates[ComparisonPeriod.forecast],
            this.currentDateRange.dateFrom.month,
            this.currentDateRange.dateTo.month,
          ),
          [ComparisonPeriod.actual]: this.buildLeanData(
            this.monthDataByDates[ComparisonPeriod.actual],
            this.currentDateRange.dateFrom.month,
            this.currentDateRange.dateTo.month,
          ),
          [ComparisonPeriod.actualForecast]: this.buildLeanData(
            this.monthDataByDates[ComparisonPeriod.actualForecast],
            this.currentDateRange.dateFrom.month,
            this.currentDateRange.dateTo.month,
          ),
        };
        break;
      case "quarterly":
        this.chartDates = {
          [ComparisonPeriod.previousPeriod]: this.buildQuarterlyData(
            this.monthDataByDates[ComparisonPeriod.actual],
            this.getDateFromDifference(
              this.currentDateRange.dateFrom.month,
              -Math.abs(
                this.currentDateRange.dateTo.month.Order -
                  this.currentDateRange.dateFrom.month.Order,
              ) - 1,
              this.getMonthDatesAll(),
            ),
            this.getDateFromDifference(
              this.currentDateRange.dateFrom.month,
              -1,
              this.getMonthDatesAll(),
            ),
          ),
          [ComparisonPeriod.previousYear]: this.buildQuarterlyData(
            this.monthDataByDates[ComparisonPeriod.actual],
            this.getDateFromDifference(
              this.currentDateRange.dateFrom.month,
              -12,
              this.getMonthDatesAll(),
            ),
            this.getDateFromDifference(
              this.currentDateRange.dateTo.month,
              -12,
              this.getMonthDatesAll(),
            ),
          ),
          [ComparisonPeriod.forecast]: this.buildQuarterlyData(
            this.monthDataByDates[ComparisonPeriod.forecast],
            this.currentDateRange.dateFrom.month,
            this.currentDateRange.dateTo.month,
          ),
          [ComparisonPeriod.actual]: this.buildQuarterlyData(
            this.monthDataByDates[ComparisonPeriod.actual],
            this.currentDateRange.dateFrom.month,
            this.currentDateRange.dateTo.month,
          ),
          [ComparisonPeriod.actualForecast]: this.buildLeanData(
            this.monthDataByDates[ComparisonPeriod.actualForecast],
            this.currentDateRange.dateFrom.month,
            this.currentDateRange.dateTo.month,
          ),
        };
        break;
      case "annually":
        this.chartDates = {
          [ComparisonPeriod.previousPeriod]: this.buildYearlyData(
            this.monthDataByDates[ComparisonPeriod.actual],
            this.getDateFromDifference(
              this.currentDateRange.dateFrom.month,
              -Math.abs(
                this.currentDateRange.dateTo.month.Order -
                  this.currentDateRange.dateFrom.month.Order,
              ) - 1,
              this.getMonthDatesAll(),
            ),
            this.getDateFromDifference(
              this.currentDateRange.dateFrom.month,
              -1,
              this.getMonthDatesAll(),
            ),
          ),
          [ComparisonPeriod.previousYear]: this.buildYearlyData(
            this.monthDataByDates[ComparisonPeriod.actual],
            this.getDateFromDifference(
              this.currentDateRange.dateFrom.month,
              -12,
              this.getMonthDatesAll(),
            ),
            this.getDateFromDifference(
              this.currentDateRange.dateTo.month,
              -12,
              this.getMonthDatesAll(),
            ),
          ),
          [ComparisonPeriod.forecast]: this.buildYearlyData(
            this.monthDataByDates[ComparisonPeriod.forecast],
            this.currentDateRange.dateFrom.month,
            this.currentDateRange.dateTo.month,
          ),
          [ComparisonPeriod.actual]: this.buildYearlyData(
            this.monthDataByDates[ComparisonPeriod.actual],
            this.currentDateRange.dateFrom.month,
            this.currentDateRange.dateTo.month,
          ),
          [ComparisonPeriod.actualForecast]: this.buildLeanData(
            this.monthDataByDates[ComparisonPeriod.actualForecast],
            this.currentDateRange.dateFrom.month,
            this.currentDateRange.dateTo.month,
          ),
        };
        break;
      case "annuallyY":
        this.chartDates = {
          [ComparisonPeriod.forecast]: this.buildLeanData(
            this.yearDataByDates[ComparisonPeriod.forecast],
            this.currentDateRange.dateFrom.year,
            this.currentDateRange.dateTo.year,
          ),
          [ComparisonPeriod.actual]: this.buildLeanData(
            this.yearDataByDates[ComparisonPeriod.actual],
            this.currentDateRange.dateFrom.year,
            this.currentDateRange.dateTo.year,
          ),
          [ComparisonPeriod.actualForecast]: this.buildLeanData(
            this.yearDataByDates[ComparisonPeriod.actualForecast],
            this.currentDateRange.dateFrom.year,
            this.currentDateRange.dateTo.year,
          ),
        };
        break;
    }
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return false;
  }

  componentWillUnmount() {}

  buildYearlyData = (obj, startDateInfo, endDateInfo) => {
    let newYearObj = null;
    let currentYear = null;
    let yearsArr = [];
    if (obj) {
      Object.values(obj)
        .filter((c) => {
          return (
            c.Date.Order >= startDateInfo.Order &&
            (!endDateInfo || c.Date.Order <= endDateInfo.Order)
          );
        })
        .forEach((month) => {
          if (month.Date.Order % 12 === 0) {
            if (newYearObj) {
              yearsArr.push(newYearObj);
              newYearObj = null;
            }
          }
          if (!newYearObj) {
            currentYear = month.Date.Year;
            newYearObj = {
              Date: { Header: `${month.Date.Year}` },
              Drivers: [],
            };
          }
          month.Drivers.forEach((driver, index) => {
            if (!newYearObj.Drivers[index]) {
              newYearObj.Drivers.push({
                DriverValue: driver.DriverValue,
                DriverName: driver.DriverName,
                isChartSubtotal: driver.isChartSubtotal,
                LastPeriodOnly: driver.LastPeriodOnly,
                isExpense: driver.isExpense,
              });
            } else {
              newYearObj.Drivers[index].DriverValue = this.getValueByUnitType(
                newYearObj.Drivers[index].DriverValue,
                driver.DriverValue,
                index,
              );
            }
          });
        });
      if (newYearObj) {
        yearsArr.push(newYearObj);
      }
      return yearsArr;
    }
    return [];
  };

  buildQuarterlyData = (obj, startDateInfo, endDateInfo) => {
    let currentQuarter = 0;
    let newQuarterObj = null;
    let currentYear = null;
    let quartersArr = [];
    if (obj) {
      Object.values(obj)
        .filter((c) => {
          return (
            c.Date.Order >= startDateInfo.Order &&
            (!endDateInfo || c.Date.Order <= endDateInfo.Order)
          );
        })
        .forEach((quarter, index) => {
          if (quarter.Date.Order % 12 === 0) {
            currentQuarter = 0;
            currentYear = quarter.Date.Year;
          }
          if (quarter.Date.Order % 3 === 0) {
            if (currentQuarter === 0 && index === 0 && quarter.Date.Order % 12 !== 0) {
              let diff = parseInt(Number(quarter.Date.Order) / 3);
              if (diff < 0) {
                diff += 5;
              }
              currentQuarter = diff;
              currentYear = quarter.Date.Year.Year;
            } else {
              currentQuarter += 1;
            }
            if (newQuarterObj) {
              quartersArr.push(newQuarterObj);
            }
            newQuarterObj = {
              Date: { Header: `${quarter.Date.Header.split(" ")[0]}` },
              Drivers: [],
            };
          } else if (index === 0) {
            let diff = parseInt(Number(quarter.Date.Order) / 3);
            if (diff < 0) {
              diff += 4;
            } else {
              diff++;
            }
            currentQuarter = diff;
            currentYear = quarter.Date.Year.Year;
            newQuarterObj = {
              Date: { Header: `${quarter.Date.Header.split(" ")[0]}` },
              Drivers: [],
            };
          }
          quarter.Drivers.forEach((driver, index) => {
            if (!newQuarterObj.Drivers[index]) {
              newQuarterObj.Drivers.push({
                DriverValue: driver.DriverValue,
                isChartSubtotal: driver.isChartSubtotal,
                LastPeriodOnly: driver.LastPeriodOnly,
                DriverName: driver.DriverName,
                isExpense: driver.isExpense,
              });
            } else {
              newQuarterObj.Drivers[index].DriverValue = this.getValueByUnitType(
                newQuarterObj.Drivers[index].DriverValue,
                driver.DriverValue,
                index,
              );
            }
          });
          if (quarter.Date.Order % 3 === 2 || quarter.Date.Order % 3 === -1) {
            if (index !== 0) {
              newQuarterObj.Date.Header += ` - ${quarter.Date.Header.split(" ")[0]}`;
            }
            newQuarterObj.Date.Header += ` ${quarter.Date.Header.split(" ")[1]}`;
          }
        });
      if (newQuarterObj) {
        quartersArr.push(newQuarterObj);
      }
      return quartersArr;
    } else {
      return [];
    }
  };

  getValueByUnitType = (locationReference, value, currentLength, shouldCalculate = true) => {
    // if(this.UnitType === UnitTypes.Percentage && shouldCalculate) {
    //     locationReference = locationReference + (value - locationReference) / (currentLength + 1);
    // }
    if (isNaN(locationReference)) {
      locationReference = 0;
    }
    if (isNaN(value)) {
      value = 0;
    }
    return locationReference + value;
  };

  changeDate = (month, year, name) => {
    this.chartData[name] = {
      month: month,
      year: year,
    };
    this.currentDateRange = this.getCurrentDateRange();
    this.buildData();
    this.chartRef.data.datasets = this.getData();
    this.chartRef.data.labels = this.chartLabels;
    this.chartRef.update();
  };

  getDefaultColor = () => {
    return Chart.defaultProps.chartOptions.barColorKeys[StreamTypes.Revenue.key];
  };

  colorGetter = (value) => {
    return Theme.colors.chart[value[0]][value[1]];
  };

  getRandomColor(key, singleColor) {
    // if(singleColor){
    //     return this.colorGetter(this.chartOptions.singleColor);
    // }
    if (this.chartOptions.barColorKeys[key]) {
      if (this.chartOptions.barColorKeys[key][0]) {
        return this.colorGetter(this.chartOptions.barColorKeys[key]);
      }
      return this.chartOptions.barColorKeys[key][1];
    }
    return this.colorGetter(this.getDefaultColor());
  }

  getAdditionalDataSettings() {
    return {
      datalabels: {
        labels: {
          value: {},
          growth: this.getGrowthDataLabel(),
        },
      },
    };
  }

  getGrowthValue(value, ctx) {
    let growthValue = "-";
    if (ctx.dataIndex > 0) {
      growthValue = MxMath.Round(
        ((value - ctx.chart.config.data.datasets[ctx.datasetIndex].data[ctx.dataIndex - 1]) /
          ctx.chart.config.data.datasets[ctx.datasetIndex].data[ctx.dataIndex - 1]) *
          100,
      );
    }
    return growthValue;
  }

  getGrowthOffset() {
    return "\n";
  }

  getEmptySpaces = (numOfSpaces = 0) => {
    let emptyString = "";
    for (let i = 0; i < numOfSpaces; i++) {
      emptyString += " ";
    }
    return emptyString;
  };

  getGrowthDataLabel() {
    return {
      display: this.chartData.growth,
      anchor: (context) => this.getLabelAlignMent(context),
      align: (context) => this.getLabelAlignMent(context),
      color: "black",
      formatter: (value, ctx) => {
        let growthValue = this.getGrowthValue(value, ctx);
        if (growthValue === "" || growthValue === "-") {
          return `${this.getGrowthOffset(value)}${growthValue}`;
        }
        if (isNaN(Number(growthValue))) {
          growthValue = 0;
        }
        growthValue = this.getPercentageFormat(growthValue);
        growthValue += "%";
        return `${this.getGrowthOffset(value)}${growthValue}`;
      },
      font: {
        size: this.chartOptions.dataLabelsFontSize - 2,
        style: "italic",
      },
      opacity: 1,
    };
  }

  getDatasetStyle = (key, singleColor) => {
    let color = this.getRandomColor(key, singleColor);
    return {
      backgroundColor: color,
      borderColor: color,
      categoryPercentage: this.chartOptions.categoryGapWidth / 10,
      barPercentage: this.chartOptions.gapWidth / 10,
      minBarLength: 0,
      borderRadius: 5,
      fill: false,
      ...this.getAdditionalDataSettings(),
    };
  };

  getCurrencyIfPrice = () => {
    let unitType = this.getUnitType();

    if (unitType == UnitTypes.Price) {
      if (global.Modeliks.CompanyInfo) {
        return global.Modeliks.CompanyInfo.Currency.value;
      } else if (this.props.snapShotData) {
        return this.props.snapShotData.Currency.value;
      }
    }
    return "";
  };

  getPercentIfPercent = () => {
    let unitType = this.getUnitType();
    if (unitType == UnitTypes.Multiple) {
      return "x";
    }
    if (unitType == UnitTypes.Percentage) {
      return "%";
    }
    return "";
  };

  getSomeGrowth = (dataIndex) => {
    if (!isNaN(this.chartData.growth[dataIndex])) {
      if (this.chartData.growth[dataIndex] !== null) {
        if (isFinite(this.chartData.growth[dataIndex])) {
          return "\n" + this.DataLabelFormat(this.chartData.growth[dataIndex]) + "%";
        } else {
          return "\n100%";
        }
      } else {
        return "";
      }
    }
    return "";
  };

  getDataLabel = (value, dataIndex) => {
    return (
      (isNaN(value) ? "" : this.getCurrencyIfPrice()) +
      this.DataLabelFormat(value) +
      this.getPercentIfPercent() +
      this.getSomeGrowth(dataIndex)
    );
  };

  getGrowthByChart = (growth) => {
    if (ChartTypes[this.chartClassName].name == ChartTypes.HorizontalBarChart.name) {
      return `   ${growth}`;
    } else {
      return `\n${growth}`;
    }
  };

  getCurrency = (value = "") => {
    let unitType = this.getUnitType();

    if (unitType == UnitTypes.Price) {
      let currency = "";
      if (global.Modeliks.CompanyInfo) {
        currency = global.Modeliks.CompanyInfo.Currency.value;
      } else if (this.props.snapShotData) {
        currency = this.props.snapShotData.Currency.value;
      }
      return currency + value;
    }
    if (unitType == UnitTypes.Percentage) {
      return value + "%";
    }

    if (unitType == UnitTypes.Multiple) {
      return value + "x";
    }
    return value;
  };

  getChartActual = (comparisonBar) => {
    if (Object.keys(this.comparisonPeriodTotals[comparisonBar]).length) {
      return this.getCurrency() + this.DataLabelFormat(this.comparisonPeriodTotals[comparisonBar]);
    }
    return 0; //
  };

  getChartTitleGrowthUnit = () => {
    if (this.UnitType == UnitTypes.Price) {
      return "%";
    }
    return "pp";
  };

  getLabelAlignMent(context) {
    if (context.dataset.data[context.dataIndex] >= 0) {
      return "end";
    } else {
      return "start";
    }
  }

  getChartTitleChange = () => {
    if (this.UnitType == UnitTypes.Percentage) {
      return this.DataLabelFormat(
        this.comparisonPeriodTotals[
          this.chartData.comparisonPeriod.find((c) => c != this.chartOptions.comparisonBar)
        ] - this.comparisonPeriodTotals[this.chartOptions.comparisonBar],
      );
    }
    if (this.comparisonPeriodTotals) {
      return this.DataLabelFormat(
        this.comparisonPeriodTotals[
          this.chartData.comparisonPeriod.find((c) => c != this.chartOptions.comparisonBar)
        ] /
          this.comparisonPeriodTotals[this.chartOptions.comparisonBar] -
          1,
      );
    }
  };

  DataLabelFormat = (
    number,
    ctx,
    minDecimalPoints = [
      ChartTypes.StackedBarChart.name,
      ChartTypes.LineChart.name,
      ChartTypes.BarChart.name,
    ].includes(ChartTypes[this.chartClassName].name)
      ? this.chartOptions.datalabelsDecimalPoints
      : 0,
    maxDecimalPoints = this.chartOptions.datalabelsDecimalPoints,
  ) => {
    let num = number;
    if (Array.isArray(number)) {
      num = number[0];
      if (this.subtotalIndexes.findIndex((c) => c === ctx.dataIndex) === -1) {
        num = number[1] - number[0];
      }
    }
    if (this.getUnitType() !== UnitTypes.Percentage) {
      num /= NumberFormatTypes[this.chartOptions.numberFormat].splitBy;
    }
    if (num == undefined || num == null) {
      num = 0;
    }
    return num.toLocaleString(undefined, {
      minimumFractionDigits: minDecimalPoints,
      maximumFractionDigits: maxDecimalPoints,
    });
  };

  getTopPadding() {
    let ultimatePadding = 80;
    if (this.chartOptions.showTitle) {
      ultimatePadding += 30;
    }
    if (this.chartOptions.showSubtitle) {
      ultimatePadding += 20;
    }
    if (this.props.isScaled) {
      ultimatePadding -= 35;
    }

    return ultimatePadding;
  }

  getAnimationProperties = () => {
    if (this.props.isScaled) {
      if (this.props.businessPlan) {
        if (
          window.iscurrentlyPrinting &&
          window.chartsForRender.indexOf(this.getAnimationProperties) === -1
        ) {
          window.chartsForRender.push(this.getAnimationProperties);
        }
        return {
          animation: {
            duration: window.iscurrentlyPrinting ? 1 : 0,
            onComplete: () => {
              if (this.props.preview && this.props.businessPlan && this.chartRef) {
                this.imgSrc = this.chartRef.toBase64Image();
                this.forceUpdate(() => {
                  window.amountOfChartsRendered++;
                  global.Modeliks.NotifySubsctiptions("onChartRenderFinish");
                });
              }
            },
          },
        };
      } else {
        return {
          animation: {
            duration: 0,
            onComplete: () => {
              if (this.props.preview && this.props.businessPlan && this.chartRef) {
                this.imgSrc = this.chartRef.toBase64Image();
                this.forceUpdate(() => {
                  window.amountOfChartsRendered++;
                  global.Modeliks.NotifySubsctiptions("onChartRenderFinish");
                });
              }
            },
          },
        };
      }
    }
    return {};
  };

  getPercentageFormat = (num, f_n = 1) => {
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: f_n,
    });
  };

  getDevicePixelRatio = () => {
    if (this.props.isScaled) {
      return 2;
    }
    return window.devicePixelRatio;
  };

  getIfDatalabelDisplay = () => {
    if (ChartTypes[this.chartClassName].name == ChartTypes.StackedBarChart.name) {
      return this.chartOptions.showDataLabels;
    }
    return this.chartOptions.showDataLabels ? "auto" : false;
  };

  getChartOptions = () => {
    this.chartValueSums = [];
    return {
      responsive: true,
      devicePixelRatio: this.getDevicePixelRatio(),
      maintainAspectRatio: false,
      barRoundness: 0.3,
      layout: {
        padding: {
          top: this.getTopPadding(),
          right: this.ChartRightPadding,
        },
      },
      scales: {
        x: {
          grid: {
            display: this.chartOptions.xGrid,
            drawBorder: this.chartOptions.xGrid,
          },
          ticks: {
            display: this.chartOptions.showHorizontalAxis,
            color: "#889299",
            callback: function (val) {
              const labelVal = this.getLabelForValue(val);
              return stringFormatter(labelVal);
            },
            font: {
              weight: 400,
              style: "normal",
              size: this.chartOptions.axisFontSize, //12,
              lineHeight: "160%",
            },
          },
          stacked: false,
        },
        y: {
          grid: {
            display: this.chartOptions.yGrid,
            drawBorder: this.chartOptions.yGrid,
          },
          ticks: {
            display: this.chartOptions.showVerticalAxis,
            minTicksLimit: 4,
            maxTicksLimit: 4,
            color: "#889299",
            callback: function (val) {
              const labelVal = this.getLabelForValue(val);
              return stringFormatter(labelVal);
            },
            font: {
              weight: 400,
              style: "normal",
              size: this.chartOptions.axisFontSize, //12,
              lineHeight: "160%",
            },
          },
        },
      },
      ...this.getAnimationProperties(),
      plugins: {
        datalabels: {
          clamp: true,
          anchor: (context) => this.getLabelAlignMent(context),
          align: (context) => this.getLabelAlignMent(context),
          textAlign: "center",
          display: this.getIfDatalabelDisplay(),
          formatter: (value, ctx) => {
            if (ChartTypes[this.chartClassName].name == ChartTypes.StackedBarChart.name) {
              const sumValue = ctx.chart.config.data.datasets.map((datapoint) => {
                if (datapoint.data[ctx.dataIndex] && datapoint.hidden !== true) {
                  return datapoint.data[ctx.dataIndex];
                } else {
                  return 0;
                }
              });

              let growthValue = "-";

              function totalSum(total, datapoint) {
                return total + datapoint;
              }

              let sum = sumValue.reduce(totalSum, 0);

              let newSum = 0.0;
              if (isNaN(sum)) {
                newSum = "-";
                this.chartValueSums.push(0);
              } else {
                this.chartValueSums.push(sum);
                if (ctx.dataIndex < ctx.dataset.data.length - 1) {
                  growthValue =
                    MxMath.Round(
                      ((sum - this.chartValueSums[ctx.dataIndex + 1]) /
                        this.chartValueSums[ctx.dataIndex + 1]) *
                        100,
                    ) + "%";
                }
                if (this.chartOptions.unitValue === "Millions") {
                  newSum = sum / 1000000;
                } else if (this.chartOptions.unitValue === "Thousands") {
                  newSum = sum / 1000;
                } else {
                  newSum = sum;
                }
              }
              if (ctx.datasetIndex == ctx.chart.config.data.datasets.length - 1) {
                let sufix = "";
                if (this.chartData.growth) {
                  sufix = "\n";
                }

                return this.getDataLabel(newSum, ctx.dataIndex) + sufix;
              } else {
                return "";
              }
            } else {
              let sufix = "";
              if (this.chartData.growth) {
                sufix = this.getGrowthOffset(0);
              }
              if (ChartTypes[this.chartClassName].name === ChartTypes.BarChart.name) {
                if ((isNaN(value) && !Array.isArray(value)) || value === null) {
                  return "";
                }
                const maxLength = 12;
                let newVal = value;

                if (newVal.toString().length > maxLength) {
                  let convertedValue = this.DataLabelFormat(newVal, ctx);
                  if (convertedValue.toString().length > maxLength) {
                    convertedValue = parseInt(convertedValue.toString().substring(0, maxLength));
                    return this.getCurrency(convertedValue) + "...";
                  }
                }

                return this.getCurrency(this.DataLabelFormat(value, ctx));
              }
              return (
                ((isNaN(value) && !Array.isArray(value)) || value === null
                  ? ""
                  : this.getCurrency(this.DataLabelFormat(value, ctx))) + sufix
              );
            }
          },
          color: "#252525",
          font: {
            size: this.chartOptions.dataLabelsFontSize, //10,
            weight: "700",
            lineHeight: "17px",
          },
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              let obj = context.parsed._custom;
              let label = context.dataset.label || "";

              if (label) {
                label += ": ";
              }

              let value = context.parsed.y;
              if (
                ChartTypes[this.chartClassName].name == ChartTypes.WaterfallChart.name &&
                obj &&
                obj.end !== 0
              ) {
                value = obj.end - obj.start;
              }

              if (ChartTypes[this.chartClassName].name == ChartTypes.HorizontalBarChart.name) {
                label = "";
                value = context.raw;
                if (value !== null) {
                  label += this.getCurrency(this.DataLabelFormat(value, null, 2, 2));
                }
              } else {
                if (value !== null) {
                  label += this.getCurrency(this.DataLabelFormat(value));
                }
              }

              return label;
            },
          },
        },
        legend: {
          display: this.chartOptions.displayLegend,
          position: this.chartOptions.legendPosition,
          align: "center",
          labels: {
            usePointStyle: true,
            font: {
              size: this.chartOptions.legendFontSize,
            },
            padding: this.props.isScaled ? 7.5 : 15,
          },
          onClick: (e) => {},
        },
        // horizontalDottedLine: this.chartOptions.benchmark,
        horizontalDottedLine: false,
        startXLine: true,
      },
    };
  };

  changeGrowth = (e, callBack) => {
    this.chartData.growth = !this.chartData.growth;
    if (this.chartRef) {
      if (
        ChartTypes[this.chartClassName].name == ChartTypes.StackedBarChart.name ||
        ChartTypes[this.chartClassName].name == ChartTypes.HorizontalBarChart.name
      ) {
        this.chartRef.options = this.getThisChartOptions();
      } else {
        this.buildData();
        this.chartRef.data.datasets = this.getData();
        this.chartRef.options = this.getChartOptions();
      }
    } else {
      // window.alert('no chart ref')
    }
    this.chartRef.update();
    callBack && callBack();
  };

  rebuildChartData = (driverKeys = [], driverKeysObj = null, callBack, change = true) => {
    if (change) {
      if (driverKeysObj) {
        Object.keys(driverKeysObj).forEach((key) => {
          this.chartData[key] = [...driverKeysObj[key]];
        });
      } else {
        this.chartData.driverKeys = [...driverKeys];
      }
    }
    this.chartDrivers = this.getChartDrivers();
    this.indexData();
    this.buildData();
    this.chartRef.data.datasets = this.getData();
    this.chartRef.data.labels = this.chartLabels;
    this.maxDatasetsFirstValue = 0;
    this.maxDatasetsLastValue = 0;
    this.chartRef.update();
    callBack && callBack();
  };

  getData() {
    if (!global.Modeliks.CompanyInfo && this.props.snapShotData) {
      return this.props.snapShotData.datasets;
    }
    return this.getArrayDatasets();
  }

  getSeriesCategoriesKey() {
    return {
      firstKey: "series",
      secondKey: "categories",
    };
  }

  getStartingColorIndex() {
    return 0;
  }

  componentDidMount() {
    if (this.props.handleSnapShotData) {
      this.props.handleSnapShotData({
        labels: this.getChartLabels(),
        datasets: this.getData(),
        headerDateFrom:
          this.currentDateRange.validDateFrom[
            ReportFrequency[this.chartData.reportFrequency].PeriodType
          ].Header,
        headerDateTo:
          this.currentDateRange.dateTo[ReportFrequency[this.chartData.reportFrequency].PeriodType]
            .Header,
        unitType: this.getUnitType(),
        Currency: global.Modeliks.CompanyInfo.Currency.value,
      });
    }
  }

  onWeightedAverageChange = (e, callBack) => {
    this.chartOptions.isWeightedAverage = e.target.checked;
    this.rebuildChartData(
      [],
      {
        mainDriverKeys: this.chartData.mainDriverKeys,
        weightingDriverKeys: this.chartData.weightingDriverKeys,
      },
      () => {
        if (this.chartClassName == "StackedBarChart") {
          this.updateChart();
        }
        callBack && callBack();
      },
    );
  };

  getDriverName = (label, driverArray) => {
    // if(driverArray.length > 1 && this.UnitType === UnitTypes.Percentage){
    //     return label + ' Average';
    // }
    return label;
  };

  checkValidDate = (date, header, driver) => {
    if (this.chartData.reportFrequency === "monthly") {
      if (this.currentDateRange.validDateFrom.month.Order > date.Date.Order) {
        return;
      }
    }
    this.fillSeries(header, driver);
  };

  fillSeries = (serieKey, driver) => {
    let expense = 1;

    if (driver.isExpense) {
      expense = -1;
    }

    if (!this.seriesTotals[serieKey]) {
      this.seriesTotals[serieKey] = 0;
    }
    if (!isNaN(driver.DriverValue)) {
      this.seriesTotals[serieKey] += driver.DriverValue * expense;
    }
  };

  buildData() {
    if (global.Modeliks.CompanyInfo) {
      const { firstKey, secondKey } = this.getSeriesCategoriesKey();
      this.chartLabels = [];
      this.buildReportFrequency();
      this.comparisonPeriodTotals = {};
      this.seriesTotals = {};
      let driverArr = {};
      let comparisonPeriods = this.chartData.comparisonPeriod.filter((c) => !!c);

      switch (
        this.chartData[firstKey] //category
      ) {
        case ChartDataTypes.reportFrequency:
          this.chartDatasets = [];
          switch (this.chartData[secondKey]) {
            case ChartDataTypes.comparisonPeriod:
              comparisonPeriods.forEach((comparisonPeriod) => {
                this.chartDates[comparisonPeriod].forEach((date) => {
                  //date per comparison period
                  date.Drivers.forEach((driver) => {
                    this.checkValidDate(date, date.Date.Header, driver);
                  });
                });
              });
              comparisonPeriods.forEach((comparisonPeriod) => {
                this.chartLabels.push(comparisonPeriod);
                this.comparisonPeriodTotals[comparisonPeriod] = 0;
                if (this.chartDates[comparisonPeriod] && this.chartDates[comparisonPeriod].length) {
                  this.chartDates[comparisonPeriod].forEach((date, dateIndex) => {
                    //date per comparison period
                    let dateHeader = date.Date.Header;
                    if (!driverArr[dateHeader]) {
                      driverArr[dateHeader] = {
                        label: this.getDriverName(dateHeader, date.Drivers),
                        data: {},
                      };
                    }
                    date.Drivers.forEach((driver, index) => {
                      if (!driverArr[dateHeader].data[comparisonPeriod]) {
                        driverArr[dateHeader].data[comparisonPeriod] = 0;
                      }
                      if (!isNaN(Number(driver.DriverValue))) {
                        driverArr[dateHeader].data[comparisonPeriod] = this.getValueByUnitType(
                          driverArr[dateHeader].data[comparisonPeriod],
                          driver.DriverValue,
                          index,
                        );
                        this.comparisonPeriodTotals[comparisonPeriod] = this.getValueByUnitType(
                          this.comparisonPeriodTotals[comparisonPeriod],
                          driver.DriverValue,
                          index,
                        );
                      }
                    });
                  });
                }
              });
              this.currentColorIndex = this.getStartingColorIndex(this.chartLabels);
              comparisonPeriods.forEach((comparisonPeriod) => {
                if (this.chartDates[comparisonPeriod] && this.chartDates[comparisonPeriod].length) {
                  this.chartDates[comparisonPeriod].forEach((date) => {
                    //date per comparison period
                    Object.assign(
                      driverArr[date.Date.Header],
                      this.getDatasetStyle(date.Date.Header),
                    );
                  });
                }
              });
              this.chartDatasets = [Object.values(driverArr)];
              break;
            case ChartDataTypes.driver:
              this.chartDates[this.chartData.comparisonPeriod[0]].forEach((date) => {
                date.Drivers.forEach((driver, index) => {
                  this.checkValidDate(date, date.Date.Header, driver);
                });
              });
              this.chartDates[this.chartData.comparisonPeriod[0]].forEach((date, dateIndex) => {
                if (!driverArr[date.Date.Header]) {
                  driverArr[date.Date.Header] = {
                    label: this.getDriverName(date.Date.Header, date.Drivers),
                    data: {},
                  };
                }
                date.Drivers.forEach((driver, index) => {
                  if (this.chartLabels.indexOf(driver.DriverName.toString()) == -1) {
                    this.chartLabels.push(driver.DriverName.toString());
                  }
                  if (!driverArr[date.Date.Header].data[driver.DriverName]) {
                    driverArr[date.Date.Header].data[driver.DriverName] = 0;
                  }
                  if (!isNaN(driver.DriverValue)) {
                    driverArr[date.Date.Header].data[driver.DriverName] = this.getValueByUnitType(
                      driverArr[date.Date.Header].data[driver.DriverName],
                      driver.DriverValue,
                      index,
                    );
                  }
                });
              });
              this.currentColorIndex = this.getStartingColorIndex(this.chartLabels);
              this.chartDates[this.chartData.comparisonPeriod[0]].forEach((date) => {
                Object.assign(driverArr[date.Date.Header], this.getDatasetStyle(date.Date.Header));
              });
              this.chartDatasets = [Object.values(driverArr)];
              break;
          }
          break;

        case ChartDataTypes.driver:
          driverArr = {};
          switch (this.chartData[secondKey]) {
            case ChartDataTypes.reportFrequency:
              this.chartDrivers.forEach((driver) => {
                driverArr[driver.DriverName] = {
                  //check me out later
                  label: this.getDriverName(
                    driver.DriverName,
                    this.chartDates[this.chartData.comparisonPeriod[0]],
                  ),
                  data: {},
                };
              });
              this.chartDates[this.chartData.comparisonPeriod[0]].forEach((date) => {
                date.Drivers.forEach((driver, index) => {
                  this.checkValidDate(date, driver.DriverName, driver);
                });
              });
              this.chartDates[this.chartData.comparisonPeriod[0]].forEach((date, dateIndex) => {
                this.chartLabels.push(date.Date.Header);
                date.Drivers.forEach((driver, index) => {
                  if (!driverArr[driver.DriverName].data[date.Date.Header]) {
                    driverArr[driver.DriverName].data[date.Date.Header] = 0;
                  }
                  if (!isNaN(driver.DriverValue)) {
                    driverArr[driver.DriverName].data[date.Date.Header] += driver.DriverValue;
                  }
                });
              });
              this.currentColorIndex = this.getStartingColorIndex(this.chartLabels);
              this.chartDrivers.forEach((driver) => {
                Object.assign(
                  driverArr[driver.DriverName],
                  this.getDatasetStyle(driver.DriverName, true),
                );
              });
              this.chartDatasets = [Object.values(driverArr)];
              break;
            case ChartDataTypes.comparisonPeriod:
              this.chartDrivers.forEach((driver, index) => {
                let sufix = "";
                if (driverArr[driver.DriverName] && this.chartOptions.isWeightedAverage) {
                  sufix = `~${index}`;
                }
                if (!driverArr[driver.DriverName + sufix]) {
                  driverArr[driver.DriverName + sufix] = {
                    label: driver.DriverName + sufix,
                    data: {},
                  };
                }
              });
              comparisonPeriods.forEach((comparisonPeriod) => {
                this.chartDates[comparisonPeriod].forEach((date) => {
                  let hasBeen = {};
                  date.Drivers.forEach((driver, index) => {
                    if (hasBeen[driver.DriverName] && this.chartOptions.isWeightedAverage) {
                      driver.DriverName += `~${index}`;
                    }
                    hasBeen[driver.DriverName] = true;

                    this.checkValidDate(date, driver.DriverName, driver);
                  });
                });
              });
              comparisonPeriods.forEach((comparisonPeriod) => {
                this.chartLabels.push(comparisonPeriod);
                this.comparisonPeriodTotals[comparisonPeriod] = 0;
                this.chartDates[comparisonPeriod].forEach((date, dateIndex) => {
                  date.Drivers.forEach((driver, index) => {
                    if (index === 0) {
                      driverArr[driver.DriverName].label = this.getDriverName(
                        driver.DriverName,
                        date.Drivers,
                      );
                    }
                    if (!driverArr[driver.DriverName].data[comparisonPeriod]) {
                      driverArr[driver.DriverName].data[comparisonPeriod] = 0;
                    }
                    if (
                      !driver.LastPeriodOnly ||
                      dateIndex === this.chartDates[comparisonPeriod].length - 1
                    ) {
                      if (!isNaN(driver.DriverValue)) {
                        driverArr[driver.DriverName].data[comparisonPeriod] =
                          this.getValueByUnitType(
                            driverArr[driver.DriverName].data[comparisonPeriod],
                            driver.DriverValue,
                            index,
                            !this.chartOptions.isWeightedAverage,
                          );
                        this.comparisonPeriodTotals[comparisonPeriod] = this.getValueByUnitType(
                          this.comparisonPeriodTotals[comparisonPeriod],
                          driver.DriverValue,
                          index,
                          !this.chartOptions.isWeightedAverage,
                        );
                      }
                    }
                  });
                });
              });
              this.currentColorIndex = this.getStartingColorIndex(this.chartLabels);
              this.chartDrivers.forEach((driver) => {
                Object.assign(
                  driverArr[driver.DriverName],
                  this.getDatasetStyle(driver.DriverName),
                );
              });
              this.chartDatasets = [Object.values(driverArr)];
              if (this.chartOptions.isWeightedAverage) {
                this.getSplitDrivers();
              }
              break;
          }
          break;

        case ChartDataTypes.comparisonPeriod:
          switch (this.chartData[secondKey]) {
            case ChartDataTypes.reportFrequency:
              comparisonPeriods.forEach((key) => {
                this.chartDates[key].forEach((date, dateIndex) => {
                  date.Drivers.forEach((driver, index) => {
                    if (this.chartClassName == "AreaChart") {
                      if (driver.LastPeriodOnly) {
                        if (dateIndex === this.chartDates[key].length - 1) {
                          this.checkValidDate(date, key, driver);
                        }
                      } else {
                        this.checkValidDate(date, key, driver);
                      }
                    } else {
                      this.checkValidDate(date, key, driver);
                    }
                  });
                });
              });
              comparisonPeriods.forEach((key) => {
                if (!driverArr[key]) {
                  driverArr[key] = { label: key, data: {} };
                }
                this.chartDates[key].forEach((date, dateIndex) => {
                  let dateHeader = date.Date.Header;
                  if (this.chartLabels.indexOf(dateHeader) == -1) {
                    this.chartLabels.push(dateHeader);
                  }
                  date.Drivers.forEach((driver, index) => {
                    if (index === 0) {
                      driverArr[key].label = this.getDriverName(key, date.Drivers);
                    }
                    if (!driverArr[key].data[dateHeader]) {
                      driverArr[key].data[dateHeader] = 0;
                    }
                    if (!isNaN(driver.DriverValue)) {
                      driverArr[key].data[dateHeader] = this.getValueByUnitType(
                        driverArr[key].data[dateHeader],
                        driver.DriverValue,
                        index,
                      );
                    }
                  });
                });
              });
              this.currentColorIndex = this.getStartingColorIndex(this.chartLabels);
              comparisonPeriods.forEach((key) => {
                Object.assign(driverArr[key], this.getDatasetStyle(key, true));
              });
              this.chartDatasets = [Object.values(driverArr)];
              break;
            case ChartDataTypes.driver:
              comparisonPeriods.forEach((key) => {
                this.chartDates[key].forEach((date) => {
                  date.Drivers.forEach((driver) => {
                    this.checkValidDate(date, key, driver);
                  });
                });
              });
              comparisonPeriods.forEach((key) => {
                if (!driverArr[key]) {
                  driverArr[key] = { label: key, data: {} };
                }
                this.chartDates[key].forEach((date, dateIndex) => {
                  let hasBeen = {};
                  date.Drivers.forEach((driver, index) => {
                    if (index === 0) {
                      driverArr[key].label = this.getDriverName(key, date.Drivers);
                    }
                    if (driver.DriverName) {
                      if (hasBeen[driver.DriverName] && this.chartOptions.isWeightedAverage) {
                        driver.DriverName += `~${index}`;
                      }
                      hasBeen[driver.DriverName] = true;
                      if (this.chartLabels.indexOf(driver.DriverName.toString()) == -1) {
                        this.chartLabels.push(driver.DriverName.toString());
                      }
                    }
                    if (!driverArr[key].data[driver.DriverName]) {
                      driverArr[key].data[driver.DriverName] = 0;
                    }
                    if (!driver.LastPeriodOnly || dateIndex === this.chartDates[key].length - 1) {
                      if (!isNaN(driver.DriverValue)) {
                        driverArr[key].data[driver.DriverName] = this.getValueByUnitType(
                          driverArr[key].data[driver.DriverName],
                          driver.DriverValue,
                          index,
                          !this.chartOptions.isWeightedAverage,
                        );
                      }
                    }
                  });
                });
              });
              this.currentColorIndex = this.getStartingColorIndex(this.chartLabels);
              comparisonPeriods.forEach((key) => {
                Object.assign(driverArr[key], this.getDatasetStyle(key));
              });
              this.chartDatasets = [Object.values(driverArr)];
              if (this.chartOptions.isWeightedAverage) {
                this.getSplitDrivers(true);
              }
              break;
          }
          break;
      }
      this.handleColorSet();
    }
  }

  getChartElementAsImage = () => {
    return (
      <div className={"c_c_chart_container"}>
        {this.getTitleElement()}
        {this.getChartAsImg()}
      </div>
    );
  };

  getSplitDrivers = (first = false) => {
    let chartDatasets = this.chartDatasets[0];
    if (chartDatasets.length > 0) {
      if (first) {
        this.chartLabels = [];
        chartDatasets.forEach((chartDataset) => {
          let newChartDataset = {};
          if (Object.values(chartDataset.data).length > 1) {
            let dataSetKeys = Object.keys(chartDataset.data);
            for (let i = 0; i < dataSetKeys.length; i += 2) {
              if (dataSetKeys[i] && dataSetKeys[i + 1]) {
                let value =
                  chartDataset.data[dataSetKeys[i]] / chartDataset.data[dataSetKeys[i + 1]];
                if (this.getUnitType() === UnitTypes.Percentage) {
                  value *= 100;
                }
                Object.assign(newChartDataset, {
                  [dataSetKeys[i] + " / " + dataSetKeys[i + 1].split("~")[0]]: value,
                });
                let label = dataSetKeys[i] + " / " + dataSetKeys[i + 1].split("~")[0];
                if (this.chartLabels.findIndex((c) => c === label) === -1) {
                  this.chartLabels.push(label);
                }
              }
            }
          }
          chartDataset.data = newChartDataset;
        });
      } else {
        let newDatasets = [];

        for (let i = 0; i < chartDatasets.length; i += 2) {
          let firstDataset = chartDatasets[i];
          let secondDataset = chartDatasets[i + 1];

          if (firstDataset && secondDataset) {
            let combinedDataset = { ...firstDataset };
            combinedDataset.data = {};
            if (firstDataset && secondDataset) {
              combinedDataset.label =
                firstDataset.label + " / " + secondDataset.label.split("~")[0];
            } else {
              combinedDataset.label = "";
            }
            Object.keys(firstDataset.data).forEach((key, index) => {
              let value =
                Object.values(firstDataset.data)[index] / Object.values(secondDataset.data)[index];
              if (this.getUnitType() === UnitTypes.Percentage) {
                value *= 100;
              }
              Object.assign(combinedDataset.data, {
                [Object.keys(firstDataset.data)[index]]: value,
              });
            });
            newDatasets.push(combinedDataset);
          }
        }
        this.chartDatasets[0] = newDatasets;
      }
    }
  };

  getImageSrc = () => {
    return this.imgSrc;
  };

  getChartAsImg = () => {
    return <img src={this.getImageSrc()} style={{ height: "100%", width: "100%" }} />;
  };

  changeComparisonPeriod = (comparisonPeriod, index) => {
    this.chartData.comparisonPeriod[index] = comparisonPeriod;
    this.buildData();
    this.chartRef.data.datasets = this.getData();
    this.chartRef.data.labels = this.chartLabels;
    this.chartRef.update();
  };

  setChartRef = (ref) => {
    if (ref) {
      this.chartRef = ref;
    }
  };

  fillEmptyDataPoints = (data) => {
    return this.chartLabels.map((key) => {
      if (data[key] === undefined) {
        return null;
      }
      return data[key];
    });
  };

  getArrayDatasets() {
    let datasets = this.getChartDatasets();
    let obj = Object.values(datasets).map((dataset) => {
      return {
        ...dataset,
        // data: Object.values(dataset.data)
        data: this.fillEmptyDataPoints(dataset.data),
      };
    });

    if (
      this.chartClassName === ChartTypes.LineChart.key ||
      this.chartClassName === ChartTypes.BarChart.key
    ) {
      this.maxDatasetsFirstValue = Math.max(...obj.map((el) => el.data[0])).toFixed(2).length;
      this.maxDatasetsLastValue = Math.max(...obj.map((el) => el.data[el.data.length - 1])).toFixed(
        2,
      ).length;

      if (global.Modeliks.CompanyInfo.ForecastPeriodYears === 5) {
        this.maxDatasetsFirstValue = this.maxDatasetsFirstValue + 3;
        this.maxDatasetsLastValue = this.maxDatasetsLastValue + 3;
      }
    }

    return obj;
  }

  makeValuesZeroes = (data) => {
    if (data != null) {
      Object.keys(data).forEach((key) => {
        if (isNaN(data[key])) {
          data[key] = 0;
        }
      });
    }
    return data;
  };

  getChartDatasets = () => {
    let someData = [];
    this.chartDatasets.forEach((dataset) => {
      someData.push(...dataset);
    });
    someData.forEach((dataset) => {
      if (dataset && typeof dataset == "object") {
        dataset.data = this.makeValuesZeroes(dataset.data);
      }
    });

    return someData;
  };

  getChartColorDatasets = () => {
    if (this.chartClassName == "StackedBarChart") {
      return this.chartLabels.map((c) => {
        return { label: c };
      });
    }
    return this.getChartDatasets();
  };

  setChartTitleRef = (ref) => {
    this.chartTitleRef = ref;
  };

  updateChart() {
    this.forceUpdate();
  }

  handleChartOptionsChange = (props, callBack) => {
    this.chartOptions = Object.assign({ ...this.chartOptions }, props);
    if (
      ChartTypes[this.chartClassName].name == ChartTypes.StackedBarChart.name ||
      ChartTypes[this.chartClassName].name == ChartTypes.HorizontalBarChart.name
    ) {
      this.chartRef.options = this.getThisChartOptions();
    } else {
      this.chartRef.options = this.getChartOptions();
    }
    if (props.hasOwnProperty("dataLabelsFontSize")) {
      this.buildData();
      this.chartRef.data.datasets = this.getData();
    }
    this.updateChart();

    this.chartRef.update();
    if (this.props.chartOptionsObj) {
      this.props.chartOptionsObj.chartOptions = this.chartOptions;
    }
    callBack && callBack();
  };

  handleChartDataOptionsChange = (props, callBack) => {
    this.chartOptions = Object.assign({ ...this.chartOptions }, props);
    if (props.gapWidth) {
      this.chartRef.data.datasets.forEach(
        (c) => (c.barPercentage = this.chartOptions.gapWidth / 10),
      );
    } else if (props.categoryGapWidth) {
      this.chartRef.data.datasets.forEach(
        (c) => (c.categoryPercentage = this.chartOptions.categoryGapWidth / 10),
      );
    }
    this.chartRef.update();

    if (this.props.chartOptionsObj) {
      this.props.chartOptionsObj.chartOptions = this.chartOptions;
    }
    if (props.hasOwnProperty("dollarDifference") || props.hasOwnProperty("percentDifference")) {
      this.forceUpdate();
    }
    callBack && callBack();
  };

  setChartWidth = (width) => {
    this.chartConfig.width = width;
    if (this.chartClassName == "StackedBarChart") {
      this.chartConfig.width = width;
      this.chartResizerRef.style.width = `${this.getChartContainerWidth()}%`;
      this.chartContainerRef.style.width = `${this.getChartWidth()}%`;
      if (this.tableRef) {
        this.tableRef.style.width = `${this.getTableWidth()}%`;
      }
    } else {
      this.chartContainerRef.style.width = `${this.chartConfig.width}%`;
    }
  };

  changeChartWidth = () => {
    if (this.chartContainerRef.style.width) {
      this.chartContainerRef.style.width = this.chartContainerRef.style.width / 2;
    } else {
      this.chartContainerRef.style.width = "50%";
    }
  };

  getTitleAlignment = (val) => {
    if (val === "start") {
      return "flex-start";
    }
    if (val === "middle") {
      return "center";
    }
    return "flex-end";
  };

  saveCurrentSettings = () => {
    if (this.props.saveChartOptions) {
      let changeObj = {};

      if (this.lastSavedBenchmarkValue !== this.chartOptions.benchmarkValue) {
        this.lastSavedBenchmarkValue = this.chartOptions.benchmarkValue;
        Object.assign(changeObj, { benchmarkValue: this.chartOptions.benchmarkValue });
      }

      if (this.lastSavedBackgroundChartColor) {
        Object.assign(changeObj, {
          chartDatasetsColors: this.chartDatasets[0].map((d) => d.backgroundColor),
        });
      }

      if (this.lastSavedDatalabelsDecimalPoints !== this.chartOptions.datalabelsDecimalPoints) {
        this.lastSavedDatalabelsDecimalPoints = this.chartOptions.datalabelsDecimalPoints;
        Object.assign(changeObj, {
          datalabelsDecimalPoints: this.chartOptions.datalabelsDecimalPoints,
        });
      }

      if (this.lastSavedBenchmark !== this.chartOptions.benchmark) {
        this.lastSavedBenchmark = this.chartOptions.benchmark;
        Object.assign(changeObj, { benchmark: this.chartOptions.benchmark });
      }
      if (this.lastSavedDetailedNumbers !== this.chartOptions.showDetailedNumbers) {
        this.lastSavedDetailedNumbers = this.chartOptions.showDetailedNumbers;
        Object.assign(changeObj, { showDetailedNumbers: this.chartOptions.showDetailedNumbers });
      }
      if (this.lastSavedContribution !== this.chartOptions.showContribution) {
        this.lastSavedContribution = this.chartOptions.showContribution;
        Object.assign(changeObj, { showContribution: this.chartOptions.showContribution });
      }
      if (this.lastSavedNumberOfMonths !== this.chartOptions.numberOfMonths) {
        this.lastSavedNumberOfMonths = this.chartOptions.numberOfMonths;
        Object.assign(changeObj, { numberOfMonths: this.chartOptions.numberOfMonths });
      }
      if (Object.values(changeObj).length > 0) {
        this.props.saveChartOptions(changeObj);
      }
    }
  };

  changeChartType = (e, value) => {
    this.props.changeChartType(ChartTypes[value].name, () => {
      this.props.saveChartOptions &&
        this.props.saveChartOptions({ chartClassType: ChartTypes[value].name });
    });
  };

  getBottomItemsContainer() {
    return null;
  }

  getAdditionalChartSettings() {
    return null;
  }

  getAssets = () => {
    let assets = [Assets.getCurrentAssetsTotals(), Assets.getLongTermAssetsTotals()];
    return assets.flatMap((c) => this.getDriversAll(c)).filter((c) => !!c);
  };

  getDriversAll = (asset) => {
    if (asset) {
      return asset.getChildDrivers().filter((c) => c.isValid);
    } else {
      return null;
    }
  };

  getHeader = (value = null) => {
    if (value && isNaN(value)) {
      return value["Header"];
    }
    if (!isNaN(value)) {
      if (this.props.snapShotData) {
        if (value === 0) {
          return this.props.snapShotData.headerDateFrom;
        } else {
          return this.props.snapShotData.headerDateTo;
        }
      }
    }
  };

  getCompPeriod() {
    return "";
  }

  getFinances = () => {
    let finances = Financing.getFinanceTotals().getChildDrivers();
    return finances.flatMap((c) => this.getDriversAll(c)).filter((c) => !!c);
  };

  getTitleButton = () => {
    if (this.props.titleButton) {
      return this.props.titleButton;
    }
  };

  getSingleIndicatorDriverName = () => {
    let driver = this.getRevenueFunc(this.chartData.driverKeys[0]);
    if (driver) {
      if (Array.isArray(driver)) {
        if (StreamTypes[this.chartData.driverKeys[0]]) {
          return StreamTypes[this.chartData.driverKeys[0]].DriverName;
        }
        if (driver.length) {
          return driver.map(
            (c, index) => `${c.DriverName}${index !== driver.length - 1 ? ", " : ""}`,
          );
        }
      }
      return driver.DriverName;
    }
    if (StreamTypes[this.chartData.driverKeys[0]]) {
      return StreamTypes[this.chartData.driverKeys[0]].DriverName;
    }
  };

  getChartTitle = () => {
    const title = this.props.isFirstDriverName
      ? this.getSingleIndicatorDriverName()
      : this.chartOptions.title;
    if (this.props.isSaasDashboard) {
      // info icon
      const chartDescription = ChartDescriptions[title];
      if (chartDescription) {
        return (
          <>
            {title}
            <Mx_Tooltip_Hover title={chartDescription} infoIcon />
          </>
        );
      }
    }
    return title;
  };

  getTitleElement = (dateKey = "month") => {
    return (
      <div className={"chart_items_container"}>
        <ContextHook setDataManager={this.setDataManager} />
        <div
          className={"chart_title_container"}
          style={{ display: "flex", width: "100%", justifyItems: "space-between" }}
        >
          <div
            ref={this.setChartTitleRef}
            className={"chart_title_wrapper"}
            style={{ alignItems: this.getTitleAlignment(this.chartOptions.titleAlign) }}
          >
            {this.chartOptions.showTitle && (
              <div style={{ fontSize: this.chartOptions.titleFontSize }} className={"chart_title"}>
                {this.getChartTitle()} {this.getTitleButton()}
              </div>
            )}
            {this.chartOptions.showSubtitle && (
              <span
                style={{ fontSize: this.chartOptions.subTitleFontSize }}
                className={"chart_subtitle"}
              >
                {this.getHeader(
                  this.currentDateRange
                    ? this.currentDateRange.validDateFrom[
                        ReportFrequency[this.chartData.reportFrequency].PeriodType
                      ]
                    : 0,
                )}{" "}
                -{" "}
                {this.getHeader(
                  this.currentDateRange
                    ? this.currentDateRange.dateTo[
                        ReportFrequency[this.chartData.reportFrequency].PeriodType
                      ]
                    : 1,
                )}
                {this.getCompPeriod()} | {this.getNumberFormattingComponent()}
              </span>
            )}
            {/*{this.comparisonPeriodTotals[this.chartOptions.comparisonBar] && this.chartOptions.showHeadlineNumbers && <ChartActual actual={this.getChartActual(this.chartOptions.comparisonBar)} change={this.getChartTitleChange()} unit={this.getChartTitleGrowthUnit()}/>}*/}
          </div>
          {((!window.iscurrentlyPrinting &&
            global.Modeliks.PERMISSIONS.Dashboards.restrictions.edit.active) ||
            global.Modeliks.isAdmin) && (
            <div className={"chart_settings_container"}>
              {(this.chartClassName === ChartTypes.BarChart.key ||
                this.chartClassName === ChartTypes.LineChart.key) && (
                <Tabs
                  value={this.chartClassName}
                  textColor="secondary"
                  className="psdf_navigation_menu_buttons"
                  onChange={this.changeChartType}
                  indicatorColor="secondary"
                  aria-label="secondary tabs example"
                >
                  {Object.entries(ChangeableChartTypes).map(([key, value]) => {
                    return (
                      <Tab
                        key={`${key} ${value}`}
                        label={value.label}
                        value={key}
                        className="psdf_navigation_menu_tab"
                      />
                    );
                  })}
                </Tabs>
              )}

              {this.getAdditionalChartSettings() && (
                <Menu
                  onMouseDown={(e) => e.preventDefault()}
                  direction={"left"}
                  menuButton={
                    <IconButton onMouseDown={(e) => e.preventDefault()}>
                      <SettingsIcon />
                    </IconButton>
                  }
                >
                  {/*<FocusableItem className={'menu_item_focus'}>*/}
                  {/*    {({ref}) => {*/}
                  {/*        return <>*/}
                  {/*            <span>Benchmark</span>*/}
                  {/*            <input onBlur={() => this.saveCurrentSettings()} ref={ref} type="number"*/}
                  {/*                   placeholder="Type to filter"*/}
                  {/*                   value={this.chartOptions.benchmarkValue} onChange={e => {*/}
                  {/*                this.chartOptions.benchmarkValue = e.target.value;*/}
                  {/*                this.forceUpdate(() => {*/}
                  {/*                    if (this.chartRef) {*/}
                  {/*                        this.chartRef.options = this.getChartOptions();*/}
                  {/*                        this.chartRef.update();*/}
                  {/*                    }*/}
                  {/*                })*/}
                  {/*            }}/>*/}
                  {/*        </>*/}
                  {/*    }}*/}
                  {/*</FocusableItem>*/}
                  {/*<MenuItem onClick={(e) => {*/}
                  {/*    e.keepOpen = true*/}
                  {/*}}>*/}
                  {/*    <CircleCheckboxM label={'Show Benchmark'} size='medium' checked={this.chartOptions.benchmark}*/}
                  {/*                     onChange={(e) => {*/}
                  {/*                         this.chartOptions.benchmark = e.target.checked;*/}
                  {/*                         this.forceUpdate(() => {*/}
                  {/*                             if (this.chartRef) {*/}
                  {/*                                 this.chartRef.options = this.getChartOptions();*/}
                  {/*                                 this.chartRef.update();*/}
                  {/*                             }*/}
                  {/*                             this.saveCurrentSettings();*/}
                  {/*                         })*/}
                  {/*                     }}/>*/}
                  {/*</MenuItem>*/}
                  {this.getAdditionalChartSettings()}
                </Menu>
              )}
            </div>
          )}
        </div>
        {this.getBottomItemsContainer()}
      </div>
    );
  };

  SaveChart = (callBack) => {
    // this.removeUnusedColors();
    request
      .put(`/api/master/dashboardcharts`)
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .query({ ID: this.ID })
      .send(this.getDataFromChart())
      .then((res) => {
        callBack && callBack();
      });
  };

  getDataForObjSaving = () => {
    return {
      chartClassType: ChartTypes[this.chartClassName].name,
      chartOptions: this.chartOptions,
      chartData: this.chartData,
      chartConfig: this.chartConfig,
      chartType: this.chartType,
    };
  };

  removeUnusedColors = () => {
    let chartDatasets = this.getChartColorDatasets();
    if (chartDatasets.length) {
      Object.keys(this.chartOptions.barColorKeys)
        .filter((c) => !!c)
        .forEach((barColor) => {
          if (!chartDatasets.find((c) => c.label === barColor)) {
            delete this.chartOptions.barColorKeys[barColor];
          }
        });
    }
  };

  getDataFromChart = () => {
    let data = {
      Data: JSON.stringify({
        chartClassType: ChartTypes[this.chartClassName].name,
        chartOptions: this.chartOptions,
        chartData: this.chartData,
        chartConfig: this.chartConfig,
        chartType: this.chartType,
      }),
      ID: this.ID,
      Name: this.Name,
    };
    if (!this.ID) {
      delete data.ID;
    }
    return data;
  };

  changeNumberFormatting(numberFormat) {
    this.chartOptions = Object.assign({ ...this.chartOptions }, { numberFormat: numberFormat });
    this.handleChartOptionsChange({ numberFormat: numberFormat }, () => {
      this.props.saveChartOptions && this.props.saveChartOptions({ numberFormat: numberFormat });
    });
  }

  getNumberFormattingMenuItems = (unit) => {
    if (window.iscurrentlyPrinting) {
      return `${unit} ${NumberFormatTypes[this.chartOptions.numberFormat].name}`;
    }
    let menuItems = Object.values(NumberFormatTypes).map(
      (c) =>
        new ReusableMenuItem(
          `${unit} ${c.name}`,
          () => this.changeNumberFormatting(c.key),
          this.chartOptions.numberFormat == c.key,
        ),
    );
    return (
      <ReusableMenu
        menuItems={menuItems}
        menuButton={
          <Button
            style={{ fontSize: `${this.chartOptions.subTitleFontSize}px` }}
            disabled={window.iscurrentlyPrinting}
            onMouseDown={(e) => e.stopPropagation()}
          >
            {unit} {NumberFormatTypes[this.chartOptions.numberFormat].name}
          </Button>
        }
      />
    );
  };

  getUnitType = () => {
    if (!global.Modeliks.CompanyInfo && this.props.snapShotData) {
      return this.props.snapShotData.unitType;
    }
    if (this.chartOptions.isWeightedAverage) {
      return this.chartOptions.unitType;
    }
    return this.UnitType;
  };

  getNumberFormattingComponent = () => {
    let value = "";

    let unitType = this.getUnitType();

    if (unitType === UnitTypes.Percentage) {
      return "%";
    } else if (unitType === UnitTypes.Price) {
      if (global.Modeliks.CompanyInfo) {
        value = global.Modeliks.CompanyInfo.Currency.value;
      } else if (this.props.snapShotData) {
        value = this.props.snapShotData.Currency.value;
      }
    } else {
      value = "#";
    }
    //
    // if(unitType === UnitTypes.Multiple){
    //     value = '#'
    // }

    return this.getNumberFormattingMenuItems(value);
  };

  render() {
    return false;
  }
}

Chart.propTypes = {
  ID: PropTypes.number,
  Name: PropTypes.string,
  chartOptions: PropTypes.object,
  chartData: PropTypes.object,
  chartConfig: PropTypes.object,
  snapShotData: PropTypes.object,
  chartType: PropTypes.string,
  saveChartOptions: PropTypes.func,
  handleSnapShotData: PropTypes.func,
};

Chart.defaultProps = {
  Name: "",
  chartType: ChartTypes.BarChart.type,
  chartConfig: {
    width: ChartWidth.fullScreen.value,
    name: "Chart",
    waterFallType: WaterfallChartTypes.SingleComparisonPeriod,
  },
  chartOptions: {
    showContribution: false,
    showDetailedNumbers: false,
    benchmark: false,
    benchmarkValue: 0,
    showHeadlineNumbers: false,
    backgroundColor: "#FFFFFF",
    borderColor: "#FFFFFF",
    showVerticalAxis: false,
    showHorizontalAxis: true,
    showTitle: true,
    titleAlign: "start",
    showSubtitle: true,
    title: "Title",
    subtitle: "Subtitle",
    xGrid: false,
    yGrid: false,
    displayLegend: false,
    dataLabelsFontSize: 16,
    datalabelsDecimalPoints: 0,
    axisFontSize: 16,
    legendFontSize: 12,
    gapWidth: 5,
    categoryGapWidth: 7,
    titleFontSize: 20,
    subTitleFontSize: 16,
    dataType: "number",
    minimumVerticalAxisValue: "",
    maximumVerticalAxisValue: "",
    minimumHorizontalAxisValue: "",
    maximumHorizontalAxisValue: "",
    legendPosition: "bottom",
    showDataLabels: true,
    hideSeriesDataLabels: false,
    DataLabelsPosition: "end",
    hasGridlines: true,
    dollarDifference: false,
    percentDifference: false,
    comparisonBar: ComparisonPeriod.actual,
    barColorKeys: { [StreamTypes.Revenue.key]: ["BelizeHole", 500] },
    hasCustomColor: true,
    numberFormat: NumberFormatTypes.fullNumbers.key,
    isWeightedAverage: false,
    unitType: UnitTypes.Price,
    numberOfMonths: 12,
    singleColor: ["BelizeHole", 500],
  },
  chartData: {
    driverKeys: [StreamTypes.Revenue.key],
    mainDriverKeys: [StreamTypes.Revenue.key],
    weightingDriverKeys: [StreamTypes.Expense.key],

    reportFrequency: "monthly",
    comparisonPeriod: [ComparisonPeriod.actual, ComparisonPeriod.forecast],

    dateFrom: { month: new Date().getMonth(), year: new Date().getFullYear() },
    dateTo: { month: new Date().getMonth() - 1, year: new Date().getFullYear() + 1 },

    series: ChartDataTypes.driver,
    categories: ChartDataTypes.reportFrequency,
    growth: false,
  },
};

const ContextHook = (props) => {
  const { properties, DataManager } = useContext(MyContext);
  if (props.setDataManager) {
    props.setDataManager(DataManager);
  }
};

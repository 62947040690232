import Bowser from "bowser";

export class BrowserInfoService {
  instance = null;
  parser;

  constructor() {
    // initialize Bowser with the current user agent
    this.parser = Bowser.getParser(window.navigator.userAgent);
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new BrowserInfoService();
    }

    return this.instance;
  }

  getBrowserVersion() {
    return this.parser.getBrowserVersion();
  }
  
  getBrowserName() {
    return this.parser.getBrowserName();
  }

  isBrowserSupported() {
    return this.parser.satisfies({
      chrome: '>100',
      firefox: '>91',
      edge: '>100',
      opera: '>86',
      "samsung internet": '>17.0',
      android: '>101',
    });
  }
}

import Personnel from "../Personnel";
import { DriverPeriodTypes } from "../CalculatedDriver/constants";
import { maxRows } from "../../../components/constants/finance";

const PersonnelDataGridRows = (Storage) => {
  const personnelTotals = Personnel.getPersonnelTotals();
  const personnelBurdenRateTotals = Personnel.getPersonnelTotalsBurdenRate();
  const personnelBurdenRateEmpCostTotals = Personnel.getPersonnelTotalsBurdenEmpCostRate();
  const personnelMaxRows = maxRows + 1000;
  Storage.dataGridRows = {
    streamTotals: Personnel.getPersonnelCategoriesTotals(),
    burdenTotals: Personnel.getPersonnelCategoriesTotalsBurdenRate(),
    streamTotalsGrowth: Personnel.getTotalsGrowth(personnelTotals),
    personnelDirectLaborTotals: Personnel.getPersonnelDirectLaborTotals(),
    personnelTotalEmployees: Personnel.getPersonnelEmployeeTotals(),
    allDrivers: personnelTotals,
    gridRows: [],
    gridRowsBurdenRate: [],
    gridRowsBurdenRateActual: [],
    totalsRows: [],
    maxRows: personnelMaxRows,
    totalRows: 0,
  };

  let totalRows = Storage.dataGridRows.totalRows;

  const revenue_only_create = (personnel, parentRevenue, level = 0, stopRecursive = false) => {
    let row = {};

    if (personnel) {
      let children = personnel.getChildDrivers();
      totalRows = totalRows + 1;
      if (level > 1) {
        row = {
          id: personnel.ID,
          header: personnel.Name,
          revenue: personnel,
          hasEdit: false,
          onEditChildren: () => {},
          onDeleteClick: () => {},
          data: personnel,
          children: [],
        };
      } else if (level === 1) {
        row = {
          id: personnel.ID,
          header: personnel.Name,
          revenue: personnel,
          onEditClick: () => {},
          hasEdit: true,
          onDeleteClick: () => {},
          data: personnel,
          children: [],
        };
      } else {
        row = {
          id: personnel.ID,
          header: personnel.Name,
          revenue: personnel,
          hasEdit: false,
          boldRowHeader: true,
          onDeleteClick: () => {},
          data: personnel,
          isExpanded: true,
          children: [],
        };
      }

      if (
        children &&
        !stopRecursive &&
        children.length > 0 &&
        personnel.Ref_Field !== Personnel.DriversDesc.EmployeeBenefits.driverID &&
        personnel.Ref_ID !== 0
      ) {
        children.forEach((driver) => {
          if (totalRows <= maxRows) {
            row.children.push(
              revenue_only_create(
                driver,
                personnel,
                level + 1,
                personnel.PeriodType === DriverPeriodTypes.Previous,
              ),
            );
          }
        });
      }
      if (row.data && row.data.Ref_Field === "employee_number") {
        row.IsNumberFormatDisabled = true;
      }

      return row;
    } else {
      return null;
    }
  };

  const revenue_only_create_burden_rate = (
    personnel,
    parentRevenue,
    level = 0,
    stopRecursive = false,
  ) => {
    let row = {};

    if (personnel) {
      let children = personnel.getChildDrivers();
      totalRows = totalRows + 1;
      if (level > 1) {
        row = {
          id: personnel.ID,
          header: personnel.Name,
          revenue: personnel,
          hasEdit: false,
          disabledEdit: true,
          hasDelete: false,
          isNotEditable: true,
          onEditChildren: () => {},
          onDeleteClick: () => {},
          data: personnel,
          children: [],
        };
      } else if (level === 1) {
        row = {
          id: personnel.ID,
          header: personnel.Name,
          revenue: personnel,
          onEditClick: () => {},
          hasEdit: false,
          disabledEdit: true,
          hasDelete: false,
          isNotEditable: true,
          onDeleteClick: () => {},
          data: personnel,
          children: [],
        };
      } else {
        row = {
          id: personnel.ID,
          header: personnel.Name,
          revenue: personnel,
          hasEdit: false,
          disabledEdit: true,
          hasDelete: false,
          isNotEditable: true,
          boldRowHeader: true,
          onDeleteClick: () => {},
          data: personnel,
          isExpanded: true,
          children: [],
        };
      }

      if (
        children &&
        !stopRecursive &&
        children.length > 0 &&
        personnel.Ref_Field !== Personnel.DriversDesc.EmployeeBenefits.driverID &&
        personnel.Ref_ID !== 0 &&
        level <= 1
      ) {
        children.forEach((driver) => {
          if (totalRows <= maxRows) {
            row.children.push(
              revenue_only_create_burden_rate(
                driver,
                personnel,
                level + 1,
                personnel.PeriodType === DriverPeriodTypes.Previous,
              ),
            );
          }
        });
      }
      if (row.data && row.data.Ref_Field === "employee_number") {
        row.IsNumberFormatDisabled = true;
      }

      return row;
    } else {
      return null;
    }
  };
  const revenue_only_create_burden_rate_actuals = (
    personnel,
    parentRevenue,
    level = 0,
    stopRecursive = false,
  ) => {
    let row = {};
    if (personnel) {
      if (level > 1) {
        row = {
          id: personnel.ID,
          header: personnel.Name,
          revenue: personnel,
          hasEdit: false,
          disabledEdit: true,
          hasDelete: false,
          isExpanded: false,
          isNotEditable: true,
          onEditChildren: () => {},
          onDeleteClick: () => {},
          data: personnel,
          children: [],
        };
      } else if (level === 1) {
        row = {
          id: personnel.ID,
          header: personnel.Name,
          revenue: personnel,
          onEditClick: () => {},
          hasEdit: false,
          disabledEdit: true,
          isExpanded: false,
          hasDelete: false,
          isNotEditable: false,
          onDeleteClick: () => {},
          data: personnel,
          children: [],
        };
      } else {
        row = {
          id: personnel.ID,
          header: personnel.Name,
          revenue: personnel,
          hasEdit: false,
          disabledEdit: true,
          hasDelete: false,
          isNotEditable: false,
          isExpanded: true,
          boldRowHeader: true,
          onDeleteClick: () => {},
          data: personnel,
          children: [],
        };
      }
      let children = personnel.getChildDrivers();
      if (
        children &&
        !stopRecursive &&
        children.length > 0 &&
        personnel.Ref_Field !== Personnel.DriversDesc.EmployeeBenefits.driverID &&
        personnel.Ref_ID !== 0
      ) {
        const ParentStream = personnel.getParentStream();
        if (ParentStream) {
          row.children.push(
            revenue_only_create_burden_rate_actuals(
              ParentStream.EmployeeCost,
              personnel,
              level + 1,
              true,
            ),
          );
          row.children.push(
            revenue_only_create_burden_rate_actuals(
              ParentStream.EmployeeNumber,
              personnel,
              level + 1,
              true,
            ),
          );
          row.children.push(
            revenue_only_create_burden_rate_actuals(
              ParentStream.EmployeeBenefits,
              personnel,
              level + 1,
              true,
            ),
          );
        } else {
          children.forEach((driver) => {
            row.children.push(
              revenue_only_create_burden_rate_actuals(
                driver,
                personnel,
                level + 1,
                personnel.PeriodType === DriverPeriodTypes.Previous,
              ),
            );
          });
        }
      }
      if (row.data.Ref_Field === "employee_benefits") {
        row.header = "Other Benefits per Employee";
      }

      if (row.data.Ref_Field === "employee_cost") {
        row.header = "Cost per Employee";
      }

      return row;
    } else {
      return null;
    }
  };

  const row_totals = (totals = personnelTotals) => {
    totalRows = totalRows + 2;
    return [
      {
        id: totals.ID,
        unit: global.Modeliks.CompanyInfo.Currency.value,
        header: "Total Employee Cost",
        cssClass: "totals",
        boldRowHeader: true,
        data: totals,
      },
      {
        id: Storage.dataGridRows.personnelTotalEmployees.ID,
        unit: global.Modeliks.CompanyInfo.Currency.value,
        header: "Total Number of Employees ",
        cssClass: "totals",
        boldRowHeader: true,
        IsNumberFormatDisabled: true,
        data: Storage.dataGridRows.personnelTotalEmployees,
      },
    ];
  };
  const row_totals_burden_rate = (totals = personnelBurdenRateTotals) => {
    totalRows = totalRows + 2;
    return [
      {
        id: totals.ID,
        unit: global.Modeliks.CompanyInfo.Currency.value,
        header: "Total Employee Cost",
        cssClass: "totals",
        boldRowHeader: true,
        data: totals,
      },
    ];
  };

  const row_totals_burden_rate_emp_cost = (totals = personnelBurdenRateEmpCostTotals) => {
    totalRows = totalRows + 2;
    totals.Values.forEach((value) => {
      value.Formula = `MxMath.Sum([${Personnel.getPersonnelCategoriesEmployeesCostTotalsBurdenRate()
        .map(
          (d) =>
            `(${d.getItemByDateSufix(value.Date.sufix)} * ${d.getItemByDateSufix(value.Date.sufix).ID_f.replace("burden-employee-totals-cost", "number-totals")})`,
        )
        .join(
          ",",
        )}]) / ${Personnel.getPersonnelEmployeeTotals().getItemByDateSufix(value.Date.sufix)}`;
    });

    return [
      {
        id: totals.ID,
        unit: global.Modeliks.CompanyInfo.Currency.value,
        header: "Total Employee Cost",
        cssClass: "totals",
        boldRowHeader: true,
        data: totals,
      },
    ];
  };

  const FinanceExpensePersonnelParent = (revenue, isExpense = false) => {
    return revenue_totals_create({
      id: revenue.ID,
      unit: global.Modeliks.CompanyInfo.Currency.value,
      header: isExpense ? "Other Direct Expenses" : "Direct Labor",
      data: revenue,
      boldRowHeader: true,
      revenue: revenue,
      children: [],
    });
  };

  const revenue_totals_create = (
    revenue,
    isChild = false,
    level = 0,
    stopRecursive = false,
    hasEdit = false,
  ) => {
    let row = {};
    if (revenue) {
      totalRows = totalRows + 1;
      if (isChild) {
        row = {
          id: revenue.ID,
          header: revenue.Name,
          data: revenue,
          children: [],
        };
        if (revenue.getChildDrivers() && !stopRecursive) {
          let children = revenue.getChildDrivers();
          level++;
          if (children && revenue.Ref_Field !== Personnel.DriversDesc.EmployeeBenefits.driverID) {
            children.map((driver) => {
              if (
                driver.Ref_Table !== global.Modeliks.Tables.Finance_Revenues.TableName ||
                driver.Ref_ID === 0
              ) {
                if (totalRows <= maxRows) {
                  row.children.push(
                    revenue_totals_create(
                      driver,
                      true,
                      level++,
                      driver.PeriodType === DriverPeriodTypes.Previous,
                    ),
                  );
                }
              }
            });
          }
        }
        if (hasEdit) {
          (row.onEditClick = () => {}), (row.hasEdit = true);
        }
      } else {
        row = revenue;
        let children = revenue.data.getChildDrivers();
        if (children) {
          level++;
          children.map((driver) => {
            if (totalRows <= maxRows) {
              row.children.push(
                revenue_totals_create(
                  driver,
                  true,
                  level,
                  driver.PeriodType === DriverPeriodTypes.Previous,
                  true,
                ),
              );
            }
          });
        }
      }
      return row;
    } else {
      return null;
    }
  };

  const createGridRows = () => {
    Storage.dataGridRows.gridRows = Storage.dataGridRows.streamTotals
      .filter((c) => c !== null && !c.IsSimple)
      .map((personnel) => revenue_only_create(personnel));
    Storage.dataGridRows.gridRowsBurdenRate = Storage.dataGridRows.burdenTotals
      .filter((c) => c !== null && !c.IsSimple)
      .map((personnel) => revenue_only_create_burden_rate(personnel));
    Storage.dataGridRows.gridRowsBurdenRateActual = Storage.dataGridRows.burdenTotals
      .filter((c) => c !== null && !c.IsSimple)
      .map((personnel) => revenue_only_create_burden_rate_actuals(personnel));
    Storage.dataGridRows.totalsRows = row_totals();
    Storage.dataGridRows.totalsRowsBurdenRate = row_totals_burden_rate();
    Storage.dataGridRows.totalsRowsBurdenRateEmployeeCost = row_totals_burden_rate_emp_cost();

    const personnel = Storage.dataGridRows.personnelDirectLaborTotals;

    if (personnel && personnel.Formula && personnel.Formula.length > 3) {
      global.Modeliks.CostSaleStore.dataGridRows.gridRows.push(
        FinanceExpensePersonnelParent(personnel, false),
      );
    }
    Storage.dataGridRows.totalRows = totalRows;
  };

  createGridRows();
};

export default PersonnelDataGridRows;

// import HeaderPortal from './HeaderPortal'
import React, { Fragment, useState } from "react";
import "../HeaderTextEditor.scss";
import "../HeaderDefault.scss";
import { Charts, FinancialTables } from "../../components/IconButtons/SubMenuIcons";
import {
  BubbleChart,
  ClusteredHorizontalBar,
  ClusteredVerticalBar,
  Combo,
  DoughnutChart,
  FloatingBarChart,
  HorizontalBar,
  LineChart,
  PieChart,
  ScatterChart,
  StackedBarChart,
  StackedBarLine,
  VerticalBar,
} from "../../components/IconButtons/SubMenuIcons";
import { SlideObject, SlideTypes } from "../../SlideObjects/SlideStructures";
import { Menu, MenuItem, SubMenu, MenuButton } from "@szhsin/react-menu";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { ReportsTableSizes, ReportsTypes } from "../../../../data/Finance/Reports/constants";
import { tableDateType } from "../../SlideObjects/FinanceTable/FinanceTable";

function InsertFinancialTableComponent(props) {
  console.log("Object.keys(ReportsTableSizes)", Object.keys(ReportsTableSizes));
  console.log("Object.keys(ReportsTypes)", Object.keys(ReportsTypes));

  const chartMenuItem = (nameInput, frequency) => {
    return Object.keys(ReportsTableSizes).map((r, i) => {
      return Object.keys(ReportsTypes)
        .filter((key) => !!ReportsTypes[key].show && ReportsTypes[key].Name === nameInput)
        .map((key, index) => {
          return (
            <MenuItem
              key={key + frequency}
              onClick={() => {
                props.onNewObject(
                  new SlideObject(
                    SlideTypes.financeTable,
                    {
                      dateType: tableDateType[frequency],
                      reportType: ReportsTypes[key].key,
                      tableSize: ReportsTableSizes[r].size,
                    },
                    { width: 1100, height: 530, top: 170, left: 90 },
                  ),
                );
              }}
            >
              {({ hover }) => (
                <span className={"item_name"} style={{ color: hover ? "#01AFFD" : "unset" }}>
                  {ReportsTypes[key].Name +
                    " " +
                    r +
                    ` (${frequency === "month" ? "Monthly" : "Annual"})`}
                </span>
              )}
            </MenuItem>
          );
        });
    });
  };

  const menuItems = () => {
    return (
      <>
        <SubMenu
          label={({ open }) => (
            <span className="item_name" style={{ color: open ? "#01AFFD" : "unset" }}>
              Profit & Loss
            </span>
          )}
        >
          <SubMenu
            className={"nested_sub_menu"}
            label={({ open }) => (
              <span className="item_name" style={{ color: open ? "#01AFFD" : "unset" }}>
                Monthly
              </span>
            )}
          >
            {chartMenuItem("Profit and Loss", "month")}
          </SubMenu>
          <SubMenu
            className={"nested_sub_menu"}
            label={({ open }) => (
              <span className="item_name" style={{ color: open ? "#01AFFD" : "unset" }}>
                Annual
              </span>
            )}
          >
            {chartMenuItem("Profit and Loss", "year")}
          </SubMenu>
        </SubMenu>

        <SubMenu
          label={({ open }) => (
            <span className="item_name" style={{ color: open ? "#01AFFD" : "unset" }}>
              Balance Sheet
            </span>
          )}
        >
          <SubMenu
            className={"nested_sub_menu"}
            label={({ open }) => (
              <span className="item_name" style={{ color: open ? "#01AFFD" : "unset" }}>
                Monthly
              </span>
            )}
          >
            {chartMenuItem("Balance Sheet", "month")}
          </SubMenu>
          <SubMenu
            className={"nested_sub_menu"}
            label={({ open }) => (
              <span className="item_name" style={{ color: open ? "#01AFFD" : "unset" }}>
                Annual
              </span>
            )}
          >
            {chartMenuItem("Balance Sheet", "year")}
          </SubMenu>
        </SubMenu>

        <SubMenu
          label={({ open }) => (
            <span className="item_name" style={{ color: open ? "#01AFFD" : "unset" }}>
              Cash flow statement
            </span>
          )}
        >
          <SubMenu
            className={"nested_sub_menu"}
            label={({ open }) => (
              <span className="item_name" style={{ color: open ? "#01AFFD" : "unset" }}>
                Monthly
              </span>
            )}
          >
            {chartMenuItem("Cash Flow", "month")}
          </SubMenu>
          <SubMenu
            className={"nested_sub_menu"}
            label={({ open }) => (
              <span className="item_name" style={{ color: open ? "#01AFFD" : "unset" }}>
                Annual
              </span>
            )}
          >
            {chartMenuItem("Cash Flow", "year")}
          </SubMenu>
        </SubMenu>
      </>
    );
  };

  //<Tooltip disableHoverListener={props.disableEdit}  arrow title={'Insert Chart'}><span>
  // </span></Tooltip>

  //   const TableInsert = () => {
  //     return (
  //       <SubMenu
  //         className="header_menu smaller_header_menu arrow"
  //         label={({ open }) => (
  //           <span>
  //             <FinancialTables active={open} />
  //           </span>
  //         )}>
  //         {Object.keys(ReportsTableSizes).map((r, i) => {
  //           return Object.keys(ReportsTypes)
  //             .filter((key) => !!ReportsTypes[key].show)
  //             .map((key, index) => {
  //               return (
  //                 <MenuItem
  //                   onClick={() => {
  //                     props.onNewObject(
  //                       new SlideObject(
  //                         SlideTypes.financeTable,
  //                         {
  //                           dateType: tableDateType["month"],
  //                           reportType: ReportsTypes[key].key,
  //                           tableSize: ReportsTableSizes[r].size,
  //                         },
  //                         { width: 600, height: 600, top: 480, left: 100 }
  //                       )
  //                     );
  //                   }}>
  //                   <span className={"item_name"}>
  //                     {ReportsTypes[key].Name + " " + r + " (Monthly)"}
  //                   </span>
  //                 </MenuItem>
  //               );
  //               // <MenuItem key={'mnth+'+i+index} onClick={()=>{props.onNewObject(new SlideObject(SlideTypes.financeTable, {dateType: tableDateType.month, reportType: ReportsTypes[key], tableSize: ReportsTableSizes[r].size}, {width:600,height:600,top:480,left:100}))}}>{key + ' ' + r}</MenuItem>
  //             });
  //         })}
  //         {Object.keys(ReportsTableSizes).map((r, i) => {
  //           return Object.keys(ReportsTypes)
  //             .filter((key) => !!ReportsTypes[key].show)
  //             .map((key, index) => {
  //               return (
  //                 <MenuItem
  //                   onClick={() => {
  //                     props.onNewObject(
  //                       new SlideObject(
  //                         SlideTypes.financeTable,
  //                         {
  //                           dateType: tableDateType["year"],
  //                           reportType: ReportsTypes[key].key,
  //                           tableSize: ReportsTableSizes[r].size,
  //                         },
  //                         { width: 600, height: 600, top: 480, left: 100 }
  //                       )
  //                     );
  //                   }}>
  //                   <span className={"item_name"}>
  //                     {ReportsTypes[key].Name + " " + r + " (Annual)"}
  //                   </span>
  //                 </MenuItem>
  //               );
  //               // <MenuItem key={'mnth+'+i+index} onClick={()=>{props.onNewObject(new SlideObject(SlideTypes.financeTable, {dateType: tableDateType.month, reportType: ReportsTypes[key], tableSize: ReportsTableSizes[r].size}, {width:600,height:600,top:480,left:100}))}}>{key + ' ' + r}</MenuItem>
  //             });
  //         })}

  //         {/*<MenuItem onClick={()=>handleNewChartObj(6,100,20,600, 300)}>*/}
  //         {/*    <HorizontalBar/>*/}
  //         {/*    <span className='item_name'>Horizontal Bar</span>*/}
  //         {/*    /!*<span className='helper_text'>Shift+Alt</span>*!/*/}
  //         {/*</MenuItem>*/}

  //         {menuItems()}
  //       </SubMenu>
  //     );
  //   };

  return (
    <>
      <SubMenu
        className="header_menu smaller_header_menu arrow"
        label={({ open }) => (
          <span>
            <div style={{ display: "flex", columnGap: "15px", alignItems: "center" }}>
              <FinancialTables active={open} />
              <div style={{ color: open ? "#01AFFD" : "unset" }}>Data Table</div>
            </div>
          </span>
        )}
      >
        {menuItems()}
      </SubMenu>
    </>
  );
}

export default InsertFinancialTableComponent;

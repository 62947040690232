import request from "superagent";
import { DateHelper } from "./dates";
import dataStructure from "./datastructure";
import DriverValuesStorage from "./Finance/CalculatedDriver/DriverValuesStorage";
import DriversStorage from "./Finance/CalculatedDriver/DriversStorage";
import RevenuesStorage from "./Finance/Revenue/RevenuesStorage";
import CostSaleStorage from "./Finance/CostSales/CostSaleStorage";
import PersonnelStorage from "./Finance/Personnel/PersonnelStorage";
import ExpensesStorage from "./Finance/Expense/ExpensesStorage";
import AssetsStorage from "./Finance/Assets/AssetsStorage";
import FinancingStorage from "./Finance/Financing/FinancingStorage";
import TaxesStorage from "./Finance/Taxes/TaxesStorage";
import WorkingCapitalStorage from "./Finance/WorkingCapital/WorkingCapitalStorage";
import ReportsStorage from "./Finance/Reports/ReportsStorage";
import AccountReceivable from "./Finance/WorkingCapital/AccountReceivable";
import Inventory from "./Finance/WorkingCapital/Inventory";
import AccountPayable from "./Finance/WorkingCapital/AccountPayable";
import Financing from "./Finance/Financing";
import CashFlow from "./Finance/Reports/CashFlow";
import CustomKPIStorage from "./Finance/Dashboards/CustomKPI/CustomKPIStorage";
import { billing_types, Upgrade_Downgrade_types } from "./SubscriptionEnum";
import ValuationSettingsStorage from "./Finance/Valuation/ValuationSettingsStorage";
import ValuationCostOfCapitalStorage from "./Finance/Valuation/CostOfCapitalWACC/CostOfCapitalStorage";
import TransactionsMultiplesStorage from "./Finance/Valuation/TransactionMultiples/TransactionsMultiplesStorage";
import TradingMultiplesStorage from "./Finance/Valuation/TradingMultiples/TradingMultiplesStorage";
import { DateSettingsObject } from "../helpers/DateSettings";
import Valuation from "./Finance/Valuation";
import CostOfCapitalWACC from "./Finance/Valuation/CostOfCapitalWACC";
import TradingMultiples from "./Finance/Valuation/TradingMultiples";
import TransactionMultiples from "./Finance/Valuation/TransactionMultiples";
import DiscountedCashStorage from "./Finance/Valuation/DiscountedCash/DiscountedCashStorage";
import Assets from "./Finance/Assets";
import Personnel from "./Finance/Personnel";
import Expense from "./Finance/Expense";
import { DashboardSectionsObj } from "../pages/Admin/Dashboards/components/DashboardSectionsObj";
import Reports from "./Finance/Reports";
import ReportingDrivers from "./Finance/Reports/ReportingDrivers";
import ProfitLoss from "./Finance/Reports/ProfitLoss";
import { PERMISSIONS } from "./Permissions/Permissions";
import {
  SetPermissionsBySubscription,
  SetPermissionsByUserPermissions,
} from "./Permissions/AccessControl";
import { DialogTypes, ExceededLimitTypes } from "./Finance/constants";
import MxDataArray from "./MxDataArray";
import { defaultStages } from "../pages/Secure/PortfolioManagement";
import MxIDUseID from "../helpers/MxIUniqueD";
import ObjectBuilder from "../helpers/ObjectBuilder";
import { AllCurrencies } from "../helpers/constants";
import {versioningActionsCaller, versioningActionTypes} from "../helpers/VersioningHelper";
import {functions} from "../components/constants/functionNameMappings";
import httpService from "../services/api/httpService";

Object.keys(dataStructure).forEach(
  (key) => (dataStructure[key.toLowerCase()] = dataStructure[key]),
);

const debug = {
  debug: true,
  printCellData: window.localStorage.getItem("printCellData") ? true : false,
  setPrintCellData: (enable = false) => {
    debug.printCellData = enable;
    window.localStorage.setItem("printCellData", enable);
  },
  init_app: new Date(),
  log: (...args) => debug.debug && console.log(new Date() - debug.init_app, ...args),
};

global.log_msg = debug.log;

export class DataManager {
  debug = debug;
  AccountsInfo = null;
  Account = null;
  AccountUsers = [];
  User = null;
  UserPermissions = null;
  PERMISSIONS = JSON.parse(JSON.stringify(PERMISSIONS));
  NoAccount = false;
  isFirstLogin = null;
  shouldSaveTemporary = false;

  isAdmin = null;

  isInternal = false;

  StaticDataObject = null;

  InstructionsContent = {};

  PitchInstructionsContent = {};
  BPInstructionsContent = {};

  CustomDashboards = [];

  DashboardCharts = [];
  _CompanyInfo = null;
  Companies = [];

  PortfolioStages = null;

  ChartSettings = null;
  CompanyUserStages = null;

  AllUserCompanies = [];

  CompanyUsers = [];

  CompanyScenarios = [];
  CompanyScenarioInfo = null;
  PitchScenarios = [];
  PitchIndustries = [];
  PitchScenarioInfo = null;
  BusinessPlanScenarios = [];
  BusinessPlanIndustries = [];
  BusinessPlanScenarioInfo = null;
  BusinessPlanConfig = null;

  DashboardSection = null;
  _DashboardSettings = null;
  PitchFinancialChartsYearly = [];
  PitchFinancialChartsMonthly = [];
  SummaryDateSettings = null;

  redirectUrl = null;

  isAuth = window.localStorage.getItem("token") ? window.localStorage.getItem("token") : null;
  DateHelper = DateHelper;

  DriverValuesStore = null;
  DriversStore = null;

  RevenuesStore = null;
  CostSaleStore = null;
  PersonnelStore = null;
  ExpensesStore = null;
  AssetsStore = null;
  FinancingStore = null;
  TaxesStore = null;
  WorkingCapitalStore = null;
  ReportsStore = null;
  CustomKPIStore = null;
  ValuationSettingsStore = null;
  CostOfCapitalStore = null;
  DiscountedCashStore = null;
  TradingMultiplesStore = null;
  TransactionsMultiplesStore = null;

  static Tables = dataStructure;
  Tables = dataStructure;

  api_url = null;

  pendingTransactionID = null;

  limitInfo = {};
  subscriptionInfo = null;
  subscriptionPlans = null;
  transactionsInfo = null;

  mostUsedColors = {};

  allowedRouteKeys = [];

  cache_routes = {};

  doneGet = {};

  ObjectBuilder = null;

  disableCDUpdate = false;

  constructor(isAuth = true) {
    if (!isAuth) {
      this.isAuth = false;
    }
    this.debug.log("DataManager init");
    this.DriverValuesStore = DriverValuesStorage.create();
    this.DriverValuesStore.dataManager = this;
    this.ObjectBuilder = new ObjectBuilder();

    global.valIndexes = this.DriverValuesStore.indexes;

    this.DriversStore = DriversStorage.create();
    this.DriversStore.dataManager = this;
    this.DriversStore.driverValuesStorage = this.DriverValuesStore;

    this.RevenuesStore = RevenuesStorage.create();
    this.RevenuesStore.dataManager = this;
    this.RevenuesStore.driverStorage = this.DriversStore;

    this.CostSaleStore = CostSaleStorage.create();
    this.CostSaleStore.dataManager = this;
    this.CostSaleStore.driverStorage = this.DriversStore;

    this.PersonnelStore = PersonnelStorage.create();
    this.PersonnelStore.dataManager = this;
    this.PersonnelStore.driverStorage = this.DriversStore;

    this.ExpensesStore = ExpensesStorage.create();
    this.ExpensesStore.dataManager = this;
    this.ExpensesStore.driverStorage = this.DriversStore;

    this.AssetsStore = AssetsStorage.create();
    this.AssetsStore.dataManager = this;
    this.AssetsStore.driverStorage = this.DriversStore;

    this.FinancingStore = FinancingStorage.create();
    this.FinancingStore.dataManager = this;
    this.FinancingStore.driverStorage = this.DriversStore;

    this.TaxesStore = TaxesStorage.create();
    this.TaxesStore.dataManager = this;
    this.TaxesStore.driverStorage = this.DriversStore;

    this.WorkingCapitalStore = WorkingCapitalStorage.create();
    this.WorkingCapitalStore.dataManager = this;
    this.WorkingCapitalStore.driverStorage = this.DriversStore;

    this.ReportsStore = ReportsStorage.create();
    this.ReportsStore.dataManager = this;
    this.ReportsStore.driverStorage = this.DriversStore;

    this.CustomKPIStore = CustomKPIStorage.create();
    this.CustomKPIStore.dataManager = this;
    this.CustomKPIStore.driverStorage = this.DriversStore;

    //valuation
    this.ValuationSettingsStore = ValuationSettingsStorage.create();
    this.ValuationSettingsStore.dataManager = this;
    this.ValuationSettingsStore.driverStorage = this.DriversStore;
    this.CostOfCapitalStore = ValuationCostOfCapitalStorage.create();
    this.CostOfCapitalStore.dataManager = this;
    this.CostOfCapitalStore.driverStorage = this.DriversStore;
    this.DiscountedCashStore = DiscountedCashStorage.create();
    this.DiscountedCashStore.dataManager = this;
    this.DiscountedCashStore.driverStorage = this.DriversStore;
    this.TradingMultiplesStore = TradingMultiplesStorage.create();
    this.TradingMultiplesStore.dataManager = this;
    this.TradingMultiplesStore.driverStorage = this.DriversStore;
    this.TransactionsMultiplesStore = TransactionsMultiplesStorage.create();
    this.TransactionsMultiplesStore.dataManager = this;
    this.TransactionsMultiplesStore.driverStorage = this.DriversStore;

    window.setSaveFunc = this.#setSaveFunc;

    // const isAuth = ;
    if (this.isAuth) {
      const query = new URLSearchParams(window.location.search);
      const sessionId = query.get("session_id");

      if (sessionId) {
        this.refreshSubscription(
          sessionId,
          () => {
            global.log_msg("success authenticate");
            this.Authenticate();
          },
          () => {
            setTimeout(() => {
              this.initSubscription(() => {
                this.Authenticate();
              });
            }, 5000);
          },
        );
      } else {
        this.Authenticate();
      }
    }
  }

  setAllowedRoutes = () => {};

  #subscriptions = {
    onAuthChange: [],
    onCompanyNameChange: [],
    onCompanyChange: [],
    onPreviewMode: [],
    onError: [],
    onChartRenderFinish: [],
    onProfilePicChange: [],
    onLocationChange: [],
  };

  generateBusinessPlan = async () => {
    await httpService().post("/api/aiBusinessPlan/inputs", {
      BusinessPlanScenarioID: Modeliks.BusinessPlanScenarioInfo.ID,
      setDefaultInputs: true
    });
    const sectionsArrayForAwait = [
      [
        "coverPage"
      ],
      [
        "executiveSummary"
      ],
      [
        "businessDescription",
        "businessValues",
        "businessOwnership",
        "businessProductsAndServices",
        "businessIntellectualProperty"
      ],
      [
        "marketSolutions",
      ],
      [
        "marketTargets",
        "marketTrends",
        "marketCustomers",
      ],
      [
        "marketCompetitors",
        "marketCriticalSuccessFactor",
        "marketSellingPropositions",
      ],
      [
        "strategyMarketChannels",
        "strategyCustomerJourney",
        "strategyPricing",
        "strategySales",
      ],
      [
        "strategyOperations",
        "strategyEquipment",
      ],
      [
        "strategyManagementTeam",
        "strategyPersonnelTeam"
      ],
    ]

    this.del('BusinessPlanAiSections', {BusinessPlanScenarioID: Modeliks.BusinessPlanScenarioInfo.ID}, async () => {
      this.del('BusinessPlanPages_Client', { BusinessPlanScenario_ID: Modeliks.BusinessPlanScenarioInfo.ID }, async () => {
        sectionsArrayForAwait.forEach(async (sectionsArray) => {
          for (const section of sectionsArray) {
            await httpService().post('/api/aiBusinessPlan/section', {
              AccountID: this.Account.ID,
              CompanyID: this.CompanyInfo.ID,
              UserID: this.User.ID,
              shouldGeneratePage: true,
              sectionName: section,
              BusinessPlanScenarioID: Modeliks.BusinessPlanScenarioInfo.ID,
              Completed: false,
            })
          }
        })
      })
    })
  }

  NotifySubsctiptions = (notification, data = null) => {
    this.#notifySubscriptions(notification, data);
  };

  get CompanyInfo() {
    return this._CompanyInfo;
  }

  set CompanyInfo(company) {
    this._CompanyInfo = company;
    if (!company) {
      return;
    }

    this._CompanyInfo.Currency = AllCurrencies[company.Currency.code ?? company.Currency];

    if (!this._CompanyInfo.Currency) {
      this._CompanyInfo.Currency = AllCurrencies["USD"];
    }
  }

  get DashboardSettings() {
    if (this._DashboardSettings) {
      return this._DashboardSettings;
    }
    this._DashboardSettings = {
      Key: "DashboardSettings",
      Value: DateSettingsObject(),
    };
    return this._DashboardSettings;
  }

  set DashboardSettings(data) {
    this._DashboardSettings = data;
  }

  GetChartSettings = (value = null, callBack) => {
    if (value || this.isAdmin) {
      this.ChartSettings = value;
      value && this.#setSaveFunc(this.ChartSettings, this.Tables.Settings_Client.TableName);
      callBack && callBack();
    } else {
      global.Modeliks.get(
        dataStructure.Settings_Client.TableName,
        { Key: "ChartSettings" },
        (success, err) => {
          if (success) {
            if (success.length) {
              this.ChartSettings = success[0];
            }
            callBack && callBack();
          } else {
            console.error(err);
          }
        },
      );
    }
  };

  refreshSubscription = (session_id, callBack, failCallBack) => {
    this.#makeRequest(
      "GET",
      "/api/getCheckoutSubscription",
      { session_id: session_id },
      null,
      true,
      (data) => {
        if (data) {
          callBack();
        } else {
          failCallBack();
        }
      },
      (err) => {
        console.error(err);
      },
    );
  };
  initSubscription = (AccountID, callBack) => {
    if (this.isAdmin) {
      callBack && callBack();
      return;
    }
    this.#makeRequest(
      "POST",
      "/api/init",
      null,
      { AccountID: AccountID },
      false,
      (data) => {
        if (data) {
          callBack();
        }
      },
      (err) => {
        console.error(err);
      },
    );
  };

  GetSubscriptionInfo = () => {
    this.#makeRequest(
      "GET",
      "/api/getSubscriptionInfo",
      null,
      null,
      true,
      (data) => {
        if (data) {
          this.subscriptionInfo = data.SubscriptionInfo;
          this.limitInfo = data.LimitInfo;
        }
      },
      (err) => {
        console.error(err);
      },
    );
  };

  GetDashboardSections = (callBack, disableCache = false) => {
    const url = "/api/getDashboardSections";
    this.#makeRequest(
      "GET",
      url,
      { Key: "DashboardSections" },
      null,
      false,
      (data) => {
        if (data) {
          if (data.length) {
            this.DashboardSection = data[0];
            this.DashboardSection.Value = JSON.parse(data[0].Value);
            if (!this.DashboardSection.Value.DashboardSections.ForecastSummary) {
              this.DashboardSection.Value.DashboardSections.ForecastSummary =
                DashboardSectionsObj.DashboardSections.ForecastSummary;
            }
            if (!this.DashboardSection.Value.DashboardSections.ForecastSummaryMonthly) {
              this.DashboardSection.Value.DashboardSections.ForecastSummaryMonthly =
                DashboardSectionsObj.DashboardSections.ForecastSummaryMonthly;
            }
            if (!this.DashboardSection.Value.DashboardSections.SaasDashboard) {
              this.DashboardSection.Value.DashboardSections.SaasDashboard =
                DashboardSectionsObj.DashboardSections.SaasDashboard;
            }
            if (!this.DashboardSection.Value.DashboardSections.SaasDashboardMonthly) {
              this.DashboardSection.Value.DashboardSections.SaasDashboardMonthly =
                DashboardSectionsObj.DashboardSections.SaasDashboardMonthly;
            }
            callBack && callBack();
          } else {
            this.GetDashboardSections(callBack);
            // this.#makeRequest('POST', '/api/master/settings', null, {
            //     'Key': 'DashboardSections',
            //     Value: JSON.stringify(DashboardSectionsObj)
            // }, true, (data) => {
            //     this.GetDashboardSections(callBack, true);
            // })
          }
        }
      },
      (error) => {
        console.error(error);
      },
    );
  };

  GetDashboardSettings = (callBack, settings = null) => {
    if (!this.isAdmin) {
      const setSettings = (settings) => {
        if (settings.length > 0) {
          global.log_msg("settings", settings);
          this.DashboardSettings = settings[0];
          this.#setSaveFunc(this._DashboardSettings, this.Tables.Settings_Client.TableName);
          this._DashboardSettings.Value = JSON.parse(this._DashboardSettings.Value);
          if (Object.keys(this._DashboardSettings.Value).length !== 2) {
            this._DashboardSettings.Value = JSON.stringify(DateSettingsObject());
            this._DashboardSettings.Save();
            this._DashboardSettings.Value = JSON.parse(this._DashboardSettings.Value);
          }
          callBack && callBack();
        } else {
          this.post(
            "settings_client",
            {
              Key: "DashboardSettings",
              Value: JSON.stringify(DateSettingsObject()),
              CompanyID: this.CompanyInfo.ID,
            },
            (success, err) => {
              if (!success) {
                console.error("error", err);
              } else {
                this.GetDashboardSettings();
              }
            },
          );
        }
      };

      if (settings) {
        setSettings(settings);
      } else {
        this.get(
          "settings_client",
          { Key: "DashboardSettings", CompanyID: this.CompanyInfo.ID },
          (settings) => {
            setSettings(settings);
          },
        );
      }
    } else {
      this.DashboardSettings = {
        Key: "DashboardSettings",
        Value: DateSettingsObject(),
      };
      callBack && callBack();
    }
  };

  GetSummaryDateSettings = (callBack) => {
    if (!this.isAdmin) {
      this.SummaryDateSettings = null;

      this.get(
        "settings_client",
        { Key: "SummaryDateSettings", CompanyID: this.CompanyInfo.ID },
        (settings) => {
          if (settings.length > 0) {
            this.SummaryDateSettings = settings[0];
            callBack && callBack();
          }
        },
      );
    } else {
      callBack && callBack();
    }
  };

  GetPitchFinancialCharts = () => {
    const getIds = (key) => {
      return Object.keys(
        Object.values(this.DashboardSection.Value.DashboardSections[key].subsections)[0].charts,
      );
    };
    this.#makeRequest(
      "GET",
      "/api/master/dashboardcharts",
      { ID: getIds("ForecastSummary") },
      null,
      true,
      (data) => {
        if (data) {
          if (data.length) {
            this.PitchFinancialChartsYearly = data;
            this.PitchFinancialChartsYearly.forEach((c) => (c.Data = JSON.parse(c.Data)));
          }
        }
      },
      (error) => {
        console.error(error);
      },
    );
    this.#makeRequest(
      "GET",
      "/api/master/dashboardcharts",
      { ID: getIds("ForecastSummaryMonthly") },
      null,
      true,
      (data) => {
        if (data) {
          if (data.length) {
            this.PitchFinancialChartsMonthly = data;
            this.PitchFinancialChartsMonthly.forEach((c) => (c.Data = JSON.parse(c.Data)));
          }
        }
      },
      (error) => {
        console.error(error);
      },
    );
  };

  getCacheRoutes = (callBack) => {
    const ID_CompanyScenario = window.localStorage.getItem("CompanyScenarioID");

    this.#makeRequest(
      "GET",
      "/api/cach_status",
      {
        // force: true,
        ID_CompanyScenario: ID_CompanyScenario ? ID_CompanyScenario : undefined,
      },
      null,
      true,
      (data) => {
        if (data.ID_CompanyScenario) {
          window.localStorage.setItem("CompanyScenarioID", data.ID_CompanyScenario);
        }
        delete data.ID_CompanyScenario;
        this.cache_routes = data;
        callBack && callBack();
      },
    );
  };
  getLastTimeStamp = (table, callBack) => {
    global.log_msg("hmm", table);
    let url = "last_timestamp_" + table.toLowerCase();
    this.#makeRequest("GET", "/api/cdv/" + url, null, null, true, (data) => {
      this[url] = data.lastTimeStamp;
      callBack && callBack();
    });
  };

  Authenticate = (user = null, callBack) => {
    this.debug.log("Authenticate init");
    this.GetUser(() => {
      global.log_msg("GetUser");

      if (this.NoAccount) {
        this.AuthenticateNoAcc();
      } else {
        global.log_msg("DataManager", this.DriversStore);
        this.InitializeDrivers(true, callBack);
        this.GetAuthenticatedItems();
        this.GetDashboardSections(this.GetPitchFinancialCharts);
        this.GetInstructionsContent();
        this.GetPitchInstructionsContent();
        this.GetBPInstructionsContent();
      }
    });
  };

  AuthenticateNoAcc = () => {
    this.isAuth = true;
    this.NoAccount = true;
    this.#notifySubscriptions("onAuthChange", this.isAuth);
  }

  InitializeDrivers = (notify = true, callBack) => {
    this.GetDriversOnStart(() => {
      this.LoadDrivers(() => {
        global.log_msg("loaded drivers");
        this.toggleGlobalLoader && this.toggleGlobalLoader(false);
        if (notify) {
          this.isAuth = true;
          this.#notifySubscriptions("onAuthChange", this.isAuth);
        }
        callBack && callBack();
      });
    });
  }


  GetInstructionsContent = (InstructionsContent) => {
    if (InstructionsContent) {
      this.InstructionsContent = InstructionsContent;
    } else {
      this.#makeRequest(
          "GET",
          "/api/file_manager",
          {fileName: "instructions_content.json"},
        null,
        true,
        (data, error) => {
          if (data) {
            this.InstructionsContent = data;
          } else {
            console.error(error);
          }
        },
      );
    }
  };
  GetPitchInstructionsContent = (InstructionsContent) => {
    if (InstructionsContent) {
      this.PitchInstructionsContent = InstructionsContent;
    } else {
      this.#makeRequest(
        "GET",
        "/api/file_manager",
        { fileName: "pitch_instructions.json" },
        null,
        true,
        (data, error) => {
          if (data) {
            this.PitchInstructionsContent = data;
          } else {
            console.error(error);
          }
        },
      );
    }
  };

  GetPortfolio = (callBack) => {
    if (this.isAdmin) {
      callBack && callBack();
      return;
    }
    this.get(
      "user_settings",
      { Key: "Stages", UserID: this.User.ID },
      (data) => {
        if (data && data.length) {
          this.PortfolioStages = data[0];
          this.PortfolioStages.Value = MxDataArray.create(
            JSON.parse(this.PortfolioStages.Value),
            "Key",
          );
          callBack && callBack();
        } else {
          let array = defaultStages.map((c) => {
            if (!c.Deletable) {
              return c;
            }
            return { ...c, Key: MxIDUseID() };
          });
          this.post(
            "user_settings",
            {
              Key: "Stages",
              UserID: this.User.ID,
              Value: JSON.stringify(array),
            },
            (data) => {
              this.GetPortfolio(callBack);
            },
            (error) => console.error(error),
          );
        }
      },
      (error) => {},
      true,
    );
  };

  GetCompanyUsersStages = (callBack) => {
    if (this.isAdmin) {
      callBack && callBack();
      return;
    }
    this.get(
      "user_settings",
      { Key: "CompanyUsersStages", UserID: this.User.ID },
      (data) => {
        console.log("success data", data);
        if (data && data.length) {
          console.log("data", data);
          this.CompanyUserStages = data[0];
          this.CompanyUserStages.Value = JSON.parse(this.CompanyUserStages.Value);
          callBack && callBack();
        } else {
          let Value = {};
          this.PortfolioStages.Value.forEach((c) => Object.assign(Value, { [c.Key]: {} }));
          console.log("Value", Value);
          this.post(
            "user_settings",
            {
              Key: "CompanyUsersStages",
              UserID: this.User.ID,
              Value: JSON.stringify(Value),
            },
            (data) => {
              this.GetCompanyUsersStages(callBack);
            },
            (error) => console.error(error),
          );
        }
      },
      (error) => {},
      true,
    );
  };

  GetAllUserCompanies = (callBack) => {
    if (this.isAdmin) {
      callBack && callBack();
      return;
    }
    this.get(
      "View_CompanyUsers_Companies",
      { User_ID: this.User.ID },
      (companies) => {
        this.AllUserCompanies = MxDataArray.create(companies);
        callBack && callBack();
      },
      (error) => console.error(error),
      false,
    );
  };

  GetBPInstructionsContent = (InstructionsContent) => {
    if (InstructionsContent) {
      this.BPInstructionsContent = InstructionsContent;
    } else {
      this.#makeRequest(
        "GET",
        "/api/file_manager",
        { fileName: "bp_instructions.json" },
        null,
        true,
        (data, error) => {
          if (data) {
            this.BPInstructionsContent = data;
          } else {
            console.error(error);
          }
        },
      );
    }
  };

  GetUserPermissions = (callBack) => {
    this.get(
      "userpermissions",
      this.isAdmin
        ? null
        : {
            User_ID: this.User.ID,
            AccountID: this.Account.ID,
          },
      callBack,
    );
  };

  GetAuthenticatedItems = () => {
    if (!this.isAdmin) {
      this.get(
        "/api/authenticated_items",
        { CompanyID: this.CompanyInfo.ID },
        (items) => {
          this.SummaryDateSettings = items.SummaryDateSettings[0];
          this.GetDashboardSettings(undefined, items.DashboardSettings);
          this.GetChartSettings(items.ChartSettings[0]);
          this.GetCompanyPitchScenarios(undefined, items.PitchScenarios);
          this.GetCompanyBusinessPlanScenarios(undefined, items.BusinessPlanScenarios);
          this.GetCompanyBusinessPlanConfig(undefined, items.BusinessPlanConfig);
          this.GetPitchIndustries(undefined, items.PitchIndustries);
          this.GetBusinessPlanIndustries(undefined, items.BusinessPlanIndustries);
          this.GetCustomDashboards(undefined, items.CustomDashboards);
          this.GetMostUsedColors(items.MostUsedColors);
        },
        undefined,
        false,
        false,
        true,
      );
    } else {
      this.GetDashboardSettings();
      this.GetChartSettings();
      this.GetCompanyPitchScenarios();
      this.GetCompanyBusinessPlanScenarios();
      this.GetCompanyBusinessPlanConfig();
      this.GetPitchIndustries();
      this.GetBusinessPlanIndustries();
      this.GetCustomDashboards();
      this.GetMostUsedColors();
    }
  };

  ClearDriversForCompanyChange = (callBack) => {
    this.DriverValuesStore.clear();
    this.DriversStore.clear();
    this.RevenuesStore.clear();
    this.CostSaleStore.clear();
    this.PersonnelStore.clear();
    this.ExpensesStore.clear();
    this.AssetsStore.clear();
    this.FinancingStore.clear();
    this.TaxesStore.clear();
    this.WorkingCapitalStore.clear();
    this.ReportsStore.clear();
    this.CustomKPIStore.clear();
    this.ValuationSettingsStore.clear();
    this.CostOfCapitalStore.clear();
    this.DiscountedCashStore.clear();
    this.TradingMultiplesStore.clear();
    this.TransactionsMultiplesStore.clear();
    callBack && callBack();
  };

  LoadAllDrivers = () => {
    this.DriverValuesStore.loadDriverValues(this.#finishLoadingDrivers, false);
    this.DriversStore.loadDrivers(this.#finishLoadingDrivers, false);
    if (this.StaticDataObject) {
      this.#finishLoadingDrivers();
    } else {
      this.DriversStore.loadTemporaryCDVs(this.#finishLoadingDrivers, false);
    }
    this.RevenuesStore.loadRevenues(this.#finishLoadingDrivers, false);
    this.CostSaleStore.loadCostSales(this.#finishLoadingDrivers, false);
    this.PersonnelStore.loadPersonnel(this.#finishLoadingDrivers, false);
    this.ExpensesStore.loadExpenses(this.#finishLoadingDrivers, false);
    this.AssetsStore.loadAssets(this.#finishLoadingDrivers, false);
    this.FinancingStore.loadFinancing(this.#finishLoadingDrivers, false);
    this.TaxesStore.loadTaxes(this.#finishLoadingDrivers, false);
    this.WorkingCapitalStore.loadWorkingCapital(this.#finishLoadingDrivers, false);
    this.ReportsStore.loadReports(this.#finishLoadingDrivers, false);
    this.CustomKPIStore.loadCustomKPI(this.#finishLoadingDrivers, false);
    this.ValuationSettingsStore.load(this.#finishLoadingDrivers, false);
    this.CostOfCapitalStore.load(this.#finishLoadingDrivers, false);
    this.TradingMultiplesStore.load(this.#finishLoadingDrivers, false);
    this.TransactionsMultiplesStore.load(this.#finishLoadingDrivers, false);
  };

  setDataGridRows = () => {
    this.RevenuesStore.setGridRows();
    this.CostSaleStore.setGridRows();
    this.PersonnelStore.setGridRows();
    this.ExpensesStore.setGridRows();
    this.AssetsStore.setGridRows();
    this.FinancingStore.setGridRows();
    this.TaxesStore.setGridRows();
  };

  #loadingCallback = null;
  #loadingDriver = 0;
  #finishLoadingDrivers = () => {
    this.#loadingDriver++;
    global.log_msg(this.#loadingDriver);
    if (this.#loadingDriver == 17) {
      global.log_msg("building");
      this.DriversStore.setData(() => {
        this.RevenuesStore.setData(() => {
          this.CostSaleStore.setData(() => {
            this.PersonnelStore.setData(() => {
              this.ExpensesStore.setData(() => {
                this.AssetsStore.setData(() => {
                  this.FinancingStore.setData(() => {
                    this.TaxesStore.setData(() => {
                      this.WorkingCapitalStore.setData(() => {
                        this.ReportsStore.setData(() => {
                          this.CustomKPIStore.setData(() => {
                            this.ValuationSettingsStore.setData(() => {
                              this.CostOfCapitalStore.setData(() => {
                                this.TradingMultiplesStore.setData(() => {
                                  this.TransactionsMultiplesStore.setData(() => {
                                    this.DriversStore.driverValuesStorage.forEach(
                                      (driverValue, index) => {
                                        driverValue.IsSimple == false && driverValue.buildFormula();
                                        if (
                                          index + 1 ===
                                          this.DriversStore.driverValuesStorage.length
                                        ) {
                                          this.setDataGridRows();
                                          global.log_msg("finsihed building");
                                          this.#loadingCallback();
                                          if (this.shouldSaveTemporary) {
                                            this.DriversStore.postAllTemporary();
                                          }
                                        }
                                      },
                                    );
                                  });
                                });
                              });
                            });
                          });
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        });
      });
    }
  };

  LoadDrivers = (callBack) => {
    this.#loadingCallback = callBack;
    this.ClearDriversForCompanyChange(() => {
      this.#loadingDriver = 0;
      this.LoadAllDrivers();
    });
  };

  GetCompanyBusinessPlanConfig = (callBack, configs) => {
    if (configs && configs.length) {
      this.BusinessPlanConfig = configs[0];
      this.#setSaveFunc(this.BusinessPlanConfig, this.Tables.BusinessPlanConfig.TableName);
      callBack && callBack();
    } else {
      this.get("businessplanconfig", { CompanyID: this.CompanyInfo.ID }, (configs) => {
        if (configs.length > 0) {
          this.BusinessPlanConfig = configs[0];
          callBack && callBack();
        }
      });
    }
  };

  GetCompanyPitchScenarios = (callBack, scenarios) => {
    const setSecnarios = (scenarios) => {
      if (scenarios.length > 0) {
        this.PitchScenarios = MxDataArray.create(scenarios);

        if (window.localStorage.PitchScenarioID) {
          this.PitchScenarioInfo = this.PitchScenarios.getItem(
            Number(window.localStorage.PitchScenarioID),
          );
        }

        if (this.PitchScenarioInfo == null && scenarios.length > 0) {
          this.PitchScenarioInfo = scenarios[0];
          window.localStorage.setItem("PitchScenarioID", this.PitchScenarioInfo.ID);
        }

        callBack && callBack();
      }
    };

    if (scenarios) {
      scenarios.forEach((c) => this.#setSaveFunc(c, this.Tables.PitchScenarios.TableName));
      setSecnarios(scenarios);
    } else {
      this.get("pitchscenarios", { Company_ID: this.CompanyInfo.ID }, setSecnarios);
    }
  };

  GetCompanyBusinessPlanScenarios = (callBack, scenarios) => {
    if (!this.isAdmin) {
      const setScenarios = (scenarios) => {
        if (scenarios.length > 0) {
          this.BusinessPlanScenarios = scenarios;

          if (window.localStorage.BusinessPlanScenarioID) {
            this.BusinessPlanScenarioInfo = this.BusinessPlanScenarios.find(
              (el) => el.ID === Number(window.localStorage.BusinessPlanScenarioID),
            );
          }

          if (this.BusinessPlanScenarioInfo == null && scenarios.length > 0) {
            this.BusinessPlanScenarioInfo = scenarios[0];
            window.localStorage.setItem("BusinessPlanScenarioID", this.BusinessPlanScenarioInfo.ID);
          }

          callBack && callBack();
        }
      };
      if (scenarios) {
        scenarios.forEach((c) => this.#setSaveFunc(c, this.Tables.BusinessPlanScenarios.TableName));
        setScenarios(scenarios);
      } else {
        this.get("businessplanscenarios", { Company_ID: this.CompanyInfo.ID }, setScenarios);
      }
    }
  };

  GetPitchIndustries = (callBack, industries) => {
    if (!this.isAdmin) {
      if (industries) {
        if (industries.length > 0) {
          this.PitchIndustries = industries;
          this.PitchIndustries.forEach((c) =>
            this.#setSaveFunc(c, this.Tables.PitchIndustries.TableName),
          );
        }
      } else {
        this.get("pitchindustries", { Hidden: false }, (industries) => {
          if (industries.length > 0) {
            this.PitchIndustries = industries;
          }
        });
      }
    }
  };

  GetBusinessPlanIndustries = (callBack, industries) => {
    if (!this.isAdmin) {
      if (industries) {
        this.BusinessPlanIndustries = industries;
        this.BusinessPlanIndustries.forEach((c) =>
          this.#setSaveFunc(c, this.Tables.BusinessPlanIndustries.TableName),
        );
      } else {
        this.get("businessplanindustries", { Hidden: false }, (industries) => {
          if (industries.length > 0) {
            this.BusinessPlanIndustries = industries;
          }
        });
      }
    }
  };

  GetCompanyUsers = (callBack) => {
    this.get("companyusers", null, (companyUsers) => {
      this.CompanyUsers = companyUsers;
      callBack && callBack();
    });
  };

  GetCompanies = (callBack, archivingInProgress = false) => {
    let companyIDs = this.CompanyUsers.map((user) => user.Company_ID);
    this.get("companies", { ID: companyIDs }, (companies) => {
      this.setCompanies(companies, callBack, archivingInProgress);
    });
  };

  setCompanies = (companies = [], callBack, archivingInProgress = false) => {
    if (companies.length > 0) {
      companies.forEach((c) => this.#setSaveFunc(c, global.Modeliks.Tables.Companies.TableName));
      this.Companies = companies.filter((c) => !!c.Status);
      this.CompanyInfo = null;
      this.CompanyInfo = this.Companies.find((c) => c.ID == this.CompanyScenarioInfo.ID_Company);
      if (this.CompanyInfo) {
        this.CompanyInfo.StartMonth = parseInt(this.CompanyInfo.StartMonth);
        this.CompanyScenarios = this.CompanyScenarios.filter(
          (c) => c.ID_Company == this.CompanyInfo.ID,
        );
        this.CompanyScenarios.forEach((c) =>
          this.#setSaveFunc(c, global.Modeliks.Tables.CompanyScenarios.TableName),
        );
        this.#notifySubscriptions("onCompanyNameChange", true);
        this.DateHelper.buildDates(this.CompanyInfo);
        callBack && callBack();
      } else {
        if (archivingInProgress) {
          callBack && callBack();
        } else {
          this.toggleRestrictedAccessToCompany && this.toggleRestrictedAccessToCompany(true); //leads to restricted company access
        }
        // this.Authenticate();
      }
    } else {
      if (global.Modeliks.User.ID == global.Modeliks.Account.Owner_ID) {
        this.#notifySubscriptions("onAuthChange", true); //leads to onboarding
      } else {
        this.toggleRestrictedAccessToCompany && this.toggleRestrictedAccessToCompany(true); //leads to restricted company access
      }
    }
  };

  circleRef = false;

  GetCircleReferenceDrivers = (val) => {
    if (this.circleRef) {
      return;
    }
    this.circleRef = true;
    const curDriver = global.Modeliks.DriversStore.getItem(val.ID_Driver);
    const AllCircleRefDrivers = [];
    curDriver.Values.forEach((v) =>
        global.Modeliks.DriversStore.GetParentDriversRecursion(val, AllCircleRefDrivers),
    );
    if (AllCircleRefDrivers.length) {
      global.Modeliks.showDialog(AllCircleRefDrivers, DialogTypes.RefErrorAllDrivers);
    }
    // return global.Modeliks.NavigateTo(`/formula/${curDriver.ID}`);
  };

  GetAccountUsers = (callBack) => {
    if (!this.isAdmin) {
      this.get(
        "/api/users_of_acc",
        { Account_ID: this.Account.ID },
        (accountusers) => {
          this.AccountUsers = MxDataArray.create(accountusers);
          callBack && callBack();
        },
        undefined,
        false,
        false,
        true,
      );
    } else {
      callBack && callBack();
    }
  };

  SetDeletedObject = () => {
    if (window.localStorage.getItem(this.getFilterCDName())) {
      this.deletedObj = JSON.parse(window.localStorage.getItem(this.getFilterCDName()));
    } else {
      this.deletedObj = {};
    }
  };

  GetDriversOnStart = (callBack) => {
    this.SetDeletedObject();
    this.get(
      dataStructure.Finance_WorkingCapital.TableName,
      { ID_CompanyScenario: this.CompanyScenarioInfo.ID },
      (driver) => {
        if (driver && driver.length > 0) {
          callBack();
        } else {
          AccountReceivable.getAccountReceivableDriver(() => {
            AccountPayable.getAccountPayableDriver(() => {
              Inventory.getInventoryDriver(() => {
                ProfitLoss.getReport();
                Financing.getOpenningBalanceRetainedEarnings(() => {
                  CashFlow.getOpeningCashBalance(() => {
                    Reports.getNumberOfDaysPerMonthAndYear();
                    Reports.getNumberOneDriver();
                    Assets.getAssetsCurrent();
                    Assets.getAssetPayableLongTerm();
                    Assets.getAssetPayableCurrent();
                    Assets.getLongTermAssetPaymentsFromDriversNegative();
                    Assets.getLongTermAssetSalesFromDrivers();
                    Assets.getDepreciation();
                    Assets.getLongTermAssetsTotals();
                    Assets.getAssetGainAndLoss();
                    Expense.getExpensesDirectCostTotals();
                    Expense.getPersonnelExpenses();
                    Financing.getCurrentOtherLiabilities();
                    Financing.getLongTermOtherLiabilities();
                    Financing.getEquityInvestments();
                    Financing.getClosingBalanceRetainedEarnings();
                    Financing.getLoanReceipts();
                    Financing.getLoanRepayments();
                    Financing.getInterestExpense();
                    Financing.getPrepaidRevenueOpeningBalance();
                    Financing.getChangeInLone();
                    Financing.getChangeInLineOfCredit();
                    Financing.getPrepaidRevenueClosingBalance();
                    Financing.getDividendsTotals();
                    Personnel.getPersonnelDirectLaborTotals();
                    Financing.getEquityInvestmentsOnlyEquity();
                    CashFlow.getReport(false);
                    Personnel.getNumberOfNewEmployees();
                    // ReportingDrivers.getReportingDrivers();

                    Valuation.getValuationSettings(() => {
                      CostOfCapitalWACC.getCostOfCapitalWACC(() => {
                        TradingMultiples.getTradingMultiples(() => {
                          TransactionMultiples.getTransactionsMultiples(() => {
                            callBack();
                          });
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        }
      },
    );
  };

  GetCustomDashboards = (callBack, data) => {
    if (!this.isAdmin) {
      const setCustomDashboards = (data, error) => {
        if (data) {
          if (data.length) {
            data.forEach((c) => (c.Settings = JSON.parse(c.Settings)));
          }
          this.CustomDashboards = MxDataArray.create(data);
          callBack && callBack();
        } else {
          console.error(error);
        }
      };
      if (data) {
        data.forEach((c) => this.#setSaveFunc(c, this.Tables.CustomDashboards.TableName));

        setCustomDashboards(data);
      } else {
        this.get(
          this.Tables.CustomDashboards.TableName,
          { CompanyID: this.CompanyInfo.ID },
          setCustomDashboards,
        );
      }
    } else {
      callBack && callBack();
    }
  };

  GetMostUsedColors = (data) => {
    if (this.isAdmin) {
      this.#makeRequest("GET", "/api/getAdminMostUsedColors", null, null, true, (data, error) => {
        if (data) {
          this.mostUsedColors = data;
        } else {
          console.error(error);
        }
      });
    } else {
      if (data) {
        this.mostUsedColors = data;
      } else {
        this.#makeRequest("GET", "/api/getMostUsedColors", null, null, true, (data, error) => {
          if (data) {
            this.mostUsedColors = data;
          } else {
            console.error(error);
          }
        });
      }
    }
  };

  SaveMostUsedColors = () => {
    if (this.isAdmin) {
      this.#makeRequest(
        "POST",
        "/api/saveAdminMostUsedColors",
        null,
        { colorObject: JSON.stringify(this.mostUsedColors) },
        true,
        (success, error) => {},
      );
    } else {
      this.#makeRequest(
        "POST",
        "/api/saveMostUsedColors",
        null,
        { colorObject: JSON.stringify(this.mostUsedColors) },
        true,
        (success, error) => {},
      );
    }
  };

  addColor = (color) => {
    let flag = false;

    Object.keys(this.mostUsedColors).forEach((key) => {
      if (key.toLowerCase() === color) {
        this.mostUsedColors[key] = this.mostUsedColors[key] + 1;
        flag = true;
      }
    });

    if (!flag) {
      this.mostUsedColors[color] = 1;
    }
  };

  filteredSubscriptionPlans = () => {
    if (this.subscriptionInfo && this.subscriptionPlans) {
      let shouldShowFree = this.subscriptionInfo && this.subscriptionInfo.price === 0;
      if (!shouldShowFree) {
        return this.subscriptionPlans.filter(
          (c) => c.billing_type !== billing_types.daily && c.recurring_price !== 0,
        );
      }
    }
    return this.subscriptionPlans;
  };
  sortSubscriptions = (subscriptiopns) => {
    subscriptiopns.sort(function (a, b) {
      if (a.order > b.order) {
        return 1;
      } else if (a.order < b.order) {
        return -1;
      } else {
        return a.ID > b.ID ? 1 : -1;
      }
    });
    return subscriptiopns;
  };

  GetSubscriptionPlans = (callBack) => {
    this.#makeRequest("GET", "/api/getAllSubscriptions", null, null, true, (data, error) => {
      if (data) {
        this.subscriptionPlans = this.sortSubscriptions(
          data.filter((c) => c.billing_type !== billing_types.daily),
        );
        callBack && callBack();
      } else {
        console.error(error);
      }
    });
  };

  GetUser = (callBack) => {
    let queryObj = null;
    this.NoAccount = false;
    const ID_CompanyScenario = window.localStorage.getItem("CompanyScenarioID");
    if (ID_CompanyScenario) {
      queryObj = Object.assign({}, { ID_CompanyScenario: ID_CompanyScenario });
    }
    if (window.localStorage.getItem("token") == null) {
      return;
    }
    this.#makeRequest("GET", "/api/initializeUser", queryObj, null, true, (data, error) => {
      if (data) {
        if (data.changeAccount) {
          if (global[functions.toggle_switch_acc_dialog]) {
            global[functions.toggle_switch_acc_dialog](true)
          }
          return;
        }
        if (data.NoAccount) {
          this.api_url = "/api/master/";
          this.User = data.user;
          this.NoAccount = true;
          this.showDialog && this.showDialog('', DialogTypes.NoAccount,
              undefined,
              undefined,
              undefined,
              undefined,
              'no_account')
          callBack && callBack();
        } else {
          this.referralId = data.referralId;
          this.referralSource = data.referralSource;
          this.pendingTransactionID = data.pendingTransactionID;
          this.limitInfo.ActiveCompanies = data.ActiveCompanies;
          this.limitInfo.ActiveUsers = data.ActiveUsers;
          this.limitInfo.TotalCompanies = data.TotalCompanies;
          this.limitInfo.TotalUsers = data.TotalUsers;
          this.subscriptionInfo = data.SubscriptionInfo;
          this.transactionsInfo = data.transactionsInfo;
          this.isFirstLogin = data.isFirstLogin;
          this.User = data.user;
          this.isInternal =
              this.User.isSupport || this.User.isTest || this.User.Email.indexOf("modeliks.com") > -1;
          this.Account = data.account;
          this.Account ? (this.Account.accounts_number = data.accounts_number) : null;
          this.isAdmin = data.isAdmin;
          this.subscriptionPlans = data.subscriptions;
          this.cache_routes = data.cache_routes;
          this.AccountUsers = data.users_of_acc;
          if (this.isAdmin) {
            this.api_url = "/api/public/";

            this.GetCompanyScenarios(() => {
              this.GetCompanyUsers(() => {
                this.GetCompanies(() => {
                  this.GetUserPermissions((permissons) => {
                    this.setUserPermissions(permissons);
                    callBack && callBack();
                  });
                });
              });
            });
          } else {
            this.api_url = "/api/master/";
            this.CompanyUsers = data.companyUsers;

            this.UserPermissions = {};
            data.userPermissions.forEach((permission) =>
                Object.assign(this.UserPermissions, {
                  [permission.Permission_Key]: permission.Permission,
                }),
            );
            SetPermissionsBySubscription();
            SetPermissionsByUserPermissions();

            this.setScenarios(data.companyScenarios);

            this.setCompanies(data.companies, callBack);
          }
        }
      } else {
        console.error(error);
      }
    });
  };

  setUserPermissions = (permissions) => {
    this.UserPermissions = {};
    permissions.forEach((permission) =>
      Object.assign(this.UserPermissions, { [permission.Permission_Key]: permission.Permission }),
    );
    SetPermissionsBySubscription();
    SetPermissionsByUserPermissions();
  };

  GetCompanyScenarios = (callBack, setScenario = true) => {
    const query = this.CompanyInfo ? { ID_Company: this.CompanyInfo.ID } : null;
    this.get("companyscenarios", query, (scenarios) => {
      this.setScenarios(scenarios, setScenario);
      callBack && callBack();
    });
  };

  setScenarios = (scenarios = [], setScenario = true) => {
    this.CompanyScenarios = scenarios;
    if (!setScenario) {
      return;
    }
    if (window.localStorage.CompanyScenarioID) {
      this.CompanyScenarioInfo = this.CompanyScenarios.find(
        (el) => el.ID === Number(window.localStorage.CompanyScenarioID),
      );
    }
    if (
      (this.CompanyScenarioInfo == null || this.CompanyScenarioInfo == undefined) &&
      scenarios.length > 0
    ) {
      const Company_ID = window.localStorage.getItem("Company_ID");
      const companyScenarioIndex = scenarios.findIndex((c) => c.ID_Company == Company_ID);
      if (Company_ID && companyScenarioIndex > -1) {
        this.CompanyScenarioInfo = scenarios[companyScenarioIndex];
      } else {
        this.CompanyScenarioInfo = scenarios[0];
      }
      window.localStorage.removeItem("Company_ID");
      window.localStorage.setItem("CompanyScenarioID", this.CompanyScenarioInfo.ID);
    }
  };

  ChangeCompanyScenario = (CompanyScenarioID) => {
    window.localStorage.setItem("CompanyScenarioID", CompanyScenarioID);
    this.CompanyScenarioInfo = this.CompanyScenarios.find(
      (el) => el.ID === Number(CompanyScenarioID),
    );
    window.location.reload();
  };

  googleClient = (callback) => google.accounts.oauth2.initCodeClient({
    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    scope: "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
    ux_mode: "popup",
    callback
  });

  handleUnauthorized = () => {
    if (this.isAuth) {
      this.showDialog && this.showDialog("", DialogTypes.SessionExpired)
      this.LogOut();
    }
  }

  ChangeCompany = (company, callBack) => {
    this.CompanyInfo = company;
    if (this.CompanyInfo) {
      this.CompanyInfo.StartMonth = parseInt(this.CompanyInfo.StartMonth);
      this.DateHelper.buildDates(this.CompanyInfo);
      this.GetAuthenticatedItems();
    }

    this.ClearDriversForCompanyChange(() => {
      this.GetCompanyScenarios(() => {
        this.GetCustomDashboards(() => {
          this.getCacheRoutes(() => {
            this.GetCompanyPitchScenarios(() => {
              this.GetCompanyBusinessPlanScenarios(() => {
                this.GetCompanyBusinessPlanConfig(() => {
                  this.GetDriversOnStart(() => {
                    this.LoadDrivers(() => {
                      this.#notifySubscriptions("onCompanyChange", true);
                      callBack && callBack();
                    });
                  });
                });
              });
            });
          });
        });
      });
    });
  };

  Login = (user, shouldInit, callBack) => {
    console.log("user", user);

    this.PERMISSIONS = JSON.parse(JSON.stringify(PERMISSIONS));

    if (shouldInit) {
      this.initSubscription(this.Account.ID, () => {
        this.Authenticate(user, callBack);
      });
    } else {
      this.Authenticate(user, callBack);
    }

    if (global.onUserLogin) {
      global.onUserLogin(user.ID);
    }

    this.isAuth = true;
  };

  onPreviewMode = (data) => {
    this.#notifySubscriptions("onPreviewMode", data);
  };

  LogOut = (callBack) => {
    this.NoAccount = false;
    this.ClearDriversForCompanyChange();
    this.isAuth = false;
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("Account_ID");
    window.localStorage.removeItem("User_ID");
    window.localStorage.removeItem("PitchScenarioID");
    window.localStorage.removeItem("CompanyScenarioID");
    this.UserClient = null;
    this.Account = null;
    this.AccountUsers = [];
    this.User = null;
    this.UserClient = null;
    this.UserPermissions = null;
    this.allowedRouteKeys = [];

    this.CompanyInfo = null;
    this.Companies = [];
    this.InactiveCompanies = [];

    this.CompanyScenarios = [];
    this.CompanyScenarioInfo = null;
    this.PitchScenarios = [];
    this.PitchScenarioInfo = null;

    this.toggleRestrictedAccessToCompany && this.toggleRestrictedAccessToCompany(false);
    this.isAuth = null;
    if (callBack) {
      callBack();
    } else {
      this.#notifySubscriptions("onAuthChange", this.isAuth);
    }
  };

  Subscribe = (subscription, callBack) => {
    this.#subscriptions[subscription].push(callBack);
  };

  Unsubscribe = (subscription, callBack) => {
    if (this.#subscriptions[subscription].indexOf(callBack) > -1) {
      this.#subscriptions[subscription].slice(
        this.#subscriptions[subscription].indexOf(callBack),
        1,
      );
    }
  };

  Unsubscribe2 = (subscription, callBack) => {
    if (this.#subscriptions[subscription].indexOf(callBack) > -1) {
      this.#subscriptions[subscription].splice(
        this.#subscriptions[subscription].indexOf(callBack),
        1,
      );
    }
  };

  #notifySubscriptions = (subscription, data) => {
    this.debug.log(subscription);
    if (this.#subscriptions[subscription]) {
      this.#subscriptions[subscription].forEach((observer) => observer(data));
    }
  };

  #makeRequest = (
    type,
    url,
    query,
    data,
    includeCredentials = true,
    success,
    error,
    cache = false,
    disableCache = false,
  ) => {
    let req = null;
    switch (type) {
      case "GET":
        req = request.get(url);
        break;
      case "POST":
        req = request.post(url);
        break;
      case "PUT":
        req = request.put(url);
        break;
      case "DEL":
        req = request.del(url);
        break;
      default:
    }
    process.env.REACT_APP_FE_VERSION && req.set("version-fe", process.env.REACT_APP_FE_VERSION);
    if (type == "GET" && (cache || (this.cache_routes.hasOwnProperty(url) && !disableCache))) {
      let num = -1;
      if (this.cache_routes[url]) {
        num = this.cache_routes[url];
      }
      if (!query) {
        query = {};
      }
      if (num === -1) {
        success([]);
        return;
      }
      query["cache"] = true;
      if (query["v"]) {
        num = query["v"];
        delete query["v"];
      }
      query["v"] = num;
    } else {
      if (query) {
        delete query["cache"];
        delete query["v"];
      }
    }

    if (query) {
      req = req.query(query);
    }

    if (includeCredentials) {
      req = req.withCredentials();
    }

    if (data) {
      req = req.send(data);
    }

    req = req
      .set("Access-Control-Allow-Origin", window.location.protocol + "//" + window.location.host)
      .set("Accept", "application/json");

    if (includeCredentials) {
      req = req.set("authorization", "Bearer " + window.localStorage.getItem("token"));
    }
    req.type("application/json").then(
      (res) => {
        if (success) {
          success(res.body);
        }
      },
      (err) => {
        console.log("err", err);
        if (err.response.statusCode == 401) {
          console.error(err);
          this.handleUnauthorized()
        } else if (
          err.response.statusCode == 405 &&
          this.showDialog &&
          err?.response?.body?.error == "new version needed"
        ) {
          let actionKeys = err?.response?.body?.actions;
          let keys = actionKeys ? JSON.parse(actionKeys) : [];
          if (!keys.length) {
            keys = [versioningActionTypes.refresh];
          }
          this.showDialog(
            "",
            DialogTypes.AppUpdate,
            undefined,
            () => versioningActionsCaller(keys),
            undefined,
            true,
          );
        } else if (err.response.statusCode != 405) {
          this.#notifySubscriptions("onError", err.message);
        } else {
          error ? error(err) : console.error(err);
        }
      },
    );
  };

  cleanData = (data, tableName, removeID = false) => {
    if (dataStructure.hasOwnProperty(tableName) == false) {
      return data;
    }

    let additionalFields = [];
    if (
      tableName == this.Tables.Finance_CalculatedDriver_Values.TableName ||
      tableName == this.Tables.Finance_CalculatedDrivers.TableName
    ) {
      additionalFields[0] = "_id";
    }

    if (Array.isArray(data)) {
      const res = [];
      data.forEach((d) => res.push(this.cleanData(d, tableName)));
      return res;
    }
    const tableStruct = dataStructure[tableName];
    const tmpData = {};

    [...Object.keys(tableStruct.Fields), ...additionalFields].forEach((key) => {
      if (
        !data.hasOwnProperty(key) ||
        (removeID && key == "ID" && tableStruct.Fields[key].dataType == "int")
      ) {
        return;
      }
      tmpData[key] = data[key];
    });

    return tmpData;
  };

  #setSaveFunc = (item, tablename) => {
    if (
      item.hasOwnProperty("Year") &&
      item.hasOwnProperty("Month") &&
      item.hasOwnProperty("PeriodType")
    ) {
      this.DateHelper.setDateIndex(item);
      this.DateHelper.setHeader(item);
      this.DateHelper.setDateID(item);
    }
    item.tableName = tablename;
    item.Save = (success, error) => {
      this.put(item.tableName, null, item, success, error);
    };
  };

  getPath = (tableName, shouldRemoveJson) => {
    if (this.isAdmin && tableName.toLowerCase()) {
      if (shouldRemoveJson) {
        return "/api/master/" + tableName.toLowerCase();
      }
      return this.api_url + tableName.toLowerCase() + ".json";
    } else if (
      tableName === this.Tables.Finance_CalculatedDrivers.TableName ||
      tableName === this.Tables.Finance_CalculatedDriver_Values.TableName
    ) {
      return "/api/cdv/" + tableName.toLowerCase();
    } else {
      return this.api_url + tableName.toLowerCase();
    }
  };

  getTimeStampName = (tablename, ID_CompanyScenario = this.CompanyScenarioInfo.ID) => {
    if (this.CompanyScenarioInfo) {
      return `${lastUpdatedKeyWord}_${tablename}_${this.CompanyScenarioInfo.ID}`;
    }
    return "test";
  };

  mergeCDVs = (items, data) => {
    let new_arr = data;
    if (items.length) {
      const indexes = {};
      // new_arr = [...items, ...data];
      data.forEach((i) => (indexes[i._id] = true));
      items.forEach((i) => {
        if (indexes[i._id] !== true) {
          new_arr.push(i);
        }
      });
    }
    return new_arr;
  };

  get = (
    tablename,
    query,
    success,
    error,
    shouldSetSaveFunction = true,
    shouldRemoveJson = false,
    skipPrefix = false,
  ) => {
    if (this.StaticDataObject && this.StaticDataObject[tablename]) {
      success(this.StaticDataObject[tablename]);
      return;
    }
    if (
      tablename == dataStructure.Finance_CalculatedDrivers.TableName ||
      tablename == dataStructure.Finance_CalculatedDriver_Values.TableName
    ) {
      this.doneGet[tablename] = true;
      let last_updated = 0;
      let timestamp = window.localStorage.getItem(this.getTimeStampName(tablename))
      if (timestamp) {
        try {
          last_updated = JSON.parse(timestamp);
        } catch (e) {
          console.log(e)
        }
      }

      let array1 = [];
      let array2 = [];
      let counter = 0;
      let max = 2;

      let v_query = {};
      if (last_updated) {
        v_query = {v: last_updated};
      }

      this.#makeRequest(
        "GET",
        this.getPath(tablename, shouldRemoveJson),
        { ...query, ...v_query },
        null,
        true,
        (data) => {
          if (shouldSetSaveFunction) {
            if (Array.isArray(data)) {
              data.forEach((item) => this.#setSaveFunc(item, tablename));
            } else {
              this.#setSaveFunc(data, tablename);
            }
          }
          array1 = data;
          if (++counter >= max) {
            success(this.mergeCDVs(array1, array2, tablename, last_updated));
          }
        },
      );
      if (last_updated) {
        query.lastTimeStamp = last_updated;
        this.#makeRequest(
          "GET",
          this.getPath(tablename, shouldRemoveJson),
          query,
          null,
          true,
          (data) => {
            if (shouldSetSaveFunction) {
              if (Array.isArray(data)) {
                data.forEach((item) => this.#setSaveFunc(item, tablename));
              } else {
                this.#setSaveFunc(data, tablename);
              }
            }
            array2 = data;
            if (++counter >= max) {
              success(this.mergeCDVs(array1, array2, tablename, last_updated));
            }
          },
          error,
          false,
          true,
        );
      } else {
        max = 1;
      }
    } else {
      this.#makeRequest(
        "GET",
        skipPrefix ? tablename : this.getPath(tablename, shouldRemoveJson),
        query,
        null,
        true,
        (data) => {
          if (shouldSetSaveFunction) {
            if (Array.isArray(data)) {
              data.forEach((item) => this.#setSaveFunc(item, tablename));
            } else {
              this.#setSaveFunc(data, tablename);
            }
          }
          success(data);
        },
        error,
      );
    }
  };

  interceptRequestSucess = (tablename, success) => {
    let tmpSuccess = success;
    success = (data) => {
      if (
        tablename == dataStructure.Finance_CalculatedDrivers.TableName ||
        tablename == dataStructure.Finance_CalculatedDriver_Values.TableName
      ) {
        if (
          this.doneGet.hasOwnProperty(tablename) &&
          window.localStorage.getItem(this.getTimeStampName(tablename)) === null
        ) {
          window.localStorage.setItem(
            this.getTimeStampName(tablename),
            this.cache_routes[this.getPath(tablename)],
          );
        }
        if (this.cache_routes.hasOwnProperty(this.getPath(tablename))) {
          this.cache_routes[this.getPath(tablename)] = data;
        }
        tmpSuccess && tmpSuccess();
      } else {
        if (this.cache_routes.hasOwnProperty(this.getPath(tablename))) {
          let newTimeStamp;
          if (Array.isArray(data) && data.length) {
            newTimeStamp = data[0].result.last_updated;
          } else {
            newTimeStamp = data.last_updated;
          }
          if (newTimeStamp) {
            this.cache_routes[this.getPath(tablename)] = newTimeStamp;
          }
        }
        tmpSuccess && tmpSuccess(data);
      }
    };
    return success;
  };

  interceptRequestQuery = (tablename, query) => {
    if (
      tablename != dataStructure.Finance_CalculatedDrivers.TableName &&
      tablename != dataStructure.Finance_CalculatedDriver_Values.TableName
    ) {
      if (query == null || query == undefined) {
        query = {};
      }
      if (global.Modeliks.CompanyScenarioInfo) {
        Object.assign(query, {
          ID_CompanyScenario_Cache: global.Modeliks.CompanyScenarioInfo.ID,
        });
      }
    }
    return query;
  };

  post = (tablename, data, success, error, skipPrefix = false, shouldRemoveJson) => {
    if (
      this.disableCDUpdate &&
      (tablename.toLowerCase() == dataStructure.Finance_CalculatedDrivers.TableName.toLowerCase() ||
        tablename.toLowerCase() ==
          dataStructure.Finance_CalculatedDriver_Values.TableName.toLowerCase())
    ) {
      success && success();
      return;
    }
    const cleanData = this.cleanData(data, tablename, true);
    this.#makeRequest(
      "POST",
      skipPrefix ? tablename : this.getPath(tablename, shouldRemoveJson),
      this.interceptRequestQuery(tablename),
      cleanData,
      true,
      this.interceptRequestSucess(tablename, success),
      error,
    );
  };

  put = (tablename, query, data, success, error, shouldRemoveJson = false, skipPrefix = false) => {
    if (
      this.disableCDUpdate &&
      (tablename.toLowerCase() == dataStructure.Finance_CalculatedDrivers.TableName.toLowerCase() ||
        tablename.toLowerCase() ==
          dataStructure.Finance_CalculatedDriver_Values.TableName.toLowerCase())
    ) {
      success && success();
      return;
    }
    const cleanData = this.cleanData(data, tablename);
    this.#makeRequest(
      "PUT",
      skipPrefix ? tablename : this.getPath(tablename, shouldRemoveJson),
      this.interceptRequestQuery(tablename, query),
      cleanData,
      true,
      this.interceptRequestSucess(tablename, success),
      error,
    );
  };

  compareObjects = (queryObj, comparingObject) => {
    let isEqual = true;
    Object.keys(queryObj).forEach((key) => {
      if (queryObj[key] != comparingObject[key]) {
        isEqual = false;
      }
    });

    return isEqual;
  };

  getFilterCDName = (tableName = dataStructure.Finance_CalculatedDrivers.TableName) => {
    if (global.Modeliks.CompanyScenarioInfo) {
      return tableName + "_" + global.Modeliks.CompanyScenarioInfo.ID;
    }
  };

  del = (tablename, query, success, error) => {
    if (
      tablename == dataStructure.Finance_CalculatedDrivers.TableName &&
      query &&
      Object.keys(query).length
    ) {
      let drivers = Modeliks.DriversStore.filter((c) => this.compareObjects(query, c));
      if (global.Modeliks.CompanyScenarioInfo.ID) {
        if (drivers.length) {
          let leanDrivers = drivers.map((c) => {
            return {
              _id: c._id,
              ID: c.ID,
              ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID,
            };
          });
          leanDrivers.forEach((c) => (this.deletedObj[c._id] = c._id));
          window.localStorage.setItem(this.getFilterCDName(), JSON.stringify(this.deletedObj));

          this.#makeRequest(
            "DEL",
            this.getPath(tablename),
            null,
            leanDrivers,
            true,
            this.interceptRequestSucess(tablename, success),
            error,
          );
        } else {
          success([]);
        }
      }
    } else {
      // this.#makeRequest("DEL", this.getPath(tablename), query, null, true, success, error);
      this.#makeRequest(
        "DEL",
        this.getPath(tablename),
        this.interceptRequestQuery(tablename, query),
        null,
        true,
        this.interceptRequestSucess(tablename, success),
        error,
      );
    }
  };

  deleteCompany = (company, callBack) => {
    this.del(
      "publishaccounts",
      { CompanyID: company.ID },
      () => {
        this.del(
          "companyscenarios",
          { ID_Company: company.ID },
          () => {
            this.del(
              "companyusers",
              { Company_ID: company.ID },
              () => {
                this.del(
                  "companies",
                  { ID: company.ID },
                  () => {
                    this.Companies.splice(
                      this.Companies.findIndex((c) => c.ID == company.ID),
                      1,
                    );
                    global.Modeliks.NotifySubsctiptions("onCompanyNameChange");
                    callBack();
                  },
                  (err) => console.error(err),
                );
              },
              (err) => console.error(err),
            );
          },
          (err) => console.error(err),
        );
      },
      (err) => console.error(err),
    );
  };

  OnCompanyNameChange = () => {
    this.#notifySubscriptions("onCompanyNameChange", true);
  };

  deleteScenarioData = (deleteValues = false) => {
    const query = { ID_CompanyScenario: this.CompanyScenarioInfo.ID };
    this.del(this.Tables.Finance_Revenues.TableName, query, () => {
      this.del(this.Tables.Finance_CostSales.TableName, query, () => {
        this.del(this.Tables.Finance_Personnel.TableName, query, () => {
          this.del(this.Tables.Finance_Expenses.TableName, query, () => {
            this.del(this.Tables.Finance_Assets.TableName, query, () => {
              this.del(this.Tables.Finance_Financing.TableName, query, () => {
                this.del(this.Tables.Finance_Taxes.TableName, query, () => {
                  this.del(this.Tables.Finance_WorkingCapital.TableName, query, () => {
                    this.del(this.Tables.Finance_Reports.TableName, query, () => {
                      this.del(this.Tables.Dashboards_CustomKPI.TableName, query, () => {
                        this.del(this.Tables.Valuation_Settings.TableName, query, () => {
                          this.del(this.Tables.Valuation_CostOfCapital.TableName, query, () => {
                            this.del(
                              this.Tables.Valuation_Trading_Multiples.TableName,
                              query,
                              () => {
                                this.del(
                                  this.Tables.Valuation_Transactions_Multiples.TableName,
                                  query,
                                  () => {
                                    if (deleteValues) {
                                      this.del(
                                        this.Tables.Finance_CalculatedDriver_Values.TableName,
                                        { ID_CompanyScenario: this.CompanyScenarioInfo.ID },
                                        () => {
                                          this.del(
                                            this.Tables.Finance_CalculatedDrivers.TableName,
                                            { ID_CompanyScenario: this.CompanyScenarioInfo.ID },
                                            () => {},
                                          );
                                        },
                                      );
                                    }
                                  },
                                );
                              },
                            );
                          });
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        });
      });
    });
  };
}

//
// if (!(global.Modeliks)) {
//     global.Modeliks = new DataManager();
// }

const Modeliks = new DataManager();

export default Modeliks;

export const lastUpdatedKeyWord = "last_updated";

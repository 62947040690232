import Taxes from "./index";
import { TaxesTypes } from "./constants";
import { DriverPeriodTypes } from "../CalculatedDriver/constants";
import { maxRows } from "../../../components/constants/finance";

const TaxesDataGridRows = (Storage) => {
  const taxesTotals = Taxes.getTaxesTotals();
  Storage.dataGridRows = {
    streamTotals: taxesTotals,
    streamTotalsGrowth: Taxes.getTotalsGrowth(taxesTotals),
    gridRows: [],
    gridRowsActual: [],
    totalsRows: [],
    maxRows,
    totalRows: 0,
  };
  let totalRows = Storage.dataGridRows.totalRows;

  const row_create = (tax) => {
    return revenue_only_create(tax);
  };
  const revenue_only_create = (
    tax,
    isChild = false,
    level = 0,
    stopRecursive = false,
    isActual = false,
  ) => {
    let row = {};
    if (tax) {
      totalRows = totalRows + 1;
      if (level) {
        row = {
          id: tax.ID,
          header: tax.Name,
          revenue: tax,
          hasEdit: false,
          disabledEdit: true,
          IsEditable: false,
          isNotEditable: tax && tax.Ref_Field.includes("payments"),
          onEditClick: () => {},
          onEditChildren: () => {},
          onDeleteClick: () => {},
          data: tax,
          children: [],
        };
        if (tax.getChildDrivers()) {
          let children = tax.getChildDrivers();
          if (children && !stopRecursive) {
            children.map((driver) => {
              if (totalRows <= maxRows && driver) {
                row.children.push(
                  revenue_only_create(
                    driver,
                    true,
                    level + 1,
                    driver.PeriodType === DriverPeriodTypes.Previous,
                    isActual,
                  ),
                );
              }
            });
          }
        }
      } else {
        if (tax.hasOwnProperty("Totals")) {
          row = {
            id: tax.Totals.ID,
            header: tax.Totals ? tax.Totals.DriverName : tax.Name,
            revenue: tax.Totals ? tax.Totals : tax,
            onEditClick: () => {},
            onEditChildren: () => {},
            hasEdit: true,
            boldRowHeader: true,
            onDeleteClick: () => {},
            data: tax.Totals ? tax.Totals : tax,
            isExpanded: true,
            children: [],
          };
          if (tax && tax.hasOwnProperty("TaxType")) {
            if (tax.TaxType === TaxesTypes.IncomeTax) {
              if (totalRows <= maxRows) {
                row.children.push(revenue_only_create(tax.Totals, true, level + 1, isActual));
                row.children.push(
                  revenue_only_create(tax.IncomeTaxRate, true, level + 1, isActual),
                );
                if (!isActual) {
                  row.children.push(
                    revenue_only_create(tax.CalculatedAccruedIncomeTax, true, level + 1, isActual),
                  );
                  row.children.push(
                    revenue_only_create(
                      tax.IncomeTaxPayableOpenningBalance,
                      true,
                      level + 1,
                      isActual,
                    ),
                  );
                }
                row.children.push(
                  revenue_only_create(tax.IncomeTaxPayments, true, level + 1, isActual),
                );
                row.children.push(
                  revenue_only_create(
                    tax.IncomeTaxPayableClosingBalance,
                    true,
                    level + 1,
                    isActual,
                  ),
                );
              }
            } else if (tax.TaxType === TaxesTypes.SalesTax) {
              if (totalRows <= maxRows) {
                row.children.push(revenue_only_create(tax.SalesTaxRate, true, level + 1));
                row.children.push(revenue_only_create(tax.TotalAccruedSalesTax, true, level + 1));
                row.children.push(
                  revenue_only_create(tax.SalesTaxPayableOpenningBalance, true, level + 1),
                );
                row.children.push(revenue_only_create(tax.SalesTaxPayments, true, level + 1));
                row.children.push(
                  revenue_only_create(tax.SalesTaxPayableClosingBalance, true, level + 1),
                );
              }
            } else if (tax.TaxType === TaxesTypes.VAT) {
              if (totalRows <= maxRows) {
                row.children.push(revenue_only_create(tax.Totals, true, level + 1));
                row.children.push(revenue_only_create(tax.VATTaxRate, true, level + 1));
                row.children.push(revenue_only_create(tax.TotalAccruedVATTax, true, level + 1));
                row.children.push(
                  revenue_only_create(tax.VATTaxPayableOpenningBalance, true, level + 1),
                );
                row.children.push(revenue_only_create(tax.VATTaxPayments, true, level + 1));
                row.children.push(
                  revenue_only_create(tax.VATTaxPayableClosingBalance, true, level + 1),
                );
              }
            }
          }
        }
      }
      return row;
    } else {
      return null;
    }
  };

  const row_totals = (totals = taxesTotals) => {
    totalRows = totalRows + 1;
    return {
      id: totals.ID,
      unit: global.Modeliks.CompanyInfo.Currency.value,
      header: "Total Taxes",
      cssClass: "totals",
      boldRowHeader: true,
      data: totals,
    };
  };

  const createGridRows = () => {
    Storage.dataGridRows.gridRows = Storage.map((tax) => {
      if (tax.TaxType === TaxesTypes.IncomeTax) {
        Storage.dataGridRows.gridRowsActual = [revenue_only_create(tax, false, 0, false, true)];
      }
      return row_create(tax);
    }).filter((c) => c != null);
    Storage.dataGridRows.totalsRows = [row_totals()];
    Storage.dataGridRows.totalRows = totalRows;
  };

  createGridRows();
};

export default TaxesDataGridRows;

import { fetchSection } from "../Services/api/Repositories/BusinessPlanGenerator";

export async function getSection(sectionName, bpScenarioId) {
  try {
    const response = await fetchSection({
      BusinessPlanScenarioID: bpScenarioId,
      sectionName: sectionName,
    });

    return response;
  } catch (error) {
    console.error(error);
  }
}

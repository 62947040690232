import Tooltip from "@mui/material/Tooltip";
import { SubMenu } from "@szhsin/react-menu";
import React, { useEffect, useRef, useState } from "react";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";
import "../HeaderDefault.scss";
import "../HeaderTextEditor.scss";
import { FooterIcon } from "../../components/IconButtons/SubMenuIcons";

const SUBMENU_OPENING_TRANSITION_DURATION = 300;

function FooterValueComponent(props) {
  const [isShown, setIsShown] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState(
    !props.businessPlan
      ? global.Modeliks.PitchScenarioInfo.FooterText
        ? global.Modeliks.PitchScenarioInfo.FooterText
        : ""
      : global.Modeliks.BusinessPlanConfig.FooterText
        ? global.Modeliks.BusinessPlanConfig.FooterText
        : "",
  );

  const inputElement = useRef(null);
  useEffect(() => {
    if (isShown && inputElement.current) {
      // submenu has inner transition that shows the footer input field
      setTimeout(() => {
        inputElement.current.focus();
      }, SUBMENU_OPENING_TRANSITION_DURATION);
    }
  }, [isShown]);
  const openBulleting = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onClick = (e) => {
    props.onClose();
    if (value) {
      if (!props.businessPlan) {
        global.Modeliks.PitchScenarioInfo.FooterText = value;
        global.Modeliks.PitchScenarioInfo.Save(
          () => {
            props.setFooter();
          },
          (err) => console.error(err),
        );
      } else {
        global.Modeliks.BusinessPlanConfig.FooterText = value;
        global.Modeliks.BusinessPlanConfig.Save(
          () => {
            props.setFooter();
          },
          (err) => console.error(err),
        );
      }
    } else {
      if (!props.businessPlan) {
        global.Modeliks.PitchScenarioInfo.FooterText = null;
        global.Modeliks.PitchScenarioInfo.Save(
          () => {
            props.setFooter();
          },
          (err) => console.error(err),
        );
      } else {
        global.Modeliks.BusinessPlanConfig.FooterText = null;
        global.Modeliks.BusinessPlanConfig.Save(
          () => {
            props.setFooter();
          },
          (err) => console.error(err),
        );
      }
    }
  };
  const RenderFooter = () => {
    return (
      <div onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
        <SubMenu
          label={({ open }) => (
            <Tooltip title="" arrow placement={"left"}>
              <span className={"o_i_btn text"}>
                <div style={{ display: "flex", columnGap: "15px", alignItems: "center" }}>
                  <FooterIcon
                    active={
                      open ||
                      (props.businessPlan && global.Modeliks.BusinessPlanConfig.FooterText) ||
                      (!props.businessPlan && global.Modeliks.PitchScenarioInfo.FooterText)
                    }
                  />
                  <div style={{ color: open ? "#01AFFD" : "unset" }}>Footer</div>
                </div>
              </span>
            </Tooltip>
          )}
          className="footer"
          direction={"right"}
        >
          <div className={"footer_input_container"}>
            <input
              ref={inputElement}
              type="text"
              onChange={(e) => {
                setValue(e.target.value);
              }}
              value={value}
              onFocus={() => (global.pasteListener = null)}
            />
            <ButtonMui
              style={{ width: "40px", minWidth: "30px", height: "26px" }}
              onClick={onClick}
              label={"SET"}
              variant={"contained"}
              roundnessType={"squared"}
            />
          </div>
        </SubMenu>
      </div>
    );
  };

  return <>{RenderFooter()}</>;
}

export default FooterValueComponent;

import { useEffect, useState } from "react";
import { Button } from "../../components/CopiedFromRefactor/Button";
import { CardSelect } from "../../components/CardSelect";
import { QuestionWrapper } from "../../components/QuestionWrapper";
import { Input } from "../../components/Input";
import { SectionButtons } from "../../components/SectionButtons";
import { usePage } from "../../contexts/PageProvider";
import { SectionMode } from "../../helpers/enums";
import { useSave } from "../../hooks/useSave";
import { strategyCustomerJourneyObjectToAiGeneratedContentDto } from "../../models/aiGeneratedContent/transformer";
import { fetchOptions } from "../../Services/api/Repositories/BusinessPlanGenerator";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import { useNavigate } from "react-router-dom";
import { FormNavigation } from "../../components/FormNavigation";

const MAX_CARDS = 10;

export function CustomerJourney() {
  const navigate = useNavigate();
  const {
    section,
    strategyCustomerJourney,
    setStrategyCustomerJourney,
    getGeneratedData,
    setLoading,
    isLoading,
    options,
    setOptions,
  } = usePage();

  const [sectionName, setSectionName] = useState({ sectionName: "" });
  const { nextSection, generateSection, saveSectionInputs } = useSave(sectionName);

  const [cards, setCards] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [inputError, setInputError] = useState(false);
  const [isInputActive, setIsInputActive] = useState(false);
  const [loadingCustomerJourney, setLoadingCustomerJourney] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  const [newCustomerJourneyOptions, setNewCustomerJourneyOptions] = useState([]);

  useEffect(() => {
    if (options)
      setOptions({
        ...options,
        customerJourneyStages: [
          ...options.customerJourneyStages,
          ...newCustomerJourneyOptions.filter(
            (card) => !options.customerJourneyStages.includes(card),
          ),
        ],
      });
  }, [newCustomerJourneyOptions]);

  useEffect(() => {
    if (section) {
      setSectionName({ sectionName: section.name });
    }
  }, [section]);

  const bpScenarioId = global.Modeliks.BusinessPlanScenarioInfo.ID;

  function transformToSelectable(items = [], selectedItems = []) {
    const allItems = items.map((item) => ({
      title: item,
      isSelected: selectedItems.includes(item),
    }));

    selectedItems.forEach((selectedItem) => {
      if (!items.includes(selectedItem)) {
        allItems.push({ title: selectedItem, isSelected: true });
      }
    });

    return allItems;
  }

  async function fetchCustomerJourneyStages() {
    try {
      setLoadingCustomerJourney(true);
      const customerJourneyResponse = await fetchOptions({
        bpScenarioId,
        optionKey: "customerJourneyStages",
      });
      setOptions({
        ...options,
        customerJourneyStages: customerJourneyResponse.SectionData.Options.customerJourneyStages,
      });
      setCards(
        transformToSelectable(
          customerJourneyResponse.SectionData.Options.customerJourneyStages,
          strategyCustomerJourney.customerJourneyStages,
        ),
      );
    } catch (error) {
      console.error("Error fetching customer journey stages:", error);
    } finally {
      setLoadingCustomerJourney(false);
    }
  }

  useEffect(() => {
    if (isLoading) return;

    if (strategyCustomerJourney) {
      if (strategyCustomerJourney.customerJourneyStages) {
        setCards((prev) =>
          transformToSelectable(
            options?.customerJourneyStages || prev,
            strategyCustomerJourney.customerJourneyStages,
          ),
        );
      }
    }
  }, [strategyCustomerJourney]);

  useEffect(() => {
    if (isLoading) return;

    if (options) {
      setInitialRender(false);
      if (options.customerJourneyStages.length === 0) {
        fetchCustomerJourneyStages();
      } else {
        if (initialRender)
          setCards(
            transformToSelectable(
              options.customerJourneyStages,
              strategyCustomerJourney.customerJourneyStages,
            ),
          );
      }
    }
  }, [options, isLoading]);

  function handleInputChange(event) {
    setInputValue(event.target.value);
  }

  function addCard() {
    const trimmedValue = inputValue.trim();
    if (!trimmedValue) return;

    const cardExists = cards.some((card) => card.title === trimmedValue);
    if (cardExists) {
      setInputError(true);
      return;
    }

    const newCard = { title: trimmedValue, isSelected: false };
    setCards((prevCards) => [...prevCards, newCard]);
    setNewCustomerJourneyOptions((prevCards) => [...prevCards, newCard.title]);
    setInputValue("");
    setIsInputActive(false);
    setInputError(false);
  }

  function onCardClick(index) {
    const selectedCount = cards.filter((card) => card.isSelected).length;

    setCards((prevCards) =>
      prevCards.map((card, i) =>
        i === index
          ? {
              ...card,
              isSelected: card.isSelected ? false : selectedCount < MAX_CARDS,
            }
          : card,
      ),
    );
  }

  function handleSaveEdit(index, newTitle) {
    setCards((prevCards) =>
      prevCards.map((card, i) => (i === index ? { ...card, title: newTitle } : card)),
    );
  }

  function validate() {
    return cards.filter((card) => card.isSelected).length > 0;
  }

  async function onNextHandle() {
    if (!validate()) return "strategy/pricing";
    await setStrategyCustomerJourney({
      customerJourneyStages: cards.filter((card) => card.isSelected).map((card) => card.title),
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await nextSection(
            strategyCustomerJourneyObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });

    return "strategy/pricing";
  }

  async function onGenerateHandle() {
    if (!validate()) return;

    await setStrategyCustomerJourney({
      customerJourneyStages: cards.filter((card) => card.isSelected).map((card) => card.title),
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await generateSection(
            strategyCustomerJourneyObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });

    return sectionName;
  }

  async function onSave() {
    await setStrategyCustomerJourney({
      customerJourneyStages: cards.filter((card) => card.isSelected).map((card) => card.title),
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await saveSectionInputs(
            strategyCustomerJourneyObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });
  }

  return (
    <>
      <QuestionWrapper
        isAnswered={cards.some((card) => card.isSelected)}
        question="What are the steps that your customers go through?"
        description="Define all relevant steps that your customers go through when buying your products and services."
        maxSelectiveOptions={MAX_CARDS}
        onRegenerateOptions={fetchCustomerJourneyStages}
      >
        {loadingCustomerJourney ? (
          <CircleLoader />
        ) : (
          <div className="grid grid-cols-2 gap-3 auto-rows-fr">
            {cards.map((card, index) => (
              <CardSelect
                value={card.title}
                onClick={() => onCardClick(index)}
                key={`select-card-${index}`}
                isSelected={card.isSelected}
                onSaveEdit={(i, newValue) => handleSaveEdit(i, newValue)}
                index={index}
              >
                {card.title}
              </CardSelect>
            ))}

            {isInputActive && (
              <Input
                error={inputError}
                placeholder="Add new customer journey step"
                value={inputValue}
                onChange={handleInputChange}
                onAddCard={addCard}
              />
            )}
          </div>
        )}

        <Button className={"w-38"} rounded outline onClick={() => setIsInputActive(true)}>
          Add More
        </Button>
      </QuestionWrapper>

      <SectionButtons onNext={onNextHandle} onGenerate={onGenerateHandle} />

      <FormNavigation onSave={onSave} />
    </>
  );
}

export function AiSectionDto({
                               completed,
                               sectionName,
                               shouldGenerate,
                               account,
                               company,
                               user,
                               bpScenarioId,
                             }) {
  return {
    AccountID: account,
    CompanyID: company,
    UserID: user,
    sectionName,
    shouldGeneratePage: shouldGenerate,
    BusinessPlanScenarioID: bpScenarioId,
    Completed: completed,
  };
}

import React from "react";
import { Shapes } from "../../components/IconButtons/SubMenuIcons";
import CalloutsMenu from "./InsertShapeCalloutsComponent";
import ArrowsMenu from "./InsertShapeArrowsComponent";
import ShapesMenu from "./InsertShapeShapesComponent";
import EquationMenu from "./InsertShapesEquationComponent";
import IconButton from "@mui/material/IconButton";
import {
  Divide,
  DoubleBrace,
  DoubleBrackets,
  Equal,
  LeftBrace,
  LeftBracket,
  Minus,
  Multiply,
  NotEqual,
  Plus,
  RightBrace,
  RightBracket,
} from "../../components/Shapes/EquationIcons";
import { SubMenu, Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import Tooltip from "@mui/material/Tooltip";
import { SlideObject, SlideTypes } from "../../SlideObjects/SlideStructures";
import {
  Arc,
  BlockArc,
  Bolt,
  Can,
  Chord,
  Cloud,
  Cross,
  Cube,
  Decagon,
  DiagonalStripe,
  Diamond,
  Dodecagon,
  Donut,
  FlowchartAccessStorage,
  FlowchartCard,
  FlowchartCollate,
  FlowchartConnector,
  FlowchartDecision,
  FlowchartDelay,
  FlowchartDirectAccessStorage,
  FlowchartDisplay,
  FlowchartDocument,
  FlowchartMagneticDisc,
  FlowchartMerge,
  FlowchartOffPageConnector,
  FlowchartOr,
  FlowchartPredifinedProcess,
  FlowchartPreparation,
  FlowchartSort,
  FlowchartStoredData,
  FlowchartSummingJunction,
  FlowchartTape,
  FlowchartTerminator,
  FoldedCorner,
  Frame,
  HalfFrame,
  Heart,
  Heptagon,
  Hexagon,
  InternalStorage,
  LShape,
  ManualInput,
  ManualOperation,
  Moon,
  MultipleDocuments,
  NoSymbol,
  Octagon,
  Oval,
  Parallelogram,
  Pentagon,
  Pie,
  Plague,
  Rectangle,
  RightTriangle,
  RoundDiagonal,
  RoundedRectangle,
  RoundSameSide,
  RoundSingle,
  Smiley,
  SnipAndRoundSingle,
  SnipDiagonal,
  SnipSameSide,
  SnipSingleCorner,
  Sun,
  Teardrop,
  Trapezoid,
  Triangle,
} from "../../components/Shapes/ShapeIcons";

import {
  Bent,
  BentUp,
  Chevron,
  Down,
  DownCallout,
  Left,
  LeftCallout,
  LeftRight,
  LeftRightUp,
  LeftUp,
  Notched,
  Quad,
  Right,
  RightCallout,
  Striped,
  Up,
  UpCallout,
  UpDown,
  UTurn,
} from "../../components/Shapes/ArrowIcons";
import { Rectangular, Rounded } from "../../components/Shapes/CalloutIcons";

function InsertShapeComponent(props) {
  const insertCalloutObject = (val, top, left) => {
    props.onNewObject(
      new SlideObject(SlideTypes.calloutObject, null, {
        width: 300,
        height: 200,
        top: top,
        left: left,
        calloutHeight: 200,
        calloutAngle: 330,
        calloutType: val,
        backgroundColor: "#585858",
      }),
    );
  };
  const handleNewCalloutObject = (val, e) => {
    props.setInsertMode(
      SlideTypes.shapeObject,
      (top, left) => insertCalloutObject(val, top, left),
      e,
    );
  };

  const insertShapeObject = (shapeType, top, left) => {
    const nObj = new SlideObject(SlideTypes.shapeObject, null, {
      shapeType,
      width: 300,
      height: 300,
      top: top,
      left: left,
      id: new Date().getTime(),
    });
    props.onNewObject(nObj);
  };

  const handleNewShapeObj = (shapeType, e) => {
    props.setInsertMode(
      SlideTypes.shapeObject,
      (top, left) => insertShapeObject(shapeType, top, left),
      e,
    );
  };
  const ShapeInsert = () => {
    return (
      <SubMenu
        className="header_menu arrow shapes"
        // menuButton={({ open }) => <Tooltip disableHoverListener={props.disableEdit} arrow title={'Insert Shape'}><span>
        //  <MenuButton disabled={props.disableEdit}><Shapes active={open}/></MenuButton>
        // </span></Tooltip>}
        label={({ open }) => (
          <span>
            <div style={{ display: "flex", columnGap: "15px", alignItems: "center" }}>
              <Shapes active={open} />
              <div style={{ color: open ? "#01AFFD" : "unset" }}>Shapes</div>
            </div>
          </span>
        )}
      >
        <SubMenu
          label={({ open }) => (
            <>
              <Rectangle active={open} />
              <span className="item_name" style={{ color: open ? "#01AFFD" : "unset" }}>
                Shapes
              </span>
            </>
          )}
        >
          <MenuItem onClick={(e) => handleNewShapeObj(1, e)}>
            <Rectangle />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(2, e)}>
            <RoundedRectangle />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(3, e)}>
            <SnipSingleCorner />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(4, e)}>
            <SnipSameSide />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(5, e)}>
            <SnipDiagonal />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(6, e)}>
            <SnipAndRoundSingle />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(7, e)}>
            <RoundSingle />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(8, e)}>
            <RoundSameSide />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(9, e)}>
            <RoundDiagonal />
          </MenuItem>
          <div className="separator_container">
            <div className="horizontal_separator" />
          </div>
          <MenuItem onClick={(e) => handleNewShapeObj(10, e)}>
            <Oval />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(11, e)}>
            <Triangle />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(12, e)}>
            <RightTriangle />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(13, e)}>
            <Parallelogram />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(14, e)}>
            <Trapezoid />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(15, e)}>
            <Diamond />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(16, e)}>
            <Pentagon />
          </MenuItem>
          {/*<MenuItem onClick={()=>handleNewShapeObj(17)}><Hexagon /></MenuItem>*/}
          {/*<MenuItem onClick={()=>handleNewShapeObj(18)}><Heptagon /></MenuItem>*/}
          {/*<MenuItem onClick={()=>handleNewShapeObj(19)}><Octagon /></MenuItem>*/}
          {/*<MenuItem onClick={()=>handleNewShapeObj(20)}><Decagon /></MenuItem>*/}
          {/*<MenuItem onClick={()=>handleNewShapeObj(21)}><Dodecagon /></MenuItem>*/}
          <MenuItem onClick={(e) => handleNewShapeObj(22, e)}>
            <Pie />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(23, e)}>
            <Arc />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(24, e)}>
            <Chord />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(25, e)}>
            <Teardrop />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(26, e)}>
            <Frame />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(27, e)}>
            <HalfFrame />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(28, e)}>
            <LShape />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(29, e)}>
            <DiagonalStripe />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(30, e)}>
            <Cross />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(31, e)}>
            <Plague />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(32, e)}>
            <Can />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(33, e)}>
            <Cube />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(34, e)}>
            <Donut />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(35, e)}>
            <NoSymbol />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(36, e)}>
            <BlockArc />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(37, e)}>
            <FoldedCorner />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(38, e)}>
            <Smiley />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(39, e)}>
            <Heart />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(40, e)}>
            <Bolt />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(41, e)}>
            <Sun />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(42, e)}>
            <Moon />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(43, e)}>
            <Cloud />
          </MenuItem>
          <div className="separator_container">
            <div className="horizontal_separator" />
          </div>

          <MenuItem onClick={(e) => handleNewShapeObj(1, e)}>
            <Rectangle />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(2, e)}>
            <RoundedRectangle />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(44, e)}>
            <FlowchartDecision />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(45, e)}>
            <FlowchartPredifinedProcess />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(46, e)}>
            <InternalStorage />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(47, e)}>
            <FlowchartDocument />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(48, e)}>
            <MultipleDocuments />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(49, e)}>
            <FlowchartTerminator />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(50, e)}>
            <FlowchartPreparation />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(51, e)}>
            <ManualInput />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(52, e)}>
            <ManualOperation />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(53, e)}>
            <FlowchartConnector />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(54, e)}>
            <FlowchartOffPageConnector />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(55, e)}>
            <FlowchartCard />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(56, e)}>
            <FlowchartTape />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(57, e)}>
            <FlowchartSummingJunction />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(58, e)}>
            <FlowchartOr />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(59, e)}>
            <FlowchartCollate />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(60, e)}>
            <FlowchartSort />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(61, e)}>
            <FlowchartMerge />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(62, e)}>
            <FlowchartStoredData />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(63, e)}>
            <FlowchartDelay />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(64, e)}>
            <FlowchartAccessStorage />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(65, e)}>
            <FlowchartMagneticDisc />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(66, e)}>
            <FlowchartDirectAccessStorage />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(67, e)}>
            <FlowchartDisplay />
          </MenuItem>
        </SubMenu>
        <SubMenu
          label={({ open }) => (
            <>
              <Right active={open} />
              <span className="item_name" style={{ color: open ? "#01AFFD" : "unset" }}>
                Arrows
              </span>
            </>
          )}
        >
          <MenuItem onClick={(e) => handleNewShapeObj(69, e)}>
            <Right />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(70, e)}>
            <Left />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(71, e)}>
            <Up />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(72, e)}>
            <Down />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(73, e)}>
            <LeftRight />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(74, e)}>
            <UpDown />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(75, e)}>
            <Quad />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(76, e)}>
            <LeftRightUp />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(77, e)}>
            <Bent />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(78, e)}>
            <UTurn />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(79, e)}>
            <LeftUp />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(80, e)}>
            <BentUp />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(81, e)}>
            <Striped />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(82, e)}>
            <Notched />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(83, e)}>
            <Pentagon />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(84, e)}>
            <Chevron />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(85, e)}>
            <RightCallout />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(86, e)}>
            <DownCallout />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(87, e)}>
            <LeftCallout />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(88, e)}>
            <UpCallout />
          </MenuItem>
        </SubMenu>
        <SubMenu
          className="callouts"
          label={({ open }) => (
            <>
              <Rectangular active={open} />
              <span className={"item_name"} style={{ color: open ? "#01AFFD" : "unset" }}>
                Callouts
              </span>
            </>
          )}
        >
          <MenuItem onClick={(e) => handleNewCalloutObject(1, e)}>
            <Rectangular />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewCalloutObject(2, e)}>
            <Rounded />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewCalloutObject(3, e)}>
            <Oval />
          </MenuItem>
          {/*<MenuItem onClick={()=>handleNewShapeObj(92)}><Cloud/></MenuItem>*/}
        </SubMenu>
        <SubMenu
          label={({ open }) => (
            <>
              {" "}
              <Plus active={open} />
              <span className={"item_name"} style={{ color: open ? "#01AFFD" : "unset" }}>
                Equation
              </span>
            </>
          )}
        >
          <MenuItem onClick={(e) => handleNewShapeObj(93, e)}>
            <Plus />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(94, e)}>
            <Minus />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(95, e)}>
            <Multiply />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(96, e)}>
            <Divide />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(97, e)}>
            <Equal />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(98, e)}>
            <NotEqual />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(99, e)}>
            <DoubleBrackets />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(100, e)}>
            <DoubleBrace />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(101, e)}>
            <LeftBracket />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(102, e)}>
            <RightBracket />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(103, e)}>
            <LeftBrace />
          </MenuItem>
          <MenuItem onClick={(e) => handleNewShapeObj(104, e)}>
            <RightBrace />
          </MenuItem>
        </SubMenu>
      </SubMenu>
    );
  };

  return <>{ShapeInsert()}</>;
}

export default InsertShapeComponent;

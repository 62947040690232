const NAVBAR_HEIGHT = "68px";

export function PromptWrapper(props) {
  const { children } = props;

  return (
    <div style={{ marginBottom: NAVBAR_HEIGHT }} className={`flex flex-col gap-7`}>
      {children}
    </div>
  );
}

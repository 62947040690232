import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import Chart from "./Chart";
import ChartActual from "./ChartActual";
import { ChartTypes, ChartWidth } from "../../../components/constants/charts";
import ChartTableComponent from "./ChartTableComponent";
import MxMath from "../../../data/MxMath";

export default class HorizontalBarChart extends Chart {
  subscriptions = [];
  constructor(props) {
    super(props);
    this.chartClassName = "HorizontalBarChart";
    this.setClassName();
    this.buildData();
  }

  getSeriesCategoriesKey() {
    return {
      firstKey: "categories",
      secondKey: "series",
    };
  }

  changeNumberFormatting(numberFormat) {
    super.changeNumberFormatting(numberFormat);
    this.forceUpdate();
  }

  shouldComponentUpdate(prevProps, prevState) {
    if (this.chartConfig.width !== this.props.chartConfig.width) {
      // this.chartConfig.width = this.props.chartConfig.width;
      this.chartOptions.numberFormat = this.props.chartOptions.numberFormat;
      return true;
    }
    return this.props != prevProps;
  }

  getChartContainerWidth = () => {
    if (this.chartOptions.dollarDifference || this.chartOptions.percentDifference) {
      if (this.chartConfig.width == ChartWidth.fullScreen.value) {
        return this.chartConfig.width;
      } else {
        return this.chartConfig.width + ChartWidth.quarterScreen.value;
      }
    }
    return this.chartConfig.width;
  };

  getDifferences = () => {
    return this.getArrayDatasets().map((c) => {
      return { data: c.data, backgroundColor: c.backgroundColor };
    });
  };

  getChartWidth = () => {
    if (this.chartOptions.dollarDifference || this.chartOptions.percentDifference) {
      if (
        this.props.chartConfig.width == ChartWidth.fullScreen.value ||
        this.props.chartConfig.width == ChartWidth.threeQuartersScreen.value
      ) {
        return ChartWidth.threeQuartersScreen.value;
      } else if (this.props.chartConfig.width == ChartWidth.halfScreen.value) {
        return 67;
      } else {
        return ChartWidth.halfScreen.value;
      }
    }
    return ChartWidth.fullScreen.value;
  };

  setChartWidth = (width) => {
    this.chartConfig.width = width;
    this.chartResizerRef.style.width = `${this.getChartContainerWidth()}%`;
    this.chartContainerRef.style.width = `${this.getChartWidth()}%`;
    if (this.tableRef) {
      this.tableRef.style.width = `${this.getTableWidth()}%`;
    }
    this.SaveChart();
  };

  getTableWidth = () => {
    if (this.props.chartConfig.width == ChartWidth.quarterScreen.value) {
      return ChartWidth.halfScreen.value;
    } else if (this.props.chartConfig.width == ChartWidth.halfScreen.value) {
      return 33;
    } else {
      return ChartWidth.quarterScreen.value;
    }
  };

  getGrowthOffset(value) {
    return this.getEmptySpaces((value.toString().length + 1) * 4);
  }

  getGrowthValue(value, ctx) {
    let growthValue = "-";

    if (ctx.dataIndex > 0) {
      let prevValue = ctx.chart.config.data.datasets[ctx.datasetIndex].data[ctx.dataIndex - 1];
      if (prevValue == 0) {
        growthValue = 0;
      }
      growthValue = MxMath.Round(((value - prevValue) / prevValue) * 100);
    }
    return growthValue;
  }

  getPlugins() {
    return [this.horizontalDottedLine, this.startYLine];
  }

  getLabelAlignMent() {
    return "end";
  }

  getThisChartOptions = () => {
    let obj = { ...this.getChartOptions() };
      obj.scales.y.ticks.maxTicksLimit = 10

      Object.assign(
      obj,
      { indexAxis: "y" },
      { layout: { padding: { top: this.getTopPadding(), right: 65 } } },
    );
    return obj;
  };

    getThisChartDatasets = () => {
        let obj = this.getData();
        obj.forEach(o => {
            o.datalabels.labels.value = o.datalabels.labels.growth
            o.datalabels.labels.display = true;
            o.datalabels.labels.size = 10;
        })

        Object.assign(
            obj,
        );
        return obj;
    };



  render() {
    return (
      <div
        className={"chart_row"}
        style={{ width: `${this.props.grid ? 100 : this.getChartContainerWidth()}%` }}
        ref={this.setChartResizerRef}
      >
        <div
          className={"c_c_chart_container"}
          style={{ width: `${this.getChartWidth()}%` }}
          ref={this.setChartContainerRef}
        >
          {/*<div ref={this.setChartTitleRef} style={{visibility: this.chartOptions.showTitle?'visible':'hidden'}} className={'chart_title_wrapper'}><ChartActual title={this.getChartTitle(this.chartOptions.comparisonBar)} change={this.getChartTitleChange()} unit={this.getChartTitleGrowthUnit()}/></div>*/}
          {this.getTitleElement()}
          <Bar
            key={this.state.key}
            ref={this.setChartRef}
            data={{
              labels: this.getChartLabels(),
              datasets: this.getThisChartDatasets(),
            }}
            plugins={this.getPlugins()}
            options={this.getThisChartOptions()}
            type={"bar"}
          />
        </div>
        <div
          className={"chart_table_container"}
          style={{
            width: `${this.getTableWidth()}%`,
            display:
              this.chartOptions.dollarDifference || this.chartOptions.percentDifference
                ? "block"
                : "none",
          }}
          ref={this.setTableRef}
        >
          <ChartTableComponent
            numberFormat={this.chartOptions.numberFormat}
            TitleObject={
              <div className={"table_title"}>
                {this.chartData.comparisonPeriod[0]} vs {this.chartData.comparisonPeriod[1]}
              </div>
            }
            data={{
              rows: this.getDifferences().reverse(),
              subscriptions: this.subscriptions,
              chart: this,
            }}
          />
        </div>
      </div>
    );
  }
}

import { Alert, createTheme, getContrastRatio, Snackbar, ThemeProvider } from "@mui/material";
import { NotificationType } from "../../../helpers/enums";
import { InfoIconRound } from "../../icons/svgIcons";

const theme = createTheme({
  palette: {
    info: {
      main: "#4fc3f7",
    },
  },
});

export function Toast(props) {
  const {
    isOpen,
    autoHideDuration = 6000,
    onClose,
    text,
    origin = { horizontal: "right", vertical: "top" },
    severity = NotificationType.INFO,
  } = props;
  return (
    <ThemeProvider theme={theme}>
      <Snackbar open={isOpen} anchorOrigin={origin} onClose={onClose} autoHideDuration={autoHideDuration}>
        <Alert
          iconMapping={{
            info: <InfoIconRound />,
          }}
          onClose={onClose}
          severity={severity}
          variant="filled"
          sx={{ width: "100%", color: "#fff", display: "flex", alignItems: "center" }}
        >
          {text}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

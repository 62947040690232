import { useEffect, useState } from "react";
import { Button } from "../../components/CopiedFromRefactor/Button";
import { CardSelect } from "../../components/CardSelect";
import { QuestionWrapper } from "../../components/QuestionWrapper";
import { Input } from "../../components/Input";
import { SectionButtons } from "../../components/SectionButtons";
import { usePage } from "../../contexts/PageProvider";
import { SectionMode } from "../../helpers/enums";
import { useSave } from "../../hooks/useSave";
import { strategySalesObjectToAiGeneratedContentDto } from "../../models/aiGeneratedContent/transformer";
import { fetchOptions } from "../../Services/api/Repositories/BusinessPlanGenerator";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import { useNavigate } from "react-router-dom";
import { FormNavigation } from "../../components/FormNavigation";

const MAX_CARDS = 5;

export function Sales() {
  const navigate = useNavigate();
  const {
    section,
    strategySales,
    setStrategySales,
    getGeneratedData,
    setLoading,
    isLoading,
    options,
    setOptions,
  } = usePage();

  const [sectionName, setSectionName] = useState({ sectionName: "" });
  const { nextSection, generateSection, saveSectionInputs } = useSave(sectionName);

  const [cards, setCards] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [inputError, setInputError] = useState(false);
  const [isInputActive, setIsInputActive] = useState(false);
  const [loadingSalesChannels, setLoadingSalesChannels] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  const [newSalesOptions, setNewSalesOptions] = useState([]);

  useEffect(() => {
    if (options)
      setOptions({
        ...options,
        salesChannels: [
          ...options.salesChannels,
          ...newSalesOptions.filter((card) => !options.salesChannels.includes(card)),
        ],
      });
  }, [newSalesOptions]);

  useEffect(() => {
    if (section) {
      setSectionName({ sectionName: section.name });
    }
  }, [section]);

  const bpScenarioId = global.Modeliks.BusinessPlanScenarioInfo.ID;

  function transformToSelectable(items = [], selectedItems = []) {
    const allItems = items.map((item) => ({
      title: item,
      isSelected: selectedItems.includes(item),
    }));

    selectedItems.forEach((selectedItem) => {
      if (!items.includes(selectedItem)) {
        allItems.push({ title: selectedItem, isSelected: true });
      }
    });

    return allItems;
  }

  async function fetchSalesChannels() {
    try {
      setLoadingSalesChannels(true);
      const salesChannelsResponse = await fetchOptions({
        bpScenarioId,
        optionKey: "salesChannels",
      });
      setOptions({
        ...options,
        salesChannels: salesChannelsResponse.SectionData.Options.salesChannels,
      });
      setCards(transformToSelectable(salesChannelsResponse.SectionData.Options.salesChannels));
    } catch (error) {
      console.error("Error fetching sales channels:", error);
    } finally {
      setLoadingSalesChannels(false);
    }
  }

  useEffect(() => {
    if (isLoading) return;
    if (strategySales) {
      if (strategySales.salesChannels) {
        setCards((prev) =>
          transformToSelectable(options?.salesChannels || prev, strategySales.salesChannels),
        );
      }
    }
  }, [strategySales]);

  useEffect(() => {
    if (isLoading) return;

    if (options) {
      setInitialRender(false);
      if (options.salesChannels.length === 0) {
        fetchSalesChannels();
      } else {
        if (initialRender) {
          setCards(transformToSelectable(options.salesChannels, strategySales.salesChannels));
        }
      }
    }
  }, [options]);

  function handleInputChange(event) {
    setInputValue(event.target.value);
  }

  function addCard() {
    const trimmedValue = inputValue.trim();
    if (!trimmedValue) return;

    const cardExists = cards.some((card) => card.title === trimmedValue);
    if (cardExists) {
      setInputError(true);
      return;
    }

    const newCard = { title: trimmedValue, isSelected: false };
    setCards((prevCards) => [...prevCards, newCard]);
    setNewSalesOptions((prevCards) => [...prevCards, newCard.title]);
    setInputValue("");
    setIsInputActive(false);
    setInputError(false);
  }

  function onCardClick(index) {
    const selectedCount = cards.filter((card) => card.isSelected).length;

    setCards((prevCards) =>
      prevCards.map((card, i) =>
        i === index
          ? {
              ...card,
              isSelected: card.isSelected ? false : selectedCount < MAX_CARDS,
            }
          : card,
      ),
    );
  }

  function handleSaveEdit(index, newTitle) {
    setCards((prevCards) =>
      prevCards.map((card, i) => (i === index ? { ...card, title: newTitle } : card)),
    );
  }

  function validate() {
    return cards.filter((card) => card.isSelected).length > 0;
  }

  async function onNextHandle() {
    if (!validate()) return "strategy/operations";
    await setStrategySales({
      salesChannels: cards.filter((card) => card.isSelected).map((card) => card.title),
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await nextSection(strategySalesObjectToAiGeneratedContentDto(generatedData, sectionName));
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });

    return "strategy/operations";
  }

  async function onGenerateHandle() {
    if (!validate()) return;

    await setStrategySales({
      salesChannels: cards.filter((card) => card.isSelected).map((card) => card.title),
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await generateSection(
            strategySalesObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });

    return sectionName;
  }

  async function onSave() {
    await setStrategySales({
      salesChannels: cards.filter((card) => card.isSelected).map((card) => card.title),
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await saveSectionInputs(
            strategySalesObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });
  }

  return (
    <>
      <QuestionWrapper
        isAnswered={cards.some((card) => card.isSelected)}
        question={"What are your Sales Channels?"}
        description={"Define the sales channels for your business."}
        maxSelectiveOptions={5}
        onRegenerateOptions={fetchSalesChannels}
      >
        {loadingSalesChannels ? (
          <CircleLoader />
        ) : (
          <div className="grid grid-cols-2 gap-3 auto-rows-fr">
            {cards.map((card, index) => (
              <CardSelect
                value={card.title}
                onClick={() => onCardClick(index)}
                key={`select-card-${index}`}
                isSelected={card.isSelected}
                onSaveEdit={(i, newValue) => handleSaveEdit(i, newValue)}
                index={index}
              >
                {card.title}
              </CardSelect>
            ))}

            {isInputActive && (
              <Input
                error={inputError}
                placeholder="Add new sales channel"
                value={inputValue}
                onChange={handleInputChange}
                onAddCard={addCard}
              />
            )}
          </div>
        )}

        <Button className={"w-38"} rounded outline onClick={() => setIsInputActive(true)}>
          Add More
        </Button>
      </QuestionWrapper>

      <SectionButtons onNext={onNextHandle} onGenerate={onGenerateHandle} />

      <FormNavigation onSave={onSave} />
    </>
  );
}

import React from "react";
import Box from "@mui/material/Box";
import "./SlideHeaderContainer.scss";
import ButtonJ from "./Buttons/Button";
import { Menu, MenuItem } from "@szhsin/react-menu";
import ShareDialog from "./Dialog/ShareDialog";
import { Link, Navigate } from "react-router-dom";
import request from "superagent";
import ButtonMui from "../../../components/buttons/buttonMui/buttonMui";
import IndustriesMenu from "../../Admin/components/IndustriesMenu";
import CantShareDialog from "../../../components/dialogs/CantShareDialog/CantShareDialog";
import { ArrowDown } from "../../../components/icons/svgIcons";
import UpdatePlan from "../../../components/dialogs/LimitDialogs/UpdatePlan";
import { AccessControl } from "../../../data/Permissions/AccessControl";
import { Button } from "../../../BusinessPlan/AiGenerator/components/CopiedFromRefactor/Button";
import { BusinessPlanType } from "../../../BusinessPlan/AiGenerator/helpers/enums";
import { PageContext } from "../../../BusinessPlan/AiGenerator/contexts/PageProvider";

export default class SlideHeaderContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: false,
      link: null,
      openCantShare: false,
    };
  }

  componentWillUnmount() {}

  onPreviewMode = (data) => {};

  saveContent = (callBack) => {
    if (this.props.saveSlideContent) {
      this.props.saveSlideContent(callBack);
    } else {
      callBack();
    }
  };

  onClick = () => {
    if (this.props.canPreview) {
      this.saveContent(() => {
        request
          .get(`/api/checkIfFreeTrial`)
          .set("authorization", "Bearer " + window.localStorage.getItem("token"))
          .then((res) => {
            if (res.body.isFreeTrial) {
              this.props.openFreeTrialDialog();
            } else {
              this.setState({
                link: this.props.route ? this.props.route : "/business_plan/preview",
              });
            }
          });
      });
    } else {
      this.setState({ openCantShare: true });
    }
  };

  returnMidContainer = () => {
    if (this.props.isAdmin) {
      return (
        <IndustriesMenu
          Industries={this.props.Industries}
          selectedIndustry={this.props.selectedIndustry}
          addNewIndustry={this.props.addNewIndustry}
          deleteIndustry={this.props.deleteIndustry}
          editIndustry={this.props.editIndustry}
          onIndustryChange={this.props.onIndustryChange}
          updateIndustryVisibility={this.props.updateIndustryVisibility}
        />
      );
    }
  };

  setShowUpgradeSubscription = (value = !this.state.showUpgradePlan) =>
    this.setState({ showUpgradePlan: value });

  render() {
    if (this.state.link) {
      return (
        <>
          <Navigate to={this.state.link} />
          {this.setState({ link: null })}
        </>
      );
    }
    return (
      <PageContext.Consumer>
        {({ setShouldCreateNewBp }) => (
          <div
            className={this.state.hidden ? "se_panel_header hidden" : "se_panel_header"}
            id={"se_panel_header"}
            onMouseMove={(e) => {
              e.stopPropagation();
            }}
          >
            {this.state.showUpgradePlan && (
              <UpdatePlan
                handleClose={() => this.setShowUpgradeSubscription(false)}
                onCancel={() => this.setShowUpgradeSubscription(false)}
                open
              />
            )}
            <div className={"header_default_main_container"}>
              <div id={"hd_left_container"}>
                {this.props.goBack && (
                  <ButtonMui
                    onClick={this.props.goBack}
                    style={{ margin: "0 10px" }}
                    label={"Go Back"}
                    variant={"contained"}
                  />
                )}
              </div>

              <div id={"hd_mid_container"}>{this.returnMidContainer()}</div>
              {this.props.businessPlan &&
                (global.Modeliks.BusinessPlanScenarioInfo.Type === BusinessPlanType.AiPlan ? (
                  <Link to="/business_plan/ai_generator" className="mr-10">
                    <Button variant={"quaternary"} rounded className={"h-9 w-48"}>
                      Business Plan Wizard
                    </Button>
                  </Link>
                ) : (
                  <div className=" mr-10">
                    <Button
                      onClick={() => setShouldCreateNewBp(true)}
                      variant={"quaternary"}
                      rounded
                      className={"h-9 w-48"}
                    >
                      Business Plan Wizard
                    </Button>
                  </div>
                ))}
              <div id={"hd_right_container"}>
                {/*<ScenarioMenu pitchHeader={true} disabled={global.Modeliks.UserPermissions[PermissionTypes.Financials]==0} onScenarioChange={props.onScenarioChange} selectedScenario={global.Modeliks.CompanyScenarioInfo} companyScenario={true} Scenarios={global.Modeliks.CompanyScenarios}/>*/}
                {/*<ScenarioMenu setSaving={props.setSaving} disabled={global.Modeliks.UserPermissions[PermissionTypes.Pitch]==0} onScenarioChange={props.onScenarioChange} selectedScenario={global.Modeliks.PitchScenarioInfo} pitchScenario={true} Scenarios={global.Modeliks.PitchScenarios}/>*/}
                {this.props.businessPlan ? (
                  <AccessControl
                    allowedPermissions={[
                      {
                        [global.Modeliks.PERMISSIONS.BusinessPlan.key]:
                          global.Modeliks.PERMISSIONS.BusinessPlan.restrictions.ExportPDF.key,
                      },
                      {
                        [global.Modeliks.PERMISSIONS.BusinessPlan.key]:
                          global.Modeliks.PERMISSIONS.BusinessPlan.restrictions.ShareLink.key,
                      },
                    ]}
                    renderNoAccess={
                      <ButtonMui
                        onClick={(e) => {
                          this.setShowUpgradeSubscription(true);
                        }}
                        variant={"contained"}
                        label={this.props.label ? this.props.label : "Preview Mode"}
                        color={"grey"}
                        endIcon={<ArrowDown />}
                      />
                    }
                  >
                    <ButtonMui
                      label={this.props.label ? this.props.label : "Preview Mode"}
                      variant={"outlined"}
                      onClick={this.onClick}
                    />
                  </AccessControl>
                ) : (
                  <ButtonMui //onClick={this.props.onPreview}
                    variant={this.props.variant ?? "outlined"}
                    startIcon={this.props.startIcon ?? undefined}
                    label={this.props.label ?? "Preview Mode"}
                    onClick={() => {
                      if (this.props.canPreview) {
                        this.props.onClick();
                      } else {
                        this.setState({ openCantShare: true });
                      }
                    }}
                  />
                )}
              </div>
            </div>

            <CantShareDialog
              businessPlan={this.props.businessPlan}
              functionality={this.props.businessPlan ? "preview" : "present"}
              open={this.state.openCantShare}
              handleClose={() => {
                this.setState({ openCantShare: false });
              }}
            />
          </div>
        )}
      </PageContext.Consumer>
    );
  }
}

function stringFormatter(input, maxLength = 19) {
  let formattedLabel = [];

  for (let i = 0; i < input.length; i += maxLength) {
    formattedLabel.push(input.substr(i, maxLength));
  }

  return formattedLabel;
}

export {stringFormatter};

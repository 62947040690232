import CalculatedDriver from "../CalculatedDriver/index";
import { DriverCategories, UnitTypes } from "../CalculatedDriver/constants";
import MxIDHelper from "../../MxIDHelper";
import datastructure from "../../datastructure.json";
import FinanceGeneric from "../FinanceGeneric";
import { AssetCategories, AssetType, PaymentType, PurchaseType, ResellType } from "./constants";
import CalculatedDriver_Values from "../CalculatedDriver/CalculatedDriver_Values";
import MxMath from "../../MxMath";
import Inventory from "../WorkingCapital/Inventory";
import AccountReceivable from "../WorkingCapital/AccountReceivable";
import { ReportsTypes } from "../Reports/constants";
import IncomeTax from "../Taxes/IncomeTax";
import CostSales from "../CostSales";
import { CostOfSalesGeneral, CostOfSalesTypes } from "../../../components/constants/costofsales";
import { PERMISSIONS } from "../../Permissions/Permissions";
import Personnel from "../Personnel";
import { DialogTypes } from "../constants";

class Assets extends FinanceGeneric {
  Name = "";
  ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
  AssetType = AssetType.LongTerm;
  AssetCategory = AssetCategories.PropertyPlantAndEquipment;
  PurchaseType = PurchaseType.OneTime;
  PaymentType = PaymentType.FullPrice;
  ResellType = ResellType.No;
  OpeningBalance = false;

  PaymentPercentAtPurchase;
  BalancePaidOver;
  TimeOfSale;
  SalePriceOfPurchasePrice;
  PurchaseTime;
  AssetLifeSpanMonths;
  PaymentAtTimeOfPurchase;
  GrossBookValueOpeningBalance;
  AccumulatedDepreciationOpeningBalance;
  NetBookValueOpeningBalance;

  constructor(db_record) {
    super(db_record);
    if (this.db_record) {
      this.clean();
      this.setDriversFromDataStorageSub();
      // this.setAsset()
    }
    this.SaveRevenue = this.Save;
    this.Save = this.SaveAssets;
  }

  clean = (cleanDrivers = false) => {
    if (this.db_record && this.Totals) {
      this.Name = this.db_record.Name;
      this.AssetType = this.db_record.AssetType;
      this.AssetCategory = this.db_record.AssetCategory;
      this.PurchaseType = this.db_record.PurchaseType;
      this.PaymentType = this.db_record.PaymentType;
      this.ResellType = this.db_record.ResellType;
      this.ID_CompanyScenario = this.db_record.ID_CompanyScenario;
      this.OpeningBalance = this.db_record.OpeningBalance;
      this.ID_CompanyScenario = this.db_record.ID_CompanyScenario;
      if (cleanDrivers) {
        this.AssetLifeSpanMonths.cleanValue();
        this.PaymentPercentAtPurchase.cleanValue();
        this.BalancePaidOver.cleanValue();
        this.TimeOfSale.cleanValue();
        this.SalePriceOfPurchasePrice.cleanValue();
        this.PurchaseTime.cleanValue();
        this.GrossBookValueOpeningBalance.cleanValue();
        this.AccumulatedDepreciationOpeningBalance.cleanValue();
        this.NetBookValueOpeningBalance.cleanValue();
        this.cleanDrivers();
      }
    }
  };

  static DriversDesc = Object.assign({
    Total: {
      driverName: "$Name",
      fieldName: "Totals",
      driverID: "total",
      unit: UnitTypes.Price,
      category: DriverCategories.LastPeriod,
    },
    AssetPurchases: {
      driverName: "Asset Purchases - $Name",
      fieldName: "AssetPurchases",
      driverID: "asset_purchase",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    CumulativeAssetPurchases: {
      driverName: "Cumulative Asset Purchases - $Name",
      fieldName: "CumulativeAssetPurchases",
      driverID: "cumulative_asset_purchase",
      unit: UnitTypes.Price,
      category: DriverCategories.LastPeriod,
    },
    PaymentAtTimeOfPurchase: {
      driverName: "Payment At Time Of Purchase - $Name",
      fieldName: "PaymentAtTimeOfPurchase",
      driverID: "payment_at_time_of_purchase",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    BalanceToBePayed: {
      driverName: "Balance To Be Payed - $Name",
      fieldName: "BalanceToBePayed",
      driverID: "balance_to_be_payed",
      unit: UnitTypes.Price,
      category: DriverCategories.LastPeriod,
    },
    PaymentOfBalance: {
      driverName: "Payment Of Balance - $Name",
      fieldName: "PaymentOfBalance",
      driverID: "payment_of_balance",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    AssetPayableBalance: {
      driverName: "Asset Payable Balance - $Name",
      fieldName: "AssetPayableBalance",
      driverID: "asset_payable_balance",
      unit: UnitTypes.Price,
      category: DriverCategories.LastPeriod,
    },
    TotalAssetPayments: {
      driverName: "Total Asset Payments - $Name",
      fieldName: "TotalAssetPayments",
      driverID: "total_asset_payments",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    AssetSales: {
      driverName: "Asset Sales - $Name",
      fieldName: "AssetSales",
      driverID: "asset_sales",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    PurchaseValueOfSoldAssets: {
      driverName: "Purchase Value Of Sold Assets - $Name",
      fieldName: "PurchaseValueOfSoldAssets",
      driverID: "purchase_value_of_sold_assets",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    CumulativePurchaseValueOfSoldAssets: {
      driverName: "Cumulative Purchase Value Of Sold Assets - $Name",
      fieldName: "CumulativePurchaseValueOfSoldAssets",
      driverID: "cumulative_purchase_value_of_sold_assets",
      unit: UnitTypes.Price,
      category: DriverCategories.LastPeriod,
    },
    GrossBookValue: {
      driverName: "Gross Book Value - $Name",
      fieldName: "GrossBookValue",
      driverID: "gross_book_value",
      unit: UnitTypes.Price,
      category: DriverCategories.LastPeriod,
    },
    Depreciation: {
      driverName: "Depreciation - $Name",
      fieldName: "Depreciation",
      driverID: "depreciation",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    DepreciationOfOpeningBalance: {
      driverName: "Depreciation Of Opening Balance - $Name",
      fieldName: "DepreciationOfOpeningBalance",
      driverID: "depreciation_of_opening_balance",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    AccumulatedDepreciationOfSoldAssets: {
      driverName: "Accumulated Depreciation Of Sold Assets - $Name",
      fieldName: "AccumulatedDepreciationOfSoldAssets",
      driverID: "accumulated_depreciation_of_sold_assets",
      unit: UnitTypes.Price,
      category: DriverCategories.LastPeriod,
    },
    AccumulatedDepreciation: {
      driverName: "Accumulated Depreciation - $Name",
      fieldName: "AccumulatedDepreciation",
      driverID: "accumulated_depreciation",
      unit: UnitTypes.Price,
      category: DriverCategories.LastPeriod,
    },
    CumulativeDepreciationOfOpeningBalance: {
      driverName: "Cumulative Depreciation Of Opening Balance - $Name",
      fieldName: "CumulativeDepreciationOfOpeningBalance",
      driverID: "cumulative_depreciation_of_opening_balance",
      unit: UnitTypes.Price,
      category: DriverCategories.LastPeriod,
    },
    NetBookValue: {
      driverName: "Net Book Value - $Name",
      fieldName: "NetBookValue",
      driverID: "net_book_value",
      unit: UnitTypes.Price,
      category: DriverCategories.LastPeriod,
    },
    GainLossFromAssetSales: {
      driverName: "Gain Loss From Asset Sales - $Name",
      fieldName: "GainLossFromAssetSales",
      driverID: "gain_loss_from_asset_sales",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
  });

  static TableName = datastructure.Finance_Assets.TableName;

  setDriversFromDataStorageSub = () => {
    this.AssetLifeSpanMonths = global.Modeliks.DriverValuesStore.getItem(
      this.getAssetLifeSpanMonthsID(),
    );
    this.PurchaseTime = global.Modeliks.DriverValuesStore.getItem(this.getPurchaseTimeID());
    this.PaymentPercentAtPurchase = global.Modeliks.DriverValuesStore.getItem(
      this.getPaymentPercentAtPurchaseID(),
    );
    this.BalancePaidOver = global.Modeliks.DriverValuesStore.getItem(this.getBalancePaidOverID());
    this.TimeOfSale = global.Modeliks.DriverValuesStore.getItem(this.getTimeOfSaleID());
    this.SalePriceOfPurchasePrice = global.Modeliks.DriverValuesStore.getItem(
      this.getSalePriceOfPurchasePriceID(),
    );
    this.GrossBookValueOpeningBalance = global.Modeliks.DriverValuesStore.getItem(
      this.getGrossBookValueOpeningBalanceID(),
    );
    this.AccumulatedDepreciationOpeningBalance = global.Modeliks.DriverValuesStore.getItem(
      this.getAccumulatedDepreciationOpeningBalanceID(),
    );
    this.NetBookValueOpeningBalance = global.Modeliks.DriverValuesStore.getItem(
      this.getNetBookValueOpeningBalanceID(),
    );
  };

  Save = (callBack, saveDrivers = true) => {
    if (!this.isNew && !this.IsCreated) {
      global.Modeliks.put(this.constructor.TableName, null, this, (res) => {
        if (saveDrivers) {
          this.SaveDrivers(callBack);
        } else {
          callBack();
        }
      });
    } else {
      global.Modeliks.put(this.constructor.TableName, null, this, (res) => {
        global.Modeliks.DriversStore.createDriversFrom(
          this.ID,
          this.constructor.TableName,
          this.Totals,
          this,
        );
        if (saveDrivers) {
          this.SaveDrivers(callBack);
        } else {
          callBack();
        }
      });
    }
  };

  SaveAssets = (callBack, saveDrivers = true) => {
    console.log("SaveAssets", this);
    this.SaveRevenue(() => {
      this.AssetLifeSpanMonths.Save();
      this.PaymentPercentAtPurchase.Save();
      this.BalancePaidOver.Save();
      this.TimeOfSale.Save();
      this.SalePriceOfPurchasePrice.Save();
      this.PurchaseTime.Save();
      this.GrossBookValueOpeningBalance.Save();
      this.AccumulatedDepreciationOpeningBalance.Save();
      this.NetBookValueOpeningBalance.Save();
      if (saveDrivers) {
        this.SaveDrivers(callBack, this.ID);
      } else {
        callBack(this.ID);
      }
    }, false);
  };

  changeDriversName = () => {
    Object.keys(this.constructor.DriversDesc).forEach((key) => {
      const driverDesc = this.constructor.DriversDesc[key];
      if (this[driverDesc.fieldName]) {
        this[driverDesc.fieldName].DriverName = driverDesc.driverName.replace("$Name", this.Name);
      } else {
        console.log("driver not found", this, driverDesc, this[driverDesc.fieldName]);
      }
    });
  };

  periodsData = {};

  buildPeriodsData = () => {
    const allPeriods = this.AssetPurchases.Values.map((c) => c.Date);
    allPeriods.forEach((period) => {
      this.periodsData[period.dateID] = {};
      Object.values(this.constructor.DriversDesc).forEach(
        (driver) =>
          (this.periodsData[period.dateID][driver.fieldName] = this[
            driver.fieldName
          ].getItemByDateSufix(period.sufix)),
      );
      this.periodsData[period.dateID].Date = period;
    });
  };

  getAssetLifeSpanMonthsID = () => {
    return `${Assets.TableName}-${this.ID}-asset_lifespan_months`;
  };
  getPaymentPercentAtPurchaseID = () => {
    return `${Assets.TableName}-${this.ID}-payment_at_purchase`;
  };
  getBalancePaidOverID = () => {
    return `${Assets.TableName}-${this.ID}-balance_paid_over`;
  };
  getTimeOfSaleID = () => {
    return `${Assets.TableName}-${this.ID}-time_of_sale`;
  };
  getSalePriceOfPurchasePriceID = () => {
    return `${Assets.TableName}-${this.ID}-sale_price_as_percent_of_purchase_price`;
  };
  getPurchaseTimeID = () => {
    return `${Assets.TableName}-${this.ID}-purchase_time`;
  };
  getGrossBookValueOpeningBalanceID = () => {
    return `${Assets.TableName}-${this.ID}-gross_book_value_opening_balance`;
  };
  getAccumulatedDepreciationOpeningBalanceID = () => {
    return `${Assets.TableName}-${this.ID}-accumulated_depreciation_opening_balance`;
  };
  getNetBookValueOpeningBalanceID = () => {
    return `${Assets.TableName}-${this.ID}-net_book_value_opening_balance`;
  };

  getMonthDatesAll = () => {
    return [...global.Modeliks.DateHelper.months, ...global.Modeliks.DateHelper.months_after];
  };

  getYearDatesAll = () => {
    return global.Modeliks.DateHelper.years_all;
  };

  createExtraPeriods = () => {
    Object.values(this.constructor.DriversDesc).forEach((driver) => {
      this[driver.fieldName].addMonths(0, true);
    });
  };
  createMonthsFormulas = () => {
    const months = this.getMonthDatesAll();

    if (this.PaymentType === PaymentType.FullPrice) {
      this.PaymentPercentAtPurchase.Value = 100;
      this.BalancePaidOver.Value = 1;
    }

    if (this.ResellType === ResellType.No) {
      this.SalePriceOfPurchasePrice.Value = 0;
      this.TimeOfSale.Value = months.length + 5;
    }

    let AssetLifeSpanMonths = this.AssetLifeSpanMonths.Value;
    if (this.AssetType === AssetType.LongTerm && AssetLifeSpanMonths !== 0) {
      AssetLifeSpanMonths = AssetLifeSpanMonths * 12;
    }

    months.forEach((month) => {
      const prevMonthDate = months.find((c) => c.Order == month.Order - 1);
      const nextMonthDate = months.find((c) => c.Order == month.Order + 1);
      const curMonth = this.periodsData[month.dateID];
      const prevMonth = prevMonthDate ? this.periodsData[prevMonthDate.dateID] : null;
      const prevMonthsDates = (month, prevMonthsNumber) =>
        months.filter((c) => c.Order <= month.Order && c.Order > month.Order - prevMonthsNumber);
      const prevSingleMonthDate = (month, prevMonths) =>
        this.periodsData[
          months.find((c) => c.Order === month.Order - prevMonths) &&
            months.find((c) => c.Order === month.Order - prevMonths).dateID
        ];

      if (this.PurchaseType === PurchaseType.OneTime && this.PurchaseTime.Value !== month.Order) {
        curMonth.AssetPurchases.Value = 0;
      }
      curMonth.PaymentAtTimeOfPurchase.Formula = `${curMonth.AssetPurchases} * ${this.PaymentPercentAtPurchase}`;

      let PaymentOfBalanceArr = [];

      if (prevMonth) {
        curMonth.AssetPayableBalance.Formula = `${prevMonth.AssetPayableBalance} + ${curMonth.BalanceToBePayed} - ${curMonth.PaymentOfBalance}`;
        curMonth.CumulativeAssetPurchases.Formula = `${prevMonth.CumulativeAssetPurchases} + ${curMonth.AssetPurchases}`;
        curMonth.CumulativePurchaseValueOfSoldAssets.Formula = `${prevMonth.CumulativePurchaseValueOfSoldAssets} + ${curMonth.PurchaseValueOfSoldAssets}`;
        curMonth.AccumulatedDepreciation.Formula = `${prevMonth.AccumulatedDepreciation} + ${curMonth.Depreciation} - ${curMonth.AccumulatedDepreciationOfSoldAssets}`;
        PaymentOfBalanceArr = prevMonthsDates(prevMonthDate, this.BalancePaidOver.Value);
        curMonth.CumulativeDepreciationOfOpeningBalance.Formula = `${prevMonth.CumulativeDepreciationOfOpeningBalance} + ${curMonth.DepreciationOfOpeningBalance}`;
        curMonth.DepreciationOfOpeningBalance.Formula = `MxMath.IFELSE(${prevMonth.CumulativeDepreciationOfOpeningBalance} >= ${this.NetBookValueOpeningBalance}, 0 ,MxMath.IFELSE((${prevMonth.CumulativeDepreciationOfOpeningBalance} + ${prevMonth.DepreciationOfOpeningBalance}) > ${this.NetBookValueOpeningBalance}, ${this.NetBookValueOpeningBalance} - ${prevMonth.CumulativeDepreciationOfOpeningBalance}, ${this.GrossBookValueOpeningBalance} / ${AssetLifeSpanMonths} ))`;
      } else {
        curMonth.AssetPayableBalance.Formula = `${curMonth.BalanceToBePayed} - ${curMonth.PaymentOfBalance}`;
        curMonth.CumulativeAssetPurchases.Formula = `${curMonth.AssetPurchases}`;
        curMonth.CumulativePurchaseValueOfSoldAssets.Formula = `${curMonth.PurchaseValueOfSoldAssets}`;
        curMonth.AccumulatedDepreciation.Formula = `${curMonth.Depreciation} - ${curMonth.AccumulatedDepreciationOfSoldAssets} + ${this.AccumulatedDepreciationOpeningBalance}`;
        curMonth.CumulativeDepreciationOfOpeningBalance.Formula = `${curMonth.DepreciationOfOpeningBalance}`;
        curMonth.DepreciationOfOpeningBalance.Formula = `${this.GrossBookValueOpeningBalance} / ${AssetLifeSpanMonths}`;
      }

      const prevTimeOfSale = prevSingleMonthDate(month, this.TimeOfSale.Value);

      if (prevTimeOfSale) {
        curMonth.AssetSales.Formula = `${prevTimeOfSale.AssetPurchases} * ${this.SalePriceOfPurchasePrice}`;
        curMonth.PurchaseValueOfSoldAssets.Formula = `${prevTimeOfSale.AssetPurchases}`;
      } else {
        curMonth.AssetSales.Value = 0;
        curMonth.PurchaseValueOfSoldAssets.Value = 0;
        curMonth.AssetSales.Formula = null;
        curMonth.PurchaseValueOfSoldAssets.Formula = null;
      }

      let Depreciation = prevMonthsDates(
        month,
        MxMath.Min(this.TimeOfSale.Value, AssetLifeSpanMonths),
      );

      curMonth.PaymentOfBalance.Formula = `MxMath.Sum([${PaymentOfBalanceArr.map((month) => this.periodsData[month.dateID].BalanceToBePayed.ID_f).join(",")}]) / ${this.BalancePaidOver}`;
      curMonth.Depreciation.Formula = `MxMath.Sum([${Depreciation.map((month) => this.periodsData[month.dateID].AssetPurchases.ID_f).join(",")}]) / ${AssetLifeSpanMonths} + ${curMonth.DepreciationOfOpeningBalance}`;
      curMonth.AccumulatedDepreciationOfSoldAssets.Formula = `MxMath.Min(${curMonth.PurchaseValueOfSoldAssets} / ${AssetLifeSpanMonths} * ${this.TimeOfSale}, ${curMonth.PurchaseValueOfSoldAssets})`;
      curMonth.GainLossFromAssetSales.Formula = `MxMath.IF(${curMonth.AssetSales}, ${curMonth.AssetSales} - (${curMonth.PurchaseValueOfSoldAssets} - ${curMonth.AccumulatedDepreciationOfSoldAssets}))`;
      curMonth.BalanceToBePayed.Formula = `${curMonth.AssetPurchases} - ${curMonth.PaymentAtTimeOfPurchase}`;
      curMonth.TotalAssetPayments.Formula = `${curMonth.PaymentOfBalance} + ${curMonth.PaymentAtTimeOfPurchase}`;
      curMonth.GrossBookValue.Formula = `${curMonth.CumulativeAssetPurchases} - ${curMonth.CumulativePurchaseValueOfSoldAssets} + ${this.GrossBookValueOpeningBalance}`;
      curMonth.NetBookValue.Formula = `${curMonth.GrossBookValue} - ${curMonth.AccumulatedDepreciation}`;
      curMonth.Totals.Formula = `${curMonth.NetBookValue}`;

      if (AssetLifeSpanMonths === 0) {
        curMonth.Depreciation.Formula = null;
        curMonth.AccumulatedDepreciation.Formula = null;
        curMonth.AccumulatedDepreciationOfSoldAssets.Formula = null;
        curMonth.Depreciation.Value = 0;
        curMonth.AccumulatedDepreciation.Value = 0;
        curMonth.AccumulatedDepreciationOfSoldAssets.Value = 0;
      }
    });
  };

  createYearsFormulas = () => {
    const years = this.getYearDatesAll();

    years.forEach((year) => {
      const curYear = this.periodsData[year.dateID];
      const months = this.periodsData[year.dateID].Date.monthIndexes.map(
        (index) => this.periodsData[index],
      );
      const lastMonth = months[months.length - 1];
      if (year.Active) {
        months[6].AssetPurchases.Formula = `${curYear.AssetPurchases}`;
        if (this.PurchaseType === PurchaseType.OneTime && this.PurchaseTime.Value !== year.Order) {
          curYear.AssetPurchases.Value = 0;
        }
      }

      curYear.DepreciationOfOpeningBalance.Formula = `MxMath.Sum([${months.map((c) => c.DepreciationOfOpeningBalance.ID_f).join(",")}])`;
      curYear.CumulativeDepreciationOfOpeningBalance.Formula = `${lastMonth.CumulativeDepreciationOfOpeningBalance}`;
      curYear.AssetPayableBalance.Formula = `${lastMonth.AssetPayableBalance}`;
      curYear.CumulativeAssetPurchases.Formula = `${lastMonth.CumulativeAssetPurchases}`;
      curYear.PaymentAtTimeOfPurchase.Formula = `MxMath.Sum([${months.map((c) => c.PaymentAtTimeOfPurchase.ID_f).join(",")}])`;
      curYear.BalanceToBePayed.Formula = `MxMath.Sum([${months.map((c) => c.BalanceToBePayed.ID_f).join(",")}])`;
      curYear.PaymentOfBalance.Formula = `MxMath.Sum([${months.map((c) => c.PaymentOfBalance.ID_f).join(",")}])`;
      curYear.TotalAssetPayments.Formula = `MxMath.Sum([${months.map((c) => c.TotalAssetPayments.ID_f).join(",")}])`;
      curYear.AssetSales.Formula = `MxMath.Sum([${months.map((c) => c.AssetSales.ID_f).join(",")}])`;
      curYear.PurchaseValueOfSoldAssets.Formula = `MxMath.Sum([${months.map((c) => c.PurchaseValueOfSoldAssets.ID_f).join(",")}])`;
      curYear.CumulativePurchaseValueOfSoldAssets.Formula = `${lastMonth.CumulativePurchaseValueOfSoldAssets}`;

      // month
      curYear.GrossBookValue.Formula = `${lastMonth.GrossBookValue}`;
      curYear.Depreciation.Formula = `MxMath.Sum([${months.map((c) => c.Depreciation.ID_f).join(",")}])`;
      curYear.AccumulatedDepreciationOfSoldAssets.Formula = `MxMath.Sum([${months.map((c) => c.AccumulatedDepreciationOfSoldAssets.ID_f).join(",")}])`;
      curYear.AccumulatedDepreciation.Formula = `${lastMonth.AccumulatedDepreciation}`;
      curYear.NetBookValue.Formula = `${curYear.GrossBookValue} - ${curYear.AccumulatedDepreciation}`;
      curYear.GainLossFromAssetSales.Formula = `MxMath.Sum([${months.map((c) => c.GainLossFromAssetSales.ID_f).join(",")}])`;
      curYear.Totals.Formula = `${curYear.NetBookValue}`;
    });
  };

  setAsset = () => {
    this.buildPeriodsData();
    this.createMonthsFormulas();
    this.createYearsFormulas();
  };

  static getEmptyAsset = (callBack) => {
    const asset = Assets.createNewEmpty();
    asset.SaveNew((record) => {
      if (record) {
        return callBack(Assets.createAsset(record));
      } else {
        return callBack();
      }
    });
  };

  static createNewEmpty = () => {
    const asset = new Assets();
    asset.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
    asset.Name = "";
    return asset;
  };

  static createAsset = (asset) => {
    const newAsset = new Assets();

    newAsset.ID = asset.ID;
    newAsset.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
    newAsset.Totals = CalculatedDriver.createDriverFromTable(
      newAsset,
      Assets.DriversDesc.Total.driverID,
      UnitTypes.Price,
      DriverCategories.Sum,
    );
    newAsset.AssetLifeSpanMonths = new CalculatedDriver_Values(
      null,
      newAsset.getAssetLifeSpanMonthsID(),
    );
    newAsset.AssetLifeSpanMonths.Value = 3;
    newAsset.PurchaseTime = new CalculatedDriver_Values(
      null,
      newAsset.getPurchaseTimeID(),
      null,
      UnitTypes.Units,
    );
    newAsset.PurchaseTime.Value = global.Modeliks.DateHelper.all[0].Order;
    newAsset.PaymentPercentAtPurchase = new CalculatedDriver_Values(
      null,
      newAsset.getPaymentPercentAtPurchaseID(),
      null,
      UnitTypes.Percentage,
    );
    newAsset.BalancePaidOver = new CalculatedDriver_Values(null, newAsset.getBalancePaidOverID());
    newAsset.BalancePaidOver.Value = 0;
    newAsset.TimeOfSale = new CalculatedDriver_Values(
      null,
      newAsset.getTimeOfSaleID(),
      null,
      UnitTypes.Units,
    );
    newAsset.TimeOfSale.Value = global.Modeliks.DateHelper.all[0].Order;
    newAsset.SalePriceOfPurchasePrice = new CalculatedDriver_Values(
      null,
      newAsset.getSalePriceOfPurchasePriceID(),
      null,
      UnitTypes.Percentage,
    );
    newAsset.GrossBookValueOpeningBalance = new CalculatedDriver_Values(
      null,
      newAsset.getGrossBookValueOpeningBalanceID(),
      null,
      UnitTypes.Price,
    );
    newAsset.GrossBookValueOpeningBalance.Value = 0;
    newAsset.AccumulatedDepreciationOpeningBalance = new CalculatedDriver_Values(
      null,
      newAsset.getAccumulatedDepreciationOpeningBalanceID(),
      null,
      UnitTypes.Price,
    );
    newAsset.AccumulatedDepreciationOpeningBalance.Value = 0;
    newAsset.NetBookValueOpeningBalance = new CalculatedDriver_Values(
      null,
      newAsset.getNetBookValueOpeningBalanceID(),
      null,
      UnitTypes.Price,
    );
    newAsset.NetBookValueOpeningBalance.Formula = `${newAsset.GrossBookValueOpeningBalance} - ${newAsset.AccumulatedDepreciationOpeningBalance}`;
    newAsset.createDrivers();
    newAsset.createExtraPeriods();
    return newAsset;
  };

  SaveNew = (callBack) => {
    let limit =
      global.Modeliks.AssetsStore.length >=
      PERMISSIONS.Financials.restrictions.MaxStreamsCount.TotalAssets;
    if (limit) {
      global.Modeliks.showDialog(
        `
            You have reached the maximum number of ${PERMISSIONS.Financials.restrictions.MaxStreamsCount.TotalAssets} streams in Assets. 
            Please reorganize your streams in Assets. \n Click outside of the box to continue with your work.`,
        DialogTypes.WarningSimpleText,
        () => {
          callBack(null);
        },
      );
    } else {
      global.Modeliks.post(this.constructor.TableName, this, (res) => {
        this.ID = res.id;
        callBack(this);
      });
    }
  };

  static getAssetsTotals = () => {
    const driverID = 0;
    let driver = global.Modeliks.DriversStore.getItem(
      Assets.TableName + "-" + driverID + "-totals",
    );

    if (driver == null) {
      const drivers = [Assets.getCurrentAssetsTotals(), Assets.getLongTermAssetsTotals()];
      driver = CalculatedDriver.createDriver(
        Assets.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.LastPeriod,
        "Total Assets",
        true,
        true,
        false,
      );
      driver.setFormula_Sum(drivers);
      const totalYears = global.Modeliks.DateHelper.years_all;
      totalYears.forEach((year) => {
        if (!year.Active) {
          driver.getItemByDateSufix(year.sufix).Formula =
            `MxMath.Sum([${drivers.map((d) => d.getItemByDateSufix(year.sufix).ID_f).join(",")}])`;
        }
      });
      driver.LastPeriodOnly = true;
    }

    return driver;
  };

  static getCurrentAssetsTotals = () => {
    const driverID = "current_assets_driver";
    let driver = global.Modeliks.DriversStore.getItem(
      Assets.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Assets.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.LastPeriod,
        "Current Assets",
        true,
        true,
        false,
      );
      driver.LastPeriodOnly = true;
    }

    const cashAtEndOfPeriod = global.Modeliks.ReportsStore.find(
      (d) => d.ReportType === ReportsTypes.CashFlow,
    ).CashAtEndOfThePeriod;
    const CurrentAssets = Assets.getAssetsCurrent();
    const AccountInventoryDriver = global.Modeliks.DriversStore.find(
      (d) => d.Ref_Field === Inventory.DriversDesc.ClosingBalance.driverID,
    );
    const AccountReceivableDriver = global.Modeliks.DriversStore.find(
      (d) => d.Ref_Field === AccountReceivable.DriversDesc.ClosingBalance.driverID,
    );

    const totalDrivers = [
      cashAtEndOfPeriod,
      AccountReceivableDriver,
      AccountInventoryDriver,
      CurrentAssets,
    ].filter((d) => d !== undefined || d !== null);

    if (totalDrivers && totalDrivers.length > 0) {
      totalDrivers.forEach((c) => (c.LastPeriodOnly = true));
      driver.setFormula_Sum(totalDrivers);
      const totalYears = global.Modeliks.DateHelper.years_all;
      totalYears.forEach((year) => {
        driver.getItemByDateSufix(year.sufix).Formula =
          `${totalDrivers.map((d) => d.getItemByDateSufix(year.sufix).ID_f).join("+")}`;
      });
    }

    return driver;
  };

  static getAssetsCurrent = () => {
    const driverID = "other_assets_driver";
    let driver = global.Modeliks.DriversStore.getItem(
      Assets.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Assets.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.LastPeriod,
        "Other Current Assets",
        false,
        true,
        false,
      );
      driver.Save();
    }
    driver.IsExisting = true;
    driver.IsFormulaEditable = false;

    if (global.Modeliks.AssetsStore.length > 0) {
      const lastCheck = global.Modeliks.AssetsStore.filter(
        (d) => d.AssetType === AssetType.CurrentAsset,
      );
      if (lastCheck && lastCheck.length > 0) {
        const totalIDs = lastCheck.map((d) => d.Totals);
        driver.setFormula_Sum(totalIDs);
        const totalYears = global.Modeliks.DateHelper.years_all;
        totalYears.forEach((year) => {
          driver.getItemByDateSufix(year.sufix).Formula =
            `${totalIDs.map((d) => d.getItemByDateSufix(year.sufix).ID_f).join("+")}`;
        });
      }
    }
    return driver;
  };

  static getAssetPayableLongTerm = () => {
    const driverID = "asset-payable-balance-long-term";
    let driver = global.Modeliks.DriversStore.getItem(
      Assets.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Assets.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.LastPeriod,
        "Long term asset purchases payable",
        false,
        false,
        false,
      );
      driver.Save();
    }
    driver.LastPeriodOnly = true;
    driver.isExpense = true;
    driver.IsFormulaEditable = false;

    if (global.Modeliks.AssetsStore.length > 0) {
      const lastCheck = global.Modeliks.AssetsStore.filter(
        (d) => d.AssetType === AssetType.LongTerm,
      );
      if (lastCheck && lastCheck.length > 0) {
        const totalIDs = lastCheck.map((d) => d.AssetPayableBalance);
        driver.setFormula_Sum(totalIDs);
        const totalYears = global.Modeliks.DateHelper.years_all;
        totalYears.forEach((year) => {
          driver.getItemByDateSufix(year.sufix).Formula =
            `${totalIDs.map((d) => d.getItemByDateSufix(year.sufix).ID_f).join("+")}`;
        });
      }
    }
    return driver;
  };

  static getAssetPayableCurrent = () => {
    const driverID = "asset-payable-b-c-t"; //asset-payable-balance-current-term
    let driver = global.Modeliks.DriversStore.getItem(
      Assets.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Assets.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.LastPeriod,
        "Current asset purchases payable",
        false,
        false,
        false,
      );
      driver.Save();
    }
    driver.LastPeriodOnly = true;
    driver.isExpense = true;
    driver.IsFormulaEditable = false;

    if (global.Modeliks.AssetsStore.length > 0) {
      const lastCheck = global.Modeliks.AssetsStore.filter(
        (d) => d.AssetType === AssetType.CurrentAsset,
      );
      if (lastCheck && lastCheck.length > 0) {
        const totalIDs = lastCheck.map((d) => d.AssetPayableBalance);
        driver.setFormula_Sum(totalIDs);
        const totalYears = global.Modeliks.DateHelper.years_all;
        totalYears.forEach((year) => {
          driver.getItemByDateSufix(year.sufix).Formula =
            `${totalIDs.map((d) => d.getItemByDateSufix(year.sufix).ID_f).join("+")}`;
        });
      }
    }

    return driver;
  };

  static getAssetPurchasesFromOpeningBalanceDrivers = () => {
    const driverID = "asset_purchases_ob_driver";
    let driver = global.Modeliks.DriversStore.getItem(
      Assets.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Assets.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Asset Purchases From Opening Balance Drivers",
        true,
        false,
        false,
      );
    }
    if (global.Modeliks.AssetsStore.length > 0) {
      const lastCheck = global.Modeliks.AssetsStore.filter(
        (d) => d.AssetType === AssetType.CurrentAsset && d.OpeningBalance === true,
      );
      if (lastCheck && lastCheck.length > 0) {
        const totalIDs = lastCheck.map((d) => d.AssetPurchases);
        driver.setFormula_Sum(totalIDs);
        const totalYears = global.Modeliks.DateHelper.years_all;
        totalYears.forEach((year) => {
          driver.getItemByDateSufix(year.sufix).Formula =
            `${totalIDs.map((d) => d.getItemByDateSufix(year.sufix).ID_f).join("+")}`;
        });
      }
    }
    return driver;
  };

  static getAssetPurchasesFromDrivers = () => {
    const driverID = "asset_purchases_ca_driver";
    let driver = global.Modeliks.DriversStore.getItem(
      Assets.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Assets.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Asset Purchases Current Assets",
        true,
        false,
        false,
      );
    }

    if (global.Modeliks.AssetsStore.length > 0) {
      const lastCheck = global.Modeliks.AssetsStore.filter(
        (d) => d.AssetType === AssetType.CurrentAsset,
      );
      if (lastCheck && lastCheck.length > 0) {
        const totalIDs = lastCheck.map((d) => d.AssetPurchases);
        driver.setFormula_Sum(totalIDs);
        const totalYears = global.Modeliks.DateHelper.years_all;
        totalYears.forEach((year) => {
          driver.getItemByDateSufix(year.sufix).Formula =
            `${totalIDs.map((d) => d.getItemByDateSufix(year.sufix).ID_f).join("+")}`;
        });
      }
    }
    return driver;
  };

  static getAssetSalesFromDrivers = () => {
    const driverID = "asset_sales_ca_driver";
    let driver = global.Modeliks.DriversStore.getItem(
      Assets.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Assets.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Asset Sales Current Assets",
        true,
        false,
        false,
      );
    }

    if (global.Modeliks.AssetsStore.length > 0) {
      const lastCheck = global.Modeliks.AssetsStore.filter(
        (d) => d.AssetType === AssetType.CurrentAsset,
      );
      if (lastCheck && lastCheck.length > 0) {
        const totalIDs = lastCheck.map((d) => d.AssetSales);
        driver.setFormula_Sum(totalIDs);
        const totalYears = global.Modeliks.DateHelper.years_all;
        totalYears.forEach((year) => {
          driver.getItemByDateSufix(year.sufix).Formula =
            `${totalIDs.map((d) => d.getItemByDateSufix(year.sufix).ID_f).join("+")}`;
        });
      }
    }

    return driver;
  };

  static getTotalIntangiblesDrivers = () => {
    const driverID = "total_intangibles_driver";
    let driver = global.Modeliks.DriversStore.getItem(
      Assets.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Assets.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Total Intangibles Drivers",
        true,
        false,
        false,
      );
    }

    if (global.Modeliks.AssetsStore.length > 0) {
      const lastCheck = global.Modeliks.AssetsStore.filter(
        (d) =>
          d.AssetCategory === AssetCategories.Intangibles && d.AssetType === AssetType.LongTerm,
      );
      if (lastCheck && lastCheck.length > 0) {
        const totalIDs = lastCheck.map((d) => d.Totals);
        driver.setFormula_Sum(totalIDs);
      }
    }

    return driver;
  };

  static getTotalInvestmentsDrivers = () => {
    const driverID = "total_investments_driver";
    let driver = global.Modeliks.DriversStore.getItem(
      Assets.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Assets.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Total Investments Drivers",
        true,
        false,
        false,
      );
    }
    if (global.Modeliks.AssetsStore.length > 0) {
      const lastCheck = global.Modeliks.AssetsStore.filter(
        (d) =>
          d.AssetCategory === AssetCategories.Investments && d.AssetType === AssetType.LongTerm,
      );
      if (lastCheck && lastCheck.length > 0) {
        const totalIDs = lastCheck.map((d) => d.Totals);
        driver.setFormula_Sum(totalIDs);
      }
    }
    return driver;
  };

  static getLongTermAssetPurchasesFromDrivers = () => {
    const driverID = "asset_purchases_lta_driver";
    let driver = global.Modeliks.DriversStore.getItem(
      Assets.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Assets.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Asset Purchases Long Term Assets",
        true,
        false,
        false,
      );
    }

    if (global.Modeliks.AssetsStore.length > 0) {
      const lastCheck = global.Modeliks.AssetsStore.filter(
        (d) => d.AssetType === AssetType.LongTerm,
      );
      if (lastCheck && lastCheck.length > 0) {
        const totalIDs = lastCheck.map((d) => d.AssetPurchases);
        driver.setFormula_Sum(totalIDs);
        const totalYears = global.Modeliks.DateHelper.years_all;
        totalYears.forEach((year) => {
          driver.getItemByDateSufix(year.sufix).Formula =
            `${totalIDs.map((d) => d.getItemByDateSufix(year.sufix).ID_f).join("+")}`;
        });
      }
    }

    return driver;
  };

  static getLongTermAssetPaymentsFromDriversNegative = () => {
    const driverID = "asset_purchases_driver";
    let driver = global.Modeliks.DriversStore.getItem(
      Assets.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Assets.TableName,
        driverID,
        "totals",
        UnitTypes.PriceNegative,
        DriverCategories.Sum,
        "Asset Purchases",
        false,
        false,
        false,
      );
      driver.Formula = "";
      driver.Save();
    }
    driver.IsFormulaEditable = false;

    const totalDrivers = global.Modeliks.AssetsStore.filter(
      (d) => d.AssetType === AssetType.LongTerm,
    );
    const LongThermAssets = Assets.getLongTermAssetsTotals();
    const Depreciation = Assets.getDepreciation();

    if (totalDrivers.length > 0) {
      const years = [
        ...global.Modeliks.DateHelper.years_before_actual,
        ...global.Modeliks.DateHelper.years_all,
      ];
      const months = [
        ...global.Modeliks.DateHelper.months_before_actual,
        ...global.Modeliks.DateHelper.months,
      ];

      const totalIDs = totalDrivers.map((d) => d.TotalAssetPayments);
      // driver.setFormula(`MxMath.Negative(${totalIDs.map(d => d.ID_f).join('+')})`);
      // driver.Formula = "";

      months.forEach((month) => {
        const prevMonthDate = months.find((c) => c.Order == month.Order - 1);
        const curMonth = driver.getItemByDateSufix(month.sufix);

        if (prevMonthDate) {
          const curMonthAsset = LongThermAssets.getItemByDateSufix(month.sufix);
          const prevMonthAssets = LongThermAssets.getItemByDateSufix(prevMonthDate.sufix);
          const curMonthDepreciation = Depreciation.getItemByDateSufix(month.sufix);
          curMonth.Formula =
            `MxMath.Negative(${totalIDs.map((d) => d.getItemByDateSufix(month.sufix).ID_f).join("+")})` +
            ` Actual(${curMonthAsset.ID_f_actual} * -1 - ${prevMonthAssets.ID_f_actual} * -1 + ${curMonthDepreciation.ID_f_actual} * -1)`;
        } else {
          curMonth.Formula = null;
          curMonth.evalFormulaActual = null;
        }
      });

      years.forEach((year) => {
        const curYear = driver.getItemByDateSufix(year.sufix);
        const prevYearDate = years.find((c) => c.Order === year.Order - 1);

        if (!prevYearDate) {
          // curYear.Formula = `${curYear.Formula ? curYear.Formula : ''}` + `Actual(${curYearClosing.ID_f_actual} - ${changeDriver.IncomeTaxPayableClosingBalance.getItemByDateSufix(prevYearDate.sufix).ID_f_actual})`
        } else {
          const curYearAssets = LongThermAssets.getItemByDateSufix(year.sufix);
          const prevYearAssets = LongThermAssets.getItemByDateSufix(prevYearDate.sufix);
          const curYearDepreciation = Depreciation.getItemByDateSufix(year.sufix);
          curYear.Formula =
            `MxMath.Negative(${totalIDs.map((d) => d.getItemByDateSufix(year.sufix).ID_f).join("+")})` +
            `Actual(${curYearAssets.ID_f_actual} * -1 - ${prevYearAssets.ID_f_actual} * -1 + ${curYearDepreciation.ID_f_actual} * -1) `;
        }
      });
    }

    return driver;
  };

  static getCategoriesDrivers = () => {
    let drivers = [];
    Object.values(AssetCategories).forEach((val) => {
      let categoryDriver = global.Modeliks.DriversStore.getItem(
        Assets.TableName + "-" + val + "-totals",
      );
      if (categoryDriver == null) {
        categoryDriver = CalculatedDriver.createDriver(
          Assets.TableName,
          val,
          "totals",
          UnitTypes.Price,
          DriverCategories.Sum,
          val,
          true,
        );
        drivers.push(categoryDriver);
      }
      drivers.push(categoryDriver);
    });
    return drivers;
  };

  static getLongTermAssetSalesFromDrivers = () => {
    const driverID = "asset_sales_driver";
    let driver = global.Modeliks.DriversStore.getItem(
      Assets.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Assets.TableName,
        driverID,
        "totals",
        UnitTypes.PriceNegative,
        DriverCategories.Sum,
        "Asset Sales",
        false,
        false,
        false,
      );
      driver.Save();
    }

    driver.IsFormulaEditable = false;

    const totalDrivers = global.Modeliks.AssetsStore.filter(
      (d) => d.AssetType === AssetType.LongTerm,
    );

    if (totalDrivers.length > 0) {
      const totalIDs = totalDrivers.map((d) => d.AssetSales);

      driver.setFormula_Sum(totalIDs);
      driver.Formula = null;

      const totalYears = global.Modeliks.DateHelper.years_all;
      totalYears.forEach((year) => {
        driver.getItemByDateSufix(year.sufix).Formula =
          `${totalIDs.map((d) => d.getItemByDateSufix(year.sufix).ID_f).join("+")}`;
      });
    }

    return driver;
  };

  static getLongTermAssetsTotals = () => {
    const driverID = "long_term_assets_driver";
    let driver = global.Modeliks.DriversStore.getItem(
      Assets.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Assets.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.LastPeriod,
        "Long Term Assets",
        true,
        true,
        false,
      );
    }

    const totalDrivers = global.Modeliks.AssetsStore.filter(
      (d) => d.AssetType === AssetType.LongTerm,
    );
    totalDrivers.forEach((c) => (c.LastPeriodOnly = true));

    let LongThermAssets = [];

    const totalYears = global.Modeliks.DateHelper.years_all;
    Object.entries(AssetCategories).forEach(([key, value]) => {
      const CategoriesDrivers = totalDrivers.filter((d) => d.AssetCategory === value);

      let currentDriverID = value;

      let CategoryDriver = global.Modeliks.DriversStore.getItem(
        Assets.TableName + "-" + currentDriverID + "-totals",
      );
      if (CategoryDriver == null) {
        CategoryDriver = CalculatedDriver.createDriver(
          Assets.TableName,
          currentDriverID,
          "totals",
          UnitTypes.Price,
          DriverCategories.LastPeriod,
          key.replace(/([A-Z])/g, " $1").trim(),
          true,
          false,
          false,
        );
        CategoryDriver.Save();
      }
      CategoryDriver.LastPeriodOnly = true;
      CategoryDriver.IsFormulaEditable = false;

      if (CategoriesDrivers.length > 0) {
        const categoryDriversTotals = CategoriesDrivers.map((d) => d.Totals);
        CategoryDriver.setFormula_Sum(categoryDriversTotals);
        totalYears.forEach((year) => {
          CategoryDriver.getItemByDateSufix(year.sufix).Formula =
            `MxMath.Sum([${categoryDriversTotals.map((d) => d.getItemByDateSufix(year.sufix).ID_f).join(",")}])`;
        });
        LongThermAssets.push(CategoryDriver);
      } else {
        if (CategoryDriver.Formula) {
          CategoryDriver.removeFormula();
          CategoryDriver.Save();
        }
      }
    });

    driver.setFormula_Sum(LongThermAssets);
    driver.LastPeriodOnly = true;
    totalYears.forEach((year) => {
      driver.getItemByDateSufix(year.sufix).Formula =
        `MxMath.Sum([${LongThermAssets.map((d) => d.getItemByDateSufix(year.sufix).ID_f).join(",")}])`;
    });

    return driver;
  };

  static getDepreciation = () => {
    const driverID = "depreciation_driver";
    let driver = global.Modeliks.DriversStore.getItem(`${Assets.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Assets.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Depreciation",
        false,
        true,
        false,
      );
      driver.Save();
    }
    driver.UseForCharts = true;
    driver.isExpense = true;
    driver.IsExisting = true;
    driver.IsFormulaEditable = false;

    const drivers = global.Modeliks.DriversStore.filter(
      (asset) => asset.Ref_Field === Assets.DriversDesc.Depreciation.driverID,
    );
    if (drivers && drivers.length > 0) {
      driver.setFormula_Sum(drivers);
    } else {
      if (driver.Formula) {
        driver.removeFormula();
        driver.Save();
      }
    }
    return driver;
  };

  static getAssetGainAndLoss = () => {
    const driverID = "sale_of_property_and_equipment_driver";
    let driver = global.Modeliks.DriversStore.getItem(`${Assets.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Assets.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Sale of Property, Plant & Equipment",
        false,
        false,
        false,
      );
      driver.Save();
    }
    driver.IsFormulaEditable = false;
    const totalDrivers = global.Modeliks.DriversStore.filter(
      (d) => d.Ref_Field === Assets.DriversDesc.GainLossFromAssetSales.driverID,
    );

    if (totalDrivers && totalDrivers.length > 0) {
      const totalYears = global.Modeliks.DateHelper.years_all;
      driver.setFormula_Sum(totalDrivers);
      totalYears.forEach((year) => {
        if (!year.Active) {
          driver.getItemByDateSufix(year.sufix).Formula =
            `MxMath.Sum([${totalDrivers.map((d) => d.getItemByDateSufix(year.sufix)).join(",")}])`;
        }
      });
      driver.Formula = null;
    }

    return driver;
  };

  static getDeferredIncomeTax = () => {
    const driverID = "deferred_income_tax_asset";
    let driver = global.Modeliks.AssetsStore.getItem(`${Assets.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Assets.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.LastPeriod,
        "Deferred Income Tax Asset",
        true,
        false,
        false,
      );
      driver.IsFormulaEditable = false;
      driver.LastPeriodOnly = true;
    }
    const drivers = global.Modeliks.DriversStore.filter(
      (asset) =>
        asset.Ref_Field === IncomeTax.DriversDesc.CumulativeCalculatedAccruedIncomeTax.driverID,
    );
    if (drivers && drivers.length > 0) {
      driver.setFormula_Sum(drivers);
      driver.Formula = null;
    }

    return driver;
  };
}

export default Assets;

import React from "react";
import { PermissionTypes } from "../../../../../components/constants/permissions";
import "./pdf_export.scss";
import PropTypes from "prop-types";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { ExportIcon } from "../../../../../components/icons/svgIcons";
import ButtonMui from "../../../../../components/buttons/buttonMui/buttonMui";
import ExportReportPortal from "../../../../../SlideEditor/components/components/Portals/ExportReportPortal";
import LocalStorage from "../../../../../helpers/LocalStorage";
import {
  tableConfig,
  tableConfigTypes,
  tableObject,
} from "../../../../../components/tables/FinanceTable/components/constants";
import ExportPDFComponent from "./../../../components/ExportReportPDF";

class ExportReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.EditPermission = global.Modeliks.UserPermissions[PermissionTypes.Financials] == 0;
    this.documentTitle = document.title;
  }

  componentWillUnmount() {
    window.removeEventListener("afterprint", this.afterPrint);
  }

  afterPrint = () => {
    document.title = this.documentTitle;
    this.setState({ printingEnabled: false });
    window.removeEventListener("afterprint", this.afterPrint);
    this.setGlobalPrintStyle(false);
  };

  getElementToPrint = () => {
    this.setState({ printingEnabled: true }, () => {
      window.addEventListener("afterprint", this.afterPrint);
      this.setGlobalPrintStyle("print");
      this.setTitle();
    });
  };

  setTitle = () => {
    if (this.props.pdfReportType) {
      document.title = this.getPageTitle();
    }
  };

  getPageTitle = (customTitle) => {
    return `${new Date().toLocaleDateString()} ${global.Modeliks.CompanyInfo.CompanyName}_${customTitle ? customTitle : this.props.pdfReportType}${this.props.pdfSuffix ? ` ${this.props.pdfSuffix}` : ""}`;
  };

  setGlobalPrintStyle = (value) => {
    document.getElementById("style3").media = value;
  };

  getReportTitle = () => {
    if (this.props.reportName) {
      return (
        <div className="report_sub_title">
          <p>{global.Modeliks.CompanyScenarioInfo.ScenarioName}</p>
          <p>Report from: {new Date().toLocaleDateString()}</p>
        </div>
      );
    }
    return "";
  };

  getTableName = () => {
    return (
      <>
        <p>{this.props.reportName}</p>
        <p>{this.getNumberFormat()}</p>
      </>
    );
  };

  getNumberFormat = () => {
    let type = LocalStorage.get(tableObject, tableConfig, tableConfigTypes.unitDisplayReportFormat);
    return `(in ${type === "fullNumbers" ? global.Modeliks.CompanyInfo.Currency.value : type})`;
  };

  splitArrayToIntervals = (array = [], interval = 12) => {
    let newArray = [];
    if (array.length > interval) {
      let amountOfNewArrays = Math.ceil(array.length / interval);
      for (let i = 0; i < amountOfNewArrays; i++) {
        let startingPoint = i * interval;
        newArray.push(array.slice(startingPoint, startingPoint + interval));
      }
    } else {
      newArray.push(array);
    }
    return newArray;
  };

  totalNum = [];
  splitRows = [];
  lengthOfSplit = 30;

  getTotalNum = (row, index = 0, parentRow = false, rows) => {
    if (row.data) {
      if (!this.totalNum.includes(row.data.ID)) {
        this.totalNum.push(row.data.ID);
      }
    }
    if (parentRow && this.totalNum.length >= this.lengthOfSplit) {
      this.lengthOfSplit = this.lengthOfSplit * 2;
      this.splitRows.push(rows.indexOf(row));
    }

    if (row.children && row.children.length > 0) {
      row.children.forEach((c) => this.getTotalNum(c));
    }
  };

  getTableComponent = () => {
    const { periods, rows } = this.props;
    rows.forEach((row, index) => this.getTotalNum(row, index, true, rows));

    let newRows = [];
    let useSplit = false;

    if (this.splitRows.length > 0) {
      useSplit = true;
      let SplitArrays = [];
      this.splitRows.forEach((row, index) => {
        if (index === 0) {
          SplitArrays.push(rows.filter((r, i) => i >= index && i <= row));
          if (index + 1 === this.splitRows.length && row < this.props.rows.length) {
            SplitArrays.push(rows.filter((r, i) => i > row));
          }
        } else {
          if (index + 1 === this.splitRows.length && row < this.props.rows.length) {
            SplitArrays.push(rows.filter((r, i) => i > this.splitRows[index - 1] && i <= row));
            SplitArrays.push(rows.filter((r, i) => i > row));
          } else {
            SplitArrays.push(rows.filter((r, i) => i > this.splitRows[index - 1] && i <= row));
          }
        }
      });
      newRows = SplitArrays;
    } else {
      newRows = rows;
    }

    // const reportTitle = <div className={'report_header'}><div className='report_title'><div>{global.Modeliks.CompanyInfo.CompanyName}</div>{this.getReportTitle()}</div></div>
    // const reportBody = this.splitArrayToIntervals(periods, 12).map(periodInterval => <div className={'table_container'}><FinanceTable tableName={this.getTableName()} value={{tableSize: ReportsTableSizes.Detailed.size}} field={this.props.field} exportPeriods={periodInterval} exportRows={rows}/></div>)
    return (
      <>
        <ExportPDFComponent
          {...this.props}
          useSplit={useSplit}
          rows={newRows}
          splitArr={() => this.splitArrayToIntervals(periods, 12)}
          ReportName={this.props.reportName}
        />
        {/*{reportTitle}*/}
        {/*{reportBody}*/}
      </>
    );
  };

  render() {
    return (
      <div className="psfce_export_div">
        {/*{<div style={{position: 'absolute', zIndex: 1000, width: '100%', height: '100%'}}>{this.getTableComponent()}</div>}*/}
        {this.state.printingEnabled && (
          <ExportReportPortal>{this.getTableComponent()}</ExportReportPortal>
        )}
        <Menu
          arrow
          menuClassName="psdf_menu"
          menuButton={
            <ButtonMui
              disabled={this.props.disabled}
              label={"Export"}
              color={"black"}
              variant={"text"}
              style={{ fontWeight: 600 }}
              width={100}
              endIcon={<ExportIcon />}
            />
          }
        >
          {this.props.showExcelExport && (
            <MenuItem
              key={"dash_excel"}
              onClick={() => this.props.exportReport(this.getPageTitle())}
            >
              Excel
            </MenuItem>
          )}
          {!this.props.hidePdfExport && <MenuItem onClick={this.getElementToPrint}>PDF</MenuItem>}
          {this.props.showExcelExportAllReports && this.props.IsExportAllReports && (
            <MenuItem
              key={"dash_excel_all_reports"}
              onClick={() => this.props.exportAllReports(this.getPageTitle("All_Reports"))}
            >
              All Reports To Excel
            </MenuItem>
          )}
        </Menu>
      </div>
    );
  }
}

ExportReport.propTypes = {
  showExcelExport: PropTypes.bool,
  pdfReportType: PropTypes.string,
  pdfSuffix: PropTypes.string,
  disabled: PropTypes.bool,
};

ExportReport.defaultProps = {
  showExcelExport: true,
};
export default ExportReport;

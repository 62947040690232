import { AiSectionDto } from "./AiSectionDto";

function getGlobalIdentifiers() {
  return {
    account: global.Modeliks.Account.ID,
    company: global.Modeliks.CompanyInfo.ID,
    user: global.Modeliks.User.ID,
    bpScenarioId: global.Modeliks.BusinessPlanScenarioInfo.ID,
  };
}

export function dtoToSectionObject() {}

export function sectionObjectToAiSectionDto(sectionData) {
  const { account, company, user, bpScenarioId } = getGlobalIdentifiers();

  const { sectionName, shouldGenerate } = sectionData;

  const data = {
    completed: true,
    sectionName,
    shouldGenerate,
    account,
    company,
    user,
    bpScenarioId,
  };

  return AiSectionDto(data);
}

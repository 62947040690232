import React from "react";
import "../../SlideEditor/App.scss";
import SlideComponent from "../../SlideEditor/components/SlideComponent";
import { SlideTypes } from "../../SlideEditor/components/SlideObjects/SlideStructures";
import Footer from "../../SlideEditor/components/components/Footer/ScalePanelFooter";
import request from "superagent";

import "../../SlideEditor/components/MenuObject.scss";

import RightMenu from "../../SlideEditor/components/components/RightMenuComponents/RightMenu";
import LeftMenu from "../../SlideEditor/components/components/menus/LeftMenu/LeftMenu";
import { Logo } from "../../components/icons/svgIcons";
import CircleLoader from "../../components/Loaders/CircleLoader/CircleLoader";
import { PermissionTypes } from "../../components/constants/permissions";
import SlideHeaderContainer from "../../SlideEditor/components/components/SlideHeaderContainer";
import LogoComponent from "../../SlideEditor/components/LogoComponent";
import FreeTrialDialog from "../../components/dialogs/FreeTrialDialog/FreeTrialDialog";
import { create, fetch } from "../../services/api/Repositories/pdf";
import { download } from "../../helpers/utils/file";
import { AddSection } from "../../SlideEditor/services/commands/AddSection";
import { CommandHistoryManager } from "../../SlideEditor/services/commands/CommandManager";
import {
  copyPageFileForBusinessScenario,
  createCustomCategorySection,
  createCustomCategorySectionForBusinessScenario,
  createNewSection,
  createNonCustomSection,
  deleteCustomSectionWithId,
  deleteNonCustomSectionWithId,
  deleteSectionWithId,
} from "../../SlideEditor/services/businessPlanFlows";
import { RemoveSection } from "../../SlideEditor/services/commands/RemoveSection";
import { AddCustomSection } from "../../SlideEditor/services/commands/AddCustomSection";
import { CommandManagerContext } from "../../SlideEditor/services/commands/CommandManagerProvider";
import { BrowserInfoService } from "../../helpers/utils/browser";
import { Toast } from "../../components/dialogs/Notifications/Toast";

const scale = {
  enabled: false,
  rightMenu: true,
  textObjs: [],
};

const DEFAULT_ZOOM_SIZE = -40;

class index extends React.Component {
  CompanyInfo = [];
  Account = null;
  saving = false;
  LogoObj = null;
  SummaryDateSettings = null;
  SummaryDateSettingsValue = {};

  constructor(props) {
    super(props);

    this.jsonFunc = {};
    this.addNewObjectFuncs = {};
    this.Categories = [];
    this.SlideSections = [];
    this.SlideSectionIndexes = null;
    this.DefaultTemplates = [];
    this.Templates = [];
    this.shouldPasteInPlace = false;
    this.timesPasted = 0;
    this.chartImages = [];

    this.currentVersion = new Date().getTime();
    global.Modeliks.currentLogoVersion = this.currentVersion;

    if (!this.props.publishMode) {
      this.EditPermission = global.Modeliks.UserPermissions[PermissionTypes.BusinessPlan] > 0;
    }

    if (this.props.publishMode) {
      this.isLinkValid();
    } else {
      this.LoadingFunc();
    }

    this.browserInfoService = BrowserInfoService.getInstance();
    const isBrowserSupported = this.browserInfoService.isBrowserSupported();

    this.state = {
      slideConfig: "",
      selectedCategory: "",
      sections: [],
      selectedSection: {},
      templates: [],
      open: false,
      zoom: DEFAULT_ZOOM_SIZE,
      openFreeTrialDialog: false,
      preview: false,
      Counter: !this.props.publishMode ? global.Modeliks.BusinessPlanConfig.Counter : "",
      selectedTemplateID: null,
      selectedTemplate: null,
      templateKey: "template_" + new Date().getTime(),
      slideKey: "slideComponent_" + new Date().getTime(),
      jsonData: "",
      printEnabled: false,
      loading: true,
      key: new Date().getTime(),
      shouldShowNotification: !isBrowserSupported,
      slideSections: [],
    };

    if (!this.props.publishMode) {
      this.AccountID = global.Modeliks.Account.ID;
    }
    if (!this.SummaryDateSettingsValue.hasOwnProperty("from")) {
      Object.assign(this.state, this.SummaryDateSettingsValue);
    }
  }

  setSlideSections = (sections) => {
    this.SlideSections = this.sortSections(sections);
    this.SlideSectionIndexes = {};
    this.SlideSections.forEach((c) => {
      Object.assign(this.SlideSectionIndexes, {
        [c.ID]: c,
      });
    });
  };
  LoadingFunc = (callBack, shouldSave = false, oldID) => {
    if (shouldSave) {
      this.saveSlideContent(() => {
        this.getAllCategories(() => {
          this.getSections(() => {
            this.getTemplateContent(
              this.state.selectedSection.isCustom
                ? this.state.selectedSection.ID
                : this.state.selectedSection.MasterPageID,
              callBack && callBack(),
            );
            this.updateOrder(false);
          });
        });
      }, oldID);
    } else {
      this.getAllCategories(() => {
        this.getSections(() => {
          this.getTemplateContent(
            this.state.selectedSection.isCustom
              ? this.state.selectedSection.ID
              : this.state.selectedSection.MasterPageID,
            callBack && callBack(),
          );
          this.updateOrder(false);
        });
      });
    }
  };

  onCompanyChange = () => {
    this.setState({ loading: true });
    this.LoadingFunc(() => {});
  };

  onWheelListener = (e) => {
    if (e.ctrlKey) {
      e.preventDefault();
      let changer = -1;
      if (e.wheelDelta > 0) {
        changer = 1;
      }
      this.onZoomChange(this.state.zoom + changer);
    }
  };

  componentDidMount() {
    global.Modeliks.Subscribe("onCompanyChange", this.onCompanyChange);
    global.wheelListener = this.onWheelListener;

    if (this.props.shouldExportToPdf) {
      this.exportToPdf();
    }
  }

  componentDidCatch(error, errorInfo) {
    console.error("error", error, errorInfo);
  }

  componentDidCatch(error, errorInfo) {
    console.error("error", error, errorInfo);
  }

  getSelectedSectionTemplates = (callBack) => {
    request
      .get("/api/SlideTemplates")
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .query({ section_id: this.state.selectedSection.ID })
      .then((templates) => {
        this.Templates = templates.body;
        let selectedTemplate = templates.body.filter((template) => template.isDefault)[0];
        if (!selectedTemplate) {
          selectedTemplate = templates.body[0];
        }
        this.state.selectedTemplate = selectedTemplate;
        this.state.selectedTemplateID = selectedTemplate.ID;
        callBack();
      })
      .catch((err) => console.error(err));
  };

  addChartImage = (index, key, str) => {
    this.state.jsonData.forEach((c, i) => {
      let obj = JSON.parse(c);

      let slideObjects = obj.slideObjects;
      let chartIndex = slideObjects.findIndex(
        (c) => c.staticKey === key || (c.props && c.props.staticKey === key),
      );
      let chart = slideObjects.find(
        (c) => c.staticKey === key || (c.props && c.props.staticKey === key),
      );

      if (chartIndex != -1) {
        slideObjects[chartIndex] = {
          type: SlideTypes.chartObject,
          imageSrc: str,
          height: chart.props.height,
        };
        this.state.jsonData[i] = JSON.stringify({ slideObjects: slideObjects });
        return;
      }
    });

    // if(index === this.state.jsonData.length - 1){
    //     setTimeout(()=>{
    //         request
    //             .post('/pdf_be/createpdf')
    //             .set('Content-Type', 'application/x-www-form-urlencoded')
    //             .set('authorization', 'Bearer ' + window.localStorage.getItem('token'))
    //             .send({
    //                 sections: this.state.jsonData,
    //                 pageNames: this.state.pageNames
    //             })
    //             .then(res => {
    //                 console.log(res)
    //             })
    //             .catch(e => console.error(e))
    //     }, 10000)
    //
    // }
  };

  sortSections = (sections) => {
    sections.sort(function (a, b) {
      if (a.order > b.order) {
        return 1;
      } else if (a.order < b.order) {
        return -1;
      } else {
        return a.ID > b.ID ? 1 : -1;
      }
    });
    return sections;
  };

  isLinkValid = () => {
    const account_guid = window.location.href.split("/")[4];
    const client_guid = window.location.href.split("/")[5];

    request
      .get(`/api/BusinessPlanPublish?account_guid=${account_guid}&client_guid=${client_guid}`)
      .then((res) => {
        if (res.text !== "0") {
          let obj = JSON.parse(res.text);
          request
            .get(
              `/api/getBusinessPlanPublish?client_id=${obj.client_id}&company_id=${obj.company_id}&pitch_scenario_id=${obj.pitch_scenario_id}&type=${obj.type}&ID=${obj.ID}`,
            )
            .then((res2) => {
              if (res2.text !== "0") {
                request.get("/api/getPublishCharts").then((chartsRes) => {
                  global.Modeliks.PitchFinancialChartsYearly = chartsRes.body;
                  [
                    ...global.Modeliks.PitchFinancialChartsYearly,
                    ...global.Modeliks.PitchFinancialChartsMonthly,
                  ].forEach((c) => {
                    if (c && c.Data) {
                      try {
                        c.Data = JSON.parse(c.Data);
                      } catch (e) {}
                    }
                  });
                  let res = JSON.parse(res2.text);
                  let templatesArr = JSON.parse(res.jsonStringArr);
                  let pageNames = res.pageNames;
                  let config = templatesArr[0];
                  templatesArr.splice(0, 1);
                  let firstPage = templatesArr[0];
                  templatesArr.splice(0, 1);

                  // let newArr = [{slideObjects: []}];
                  //
                  // for (let i = 0; i < templatesArr.length; i++) {
                  //     let tmpArr = JSON.parse(templatesArr[i]);
                  //     tmpArr.slideObjects.forEach(c => {
                  //         newArr[0].slideObjects.push(c)
                  //     })
                  // }
                  //
                  // newArr[0] = JSON.stringify(newArr[0]);

                  this.setState({
                    index: 0,
                    templatesArray: templatesArr,
                    slideConfig: JSON.parse(config),
                    pageNames: pageNames,
                    firstPage: firstPage,
                    open: true,
                    loading: false,
                  });
                });
              }
            });
        }
      });
  };

  getSections = (callBack) => {
    global.Modeliks.get(
      "businessplanpages_client",
      {
        BusinessPlanScenario_ID: global.Modeliks.BusinessPlanScenarioInfo.ID,
      },
      (sections) => {
        let filteredSections = sections.filter((c) => c.isDeleted !== true);
        let customSections = sections.filter((c) => c.CategoryID === 5);
        this.setSlideSections(filteredSections);
        this.Categories[4].Sections = customSections;
        this.state.selectedSection = this.SlideSections[0];
        if (callBack) {
          callBack();
        }
      },
    );
  };

  selectPrevSlide = () => {
    let index = this.SlideSections.indexOf(this.state.selectedSection);

    console.log("enters prev", index);

    if (index !== 0) {
      this.selectSectionByIndex(index - 1);
    } else {
      this.selectSectionByIndex(this.SlideSections.length - 1);
    }
  };

  selectNextSlide = () => {
    let index = this.SlideSections.indexOf(this.state.selectedSection);

    console.log("enters next", index, this.SlideSections.length);

    if (index !== this.SlideSections.length - 1) {
      this.selectSectionByIndex(index + 1);
    } else {
      this.selectSectionByIndex(0);
    }
  };

  selectSectionByIndex = (index) => {
    this.setState({ selectedSection: this.SlideSections[index] }, () => {
      this.getTemplateContent(
        this.state.selectedSection.isCustom
          ? this.state.selectedSection.ID
          : this.state.selectedSection.MasterPageID,
        () => {
          this.forceUpdate();
        },
      );
    });
  };

  getAllCategories = (callBack) => {
    global.Modeliks.get(
      "/api/getAdminPages",
      {},
      (data) => {
        this.Categories = data.categories;
        this.Categories.forEach((c) => {
          c.Sections = c.Sections.filter(
            (c) => c.IndustryID === global.Modeliks.BusinessPlanScenarioInfo.IndustryID,
          );
        });
        global.Modeliks.get(
          "businessplanpages_client",
          {
            isCustom: true,
            CompanyID: global.Modeliks.CompanyInfo.ID,
          },
          (sections) => {
            this.setSlideSections(sections);
            this.state.selectedSection = this.SlideSections[0];
            if (callBack) {
              callBack();
            }
          },
        );
      },
      undefined,
      false,
      false,
      true,
    );
  };

  updateState = () => {
    this.forceUpdate();
  };

  componentWillUnmount() {
    if (
      global.Modeliks &&
      global.Modeliks.CompanyInfo &&
      global.Modeliks.BusinessPlanScenarioInfo &&
      this.state.selectedSection &&
      this.state.jsonData
    ) {
      this.saveSlideContent();
    }
    global.wheelListener = null;
    global.Modeliks.currentLogoVersion = null;
    document.removeEventListener("keydown", this.handleKeyDown);
    global.Modeliks.Unsubscribe("onCompanyChange", this.onCompanyChange);

    this.context.commandHistoryManager.reset();
  }

  appendSection = (section, template, callBack) => {
    // this.SlideSections.push(section);
    // this.Categories[this.Categories.findIndex(c => c.CategoryName == CategoryTypes.Custom)].Sections.push(section);
    const index = this.SlideSections.indexOf(this.state.selectedSection) + 1;
    this.addSectionInObject(index, section, true);
    this.Templates.push(template);
    if (callBack) {
      callBack();
    }
  };

  setSaving = (val) => {
    this.saving = val;
    this.forceUpdate();
  };

  saveContent = (
    id,
    jsonString,
    callBack,
    accID,
    compID,
    pitchScenID = global.Modeliks.BusinessPlanScenarioInfo.ID,
    shouldLoad = true,
    shouldMakeScreenshot = true,
  ) => {
    if (!this.EditPermission && this.state.printEnabled) {
      return;
    }
    this.objectLoad = !shouldLoad;
    this.saving = shouldLoad;
    this.forceUpdate();
    let jsonData = JSON.parse(this.state.jsonData);
    let tmpJsonString = JSON.parse(jsonString);

    jsonData.slideBg = jsonData.slideBg ? jsonData.slideBg.toLowerCase() : "#ffffff";
    tmpJsonString.slideBg = tmpJsonString.slideBg.toLowerCase();

    if (jsonData && jsonData.slideObjects) {
      jsonData.slideObjects.forEach((obj) => {
        obj.key = -1;
        obj.oldKey = -1;
        obj.height = -1;
        obj.props.oldKey = -1;
        obj.props.replacementImage = null;
        obj.replacementImage = null;
      });
    }

    if (tmpJsonString && tmpJsonString.slideObjects) {
      tmpJsonString.slideObjects.forEach((obj) => {
        obj.key = -1;
        obj.oldKey = -1;
        obj.height = -1;
        obj.props.oldKey = -1;
        obj.props.replacementImage = null;
        obj.replacementImage = null;
      });
    }

    if (JSON.stringify(jsonData) != JSON.stringify(tmpJsonString)) {
      let api = "/api/";
      global.Modeliks.put("businessplanpages_client", { ID: id }, { isCustom: true }, (success) => {
        if (success) {
          let section = this.SlideSections.find((el) => el.ID == id);

          let currentTimeStamp = new Date().toISOString();

          section.isCustom = true;
          section.UpdatedAt = currentTimeStamp;
          request
            .post(api + "slideObject")
            .set("authorization", "Bearer " + window.localStorage.getItem("token"))
            .query({
              account_id: accID,
              company_id: compID,
              business_plan_scenario_id: pitchScenID,
              timeStamp: currentTimeStamp,
              businessPlan: true,
            })
            .send({ TemplateID: id, jsonString: jsonString })
            .then((err, res) => {
              global.Modeliks.put(
                "businessplanpages_client",
                { ID: section.ID },
                { UpdatedAt: currentTimeStamp },
                (resUpdate, err) => {
                  if (resUpdate) {
                    this.objectLoad = false;
                    global.Modeliks.SaveMostUsedColors();
                    this.setState({ jsonData: jsonString }, () => {
                      this.saving = false;
                    });
                    if (callBack) {
                      callBack();
                    }
                  } else {
                    console.error("err", err);
                  }
                },
              );
            })
            .catch((error) => console.error("error", error));
        }
      });
    } else {
      this.objectLoad = false;
      this.saving = false;
      if (callBack) {
        callBack();
      }
    }
  };

  changeCategory = (name, callBack) => {
    let category = this.Categories.filter((c) => c.CategoryName === name);
    let sections = category[0].Sections;
    let templates = sections[0].Templates;
    let selectedTemplateID = templates.filter((c) => c.isDefault === true)[0].ID;
    delete scale.textObjs;
    scale.textObjs = [];
    this.state.selectedCategory = name;
    this.state.sections = sections;
    this.state.selectedSection = this.state.sections[0].ID;
    this.state.templates = templates;
    this.state.selectedTemplateID = selectedTemplateID;
    this.state.templateKey = "template_" + new Date().getTime();
    this.forceUpdate();
    this.getTemplateContent(selectedTemplateID);
    if (callBack) {
      callBack();
    }
  };

  getAllTemplatesData = (callBack) => {
    request
      .get(
        `/api/getPublishTemplates?client_id=${global.Modeliks.Account.ID}&company_id=${global.Modeliks.CompanyInfo.ID}`,
      )
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .then((res2) => {
        if (res2.text !== "0") {
          let templatesArr = JSON.parse(res2.text);
          this.DefaultTemplates = templatesArr;
          callBack();
        }
      })
      .catch((err) => console.error(err));
  };

  deleteSection = async (section_id) => {
    if (this.state.sections.length > 1) {
      let section_index = this.state.sections.findIndex((s) => s.ID === section_id);
      let section = this.state.sections[section_index];
      if (section.CategoryID === 5) {
        await deleteSectionWithId(section_id);

        this.state.sections.splice(section_index, 1);
        if (this.state.sections.length === 0) {
          this.changeCategory(this.Categories[0].CategoryName, () => scale.refreshLeft());
        } else {
          scale.refreshLeft();
        }
      }
    }
  };

  executeDeleteSectionFlow = async (section, index, callBack) => {
    if (section.isCustom) {
      try {
        const { slideSections } = await deleteCustomSectionWithId(
          index,
          section.ID,
          section.CategoryID,
          this.SlideSections,
        );

        this.SlideSections = slideSections;

        // not sure about the flow with condition of the selectedSection and section ids
        // leave for now
        if (this.state.selectedSection.ID === section.ID || this.SlideSections.length === 1) {
          this.changeSelectedSection(index);
        } else {
          this.changeSelectedSection(index);
        }
      } catch (error) {
        console.error(error);
      }

      //callBack && callBack();
      return;
    }
    try {
      const { slideSections } = await deleteNonCustomSectionWithId(
        index,
        section.ID,
        this.SlideSections,
      );

      this.SlideSections = slideSections;

      // not sure about the flow with condition of the selectedSection and section ids
      // leave for now
      if (this.state.selectedSection.ID === section.ID || this.SlideSections.length === 1) {
        this.changeSelectedSection(index);
      } else {
        this.changeSelectedSection(index);
      }

      //callBack && callBack();
    } catch (error) {
      console.error(error);
    }
  };

  deleteSectionFlow = async (section, index, callBack) => {
    try {
      // this is primitive implementation of command pattern
      // invoker class should execute commands
      const removeSectionCommand = new RemoveSection({
        actionForward: this.executeDeleteSectionFlow.bind(this),
        actionBackward: this.constructSection.bind(this),
        index,
        section,
        callBack,
      });

      await this.context.commandHistoryManager.executeCommand(removeSectionCommand);
      this.context.addToGlobalSlideHistory();
    } catch (error) {
      console.error(error);
    }
  };

  deleteSections = (ids) => {
    this.Categories[4].Sections = this.Categories[4].Sections.filter((c) => !ids.includes(c.ID));
    this.setSlideSections(this.SlideSections.filter((c) => !ids.includes(c.ID)));
    if (ids.includes(this.state.selectedSection.ID)) {
      this.changeSelectedSection();
    }
    this.forceUpdate();
  };

  saveSlideContent = (
    callBack,
    businessPlanScenarioID = global.Modeliks.BusinessPlanScenarioInfo.ID,
  ) => {
    let jsonString =
      this.jsonFunc && this.jsonFunc.returnJsonString && this.jsonFunc.returnJsonString();
    this.saveContent(
      this.state.selectedSection.ID,
      jsonString,
      callBack,
      this.AccountID,
      global.Modeliks.CompanyInfo.ID,
      businessPlanScenarioID,
    );
  };

  handleChange = (e, val, callBack, shouldSave = true) => {
    this.loading = true;
    if (this.EditPermission) {
      let jsonString = this.jsonFunc.returnJsonString();

      if (navigator.clipboard && navigator.clipboard.readText) {
        navigator.clipboard
          .readText()
          .then((clipText) => {
            this.timesPasted = 0;
            this.shouldPasteInPlace =
              clipText.includes("copyObject") || clipText.includes("copyObjects");
            this.forceUpdate();
          })
          .catch(() => {});
      }

      if (shouldSave) {
        this.saveContent(
          this.state.selectedSection.ID,
          jsonString,
          () => {
            this.state.selectedSection =
              this.SlideSections[this.SlideSections.findIndex((section) => section.ID == val)];

            delete scale.textObjs;
            scale.textObjs = [];
            if (this.state.selectedSection.hasOwnProperty("isCustom")) {
              this.getTemplateContent(
                this.state.selectedSection.isCustom
                  ? this.state.selectedSection.ID
                  : this.state.selectedSection.MasterPageID,
                callBack,
              );
            } else {
              this.getTemplateContent(this.state.selectedSection.MasterPageID, callBack);
            }
          },
          this.AccountID,
          global.Modeliks.CompanyInfo.ID,
          global.Modeliks.BusinessPlanScenarioInfo.ID,
        );
      } else {
        if (this.state.selectedSection.hasOwnProperty("isCustom")) {
          this.getTemplateContent(
            this.state.selectedSection.isCustom
              ? this.state.selectedSection.ID
              : this.state.selectedSection.MasterPageID,
            callBack,
          );
        } else {
          this.getTemplateContent(this.state.selectedSection.MasterPageID, callBack);
        }
      }
    } else {
      this.state.selectedSection =
        this.SlideSections[this.SlideSections.findIndex((section) => section.ID == val)];

      delete scale.textObjs;
      scale.textObjs = [];
      if (this.state.selectedSection.hasOwnProperty("isCustom")) {
        this.getTemplateContent(
          this.state.selectedSection.isCustom
            ? this.state.selectedSection.ID
            : this.state.selectedSection.MasterPageID,
          callBack,
        );
      } else {
        this.getTemplateContent(this.state.selectedSection.MasterPageID, callBack);
      }
    }
  };

  selectTemplate = (template) => {
    if (this.EditPermission) {
      let jsonString = this.jsonFunc.returnJsonString();
      this.saveContent(
        this.state.selectedTemplateID,
        jsonString,
        () => {
          if (template.ID !== this.state.selectedTemplateID) template.isDefault = true;
          this.state.selectedTemplate.isDefault = false;
          global.Modeliks.put(
            "slidetemplates_client",
            { ID: [template.ID, this.state.selectedTemplate.ID] },
            [template, this.state.selectedTemplate],
            (success) => {
              this.state.selectedTemplateID = template.ID;
              this.state.selectedTemplate = template;
              this.getTemplateContent(
                this.state.selectedTemplate.isCustom
                  ? this.state.selectedTemplate.ID
                  : this.state.selectedTemplate.MasterTemplateID,
              );
            },
          );
        },
        this.AccountID,
        global.Modeliks.CompanyInfo.ID,
        global.Modeliks.BusinessPlanScenarioInfo.ID,
      );
    } else {
      this.state.selectedTemplateID = template.ID;
      this.state.selectedTemplate = template;
      this.getTemplateContent(
        this.state.selectedTemplate.isCustom
          ? this.state.selectedTemplate.ID
          : this.state.selectedTemplate.MasterTemplateID,
      );
    }
  };

  updateTemplate = (id, query, callBack) => {
    if (this.state.selectedSection.isCustom) {
      request
        .put("/api/updateTemplate")
        .query({ ID: id })
        .send(query)
        .then((err, res) => {
          if (callBack) {
            callBack();
          }
        });
    } else {
      if (callBack) {
        callBack();
      }
    }
  };

  findTemplate = (id) => {
    return this.Templates.filter((obj) => {
      return obj.ID === id;
    });
  };

  checkForChange(val, callBack) {
    let newSection = this.findNextSection(this.state.selectedSection.ID, val);
    if (newSection != null && newSection != this.state.selectedSection) {
      this.handleChange(null, newSection.ID, () => {
        setTimeout(() => scale.handleResize(), 1);
      });
    } else if (callBack) {
      callBack();
    }
  }

  handleKeyDown = (e) => {
    switch (e.key) {
      case "ArrowRight":
        this.checkForChange(1);
        break;
      case "ArrowLeft":
        this.checkForChange(-1);
        break;
    }
  };

  onPreview = () => {};

  getTemplateContent = (id, callBack) => {
    if (navigator.clipboard && navigator.clipboard.readText) {
      navigator.clipboard
        .readText()
        .then((clipText) => {
          this.timesPasted = 0;
          this.shouldPasteInPlace =
            clipText.includes("copyObject") || clipText.includes("copyObjects");
          this.forceUpdate();
        })
        .catch(() => {});
    }

    // let section = this.state.sections.filter(s => s.ID === this.state.selectedSection)[0]
    let queryString = "/api/serveStatic/";
    if (this.state.selectedSection.isCustom) {
      queryString += `ClientData/${global.Modeliks.Account.ID}/${global.Modeliks.CompanyInfo.ID}/BusinessPlanData/${global.Modeliks.BusinessPlanScenarioInfo.ID}/page`;
    } else {
      queryString += `PagesData/template`;
    }
    queryString += `${this.state.selectedSection.isCustom ? this.state.selectedSection.ID : this.state.selectedSection.MasterPageID}.json?v=${this.state.selectedSection.UpdatedAt}`;
    const afterFileCallBack = (result) => {
      if (result) {
        this.saving = false;
        this.setState(
          {
            jsonData: JSON.stringify(result.body),
            slideKey: "slideComponent_" + new Date().getTime(),
            loading: false,
            preview: false,
          },
          () => {
            callBack && callBack();
            this.saving = false;
            this.loading = false;
            this.forceUpdate();
          },
        );
      } else {
        this.saving = false;
        this.setState(
          {
            jsonData: [],
            slideKey: "slideComponent_" + new Date().getTime(),
            loading: false,
            preview: false,
          },
          () => {
            callBack && callBack();
            this.saving = false;
            this.loading = false;
            this.forceUpdate();
          },
        );
      }
    };
    request
      .get(queryString)
      .then((result) => afterFileCallBack(result))
      .catch((error) => {
        request
          .get("/api/public/BlankTemplate.json")
          .then((result) => afterFileCallBack(result))
          .catch((error) => {
            console.error("error", error);
          });
      });
  };

  updateOrder = (update = true, callBack) => {
    this.SlideSections.forEach((c, index) => {
      if (c.order != index + 1) {
        c.order = index + 1;
        global.Modeliks.put(
          "businessplanpages_client",
          { ID: c.ID },
          { order: index + 1 },
          () => {},
        );
      }
    });

    if (update) {
      this.forceUpdate(() => {
        callBack && callBack();
      });
    } else {
      callBack && callBack();
    }
  };

  addSectionInObject = async (index, section, isCustom) => {
    const slideSections = this.SlideSections;
    const categories = this.Categories;

    // Update SlideSections
    let updatedSlideSections;
    if (index >= slideSections.length) {
      updatedSlideSections = [...slideSections, section]; // Append section
    } else {
      // Insert section at index
      updatedSlideSections = [
        ...slideSections.slice(0, index),
        section,
        ...slideSections.slice(index),
      ];
    }

    // Update Categories if isCustom is true
    let updatedCategories = categories;
    if (isCustom) {
      const customCategoryIndex = 4;
      const customCategory = categories[customCategoryIndex];
      const updatedCustomCategory = {
        ...customCategory,
        Sections: [...customCategory.Sections, section], // Append section
      };
      updatedCategories = [
        ...categories.slice(0, customCategoryIndex),
        updatedCustomCategory,
        ...categories.slice(customCategoryIndex + 1),
      ];
    }

    this.SlideSections = updatedSlideSections;
    this.Categories = updatedCategories;

    this.updateOrder();
  };

  checkIfCustomTemplates = (templates, oldTemplates, index, callBack) => {
    if (index < templates.length) {
      if (templates[index].isCustom) {
        request
          .post("/api/copyFile")
          .set("authorization", "Bearer " + window.localStorage.getItem("token"))
          .query({
            old_template: oldTemplates[index].ID,
            new_template: templates[index].ID,
            company_id: global.Modeliks.CompanyInfo.ID,
            pitch_scenario_id: global.Modeliks.PitchScenarioInfo.ID,
            is_custom: true,
          })
          .then((res) => {
            if (res.body == "success") {
              this.checkIfCustomTemplates(templates, oldTemplates, index + 1, callBack);
            }
          });
      } else {
        this.checkIfCustomTemplates(templates, oldTemplates, index + 1, callBack);
      }
    } else {
      callBack();
    }
  };

  handleDuplicate = (section) => {
    this.saveContent(
      this.state.selectedSection.ID,
      this.jsonFunc.returnJsonString(),
      () => {
        this.addSection(
          section,
          () => {
            this.forceUpdate();
          },
          true,
        );
      },
      global.Modeliks.Account.ID,
      global.Modeliks.CompanyInfo.ID,
      global.Modeliks.BusinessPlanScenarioInfo.ID,
    );
  };

  constructSection = async (index, section, callBack) => {
    try {
      let is_custom = section.CategoryID == 5;
      section.CompanyID = global.Modeliks.CompanyInfo.ID;

      let newSection = {};

      if (section.isCustom || is_custom) {
        if (!section.ID) {
          return;
        }
        const { ID, ...noIdSection } = section;
        newSection = {
          ...noIdSection,
          order: this.state.selectedSection.order,
          isDeleted: false,
          isCustom: true,
        };

        const response = await createCustomCategorySectionForBusinessScenario(newSection);
        newSection.ID = response.section.id;
        await copyPageFileForBusinessScenario(section.ID, newSection.ID);
      } else {
        const { ID, ...noIdSection } = section;
        newSection = {
          ...noIdSection,
          MasterPageID: section.MasterPageID ? section.MasterPageID : section.ID,
          isCustom: is_custom,
          order: this.state.selectedSection.order + 1,
        };

        if (is_custom) {
          if (!section.ID) {
            return;
          }
          const response = await createCustomCategorySection();
          newSection.ID = response.section.id;
        } else {
          const section = await createNonCustomSection(newSection);
          newSection.ID = section.id;
        }
      }

      this.addSectionInObject(index, newSection, true);
      // changeSelectedSection has {this.SlideSections[index - 1]} for selection actual section
      // adding 1 on our index here selects the correct section when it is newly added
      this.changeSelectedSection(index + 1);
      scale.refreshLeft();
      callBack && callBack();
      return newSection.ID;
    } catch (error) {
      console.error(error);
    }
  };

  addSection = async (section, callBack, duplicate = false) => {
    try {
      let index = duplicate
        ? section.order - 1
        : this.SlideSections.indexOf(this.state.selectedSection) + 1;

      // this is primitive implementation of command pattern
      // invoker class should execute commands
      const addSectionCommand = new AddSection({
        actionForward: this.constructSection.bind(this),
        actionBackward: this.executeDeleteSectionFlow.bind(this),
        index,
        section,
        callBack,
      });

      await this.context.commandHistoryManager.executeCommand(addSectionCommand);
      this.context.addToGlobalSlideHistory();
    } catch (error) {
      console.error(error);
    }
  };

  updateSection = (id, query, callBack) => {
    let section_index = this.state.sections.findIndex((s) => s.ID === id);
    let section = this.state.sections[section_index];
    if (section.CategoryID === 5) {
      request
        .put("/api/updateSection")
        .query({ ID: id })
        .send(query)
        .then((err, res) => {
          if (callBack) {
            callBack();
          }
        });
    }
  };

  hideSection = (id, hidden, callBack) => {
    let section_index = this.state.sections.findIndex((s) => s.ID === id);
    let section = this.state.sections[section_index];
    if (section.CategoryID === 5) {
      request
        .put("/api/updateSection")
        .query({ ID: id })
        .send({ Hidden: hidden })
        .then((err, res) => {
          section.Hidden = hidden;
          if (callBack) {
            callBack();
          }
        });
    }
  };

  findNextSection = (sectionID, val) => {
    let previousSectionIndex = this.SlideSections.findIndex((section) => section.ID == sectionID);
    if (previousSectionIndex + val >= 0 && previousSectionIndex + val < this.SlideSections.length) {
      let newSection = this.SlideSections[previousSectionIndex + val];
      if (newSection.hasOwnProperty("Hidden")) {
        if (newSection.Hidden) {
          return this.findNextSection(newSection.ID, val);
        }
      }
      return newSection;
    }
    return null;
  };

  resetTemplate = () => {
    if (this.state.selectedSection.MasterPageID) {
      let id = this.state.selectedSection.ID;
      global.Modeliks.put(
        "businessplanpages_client",
        { ID: id },
        { isCustom: false, isDefault: true },
        (success) => {
          if (success) {
            this.state.selectedSection.isCustom = false;
            this.state.selectedSection.UpdatedAt = new Date().toISOString();

            this.getTemplateContent(this.state.selectedSection.MasterPageID, () => {});
          }
        },
      );
    }
  };

  getTemplatesForPrint = (callBack) => {
    request
      .get(
        `/api/BusinessPlanPreview?client_id=${global.Modeliks.Account.ID}&company_id=${global.Modeliks.CompanyInfo.ID}&business_plan_scenario_id=${global.Modeliks.BusinessPlanScenarioInfo.ID}`,
      )
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .then((data) => {
        let res = JSON.parse(data.text);
        let templatesArr = res.jsonStringArr;
        let pageNames = res.pageNames;
        let firstPage = templatesArr[0];
        templatesArr.splice(0, 1);
        this.DefaultTemplates = templatesArr;
        this.setState({ printEnabled: true, firstPage: firstPage, pageNames: pageNames }, () => {
          setTimeout(callBack, 500);
        });
      });
  };

  beforeSlidePrint = (callBack) => {
    document.getElementById("style2").media = "print";
    this.getTemplatesForPrint(callBack);
  };

  setSave = (item, tablename) => {
    item.tableName = tablename;
    item.Save = (success, error) => {
      global.Modeliks.put(item.tableName, null, item, success, error);
    };
  };

  afterSlidePrint = (callBack) => {
    document.getElementById("style2").media = "false";
    this.DefaultTemplates = [];
    this.setState({ printEnabled: false }, callBack && callBack());
  };

  onZoomChange = (val) => {
    this.setState({ zoom: val });
  };

  constructNewSection = async (index, name, callBack) => {
    try {
      const newSection = await createNewSection(name);
      this.addSectionInObject(index, newSection, true);
      // changeSelectedSection has {this.SlideSections[index - 1]} for selection actual section
      // adding 1 on our index here selects the correct section when it is newly added
      this.changeSelectedSection(index + 1);
      scale.refreshLeft();
      this.forceUpdate();
      callBack && callBack();
      return newSection;
    } catch (error) {
      console.error(error);
    }
  };

  addNewSection = async (name, callBack) => {
    try {
      const index = this.SlideSections.indexOf(this.state.selectedSection) + 1;
      // this is primitive implementation of command pattern
      // invoker class should execute commands
      const addNewSectionCommand = new AddCustomSection({
        actionForward: this.constructNewSection.bind(this),
        actionBackward: this.executeDeleteSectionFlow.bind(this),
        index,
        name,
        callBack,
      });

      await this.context.commandHistoryManager.executeCommand(addNewSectionCommand);
      this.context.addToGlobalSlideHistory();
    } catch (error) {
      console.error(error);
    }
  };

  changeName = (id, name) => {
    let section = this.SlideSections.find((c) => c.ID === id);
    section.PageName = name;
    this.forceUpdate();
  };

  changeSelectedSection = (index) => {
    console.log("index", index);

    if (index === 0) {
      this.state.selectedSection = this.SlideSections[0];
      this.handleChange("e", this.SlideSections[0].ID, () => {}, false);
    } else {
      this.state.selectedSection = this.SlideSections[index - 1];
      this.handleChange("e", this.SlideSections[index - 1].ID, () => {}, false);
    }
  };

  setShouldPasteInPlace = (val) => {
    this.shouldPasteInPlace = val;
    this.forceUpdate();
  };

  setTimesPasted = (val) => {
    this.timesPasted = val;
    this.forceUpdate();
  };

  returnPublishSlides = () => {
    return (
      <div className={"preview_se_panel_container"}>
        <SlideComponent
          onPreview={() => this.onPreview()}
          scale={scale}
          getDateRange={this.getDateRange}
          publishMode={this.props.publishMode}
          slideConfig={this.state.slideConfig}
          pageNumber={1}
          pageName={this.state.pageNames[0]}
          dontShowHeader={true}
          dontShowFooter={true}
          fixedHeight
          showLogo={true}
          onScenarioChange={this.LoadingFunc}
          jsonFunc={this.jsonFunc}
          addNewObjectFuncs={this.addNewObjectFuncs}
          width={2480}
          height={3057}
          businessPlan={true}
          onUnmount={true}
          beforeSlidePrint={this.beforeSlidePrint}
          setSaving={this.setSaving}
          zoom={this.state.zoom}
          afterPrint={this.afterSlidePrint}
          selectedSection={this.state.selectedSection}
          setCounter={() => this.setState({ Counter: !this.state.Counter })}
          SlideSections={this.SlideSections}
          toggleFormatOptions={(val) => this.setState({ formatOptions: val })}
          disableEdit={!this.EditPermission}
          saveContent={this.saveContent}
          selectedTemplateID={this.state.selectedTemplateID}
          jsonData={this.state.firstPage}
          openDialog={true}
          className={this.saving && " hidden"}
          key={this.state.slideKey}
        />

        <SlideComponent
          onPreview={() => this.onPreview()}
          scale={scale}
          getDateRange={this.getDateRange}
          fixedHeight
          disableHeader
          showLogo={true}
          publishMode={this.props.publishMode}
          slideConfig={this.state.slideConfig}
          pageNumber={2}
          pageName={"Contents"}
          preview={this.state.preview}
          onScenarioChange={this.LoadingFunc}
          jsonFunc={this.jsonFunc}
          addNewObjectFuncs={this.addNewObjectFuncs}
          width={2480}
          height={3057}
          businessPlan={true}
          onUnmount={true}
          beforeSlidePrint={this.beforeSlidePrint}
          setSaving={this.setSaving}
          zoom={this.state.zoom}
          afterPrint={this.afterSlidePrint}
          selectedSection={this.state.selectedSection}
          setCounter={() => this.setState({ Counter: !this.state.Counter })}
          SlideSections={this.SlideSections}
          toggleFormatOptions={(val) => this.setState({ formatOptions: val })}
          disableEdit={!this.EditPermission}
          saveContent={this.saveContent}
          selectedTemplateID={this.state.selectedTemplateID}
          pageNames={this.state.pageNames}
          contentTable={true}
          openDialog={true}
          className={this.saving && " hidden"}
          key={this.state.slideKey}
        />
        {this.state.templatesArray.map((c, index) => (
          <SlideComponent
            onPreview={() => this.onPreview()}
            getDateRange={this.getDateRange}
            scale={scale}
            fixedHeight
            showLogo={true}
            disableHeader
            preview={this.state.preview}
            publishMode={this.props.publishMode}
            slideConfig={this.state.slideConfig}
            pageNumber={index + 3}
            pageName={this.state.pageNames[index + 1]}
            onScenarioChange={this.LoadingFunc}
            jsonFunc={this.jsonFunc}
            addNewObjectFuncs={this.addNewObjectFuncs}
            width={2480}
            height={3057}
            businessPlan={true}
            onUnmount={true}
            beforeSlidePrint={this.beforeSlidePrint}
            setSaving={this.setSaving}
            zoom={this.state.zoom}
            afterPrint={this.afterSlidePrint}
            selectedSection={this.state.selectedSection}
            setCounter={() => this.setState({ Counter: !this.state.Counter })}
            SlideSections={this.SlideSections}
            toggleFormatOptions={(val) => this.setState({ formatOptions: val })}
            disableEdit={!this.EditPermission}
            saveContent={this.saveContent}
            selectedTemplateID={this.state.selectedTemplateID}
            jsonData={c}
            openDialog={true}
            className={this.saving && " hidden"}
            key={this.state.slideKey}
          />
        ))}
      </div>
    );
  };

  exportToPdf = () => {
    request
      .get(`/api/checkIfFreeTrial`)
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .then((res) => {
        if (res.body.isFreeTrial) {
          this.setState({ openFreeTrialDialog: true });
        } else {
          this.loading = true;
          this.forceUpdate();

          request
            .get(
              `/api/BusinessPlanPreview?client_id=${global.Modeliks.Account.ID}&company_id=${global.Modeliks.CompanyInfo.ID}&business_plan_scenario_id=${global.Modeliks.BusinessPlanScenarioInfo.ID}`,
            )
            .then((res2) => {
              if (res2.text !== "0") {
                let res = JSON.parse(res2.text);
                let templatesArr = res.jsonStringArr;
                let pageNames = res.pageNames;
                this.slideSections = res.slideSections;
                this.setState({
                  exportToPdfData: templatesArr,
                  pageNames: pageNames,
                  exportToPdf: true,
                });
              }
            });
        }
      });
  };

  checkIfChartsHaveFinished = () => {
    return window.amountOfChartsToRender === window.amountOfChartsRendered;
  };

  resetPrintingValues = () => {
    window.chartsForRender = [];
    window.amountOfChartsRendered = 0;
    window.amountOfChartsToRender = 0;
  };

  returnSlidesForExport = (shouldDownload = false) => {
    let counter = 0;
    window.iscurrentlyPrinting = true;
    this.resetPrintingValues();

    const checkFinished = async (fromChart = false) => {
      if (!fromChart) {
        counter++;
      }
      if (counter == this.state.exportToPdfData.length && this.checkIfChartsHaveFinished()) {
        if (this.isFetching) {
          return;
        }

        this.isFetching = true;

        global.Modeliks.Unsubscribe2("onChartRenderFinish", checkFinishedWrapper);
        this.resetPrintingValues();

        setTimeout(async () => {
          let el = document.getElementsByClassName("preview_hidden_container")[0].innerHTML;

          const children = document
            .getElementsByClassName("preview_hidden_container")[0]
            .childNodes.forEach((c, i) => {
              this.slideSections[i].html = c.outerHTML;
            });

          this.loading = false;
          this.setState({ exportToPdf: true });

          try {
            const pdfResponse = await create({
              config: {
                ...global.Modeliks.BusinessPlanConfig,
                companyName: global.Modeliks.CompanyInfo.CompanyName,
                logoUrl: `${window.location.protocol + "//" + window.location.host}/api/logo?account_id=${global.Modeliks.Account.ID}&company_id=${global.Modeliks.CompanyInfo.ID}`,
              },
              sections: this.slideSections,
            });

            const pdfData = await fetch(pdfResponse, {
              v: new Date().getTime(),
            });

            download(
              pdfData,
              `${global.Modeliks.CompanyInfo.CompanyName.replaceAll(".", "_")}_BP_${new Date().getTime()}`,
            );
          } catch (error) {
            console.error(error);
          } finally {
            this.loading = false;
            this.setState({ exportToPdf: false });
            window.iscurrentlyPrinting = false;
            this.isFetching = false;
          }
        }, 100);
      }

      // stop multiple requests to createPdf
    };

    function checkFinishedWrapper() {
      checkFinished(true);
    }

    global.Modeliks.Subscribe("onChartRenderFinish", checkFinishedWrapper);

    return (
      <div className={"preview_hidden_container"}>
        {this.state.exportToPdfData.map((c, index) => {
          let jsonString = JSON.parse(c);
          window.amountOfChartsToRender += jsonString.slideObjects.filter(
            (c) => c.type === SlideTypes.chartObject || c.type === SlideTypes.financialChart,
          ).length;
          return (
            <SlideComponent
              onPreview={() => this.onPreview()}
              scale={scale}
              getDateRange={this.getDateRange}
              pageBreakAfter={index === this.state.exportToPdfData.length - 1 ? false : true}
              showLogo={true}
              didMount={() => checkFinished()}
              disableHeader
              preview={true}
              width={2480}
              height={3057}
              dontShowHeader={true}
              dontShowFooter={true}
              showPageName={index !== 0}
              pageName={this.state.pageNames[index]}
              businessPlan={true}
              isBpPrinting={true}
              isFirstPage={index === 0}
              onUnmount={true}
              jsonData={c}
              openDialog={true}
              className={this.saving && " hidden"}
              key={"sc_" + index.toString()}
            />
          );
        })}
      </div>
    );
  };

  getZoom = () => {
    return this.state.zoom / 100 + 1;
  };
  returnSlideComp = () => {
    return (
      <SlideComponent
        onPreview={() => this.onPreview()}
        scale={scale}
        getDateRange={this.getDateRange}
        onScenarioChange={this.LoadingFunc}
        jsonFunc={this.jsonFunc}
        addNewObjectFuncs={this.addNewObjectFuncs}
        exportToPdf={this.exportToPdf}
        selectPrevSlide={this.selectPrevSlide}
        selectNextSlide={this.selectNextSlide}
        width={2480}
        height={3057}
        businessPlan={true}
        onUnmount={true}
        beforeSlidePrint={this.beforeSlidePrint}
        setSaving={this.setSaving}
        zoom={this.state.zoom}
        getZoom={this.getZoom}
        afterPrint={this.afterSlidePrint}
        setShouldPasteInPlace={this.setShouldPasteInPlace}
        openFreeTrialDialog={this.openFreeTrialDialog}
        shouldPasteInPlace={this.shouldPasteInPlace}
        setTimesPasted={this.setTimesPasted}
        timesPasted={this.timesPasted}
        selectedSection={this.state.selectedSection}
        setCounter={() => this.setState({ Counter: !this.state.Counter })}
        SlideSections={this.SlideSections}
        dontShowHeader={this.SlideSections.indexOf(this.state.selectedSection) === 0}
        dontShowFooter={this.SlideSections.indexOf(this.state.selectedSection) === 0}
        pageNumber={
          global.Modeliks.BusinessPlanConfig.Counter
            ? this.SlideSections.indexOf(this.state.selectedSection) + 1
            : false
        }
        toggleFormatOptions={(val) => this.setState({ formatOptions: val })}
        disableEdit={!this.EditPermission}
        saveContent={this.saveContent}
        selectedTemplateID={this.state.selectedTemplateID}
        jsonData={this.state.jsonData}
        firstPage={this.state.firstPage}
        pageNames={this.state.pageNames}
        preview={this.state.previewPDF}
        openDialog={true}
        canShare={this.SlideSections.findIndex((c) => !c.Hidden) > -1}
        className={this.saving && " hidden"}
        key={this.state.slideKey}
      />
    );
  };

  openFreeTrialDialog = () => {
    this.setState({ openFreeTrialDialog: true });
  };

  render() {
    if (
      global.Modeliks.BusinessPlanConfig &&
      global.Modeliks.BusinessPlanConfig.Logo &&
      this.state.printEnabled
    ) {
      this.DateNow = new Date().getTime();
      this.LogoObj = <LogoComponent version={this.DateNow} />;
    }
    if (this.state.loading) {
      return (
        <div className={"main_circle_loader_container"}>
          <CircleLoader />
        </div>
      );
    } else {
      return (
        <>
          <Toast
            isOpen={this.state.shouldShowNotification}
            text={`For the best experience, we recommend Chrome, Firefox or Edge. We're working to improve ${this.browserInfoService.getBrowserName()} compatibility.`}
            origin={{ horizontal: "center", vertical: "top" }}
            onClose={() => this.setState({ shouldShowNotification: false })}
          ></Toast>
          {!this.props.publishMode && this.SlideSections.length > 0 ? (
            <div className={"App slide_editor"}>
              <SlideHeaderContainer
                openFreeTrialDialog={this.openFreeTrialDialog}
                canPreview={this.SlideSections.findIndex((c) => !c.Hidden) > -1}
                saveSlideContent={this.saveSlideContent}
                businessPlan
              />
              <div className={"se_panel_wrapper bp"}>
                {this.state.exportToPdf && (
                  <div className={"main_circle_loader_container_mask grey"}>
                      <CircleLoader message={'We are preparing your business plan for download...'}/>
                  </div>
                )}
                {
                  <LeftMenu
                    sectionId={this.props.sectionId}
                    updateSection={this.updateSection}
                    hideSection={this.hideSection}
                    deleteSection={this.deleteSection}
                    deleteSectionFlow={this.deleteSectionFlow}
                    addSection={this.addSection}
                    saveSlideContent={this.saveSlideContent}
                    scale={scale}
                    disableEdit={!this.EditPermission}
                    addNewObjectFuncs={this.addNewObjectFuncs}
                    setSaving={this.setSaving}
                    user={true}
                    sectionIndexes={this.SlideSectionIndexes}
                    changeName={this.changeName}
                    tabKey={"PageName"}
                    label={"Add new section"}
                    businessPlan={true}
                    onScenarioChange={this.LoadingFunc}
                    deleteSections={this.deleteSections}
                    changeSelectedSection={this.changeSelectedSection}
                    handleDuplicate={this.handleDuplicate}
                    updateOrder={this.updateOrder}
                    updateIndex={() => {
                      this.forceUpdate();
                    }}
                    update={(val) => scale.handleResize(val)}
                    isAdmin={false}
                    tabs={this.SlideSections ? this.SlideSections : []}
                    selectedSection={this.state.selectedSection}
                    handleChange={this.handleChange}
                    categories={this.Categories}
                    CurrentScreen={0}
                    addNewSection={this.addNewSection}
                    onSectionMove={() => scale.updateSlideComp()}
                  />
                }
                <div
                  className={"se_panel_container"}
                  id={"se_panel_container"}
                  key={this.state.key}
                >
                  {(this.saving || this.loading) && (
                    <div className={"loader_container_on_top_sc bp"}>
                      <CircleLoader />
                    </div>
                  )}
                  {this.state.exportToPdf && this.returnSlidesForExport()}
                  {this.returnSlideComp()}
                  {!(this.saving || this.loading) && (
                    <div className={"se_panel_chart_editor"} id="chart_editor_portal"></div>
                  )}
                  <Footer
                    handleZoomEnd={() => {
                      scale.handleResize && scale.handleResize();
                    }}
                    enableZoom
                    zoomRange={[-50, 50]}
                    onPreviewMode={() => this.onPreview()}
                    onZoomChange={this.onZoomChange}
                    objectLoad={this.objectLoad}
                    zoomValue={this.state.zoom}
                    changeSection={(val) => {
                      this.checkForChange(val);
                    }}
                  />
                </div>
                {this.EditPermission && (
                  <RightMenu
                    scale={scale}
                    update={(val) => scale.handleResize(val)}
                    className={"options_menu"}
                    selectTemplate={this.selectTemplate}
                    dontShowTemplates={true}
                    selectedTemplateID={this.state.selectedTemplateID}
                    businessPlan={true}
                    resetTemplate={this.resetTemplate}
                    selectedTemplate={this.state.selectedTemplate}
                    templates={this.Templates}
                    selectedSection={this.state.selectedSection}
                    sections={this.SlideSections}
                  />
                )}
              </div>
            </div>
          ) : this.state.open ? (
            <div className={"se_panel_wrapper bp"}>
              <div className={"se_panel_container"} id={"se_panel_container"}>
                {this.returnPublishSlides()}
              </div>
            </div>
          ) : (
            <div className={"se_invalid_link_container"}>
              <Logo width={"30%"} height={"30%"} />
              <h1>Your access was revoked</h1>
            </div>
          )}
          <FreeTrialDialog
            open={this.state.openFreeTrialDialog}
            handleClose={() => {
              this.setState({ openFreeTrialDialog: false });
            }}
          />
        </>
      );
    }
  }
}

index.contextType = CommandManagerContext;

export default index;

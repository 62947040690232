import { useEffect, useState } from "react";
import { QuestionWrapper } from "../../components/QuestionWrapper";
import { CardSelect } from "../../components/CardSelect";
import { usePage } from "../../contexts/PageProvider";
import { iconView, iconViewSelected, iconGenerate, iconGenerateSelected } from "../../icons";
import { useSave } from "../../hooks/useSave";
import { executiveStrategyObjectToAiGeneratedContentDto } from "../../models/aiGeneratedContent/transformer";
import { useNavigate } from "react-router-dom";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import { getSection } from "../../hooks/getSection";
import { FormNavigation } from "../../components/FormNavigation";

export function ExecutiveSummaryPrompt() {
  const navigate = useNavigate();
  const { section, getGeneratedData, inputs, setLoading } = usePage();
  const [sectionName, setSectionName] = useState({ sectionName: "" });
  const { nextSection, generateSection, saveSectionInputs } = useSave(sectionName);
  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const [loadingView, setLoadingView] = useState(false);
  const bpScenarioId = global.Modeliks.BusinessPlanScenarioInfo.ID;

  useEffect(() => {
    if (section) {
      setSectionName({ sectionName: section.name });
    }
  }, [section]);

  const [selectedCard, setSelectedCard] = useState(null);

  async function onClick(cardIndex) {
    if (loadingView || loadingGenerate) return;

    setSelectedCard(cardIndex);
    setLoading(true);
    if (cardIndex === 1) {
      setLoadingGenerate(true);

      await new Promise((resolve) => {
        setTimeout(async () => {
          try {
            const generatedData = getGeneratedData();

            await generateSection(
              executiveStrategyObjectToAiGeneratedContentDto(generatedData, sectionName),
            );
          } catch (error) {
            console.error("Error during business plan generation:", error);
          }

          resolve();
        }, 0);
      });
    } else {
      setLoadingView(true);
    }
    const section = await getSection(sectionName.sectionName, bpScenarioId);

    if (section && section.PageID) {
      navigate("/business_plan", { state: { sectionId: section.PageID } });
    } else {
      console.error("Failed to retrieve section or PageId is missing.");
    }

    setLoadingGenerate(false);
    setLoadingView(false);
  }

  return (
    <>
      <QuestionWrapper
        isAnswered={selectedCard !== null}
        question={"Create your Executive Summary."}
        description={
          "The Executive Summary provides a concise view of your business plan, including a business description, your value proposition, explanation of the opportunity, the target market and customer and key financials."
        }
      >
        <div className="flex gap-14 justify-center">
          <CardSelect nonEditable onClick={() => onClick(0)} isSelected={selectedCard === 0}>
            {loadingView ? (
              <CircleLoader className="h-44" />
            ) : (
              <div className="flex flex-col gap-2 p-3 items-center justify-center max-w-36">
                {selectedCard === 0 ? <>{iconViewSelected}</> : <>{iconView}</>}
                <span
                  className={`font-bold text-center ${selectedCard === 0 ? "text-white" : "text-primary"}`}
                >
                  View & Edit Executive Summary
                </span>
              </div>
            )}
          </CardSelect>

          <CardSelect nonEditable onClick={() => onClick(1)} isSelected={selectedCard === 1}>
            {loadingGenerate ? (
              <CircleLoader className="h-44" />
            ) : (
              <div className="flex flex-col gap-2 p-3 items-center justify-center max-w-36">
                {selectedCard === 1 ? <>{iconGenerateSelected}</> : <>{iconGenerate}</>}
                <span
                  className={`font-bold text-center ${selectedCard === 1 ? "text-white" : "text-primary"}`}
                >
                  Generate Executive Summary
                </span>
              </div>
            )}
          </CardSelect>
        </div>
      </QuestionWrapper>

      <FormNavigation noSaveBtn />
    </>
  );
}

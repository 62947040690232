import { useEffect, useState } from "react";
import { CardSelect } from "../../components/CardSelect";
import { QuestionWrapper } from "../../components/QuestionWrapper";
import { Input } from "../../components/Input";
import { Button } from "../../components/CopiedFromRefactor/Button";
import { SectionButtons } from "../../components/SectionButtons";
import { usePage } from "../../contexts/PageProvider";
import { SectionMode } from "../../helpers/enums";
import { useSave } from "../../hooks/useSave";
import { strategyEquipmentObjectToAiGeneratedContentDto } from "../../models/aiGeneratedContent/transformer";
import { fetchOptions } from "../../Services/api/Repositories/BusinessPlanGenerator";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import { useNavigate } from "react-router-dom";
import { FormNavigation } from "../../components/FormNavigation";

const MAX_CARDS = 10;

export function Equipment() {
  const navigate = useNavigate();
  const {
    section,
    strategyEquipment,
    setStrategyEquipment,
    getGeneratedData,
    setLoading,
    options,
    isLoading,
    setOptions,
  } = usePage();

  const [sectionName, setSectionName] = useState({ sectionName: "" });
  const { nextSection, generateSection, saveSectionInputs } = useSave(sectionName);

  const [cards, setCards] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [inputError, setInputError] = useState(false);
  const [isInputActive, setIsInputActive] = useState(false);
  const [loadingEquipment, setLoadingEquipment] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  const [newEquipmentOptions, setNewEquipmentOptions] = useState([]);

  useEffect(() => {
    if (options)
      setOptions({
        ...options,
        equipments: [
          ...options.equipments,
          ...newEquipmentOptions.filter((card) => !options.equipments.includes(card)),
        ],
      });
  }, [newEquipmentOptions]);

  useEffect(() => {
    if (section) {
      setSectionName({ sectionName: section.name });
    }
  }, [section]);

  const bpScenarioId = global.Modeliks.BusinessPlanScenarioInfo.ID;

  function transformToSelectable(items = [], selectedItems = []) {
    const allItems = items.map((item) => ({
      title: item,
      isSelected: selectedItems.includes(item),
    }));

    selectedItems.forEach((selectedItem) => {
      if (!items.includes(selectedItem)) {
        allItems.push({ title: selectedItem, isSelected: true });
      }
    });

    return allItems;
  }

  async function fetchEquipment() {
    try {
      setLoadingEquipment(true);
      const equipmentResponse = await fetchOptions({
        bpScenarioId,
        optionKey: "equipments",
      });
      setOptions({
        ...options,
        equipments: equipmentResponse.SectionData.Options.equipments,
      });
      setCards(
        transformToSelectable(
          equipmentResponse.SectionData.Options.equipments,
          strategyEquipment.equipments || [],
        ),
      );
    } catch (error) {
      console.error("Error fetching equipment:", error);
    } finally {
      setLoadingEquipment(false);
    }
  }

  useEffect(() => {
    if (isLoading) return;

    if (strategyEquipment && strategyEquipment.equipments) {
      setCards((prev) =>
        transformToSelectable(options?.equipments || prev, strategyEquipment.equipments),
      );
    }
  }, [strategyEquipment]);

  useEffect(() => {
    if (isLoading) return;

    if (options) {
      setInitialRender(false);
      if (options.equipments.length === 0) {
        fetchEquipment();
      } else {
        if (initialRender)
          setCards(transformToSelectable(options.equipments, strategyEquipment.equipments));
      }
    }
  }, [options, strategyEquipment, isLoading]);

  function handleInputChange(event) {
    setInputValue(event.target.value);
  }

  function addCard() {
    const trimmedValue = inputValue.trim();
    if (!trimmedValue) return;

    const cardExists = cards.some((card) => card.title === trimmedValue);
    if (cardExists) {
      setInputError(true);
      return;
    }

    const newCard = { title: trimmedValue, isSelected: false };
    setCards((prevCards) => [...prevCards, newCard]);
    setNewEquipmentOptions((prevCards) => [...prevCards, newCard.title]);
    setInputValue("");
    setIsInputActive(false);
    setInputError(false);
  }

  function onCardClick(index) {
    const selectedCount = cards.filter((card) => card.isSelected).length;

    setCards((prevCards) =>
      prevCards.map((card, i) =>
        i === index
          ? {
              ...card,
              isSelected: card.isSelected ? false : selectedCount < MAX_CARDS,
            }
          : card,
      ),
    );
  }

  function handleSaveEdit(index, newTitle) {
    setCards((prevCards) =>
      prevCards.map((card, i) => (i === index ? { ...card, title: newTitle } : card)),
    );
  }

  function validate() {
    return cards.filter((card) => card.isSelected).length > 0;
  }

  async function onNextHandle() {
    if (!validate()) return "strategy/management_team";
    await setStrategyEquipment({
      equipments: cards.filter((card) => card.isSelected).map((card) => card.title),
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await nextSection(
            strategyEquipmentObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });

    return "strategy/management_team";
  }

  async function onGenerateHandle() {
    if (!validate()) return;

    await setStrategyEquipment({
      equipments: cards.filter((card) => card.isSelected).map((card) => card.title),
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await generateSection(
            strategyEquipmentObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });

    return sectionName;
  }

  async function onSave() {
    await setStrategyEquipment({
      equipments: cards.filter((card) => card.isSelected).map((card) => card.title),
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await saveSectionInputs(
            strategyEquipmentObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });
  }

  return (
    <>
      <QuestionWrapper
        isAnswered={cards.some((card) => card.isSelected)}
        question={"What equipment do you need for your business?"}
        description={"List any specialty equipment that you need to operate your business."}
        maxSelectiveOptions={MAX_CARDS}
        onRegenerateOptions={fetchEquipment}
      >
        {loadingEquipment ? (
          <CircleLoader />
        ) : (
          <div className="grid grid-cols-2 gap-3 auto-rows-fr">
            {cards.map((card, index) => (
              <CardSelect
                value={card.title}
                onClick={() => onCardClick(index)}
                key={`select-card-${index}`}
                isSelected={card.isSelected}
                onSaveEdit={(i, newValue) => handleSaveEdit(i, newValue)}
                index={index}
              >
                {card.title}
              </CardSelect>
            ))}
            {isInputActive && (
              <Input
                error={inputError}
                placeholder="Add new equipment"
                value={inputValue}
                onChange={handleInputChange}
                onAddCard={addCard}
              />
            )}
          </div>
        )}

        <Button className={"w-38"} rounded outline onClick={() => setIsInputActive(true)}>
          Add More
        </Button>
      </QuestionWrapper>

      <SectionButtons onNext={onNextHandle} onGenerate={onGenerateHandle} />

      <FormNavigation onSave={onSave} />
    </>
  );
}

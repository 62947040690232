import React, { useRef } from "react";
import {
  Edit3,
  PageCounterIcon,
  Images,
  Undo,
  Images2,
} from "../../components/IconButtons/SubMenuIcons";
import { ControlledMenu, useMenuState, MenuButton, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "../HeaderTextEditor/HorizontalSubMenu/MoreOptionsMenu.scss";
import ThemeColorComponent from "./ThemeColorComponent";
import Tooltip from "@mui/material/Tooltip";
import FooterValueComponent from "./FooterValueComponent";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";
import "./themesmenucomponent.scss";
import ThemeTemplateColorComponent from "./ThemeTemplateColorComponent";

function ThemesMenuComponent(props) {
  const ref = useRef(null);
  const [menuProps, toggleMenu] = useMenuState({ transition: true });
  const [open, setOpen] = React.useState(true);
  return (
    <>
      <ButtonMui
        disabled={props.disableEdit}
        onClick={() => {
          setOpen(!open);
          toggleMenu(open);
        }}
        ref={ref}
        isIconButton={true}
        roundnessType={"squared"}
        variant={"text"}
        color={"black"}
        label={<Edit3 />}
        tooltipText={"Theme Options"}
      />

      <ControlledMenu
        className={"themes_menu"}
        {...menuProps}
        anchorRef={ref}
        onClose={() => {
          setOpen(!open);
          toggleMenu(open);
        }}
      >
        {
          <Tooltip
            title=""
            arrow
            placement={"left"}
            onClick={() => {
              if (!props.businessPlan) {
                if (global.Modeliks.PitchScenarioInfo.Counter) {
                  global.Modeliks.PitchScenarioInfo.Counter = false;
                  global.Modeliks.PitchScenarioInfo.Save(
                    () => {
                      props.setCounter();
                    },
                    (err) => console.error(err),
                  );
                } else {
                  global.Modeliks.PitchScenarioInfo.Counter = true;
                  global.Modeliks.PitchScenarioInfo.Save(
                    () => {
                      props.setCounter();
                    },
                    (err) => console.error(err),
                  );
                }
              } else {
                if (global.Modeliks.BusinessPlanConfig.Counter) {
                  global.Modeliks.BusinessPlanConfig.Counter = false;
                  global.Modeliks.BusinessPlanConfig.Save(
                    () => {
                      props.setCounter();
                    },
                    (err) => console.error(err),
                  );
                } else {
                  global.Modeliks.BusinessPlanConfig.Counter = true;
                  global.Modeliks.BusinessPlanConfig.Save(
                    () => {
                      props.setCounter();
                    },
                    (err) => console.error(err),
                  );
                }
              }
            }}
          >
            <span>
              <MenuItem className={"o_i_btn text"}>
                {({ hover }) => (
                  <div style={{ display: "flex", columnGap: "15px", alignItems: "center" }}>
                    <PageCounterIcon
                      active={
                        hover ||
                        (!props.businessPlan && global.Modeliks.PitchScenarioInfo.Counter) ||
                        (props.businessPlan && global.Modeliks.BusinessPlanConfig.Counter)
                      }
                    />
                    <div style={{ color: hover ? "#01AFFD" : "unset" }}>Page Counter</div>
                  </div>
                )}
              </MenuItem>
            </span>
          </Tooltip>
        }
        <FooterValueComponent
          onClose={() => {
            setOpen(!open);
            toggleMenu(open);
          }}
          setFooter={props.setFooter}
          businessPlan={props.businessPlan}
        />
        <Tooltip
          title={""}
          arrow
          placement={"right"}
          onClick={() => {
            if (!props.businessPlan) {
              if (global.Modeliks.PitchScenarioInfo.Logo) {
                global.Modeliks.PitchScenarioInfo.Logo = false;
                global.Modeliks.PitchScenarioInfo.Save(
                  () => {
                    props.setLogo();
                  },
                  (err) => console.error(err),
                );
              } else {
                global.Modeliks.PitchScenarioInfo.Logo = true;
                global.Modeliks.PitchScenarioInfo.Save(
                  () => {
                    props.setLogo();
                  },
                  (err) => console.error(err),
                );
              }
            } else {
              if (global.Modeliks.BusinessPlanConfig.Logo) {
                global.Modeliks.BusinessPlanConfig.Logo = false;
                global.Modeliks.BusinessPlanConfig.UpdatedAt = new Date().toISOString();
                global.Modeliks.BusinessPlanConfig.Save(
                  () => {
                    props.setLogo();
                  },
                  (err) => console.error(err),
                );
              } else {
                global.Modeliks.BusinessPlanConfig.Logo = true;
                global.Modeliks.BusinessPlanConfig.UpdatedAt = new Date().toISOString();
                global.Modeliks.BusinessPlanConfig.Save(
                  () => {
                    props.setLogo();
                  },
                  (err) => console.error(err),
                );
              }
            }
          }}
        >
          <span>
            <MenuItem className={"o_i_btn text"}>
              {({ hover }) => (
                <div style={{ display: "flex", columnGap: "15px", alignItems: "center" }}>
                  <Images2
                    active={
                      hover ||
                      (!props.businessPlan && global.Modeliks.PitchScenarioInfo.Logo) ||
                      (props.businessPlan && global.Modeliks.BusinessPlanConfig.Logo)
                    }
                  />
                  <div style={{ color: hover ? "#01AFFD" : "unset" }}>Logo</div>
                </div>
              )}
            </MenuItem>
          </span>
        </Tooltip>
        <ThemeColorComponent
          PitchScenarioInfo={props.PitchScenarioInfo}
          update={props.update}
          changeDeckColor={props.changeDeckColor}
          businessPlan={props.businessPlan}
          name={props.businessPlan ? "Document Background" : "Slide Deck Background"}
        />
        {!props.businessPlan && (
          <ThemeTemplateColorComponent
            selectedTemplateColor={props.selectedTemplateColor ?? "#ffffff"}
            changeTemplateColor={props.changeTemplateColor}
          />
        )}
      </ControlledMenu>
    </>
  );
}

export default ThemesMenuComponent;

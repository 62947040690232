import React, { Fragment } from "react";
import HeaderPortal from "../SlideHeader/HeaderPortal.js";
import HeaderTextEditor from "../SlideHeader/HeaderTextEditor";
import TextFitting from "../components/RightMenuComponents/TextFittingComponent";
import SpecialCharacters from "../components/RightMenuComponents/SpecialCharactersComponent";
import RightMenuPortal from "../components/Portals/RightMenuPortal.js";
import LinkSubContextMenu from "../components/ContextMenus/LinkSubContextMenu";
import List from "@mui/material/List";
import ContextMenuPortal from "../Templates/ContextMenuPortal";
import CapitalizationSubContextMenu from "../components/ContextMenus/CapitalizationSubContextMenu";
import "../components/RightMenuComponents/RightMenu.css";
import SCContextMenuPortal from "../components/menus/newContextMenu/SCContextMenuPortal";
import ContextMenu from "../components/menus/newContextMenu/ContextMenu";
import "./TextObject2.scss";
import { ControlledMenu, MenuItem, SubMenu } from "@szhsin/react-menu";
import RotateSubMenu from "../components/menus/newContextMenu/RotateSubMenu";
import CapitalizationSubMenu from "../components/menus/newContextMenu/CapitalizationSubMenu";
import OrderSubMenu from "../components/menus/newContextMenu/OrderSubMenu";
import CenterOnPageSubMenu from "../components/menus/newContextMenu/CenterOnPageSubMenu";
import SearchInput from "../components/SearchInput/SearchInput";
import { Copy, Cut, Exit, FormatColor, Paste } from "../components/IconButtons/SubMenuIcons";
import HeaderDefault from "../SlideHeader/HeaderDefault";
import SizeAndRotation from "../components/RightMenuComponents/SizeAndRotationComponent";
import Position from "../components/RightMenuComponents/PositionComponent";
import ListSubheader from "@mui/material/ListSubheader";
import { IconButton } from "@mui/material";
import FormatPainterPortal from "../components/Portals/FormatPainterPortal";
import Tooltip from "@mui/material/Tooltip";
import ButtonMui from "../../../components/buttons/buttonMui/buttonMui";
import { createStyle, getCharactersFromElementNode } from "../../services/textObjectFlows.js";
import { renderLines } from "../../services/textBoxRender.js";

const DEFAULT_FONT_SIZE = "11pt";

async function getPermission(callback) {
  try {
    const permission = await navigator.permissions.query({
      name: "clipboard-read",
    });
    if (permission.state === "denied") {
      return false;
    } else {
      const clipboardContents = await navigator.clipboard.read().then((val) => {
        for (const item of val) {
          item.getType("text/plain").then((valItem) => {
            valItem.text().then((text) => {
              callback(text);
            });
          });
        }
      });
    }
  } catch (error) {
    console.error(error.message);
  }
}

class TextObject2 extends React.Component {
  constructor(props) {
    super(props);

    this.headerFunc = {};
    this.rightMenuFunc = {};
    this.selectedStyle = {};
    this.selectedRowsStyle = {};
    this.oldStruct = null;

    this.struct = props.struct ? props.struct : [];
    this.currentKey = new Date().getTime();

    this.state = {
      style: props.style ? props.style : {},
      selectedStyle: {},
      selectedRowsStyle: {},
      range: null,
      anchorPoint: { x: 0, y: 0 },
      z: undefined,
      key: "div_" + new Date().getTime(),
      activeFormatPainter: false,
    };

    this.selectCapture = null;
    this.style = null;
    this.mainDiv = React.createRef();
    this.historyTag = new Date().getTime();
  }

  componentDidMount() {
    if (this.props.selected) {
      global.pasteListener = this.handlePaste;
    }
    this.props.onMinHeightChange(
      Number(getComputedStyle(this.mainDiv.current)["height"].slice(0, -2)) * window.panelScale,
      this.state.style.borderWidth ? parseInt(this.state.style.borderWidth.replace("px", "")) : 0,
    );
    if (this.props.scale.textObjs) {
      this.props.scale.textObjs.push({ updateTextObj: this.update });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("paste", (e) => this.handlePaste(e, false));
  }

  update = () => {
    this.forceUpdate();
  };

  getSelectedStyle = () => {
    return this.selectedStyle;
  };

  getSelectedRowStyle = () => {
    return this.selectedRowsStyle;
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    if (!this.props.activeFormatPainter) {
      this.currentKey = new Date().getTime();
    }

    if (nextProps.width != this.props.width) {
      this.props.onMinHeightChange(
        Number(getComputedStyle(this.mainDiv.current)["height"].slice(0, -2)) * window.panelScale,
        this.state.style.borderWidth ? parseInt(this.state.style.borderWidth.replace("px", "")) : 0,
      );
    }

    if (nextProps.multipleSeleced != this.props.multipleSeleced) {
      if (nextProps.multipleSeleced) {
        global.pasteListener = this.handlePaste;
      }
    }

    if (nextProps.selected != this.props.selected) {
      if (nextProps.selected) {
        global.pasteListener = this.handlePaste;
      } else {
        if (this.oldStruct && this.oldStruct != JSON.stringify(this.struct)) {
          this.props.onPropsChange(
            { struct: JSON.parse(JSON.stringify(this.struct)) },
            "props",
            true,
            {
              nextChange: JSON.parse(JSON.stringify(this.struct)),
              prevChange: JSON.parse(this.oldStruct),
            },
          );
        }
      }
    }

    if (!this.props.businessPlan) {

      console.log("scu tb",  this.props.scaleX != nextProps.scaleX ||
          this.props.scaleY != nextProps.scaleY ||
          this.props.width != nextProps.width ||
          this.props.selected != nextProps.selected ||
          this.props.height != nextProps.height ||
          this.props.left != nextProps.left ||
          this.props.top != nextProps.top ||
          this.props.rotateAngle != nextProps.rotateAngle ||
          this.state.key != nextState.key ||
          this.props.formatOptions != nextProps.formatOptions ||
          this.props.style != nextProps.style ||
          this.props.activeFormatPainter != nextProps.activeFormatPainter ||
          this.props.lockedAspect != nextProps.lockedAspect)

      return (
        this.props.scaleX != nextProps.scaleX ||
        this.props.scaleY != nextProps.scaleY ||
        this.props.width != nextProps.width ||
        this.props.selected != nextProps.selected ||
        this.props.height != nextProps.height ||
        this.props.left != nextProps.left ||
        this.props.top != nextProps.top ||
        this.props.rotateAngle != nextProps.rotateAngle ||
        this.state.key != nextState.key ||
        this.props.formatOptions != nextProps.formatOptions ||
        this.props.style != nextProps.style ||
        this.props.activeFormatPainter != nextProps.activeFormatPainter ||
        this.props.lockedAspect != nextProps.lockedAspect
      );
    } else {
      return (
        this.props.scaleX != nextProps.scaleX ||
        this.props.scaleY != nextProps.scaleY ||
        this.props.width != nextProps.width ||
        this.props.selected != nextProps.selected ||
        this.props.left != nextProps.left ||
        this.props.top != nextProps.top ||
        this.props.rotateAngle != nextProps.rotateAngle ||
        this.state.key != nextState.key ||
        this.props.formatOptions != nextProps.formatOptions ||
        this.props.style != nextProps.style ||
        this.props.activeFormatPainter != nextProps.activeFormatPainter ||
        this.props.lockedAspect != nextProps.lockedAspect
      );
    }
  };

  groupStruct = (oldStruct) => {
    this.forceUpdate();

    let structArr = [];
    let key = new Date().getTime();

    this.struct = JSON.parse(JSON.stringify(this.struct));

    this.struct.forEach((row, indexRow) => {
      let arr = [];
      let obj = { char: "", style: {} };
      row.characters.forEach((character, index) => {
        if (
          !obj.char ||
          (JSON.stringify(character.style) === JSON.stringify(row.characters[index - 1].style) &&
            character.sub === row.characters[index - 1].sub &&
            character.sup === row.characters[index - 1].sup &&
            character.href === row.characters[index - 1].href)
        ) {
          obj.char += character.char;
          obj.style = character.style;
          obj.sup = character.sup;
          obj.sub = character.sub;
          obj.href = character.href;
        }

        if (
          index === row.characters.length - 1 ||
          JSON.stringify(character.style) !== JSON.stringify(row.characters[index + 1].style) ||
          character.sub !== row.characters[index + 1].sub ||
          character.sup !== row.characters[index + 1].sup ||
          character.href !== row.characters[index + 1].href
        ) {
          arr.push(obj);
          obj = { char: "", style: {} };
        }
      });

      let style = {};
      if (row.hasOwnProperty("style")) {
        style = row.style;
      }

      if (row.bullet) {
        structArr.push({
          style: style,
          bullet: row.bullet,
          curType: row.curType,
          key: key,
          bulletType: row.bulletType,
          characters: arr,
        });
      } else {
        key = new Date().getTime() + indexRow;
        structArr.push({ style: style, characters: arr });
      }
    });

    this.shouldRestoreSelection = true;
    let selectedSpansStyle =
      this.selectCapture !== null
        ? this.getSelectedSpansStyle(
            this.selectCapture.startingParagraphIndex,
            this.selectCapture.endingParagraphIndex,
            this.selectCapture.startingSpanIndex,
            this.selectCapture.endingSpanIndex,
          )
        : [];
    let selectedRowsStyle =
      this.selectCapture !== null
        ? this.getSelectedRowsStyle(
            this.selectCapture.startingParagraphIndex,
            this.selectCapture.endingParagraphIndex,
          )
        : [];
    this.selectedStyle = selectedSpansStyle;
    this.selectedRowsStyle = selectedRowsStyle;
    this.struct = structArr;
    this.props.onPropsChange({ struct: structArr }, "props", true, {
      nextChange: structArr,
      prevChange: oldStruct,
    });
    this.headerFunc.renderHeader && this.headerFunc.renderHeader();

    setTimeout(this.changeMinHeight, 10);
  };
  changeMinHeight = () => {
    if (this.mainDiv && this.mainDiv.current) {
      this.props.onMinHeightChange(
        Number(getComputedStyle(this.mainDiv.current)["height"].slice(0, -2)) * window.panelScale,
        this.state.style.borderWidth ? parseInt(this.state.style.borderWidth.replace("px", "")) : 0,
      );
    }
  };

  buildStruct = (key = "key") => {
    let rows = this.mainDiv && this.mainDiv.current ? this.mainDiv.current.children : "";
    let arr = [];
    let rowLength = 0;
    let spanLength = 0;

    for (let i = 0; i < rows.length; i++) {
      if (rows[i].tagName !== "OL" && rows[i].tagName !== "UL") {
        let rowStyle = {};
        let elements = rows[i].children;

        if (rows[i].attributes.style) {
          rowStyle = createStyle(rows[i].attributes.style.nodeValue);
          if (rowStyle && rowStyle.backgroundColor) {
            delete rowStyle.backgroundColor;
          }
        } else {
          rowStyle = { lineHeight: 1 };
        }
        let rowObj = {
          startIndex: rowLength,
          length: "",
          style: rowStyle,
          characters: [],
        };
        let characterArr = [];

        if (elements && elements.length > 0) {
          for (let j = 0; j < elements.length; j++) {
            let sup = false;
            let sub = false;
            let href = "";
            if (elements[j].nodeName === "SUP") {
              sup = true;
            } else if (elements[j].nodeName === "SUB") {
              sub = true;
            } else if (elements[j].nodeName === "A") {
              href = elements[j].attributes["href"].value;
            }

            let elemObj = {
              startIndex: spanLength,
              length: "",
              sup: sup,
              sub: sub,
              href: href,
              char: "",
              style: {},
            };

            if (elements[j].innerText) {
              if (
                elements[j].nodeName === "SUP" ||
                elements[j].nodeName === "SUB" ||
                elements[j].nodeName === "A" ||
                elements[j].nodeName === "SPAN"
              ) {
                let style = elements[j].attributes.style
                  ? elements[j].attributes.style.nodeValue
                  : "";
                elemObj.char = elements[j].innerText;
                elemObj.style = style ? createStyle(style) : { fontSize: DEFAULT_FONT_SIZE };
                spanLength += elements[j].innerText.length;
                elemObj.length = spanLength - elemObj.startIndex;
                rowLength += elements[j].innerText.length;
                characterArr.push(elemObj);
              } else {
                if (elements[j].nodeName !== "BR") {
                  let style = rows[i].attributes.style ? rows[i].attributes.style.nodeValue : "";
                  elemObj.char = rows[i].innerText;
                  elemObj.style = style ? createStyle(style) : { fontSize: DEFAULT_FONT_SIZE };
                  spanLength += rows[i].innerText.length;
                  elemObj.length = spanLength - elemObj.startIndex;
                  rowLength += rows[i].innerText.length;
                  characterArr.push(elemObj);
                }
                break;
              }
            }
          }
        } else {
          let sup = false;
          let sub = false;
          let href = "";
          if (rows[i].nodeName === "SUP") {
            sup = true;
          } else if (rows[i].nodeName === "SUB") {
            sub = true;
          } else if (rows[i].nodeName === "A") {
            href = rows[i].attributes["href"].value;
          }

          let elemObj = {
            startIndex: spanLength,
            length: "",
            sup: sup,
            sub: sub,
            href: href,
            char: "",
            style: {},
          };

          if (rows[i].innerText) {
            let style = rows[i].attributes.style ? rows[i].attributes.style.nodeValue : "";
            elemObj.char = rows[i].innerText;
            elemObj.style = style ? createStyle(style) : { fontSize: DEFAULT_FONT_SIZE };
            spanLength += rows[i].innerText.length;
            elemObj.length = spanLength - elemObj.startIndex;
            rowLength += rows[i].innerText.length;
            characterArr.push(elemObj);
          }
        }

        rowObj.characters = characterArr;
        rowObj.length = rowLength - rowObj.startIndex;
        arr.push(rowObj);
      } else if (rows[i].tagName === "UL" || rows[i].tagName === "OL") {
        let newRows = rows[i].children;
        let key = "bullet_" + i;

        for (let k = 0; k < newRows.length; k++) {
          let rowStyle = {};
          let elements = newRows[k].childNodes;

          if (newRows[k].attributes.style) {
            rowStyle = createStyle(newRows[k].attributes.style.nodeValue);
          } else {
            rowStyle = { lineHeight: 1 };
          }

          let rowObj = {
            startIndex: rowLength,
            length: "",
            style: rowStyle,
            bullet: true,
            curType: rows[i].style.listStyleType
              ? rows[i].style.listStyleType
              : rows[i].tagName === "UL"
                ? "disc"
                : "decimal",
            bulletType: rows[i].tagName === "UL" ? "bullet" : "number",
            key: key,
            characters: [],
          };
          let {
            spanLength: calculatedSpanLength,
            rowLength: calculatedRowLength,
            characters,
          } = getCharactersFromElementNode(elements, rowLength, spanLength, rows[i]);

          spanLength = calculatedSpanLength;
          rowLength = calculatedRowLength;

          rowObj.characters = characters;
          rowObj.length = rowLength - rowObj.startIndex;
          arr.push(rowObj);
        }
      }
    }

    if (JSON.stringify(this.struct) != JSON.stringify(arr)) {
      this.struct = arr;

      if (key === "text") {
        this.props.onPropsChange({ struct: arr }, "props", true, false, () => {}, this.historyTag);
        this.oldStruct = JSON.stringify(this.struct);
      } else {
        this.props.onPropsChange({ struct: arr }, "props", false);
      }
      this.props.onMinHeightChange(
        Number(getComputedStyle(this.mainDiv.current)["height"].slice(0, -2)) * window.panelScale,
        this.state.style.borderWidth ? parseInt(this.state.style.borderWidth.replace("px", "")) : 0,
      );
      this.props.onChangedSize();
    }
  };

  getSelectedRows = () => {
    let selectedLines = [];

    if (this.selectCapture !== null) {
      for (
        let i = this.selectCapture.startingParagraphIndex;
        i <= this.selectCapture.endingParagraphIndex;
        i++
      ) {
        selectedLines.push(this.struct[i]);
      }
    }

    return selectedLines;
  };

  getSelectedObjects = () => {
    let selectedChars = [];

    if (
      this.selectCapture !== null &&
      (this.selectCapture.startingParagraphIndex !== this.selectCapture.endingParagraphIndex ||
        this.selectCapture.startingSpanIndex !== this.selectCapture.endingSpanIndex ||
        this.selectCapture.startingIndexOfStartingSpan !=
          this.selectCapture.endingIndexOfEndingSpan)
    ) {
      //if the starting char is not at the beggining of the span, then we need to break it
      if (
        this.selectCapture &&
        this.selectCapture.startingIndexOfStartingSpan &&
        this.selectCapture.startingIndexOfStartingSpan > 0
      ) {
        const allChars = this.struct[this.selectCapture.startingParagraphIndex].characters;
        const oldChars = allChars[this.selectCapture.startingSpanIndex];
        const newChars = JSON.parse(JSON.stringify(oldChars));
        oldChars.char = oldChars.char.substring(0, this.selectCapture.startingIndexOfStartingSpan);
        newChars.char = newChars.char.substring(this.selectCapture.startingIndexOfStartingSpan);

        this.struct[this.selectCapture.startingParagraphIndex].characters = [
          ...allChars.slice(0, this.selectCapture.startingSpanIndex + 1),
          newChars,
          ...allChars.slice(this.selectCapture.startingSpanIndex + 1),
        ];

        //check if the ending span is in the same paragraph then update its index
        if (this.selectCapture.startingParagraphIndex === this.selectCapture.endingParagraphIndex) {
          //check if the ending span is the same span, then also update the char index
          if (this.selectCapture.startingSpanIndex == this.selectCapture.endingSpanIndex) {
            this.selectCapture.endingIndexOfEndingSpan -=
              this.selectCapture.startingIndexOfStartingSpan;
          }

          this.selectCapture.endingSpanIndex++;
        }

        this.selectCapture.startingSpanIndex++;
        this.selectCapture.startingIndexOfStartingSpan = 0;
        this.forceUpdate();
      }

      //if the ending index is not at the end of the span, then we need to break it
      if (
        this.struct[this.selectCapture.endingParagraphIndex].characters[
          this.selectCapture.endingSpanIndex
        ].char.length -
          1 !=
        this.selectCapture.endingIndexOfEndingSpan
      ) {
        const allChars = this.struct[this.selectCapture.endingParagraphIndex].characters;
        const oldChars = allChars[this.selectCapture.endingSpanIndex];
        const newChars = JSON.parse(JSON.stringify(oldChars));
        oldChars.char = oldChars.char.substring(0, this.selectCapture.endingIndexOfEndingSpan);
        newChars.char = newChars.char.substring(this.selectCapture.endingIndexOfEndingSpan);

        this.struct[this.selectCapture.endingParagraphIndex].characters = [
          ...allChars.slice(0, this.selectCapture.endingSpanIndex + 1),
          newChars,
          ...allChars.slice(this.selectCapture.endingSpanIndex + 1),
        ];
      }

      if (this.selectCapture.startingParagraphIndex === this.selectCapture.endingParagraphIndex) {
        selectedChars = this.struct[this.selectCapture.startingParagraphIndex].characters.slice(
          this.selectCapture.startingSpanIndex,
          this.selectCapture.endingSpanIndex + 1,
        );
      } else {
        for (
          let i = this.selectCapture.startingParagraphIndex;
          i <= this.selectCapture.endingParagraphIndex;
          i++
        ) {
          if (i === this.selectCapture.startingParagraphIndex) {
            selectedChars = this.struct[i].characters.slice(this.selectCapture.startingSpanIndex);
          } else if (i === this.selectCapture.endingParagraphIndex) {
            const tmpArr = this.struct[i].characters.slice(
              0,
              this.selectCapture.endingSpanIndex + 1,
            );
            selectedChars = [...selectedChars, tmpArr];
          } else {
            const tmpArr2 = this.struct[i].characters;
            selectedChars = [...selectedChars, tmpArr2];
          }
        }
      }

      if (!selectedChars[0].char) {
        return [];
      }
    } else {
      this.struct.forEach((obj) => {
        selectedChars.push(obj.characters);
      });
    }

    return selectedChars;
  };

  findCommonValues = (obj1, obj2) => {
    var result = {};
    for (let key in obj1) {
      if (obj1[key] && obj1[key] === obj2[key]) result[key] = obj1[key];
    }
    return result;
  };

  handleKeyUp = (e) => {
    switch (e.keyCode) {
      case 13:
        this.refreshHistoryTag();
        this.handleSelectCapture("text");
        break;
      case 32:
        this.refreshHistoryTag();
        this.handleSelectCapture("text");
        break;
      default:
        this.handleSelectCapture("text");
    }
  };

  getSelectedSpansStyle = (spi, epi, ssi, esi, sioss, eioes) => {
    let styles = [];

    if (spi === epi && ssi === esi && sioss === eioes) {
      if (this.struct[spi]) {
        let newStyle =
          this.struct[spi].characters[ssi] && this.struct[spi].characters[ssi].style
            ? { ...this.struct[spi].characters[ssi].style }
            : {};
        newStyle && styles.push(newStyle);
      }
    } else if (spi === epi) {
      for (let a = ssi; a <= esi; a++) {
        this.struct[spi].characters[a] &&
          this.struct[spi].characters[a].style &&
          styles.push(this.struct[spi].characters[a].style);
      }
    } else {
      for (let i = spi; i <= epi; i++) {
        if (i === spi) {
          for (let j = ssi; j < this.struct[i].characters.length; j++) {
            this.struct[i].characters[j] &&
              this.struct[i].characters[j].style &&
              styles.push(this.struct[i].characters[j].style);
          }
        } else if (i === epi) {
          for (let j = 0; j <= esi; j++) {
            this.struct[i].characters[j] &&
              this.struct[i].characters[j].style &&
              styles.push(this.struct[i].characters[j].style);
          }
        } else {
          for (let j = 0; j < this.struct[i].characters.length; j++) {
            this.struct[i].characters[j] &&
              this.struct[i].characters[j].style &&
              styles.push(this.struct[i].characters[j].style);
          }
        }
      }
    }

    if (styles.length > 1) {
      let result = {};
      for (let k = 0; k < styles.length; k++) {
        if (k === 0) {
          result = this.findCommonValues(styles[k], styles[k + 1]);
          k++;
        } else {
          result = this.findCommonValues(result, styles[k]);
        }
      }
      return result;
    } else {
      return styles[0];
    }
  };

  getSelectedRowsStyle = (spi, epi) => {
    let styles = [];

    for (let i = spi; i <= epi; i++) {
      if (this.struct[i] && this.struct[i].style) {
        styles.push({
          ...this.struct[i].style,
          bullet: this.struct[i].bullet,
          bulletType: this.struct[i].bulletType,
          curType: this.struct[i].curType,
        });
      }
    }

    if (styles.length > 1) {
      let result = {};
      for (let k = 0; k < styles.length; k++) {
        if (k === 0) {
          result = this.findCommonValues(styles[k], styles[k + 1]);
          k++;
        } else {
          result = this.findCommonValues(result, styles[k]);
        }
      }
      return result;
    } else {
      return styles[0];
    }
  };

  getIndex = (element) => {
    const mainDiv = this.mainDiv.current;
    const mainDivElements = mainDiv ? mainDiv.children : [];

    let shouldBrake = false;
    let index = -1;

    for (let i = 0; i < mainDivElements.length; i++) {
      if (mainDivElements[i].nodeName === "P") {
        index++;
        if (mainDivElements[i] === element) {
          break;
        }
      } else {
        const curElements = mainDivElements[i].children;
        for (let j = 0; j < curElements.length; j++) {
          index++;
          if (curElements[j] === element) {
            shouldBrake = true;
            break;
          }
        }

        if (shouldBrake) break;
      }
    }

    return index;
  };

  handleRestoreSelection = () => {
    const startingParagraphIndex = this.selectCapture.startingParagraphIndex;
    const endingParagraphIndex = this.selectCapture.endingParagraphIndex;
    const startingSpanIndex = this.selectCapture.startingSpanIndex;
    const endingSpanIndex = this.selectCapture.endingSpanIndex;
    const startingIndexOfStartingSpan = this.selectCapture.startingIndexOfStartingSpan;
    const endingIndexOfEndingSpan = this.selectCapture.endingIndexOfEndingSpan;

    const div = this.mainDiv.current;

    const childs = [...div.children];
    let paragraphs = [];

    for (let i = 0; i < childs.length; i++) {
      if (childs[i].nodeName === "P") {
        paragraphs.push(childs[i]);
      } else if (childs[i].nodeName === "UL" || childs[i].nodeName === "OL") {
        for (let j = 0; j < childs[i].children.length; j++) {
          paragraphs.push(childs[i].children[j]);
        }
      }
    }

    const startingParagraph = paragraphs[startingParagraphIndex];
    const endingParagraph = paragraphs[endingParagraphIndex];
    const startingSpans = startingParagraph.children;
    const endingSpans = endingParagraph.children;
    const startingSpan =
      startingSpans[
        startingSpanIndex > startingSpans.length - 1 ? startingSpans.length - 1 : startingSpanIndex
      ];
    const endingSpan =
      endingSpans[
        endingSpanIndex > endingSpans.length - 1 ? endingSpans.length - 1 : endingSpanIndex
      ];

    let range = new Range();

    range.setStart(startingSpan.firstChild, startingIndexOfStartingSpan);
    range.setEnd(endingSpan.firstChild, endingIndexOfEndingSpan);

    document.getSelection().removeAllRanges();
    document.getSelection().addRange(range);
  };

  buildStyles = (styles) => {
    let styleString = "";

    Object.keys(styles).forEach((key) => {
      let split = key.split(/(?=[A-Z])/);
      let parsedKey = "";

      split.forEach((word, index) => {
        if (index === 0) {
          parsedKey = word.toLowerCase();
        } else {
          parsedKey += "-" + word.toLowerCase();
        }
      });

      styleString += " " + parsedKey + ": " + styles[key] + ";";
    });

    return styleString;
  };

  handleSelectCapture = (key = "select") => {
    this.buildStruct(key);

    if (
      (this.struct.length === 0 && this.mainDiv && this.mainDiv.current) ||
      (this.mainDiv &&
        this.mainDiv.current &&
        this.mainDiv.current.innerHTML.indexOf("<span") === -1 &&
        this.mainDiv.current.innerHTML.indexOf("<a") === -1)
    ) {
      let stringStyle = "";

      if (this.selectedStyle) {
        this.notChangeStyle = true;
        stringStyle = this.buildStyles(this.selectedStyle);
      }

      this.mainDiv.current.innerHTML = `<p  style="font-size: ${DEFAULT_FONT_SIZE}; margin: 0; height: auto;"><span style="${stringStyle}"><br></span></p>`;
      this.buildStruct("select");
      this.forceUpdate();
      setTimeout(() => {
        this.mainDiv.current.focus();
      }, 10);
    }

    const sel = window.getSelection();

    if (sel.rangeCount) {
      const range = sel.getRangeAt(0);
      let endingSpan;
      let endingIndexOfEndingSpan;

      if (range.endContainer.nodeName && range.endContainer.nodeName === "P") {
        let index = this.getIndex(range.endContainer);
        let paragraph = this.mainDiv.current.children[index - 1];
        endingSpan = paragraph.children[paragraph.children.length - 1];
        endingIndexOfEndingSpan = endingSpan.innerText.length;
      } else {
        endingSpan = range.endContainer.parentElement;
        endingIndexOfEndingSpan = range.endOffset; //ako vo spanot ima poveke chars od koj char e
      }
      const startingSpan = range.startContainer.parentElement;
      const startingIndexOfStartingSpan = range.startOffset; //ako vo spanot ima poveke chars od koj char e

      const text = sel.toString();

      const startingSpanIndex = Array.prototype.indexOf.call(
        startingSpan.parentElement.children,
        startingSpan,
      );
      const startingParagraph = startingSpan.parentElement;
      let startingParagraphIndex = this.getIndex(startingParagraph);

      const endingSpanIndex = Array.prototype.indexOf.call(
        endingSpan.parentElement.children,
        endingSpan,
      );

      const endingParagraph = endingSpan.parentElement;

      const endingParagraphIndex = this.getIndex(endingParagraph);

      if (
        this.props.activeFormatPainter &&
        startingParagraphIndex === endingParagraphIndex &&
        startingSpanIndex === endingSpanIndex &&
        startingIndexOfStartingSpan === endingIndexOfEndingSpan
      ) {
        this.struct[startingParagraphIndex].characters.forEach((span) => {
          let tmpObject = Object.assign({}, this.props.formatPainterStyle);
          tmpObject.borderStyle = undefined;
          tmpObject.borderWeight = undefined;
          tmpObject.borderColor = undefined;
          span.style = tmpObject;
        });
        this.handleActiveFormatPainter(false);
      } else if (this.props.activeFormatPainter) {
        this.selectCapture = {
          startingParagraphIndex,
          startingSpanIndex,
          endingParagraphIndex,
          endingSpanIndex,
          startingIndexOfStartingSpan,
          endingIndexOfEndingSpan,
        };

        let selectedObjects = this.getSelectedObjects();

        selectedObjects.forEach((p) => {
          if (Object.prototype.toString.call(p) == "[object Array]") {
            p.forEach((obj) => {
              let tmpObject = Object.assign({}, this.props.formatPainterStyle);
              tmpObject.borderStyle = undefined;
              tmpObject.borderWeight = undefined;
              tmpObject.borderColor = undefined;
              obj.style = tmpObject;
            });
          } else {
            let tmpObject = Object.assign({}, this.props.formatPainterStyle);
            tmpObject.borderStyle = undefined;
            tmpObject.borderWeight = undefined;
            tmpObject.borderColor = undefined;
            p.style = tmpObject;
          }
        });

        this.handleActiveFormatPainter(false);
      }

      this.selectCapture = {
        startingParagraphIndex,
        startingSpanIndex,
        endingParagraphIndex,
        endingSpanIndex,
        startingIndexOfStartingSpan,
        endingIndexOfEndingSpan,
      };

      if (!this.notChangeStyle) {
        this.selectedStyle = this.getSelectedSpansStyle(
          startingParagraphIndex,
          endingParagraphIndex,
          startingSpanIndex,
          endingSpanIndex,
          startingIndexOfStartingSpan,
          endingIndexOfEndingSpan,
        );
        this.selectedRowsStyle = this.getSelectedRowsStyle(
          startingParagraphIndex,
          endingParagraphIndex,
        );
      }

      this.notChangeStyle = false;

      this.state.range = range;
      this.headerFunc.renderHeader && this.headerFunc.renderHeader();

      if (this.initialRender) {
        this.shouldRestoreSelection = true;
        this.initialRender = false;
      }
    } else {
      this.selectCapture = null;
    }

    document.removeEventListener("mouseup", this.handleSelectCapture);
  };

  addSymbol = (symbol) => {
    this.buildStruct();

    let oldStruct = JSON.parse(JSON.stringify(this.struct));

    if (this.selectCapture.startingParagraphIndex === this.selectCapture.endingParagraphIndex) {
      if (this.selectCapture.startingSpanIndex === this.selectCapture.endingSpanIndex) {
        let curText =
          this.struct[this.selectCapture.startingParagraphIndex].characters[
            this.selectCapture.startingSpanIndex
          ].char;
        let newText =
          curText.slice(0, this.selectCapture.startingIndexOfStartingSpan) +
          symbol +
          curText.slice(this.selectCapture.endingIndexOfEndingSpan);
        this.struct[this.selectCapture.startingParagraphIndex].characters[
          this.selectCapture.startingSpanIndex
        ].char = newText;
        this.forceUpdate();
      } else {
        for (
          let i = this.selectCapture.startingSpanIndex;
          i <= this.selectCapture.endingSpanIndex;
          i++
        ) {
          if (i === this.selectCapture.startingSpanIndex) {
            let text = this.struct[this.selectCapture.startingParagraphIndex].characters[i].char;
            text = text.slice(0, this.selectCapture.startingIndexOfStartingSpan);
            text = text + symbol;
            this.struct[this.selectCapture.startingParagraphIndex].characters[i].char = text;
          } else if (i === this.selectCapture.endingSpanIndex) {
            let text2 = this.struct[this.selectCapture.startingParagraphIndex].characters[i].char;
            text2 = text2.substring(this.selectCapture.endingIndexOfEndingSpan);
            this.struct[this.selectCapture.startingParagraphIndex].characters[i].char = text2;
          } else {
            this.struct[this.selectCapture.startingParagraphIndex].characters[i].char = "";
          }
        }
        this.forceUpdate();
      }
    } else {
      for (
        let j = this.selectCapture.startingParagraphIndex;
        j <= this.selectCapture.endingParagraphIndex;
        j++
      ) {
        if (j === this.selectCapture.startingParagraphIndex) {
          for (
            let k = this.selectCapture.startingSpanIndex;
            k < this.struct[j].characters.length;
            k++
          ) {
            if (k === this.selectCapture.startingSpanIndex) {
              let text = this.struct[j].characters[k].char;
              text = text.slice(0, this.selectCapture.startingIndexOfStartingSpan);
              text = text + symbol;
              this.struct[j].characters[k].char = text;
            } else {
              this.struct[j].characters[k].char = "";
            }
          }
        } else if (j === this.selectCapture.endingParagraphIndex) {
          for (let o = 0; o <= this.selectCapture.endingSpanIndex; o++) {
            if (o === this.selectCapture.endingSpanIndex) {
              let text2 = this.struct[j].characters[o].char;
              text2 = text2.substring(this.selectCapture.endingIndexOfEndingSpan);
              this.struct[j].characters[o].char = text2;
            } else {
              this.struct[j].characters[o].char = "";
            }
          }
        } else {
          for (let c = 0; c < this.struct[j].characters.length; c++) {
            this.struct[j].characters[c].char = "";
          }
        }
      }
      this.forceUpdate();
    }

    this.groupStruct(oldStruct);
  };

  setRow(index, row) {
    this.struct[index] = row;
  }

  handleStyleChanged = (changedStyle) => {
    const newStyle = {};
    Object.assign(newStyle, this.state.style);
    Object.assign(newStyle, changedStyle);

    this.props.onPropsChange({ style: newStyle });
    this.setState({ style: newStyle });
    this.forceUpdate();

    setTimeout(this.changeMinHeight, 20);
  };

  handleStyleRemoved = (styles) => {
    const newStyle = {};
    Object.assign(newStyle, this.state.style);
    styles.forEach((style) => {
      if (newStyle[style]) {
        delete newStyle[style];
      }
    });

    this.setState({ style: newStyle });
  };

  handleContextMenu = (e) => {
    if (!this.props.disableEdit && !this.props.moreThanOneSelected) {
      e.preventDefault();
      e.stopPropagation();
      this.shouldRestoreSelection = true;
      this.setState({
        menuProps: true,
        anchorPoint: { x: e.pageX, y: e.pageY },
      });
      this.props.onSelect();

      this.forceUpdate();
    }
  };
  handleContextMenuClose = () => {
    this.setState({ anchorPoint: { x: 0, y: 0 }, menuProps: undefined });
    this.forceUpdate();
  };

  handleActiveFormatPainter = (style = true) => {
    if (style) {
      this.props.handleActiveFormatPainter(this.selectedStyle);
    } else {
      this.props.handleActiveFormatPainter();
    }
  };

  handleMouseDown = (e) => {
    if (!this.props.selected) {
      this.initialRender = true;
    }

    if (!this.props.disableEdit && !this.props.scale.enabled && !this.props.publishMode) {
      this.refreshHistoryTag();
      document.addEventListener("mouseup", this.handleSelectCapture);
    }
  };

  checkIfShouldRestore = () => {
    if (this.selectCapture != null) {
      let selectedSpansStyle = this.getSelectedSpansStyle(
        this.selectCapture.startingParagraphIndex,
        this.selectCapture.endingParagraphIndex,
        this.selectCapture.startingSpanIndex,
        this.selectCapture.endingSpanIndex,
        this.selectCapture.startingIndexOfStartingSpan,
        this.selectCapture.endingIndexOfEndingSpan,
      );
      let selectedRowsStyle = this.getSelectedRowsStyle(
        this.selectCapture.startingParagraphIndex,
        this.selectCapture.endingParagraphIndex,
      );

      // Object.assign(this.selectedStyle, selectedSpansStyle)

      this.selectedStyle = selectedSpansStyle;
      this.selectedRowsStyle = selectedRowsStyle;

      // const styleTMP = Object.assign({}, obj.style)
      // styleTMP[key] = value;
      // obj.style = styleTMP;
      // this.selectedStyle = styleTMP;
      // this.headerFunc.renderHeader()
      // obj.style = Object.keys(obj.style).sort().reduce(function (result, key) {
      //     result[key] = obj.style[key];
      //     return result;
      // }, {});

      this.forceUpdate();

      setTimeout(this.handleRestoreSelection, 10);
    }
    this.shouldRestoreSelection = false;
  };

  handlePasteAwait = (data) => {
    this.refreshHistoryTag();

    let startIndex = this.selectCapture.startingIndexOfStartingSpan;
    let endIndex = this.selectCapture.endingIndexOfEndingSpan;
    let startingSpan = this.selectCapture.startingSpanIndex;
    let endingSpan = this.selectCapture.endingSpanIndex;
    let startingParagraph = this.selectCapture.startingParagraphIndex;
    let endingParagraph = this.selectCapture.endingParagraphIndex;

    if (
      startingParagraph === endingParagraph &&
      startingSpan === endingSpan &&
      startIndex === endIndex
    ) {
      let text = this.struct[startingParagraph].characters[startingSpan].char;

      let firstPart = text.substring(0, startIndex);
      let secondPart = text.substring(startIndex, text.length);

      this.struct[startingParagraph].characters[startingSpan].char = firstPart + data + secondPart;
      this.forceUpdate();
      this.props.onPropsChange({ struct: arr }, "props", true, false, () => {}, this.historyTag);
      this.oldStruct = JSON.stringify(this.struct);

      setTimeout(() => {
        let span = this.mainDiv.current.children[startingParagraph].children[startingSpan];

        let range = new Range();

        range.setStart(span.childNodes[0], firstPart.length + data.length);
        range.setEnd(span.childNodes[0], firstPart.length + data.length);

        document.getSelection().removeAllRanges();
        document.getSelection().addRange(range);
      }, 50);
    } else {
      if (startingParagraph !== endingParagraph) {
        for (let i = startingParagraph; i <= endingParagraph; i++) {
          if (i === startingParagraph) {
            for (let j = startingSpan; j < this.struct[i].characters.length; j++) {
              if (j === startingSpan) {
                let sratingText = this.struct[i].characters[j].char.substring(0, startIndex);
                this.struct[i].characters[j].char = sratingText;
              } else {
                this.struct[i].characters[j].char = "";
              }
            }
          } else if (i === endingParagraph) {
            for (let j = 0; j <= endingSpan; j++) {
              if (j === endingSpan) {
                let endingText = this.struct[i].characters[j].char.substring(
                  endIndex,
                  this.struct[i].characters[j].length,
                );
                this.struct[i].characters[j].char = endingText;
              } else {
                this.struct[i].characters[j].char = "";
              }
            }
          } else {
            for (let j = 0; j < this.struct[i].characters.length; j++) {
              this.struct[i].characters[j].char = "";
            }
          }
        }

        this.struct[startingParagraph].characters[startingSpan].char += data;
        this.forceUpdate();
        this.props.onPropsChange({ struct: arr }, "props", true, false, () => {}, this.historyTag);
        this.oldStruct = JSON.stringify(this.struct);
      } else {
        if (startingSpan !== endingSpan) {
          for (let j = startingSpan; j < this.struct[startingParagraph].characters.length; j++) {
            if (j === startingSpan) {
              let startText = this.struct[startingParagraph].characters[j].char.substring(
                0,
                startIndex,
              );
              this.struct[startingParagraph].characters[j].char = startText;
            } else if (j === endingSpan) {
              let endText = this.struct[startingParagraph].characters[j].char.substring(
                endIndex,
                this.struct[startingParagraph].characters[j].length,
              );
              this.struct[startingParagraph].characters[j].char = endText;
            } else {
              this.struct[startingParagraph].characters[j].char = "";
            }
          }

          this.struct[startingParagraph].characters[startingSpan].char += data;
          this.forceUpdate();
          this.props.onPropsChange(
            { struct: arr },
            "props",
            true,
            false,
            () => {},
            this.historyTag,
          );
          this.oldStruct = JSON.stringify(this.struct);
        } else {
          let start = this.struct[startingParagraph].characters[startingSpan].char.substring(
            0,
            startIndex,
          );
          let end = this.struct[startingParagraph].characters[startingSpan].char.substring(
            endIndex,
            this.struct[startingParagraph].characters[startingSpan].length,
          );
          this.struct[startingParagraph].characters[startingSpan].char = start + data + end;
          this.forceUpdate();
          this.props.onPropsChange(
            { struct: arr },
            "props",
            true,
            false,
            () => {},
            this.historyTag,
          );
          this.oldStruct = JSON.stringify(this.struct);
        }
      }
    }
  };

  handlePaste = (e, isSynthetic) => {
    if (!isSynthetic) {
      this.handleSelectCapture();

      this.refreshHistoryTag();

      e.preventDefault();
      e.stopPropagation();

      if (!e.clipboardData.getData("text")) {
        return;
      }

      if (this.props.checkIfSlideObjects(e.clipboardData.getData("text"))) {
        this.props.slideComponentPaste(e, true);
        return;
      }

      const sel = window.getSelection();

      if (!sel.focusNode || sel.focusNode.nodeName === "DIV") {
        if (this.props.moreThanOneSelected) {
          this.props.slideComponentPaste(e, true);
        } else {
          let text = e.clipboardData.getData("text");
          this.struct = [
            {
              startIndex: 0,
              length: text.length,
              style: {
                margin: "0px",
                height: "auto",
                marginBlock: "unset",
                fontSize: DEFAULT_FONT_SIZE,
              },
              characters: [
                {
                  startIndex: 0,
                  length: text.length,
                  sup: false,
                  sub: false,
                  href: "",
                  char: text,
                  style: {
                    fontSize: DEFAULT_FONT_SIZE,
                  },
                },
              ],
            },
          ];
          this.forceUpdate();
          this.props.onPropsChange(
            { struct: this.struct },
            "props",
            true,
            false,
            () => {},
            this.historyTag,
          );
          this.oldStruct = JSON.stringify(this.struct);
        }
        return;
      }

      e.stopPropagation();
      if (e.target.nodeName === "SPAN" || e.target.nodeName === "BR") {
        e.preventDefault();
        let isBr = false;
        if (e.target.nodeName === "BR") {
          isBr = true;
        }

        let startIndex = this.selectCapture.startingIndexOfStartingSpan;
        let endIndex = this.selectCapture.endingIndexOfEndingSpan;
        let startingSpan = this.selectCapture.startingSpanIndex;
        let endingSpan = this.selectCapture.endingSpanIndex;
        let startingParagraph = this.selectCapture.startingParagraphIndex;
        let endingParagraph = this.selectCapture.endingParagraphIndex;

        if (
          startingParagraph === endingParagraph &&
          startingSpan === endingSpan &&
          startIndex === endIndex
        ) {
          let text = !isBr ? e.target.innerText : e.target.parentElement.innerText;

          let firstPart = text.substring(0, startIndex);
          let secondPart = text.substring(startIndex, text.length);
          let element;
          let length;

          if (!isBr) {
            e.target.innerText = firstPart + e.clipboardData.getData("text") + secondPart;
            element = e.target.childNodes[0];
            length = firstPart.length + e.clipboardData.getData("text").length;
          } else {
            e.target.parentElement.innerText =
              firstPart + e.clipboardData.getData("text") + secondPart;
            element = this.mainDiv.current.children[startingParagraph].firstChild.childNodes[0];
            length = e.clipboardData.getData("text").length;
          }

          let range = new Range();

          range.setStart(element, length);
          range.setEnd(element, length);

          document.getSelection().removeAllRanges();
          document.getSelection().addRange(range);

          this.buildStruct("text");
          this.props.onMinHeightChange(
            Number(getComputedStyle(this.mainDiv.current)["height"].slice(0, -2)) *
              window.panelScale,
            this.state.style.borderWidth
              ? parseInt(this.state.style.borderWidth.replace("px", ""))
              : 0,
          );
        } else {
          if (startingParagraph !== endingParagraph) {
            for (let i = startingParagraph; i <= endingParagraph; i++) {
              if (i === startingParagraph) {
                for (let j = startingSpan; j < this.struct[i].characters.length; j++) {
                  if (j === startingSpan) {
                    let sratingText = this.struct[i].characters[j].char.substring(0, startIndex);
                    this.struct[i].characters[j].char = sratingText;
                  } else {
                    this.struct[i].characters[j].char = "";
                  }
                }
              } else if (i === endingParagraph) {
                for (let j = 0; j <= endingSpan; j++) {
                  if (j === endingSpan) {
                    let endingText = this.struct[i].characters[j].char.substring(
                      endIndex,
                      this.struct[i].characters[j].length,
                    );
                    this.struct[i].characters[j].char = endingText;
                  } else {
                    this.struct[i].characters[j].char = "";
                  }
                }
              } else {
                for (let j = 0; j < this.struct[i].characters.length; j++) {
                  this.struct[i].characters[j].char = "";
                }
              }
            }

            this.struct[startingParagraph].characters[startingSpan].char +=
              e.clipboardData.getData("text");
            this.forceUpdate();
            this.props.onPropsChange(
              { struct: this.struct },
              "props",
              true,
              false,
              () => {},
              this.historyTag,
            );
            this.oldStruct = JSON.stringify(this.struct);
          } else {
            if (startingSpan !== endingSpan) {
              for (
                let j = startingSpan;
                j < this.struct[startingParagraph].characters.length;
                j++
              ) {
                if (j === startingSpan) {
                  let startText = this.struct[startingParagraph].characters[j].char.substring(
                    0,
                    startIndex,
                  );
                  this.struct[startingParagraph].characters[j].char = startText;
                } else if (j === endingSpan) {
                  let endText = this.struct[startingParagraph].characters[j].char.substring(
                    endIndex,
                    this.struct[startingParagraph].characters[j].length,
                  );
                  this.struct[startingParagraph].characters[j].char = endText;
                } else {
                  this.struct[startingParagraph].characters[j].char = "";
                }
              }

              this.struct[startingParagraph].characters[startingSpan].char +=
                e.clipboardData.getData("text");
              this.forceUpdate();
              this.props.onPropsChange(
                { struct: this.struct },
                "props",
                true,
                false,
                () => {},
                this.historyTag,
              );
              this.oldStruct = JSON.stringify(this.struct);
            } else {
              let start = this.struct[startingParagraph].characters[startingSpan].char.substring(
                0,
                startIndex,
              );
              let end = this.struct[startingParagraph].characters[startingSpan].char.substring(
                endIndex,
                this.struct[startingParagraph].characters[startingSpan].length,
              );
              this.struct[startingParagraph].characters[startingSpan].char =
                start + e.clipboardData.getData("text") + end;
              this.forceUpdate();
              this.props.onPropsChange(
                { struct: this.struct },
                "props",
                true,
                false,
                () => {},
                this.historyTag,
              );
              this.oldStruct = JSON.stringify(this.struct);
            }
          }
        }
      } else {
        setTimeout(() => {
          this.buildStruct();
        }, 30);
      }
    } else {
      let permission = getPermission(this.handlePasteAwait);
    }
  };

  setStyle = (key, value, check) => {
    if (this.oldStruct && this.oldStruct !== JSON.stringify(this.struct)) {
      this.props.onPropsChange({ struct: this.struct }, "props", true, {
        nextChange: this.struct,
        prevChange: JSON.parse(this.oldStruct),
      });
    }

    let oldStruct = JSON.parse(JSON.stringify(this.struct));

    let selectedObject = this.getSelectedObjects();

    let elementCounter = 0;
    let styleCounter = 0;

    if (check) {
      selectedObject.forEach((p) => {
        if (Object.prototype.toString.call(p) == "[object Array]") {
          p.forEach((obj) => {
            elementCounter++;
            if (obj.style.hasOwnProperty(key) && obj.style[key] === value) {
              styleCounter++;
            }
          });
        } else {
          elementCounter++;
          if (p.style.hasOwnProperty(key) && p.style[key] === value) {
            styleCounter++;
          }
        }
      });
    }

    if (styleCounter !== elementCounter || !check) {
      selectedObject.forEach((p) => {
        if (Object.prototype.toString.call(p) == "[object Array]") {
          p.forEach((obj) => {
            if (!obj.style.hasOwnProperty(key) || obj.style[key] !== value) {
              const styleTMP = Object.assign({}, obj.style);
              styleTMP[key] = value;
              obj.style = styleTMP;
              this.selectedStyle = styleTMP;
              this.headerFunc.renderHeader();
              obj.style = Object.keys(obj.style)
                .sort()
                .reduce(function (result, key) {
                  result[key] = obj.style[key];
                  return result;
                }, {});
            }
          });
        } else {
          if (!p.style.hasOwnProperty(key) || p.style[key] !== value) {
            const styleTMP = Object.assign({}, p.style);
            styleTMP[key] = value;
            p.style = styleTMP;
            this.selectedStyle = styleTMP;
            this.headerFunc.renderHeader();
            p.style = Object.keys(p.style)
              .sort()
              .reduce(function (result, key) {
                result[key] = p.style[key];
                return result;
              }, {});
          }
        }
      });
    } else {
      selectedObject.forEach((p) => {
        if (Object.prototype.toString.call(p) == "[object Array]") {
          p.forEach((obj) => {
            const styleTMP = Object.assign({}, obj.style);
            delete styleTMP[key];
            obj.style = styleTMP;
            this.selectedStyle = styleTMP;
            this.headerFunc.renderHeader();
            obj.style = Object.keys(obj.style)
              .sort()
              .reduce(function (result, key) {
                result[key] = obj.style[key];
                return result;
              }, {});
          });
        } else {
          const styleTMP = Object.assign({}, p.style);
          delete styleTMP[key];
          p.style = styleTMP;
          this.selectedStyle = styleTMP;
          this.headerFunc.renderHeader();
          p.style = Object.keys(p.style)
            .sort()
            .reduce(function (result, key) {
              result[key] = p.style[key];
              return result;
            }, {});
        }
      });
    }

    this.groupStruct(oldStruct);
  };

  handleKeyDown = (event) => {
    switch (event.keyCode) {
      case 73:
        if (event.ctrlKey) {
          event.stopPropagation();
          event.preventDefault();
          this.setStyle("fontStyle", "italic", true);
        }
        break;
      case 66:
        if (event.ctrlKey) {
          event.stopPropagation();
          event.preventDefault();
          this.setStyle("fontWeight", "bold", true);
        }
        break;
      case 85:
        if (event.ctrlKey) {
          event.stopPropagation();
          event.preventDefault();
          this.setStyle("textDecoration", "underline", true);
        }
        break;
      case 90:
        if (event.ctrlKey) {
          // if (this.innerHtml !== event.target.innerHTML) {
          //     event.stopPropagation()
          // } else {
          //     event.preventDefault();
          // }
          // e.stopPropagation();
        }
        break;
      case 13:
        if (event.shiftKey) {
          event.preventDefault();
          event.stopPropagation();

          // let newEvent = event.nativeEvent;
          // newEvent.shiftKey = false;
          // newEvent.timeStamp = new Date().getTime();

          let newEvent = new Event("keydown", {
            keyCode: 13,
            shiftKey: false,
            code: "Enter",
            key: "Enter",
            which: 13,
          });
          if (this.mainDiv.current) {
            this.mainDiv.current.dispatchEvent(newEvent);
          }
        }
      default:
        event.stopPropagation();
    }
  };

  setRestoreSelection = () => {
    this.shouldRestoreSelection = true;
  };

  handleCut = () => {
    if (
      this.selectCapture.startingParagraphIndex === this.selectCapture.endingParagraphIndex &&
      this.selectCapture.startingSpanIndex === this.selectCapture.endingSpanIndex &&
      this.selectCapture.startingIndexOfStartingSpan === this.selectCapture.endingIndexOfEndingSpan
    ) {
      this.props.cutObjects();
    } else {
      let selectedObjects = this.getSelectedObjects();
      let text = "";

      selectedObjects.forEach((p) => {
        if (Object.prototype.toString.call(p) == "[object Array]") {
          p.forEach((obj) => {
            text += obj.char;
            obj.char = "";
          });
        } else {
          text += p.char;
          p.char = "";
        }
      });

      navigator.clipboard.writeText(text);
    }
  };

  handleCopy = () => {
    if (
      this.selectCapture !== null &&
      (this.selectCapture.startingParagraphIndex !== this.selectCapture.endingParagraphIndex ||
        this.selectCapture.startingSpanIndex !== this.selectCapture.endingSpanIndex ||
        this.selectCapture.startingIndexOfStartingSpan !=
          this.selectCapture.endingIndexOfEndingSpan)
    ) {
      let selectedObjects = this.getSelectedObjects();
      let text = "";

      selectedObjects.forEach((p) => {
        if (Object.prototype.toString.call(p) == "[object Array]") {
          p.forEach((obj) => {
            text += obj.char;
          });
        } else {
          text += p.char;
        }
      });

      navigator.clipboard.writeText(text);
    } else {
      this.props.copyObjects();
    }
  };

  setInnerHtml = () => {
    if (this.mainDiv && this.mainDiv.current) {
      this.innerHtml = this.mainDiv.current.innerHTML;
    }
  };

  getOldStruct = () => {
    let oldStruct = JSON.parse(JSON.stringify(this.struct));
    return oldStruct;
  };

  refreshHistoryTag = () => {
    this.historyTag = new Date().getTime();
  };

  render() {
    if (this.shouldRestoreSelection) {
      this.checkIfShouldRestore();
    }

    console.log("test tb", this.props.selected, this.props.formatOptions);

    setTimeout(this.setInnerHtml, 20);

    return (
      <Fragment>
        {this.state.menuProps && (
          <SCContextMenuPortal>
            <ContextMenu
              handleClose={() => this.handleContextMenuClose()}
              anchorPoint={this.state.anchorPoint}
              menuProps={this.state.menuProps}
              onDelete={this.props.onDelete}
            >
              <MenuItem className="cm_icon_helper" onClick={this.handleCut}>
                <Cut className="cm_icon" />
                <span className="cm_btn_name">Cut</span>
                <span className="cm_helper_text">Ctrl+X</span>
              </MenuItem>
              <MenuItem className="cm_icon_helper" onClick={this.handleCopy}>
                <Copy className="cm_icon" />
                <span className="cm_btn_name">Copy</span>
                <span className="cm_helper_text">Ctrl+C</span>
              </MenuItem>
              <MenuItem className="cm_icon_helper" onClick={(e) => this.handlePaste(e, true)}>
                <Paste className="cm_icon" />
                <span className="cm_btn_name">Paste</span>
                <span className="cm_helper_text">Ctrl+V</span>
              </MenuItem>
              <MenuItem className="cm_icon_helper" onClick={this.props.handleDuplicateObject}>
                <Paste className="cm_icon" />
                <span className="cm_btn_name">Duplicate</span>
                <span className="cm_helper_text">Ctrl+D</span>
              </MenuItem>
              {this.props.isGrouped && (
                <MenuItem
                  className="cm_icon_helper"
                  onClick={() => this.props.ungroupObjects(this.props.groupKey)}
                >
                  <span className="cm_btn_name">Ungroup Objects</span>
                </MenuItem>
              )}

              <MenuItem onClick={this.props.onDelete}>
                <span className="cm_btn_name">Delete</span>
              </MenuItem>
              <div className="cm_divider" />
              <OrderSubMenu
                sentToFront={this.props.sentToFront}
                sentToBack={this.props.sentToBack}
                bringForword={this.props.bringForword}
                bringBackward={this.props.bringBackward}
              />
              <CenterOnPageSubMenu
                {...this.props}
                horizontally={() =>
                  this.props.updatePosition(640 - this.props.width / 2, this.props.top)
                }
                vertically={() =>
                  this.props.updatePosition(this.props.left, 360 - this.props.height / 2)
                }
              />
              <RotateSubMenu
                height={this.props.height}
                width={this.props.width}
                rotateAngle={this.props.rotateAngle}
                scaleX={this.props.scaleX}
                scaleY={this.props.scaleY}
                onChange={this.props.onChange}
              />
              <CapitalizationSubMenu
                onChange={this.handleStyleChanged}
                style={this.state.style}
                buildStuct={this.buildStruct}
                getSelectedObject={this.getSelectedObjects}
                groupStruct={this.groupStruct}
              />
              <div className="cm_divider" />
              <SubMenu className="cm_sub_small" label="Link">
                <SearchInput
                  handleLink={() => this.handleContextMenuClose()}
                  getSelectedObject={this.getSelectedObjects}
                  groupStruct={this.groupStruct}
                />
              </SubMenu>
              <MenuItem onClick={this.props.toggleFormatOptions}>
                <span className={"cm_btn_name"}>Format options</span>
              </MenuItem>
            </ContextMenu>
          </SCContextMenuPortal>
        )}

        {this.props.selected && !this.props.multipleSelected && (
          <FormatPainterPortal>
            <ButtonMui
              tooltipText={"Format Painter"}
              onClick={this.handleActiveFormatPainter}
              variant={"text"}
              isIconButton
              roundnessType={"squared"}
              color={"black"}
              label={<FormatColor active={this.props.activeFormatPainter} />}
            />
          </FormatPainterPortal>
        )}

        {this.props.selected && !this.props.multipleSelected && (
          <div
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
          >
            <HeaderTextEditor
              visible={true}
              onValueChange={this.props.onValueChange}
              headerFunc={this.headerFunc}
              value={this.props.value}
              setRestoreSelection={this.setRestoreSelection}
              getSelectedStyle={this.getSelectedStyle}
              getSelectedRowsStyle={this.getSelectedRowStyle}
              style={this.selectedStyle !== null ? this.selectedStyle : {}}
              selectedRowsStyle={this.selectedRowsStyle}
              tbStyle={this.state.style}
              getOldStruct={this.getOldStruct}
              onChange={this.handleStyleChanged}
              restoreSelection={this.handleRestoreSelection}
              defaultFontSize={DEFAULT_FONT_SIZE}
              onStyleRemove={this.handleStyleRemoved}
              width={this.props.width}
              getSelectedObject={this.getSelectedObjects}
              getSelectedRows={this.getSelectedRows}
              groupStruct={this.groupStruct}
              setRow={this.setRow}
              activeFormatPainter={this.state.activeFormatPainter}
              handleActiveFormatPainter={this.handleActiveFormatPainter}
              toggleFormatOptions={this.props.toggleFormatOptions}
              setStyle={this.setStyle}
              onPreview={this.props.onPreview}
            />
          </div>
        )}

        {this.props.formatOptions && this.props.selected && (
          <RightMenuPortal>
            <div className={"right_menu_mask"}>
              <List
                style={{
                  paddingLeft: "0",
                  position: "absolute",
                  width: "100%",
                  height: "auto",
                  maxWidth: 250,
                  bgcolor: "background.paper",
                  paddingBottom: "0",
                  paddingTop: "0",
                  // overflow:'scroll',
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 700,
                          fontSize: "16px",
                          textAlign: "left",
                        }}
                      >
                        Format Options
                      </span>
                      <IconButton onClick={this.props.toggleFormatOptions}>
                        <Exit />
                      </IconButton>
                    </div>
                  </ListSubheader>
                }
              >
                <SizeAndRotation
                  changeLockedAspect={this.props.changeLockedAspect}
                  mainDiv={this.mainDiv}
                  onChange={this.props.onUpdateSizeAndRotation}
                  lockedAspect={this.props.lockedAspect}
                  updateMinHeight={() => this.props.onMinHeightChange(
                    Number(getComputedStyle(this.mainDiv.current)["height"].slice(0, -2)) *
                      window.panelScale,
                    this.state.style.borderWidth
                      ? parseInt(this.state.style.borderWidth.replace("px", ""))
                      : 0,
                  )}
                  style={this.props.style}
                  width={this.props.width}
                  rightMenuFunc={this.rightMenuFunc}
                  height={this.props.height}
                  rotateAngle={this.props.rotateAngle}
                  scaleX={this.props.scaleX}
                  scaleY={this.props.scaleY}
                />
                <Position
                  onChange={this.props.onUpdatePosition}
                  rightMenuFunc={this.rightMenuFunc}
                  style={this.props.style}
                  top={this.props.top}
                  left={this.props.left}
                />
                <TextFitting
                  onChange={this.handleStyleChanged}
                  rightMenuFunc={this.rightMenuFunc}
                  objType={"textObject"}
                  style={this.state.style}
                  {...TextObject2.config.textFitting}
                  width={this.props.width}
                />
                <SpecialCharacters
                  onChange={this.props.onChange}
                  style={this.props.style}
                  rightMenuFunc={this.rightMenuFunc}
                  onValueChange={this.props.onValueChange}
                  value={this.props.value}
                  selection={this.state.selection}
                  addSymbol={this.addSymbol}
                />
              </List>
            </div>
          </RightMenuPortal>
        )}
        <div
          onMouseDown={(e) => {
            if (this.props.selected) {
              e.stopPropagation();
            }
          }}
          onClick={(e) => {
            if (this.props.selected) {
              e.stopPropagation();
            }
          }}
          onContextMenu={(e) => {
            e.preventDefault();
            this.handleContextMenu(e);
          }}
          style={{
            ...this.state.style,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <div
            contentEditable={!this.props.scale.enabled && !this.props.publishMode}
            data-text="ENTER TEXT HERE"
            suppressContentEditableWarning
            key={this.currentKey}
            spellCheck={"true"}
            onKeyDown={this.handleKeyDown}
            onMouseDown={this.handleMouseDown}
            onMouseUp={this.handleMouseUp}
            onKeyUp={this.handleKeyUp}
            ref={this.mainDiv}
            onFocus={() => {
              this.oldStruct = JSON.stringify(this.struct);
              this.historyTag = new Date().getTime();
            }}
            style={{
              paddingTop: this.state.style.paddingTop ? this.state.style.paddingTop : 0,
              paddingBottom: this.state.style.paddingBottom ? this.state.style.paddingBottom : 0,
              paddingLeft: this.state.style.paddingLeft ? this.state.style.paddingLeft : 0,
              paddingRight: this.state.style.paddingRight ? this.state.style.paddingRight : 0,
              marginTop: this.state.style.marginTop ? this.state.style.marginTop : 0,
              marginBottom: this.state.style.marginBottom ? this.state.style.marginBottom : 0,
              marginLeft: this.state.style.marginLeft ? this.state.style.marginLeft : 0,
              marginRight: this.state.style.marginRight ? this.state.style.marginRight : 0,
            }}
            className={"tb_main-div"}
            onInput={() =>
              this.props.onMinHeightChange(
                Number(getComputedStyle(this.mainDiv.current)["height"].slice(0, -2)) *
                  window.panelScale,
                this.state.style.borderWidth
                  ? parseInt(this.state.style.borderWidth.replace("px", ""))
                  : 0,
              )
            }
            // onPaste={this.handlePaste}
          >
            {renderLines(this.struct)}
          </div>
        </div>
      </Fragment>
    );
  }
}

TextObject2.config = {
  // height: "auto",
  minWidth: 50,
  textFitting: {
    DontshowIndentationMenu: true,
  },
};

export default TextObject2;

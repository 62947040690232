import { useState, useEffect } from "react";
import { Button } from "../../components/CopiedFromRefactor/Button";
import { CardSelect } from "../../components/CardSelect";
import { QuestionWrapper } from "../../components/QuestionWrapper";
import { Input } from "../../components/Input";
import { SectionButtons } from "../../components/SectionButtons";
import { usePage } from "../../contexts/PageProvider";
import { useSave } from "../../hooks/useSave";
import { SectionMode } from "../../helpers/enums";
import { strategyPricingObjectToAiGeneratedContentDto } from "../../models/aiGeneratedContent/transformer";
import { useNavigate } from "react-router-dom";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import { FormNavigation } from "../../components/FormNavigation";

const initialCards = [
  {
    title: "Price Leader",
    description:
      "Low prices to attract high volume of customers. Aimed at price-sensitive customers.",
    isSelected: false,
  },
  {
    title: "Premium",
    description:
      "Premium prices reflect our superior product quality and create perception of exclusivity.",
    isSelected: false,
  },
  {
    title: "Subscription",
    description: "Customers pay a recurring fee for ongoing access to our products and services.",
    isSelected: false,
  },
  {
    title: "Freemium",
    description:
      "Basic product is offered for free, with additional features or services available for a premium.",
    isSelected: false,
  },
  {
    title: "Cost-Plus",
    description:
      "We add a fixed or percent markup to the cost of our products to determine the price.",
    isSelected: false,
  },
  {
    title: "Loss Leader",
    description:
      "Some products sold at loss to attract customers and cross-sell additional, higher-margin products.",
    isSelected: false,
  },
  {
    title: "Penetration",
    description:
      "Low initial prices to gain market share and will raise prices once the market position is established.",
    isSelected: false,
  },
  {
    title: "Dynamic",
    description: "Prices are adjusted in real-time based on demand, capacity, and competition.",
    isSelected: false,
  },
  {
    title: "Bundle",
    description:
      "Multiple products offered together at a lower combined price than if bought separately.",
    isSelected: false,
  },
  {
    title: "Geographic",
    description:
      "Prices vary by geography due to varying shipping costs or local market conditions.",
    isSelected: false,
  },
];

export function Pricing() {
  const navigate = useNavigate();
  const {
    section,
    strategyPricing,
    setStrategyPricing,
    getGeneratedData,
    isLoading,
    setLoading,
    hasFetchedData,
  } = usePage();

  const [sectionName, setSectionName] = useState({ sectionName: "" });
  const { nextSection, generateSection, saveSectionInputs } = useSave(sectionName);

  const [cards, setCards] = useState(initialCards);
  const [newTitle, setNewTitle] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [showInputs, setShowInputs] = useState(false);
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    if (section) {
      setSectionName({ sectionName: section.name });
    }
  }, [section]);

  useEffect(() => {
    if (
      strategyPricing &&
      strategyPricing.pricingStrategies &&
      strategyPricing.pricingStrategies.length > 0
    ) {
      try {
        const selectedCards = strategyPricing.pricingStrategies.map((strategy) => {
          const [title, description] = strategy.split(" (");
          return { title, description: description.slice(0, -1), isSelected: true };
        });

        if (!initialLoaded) {
          const updatedCards = cards.map((card) => {
            return selectedCards.some((selectedCard) => selectedCard.title === card.title)
              ? { ...card, isSelected: true }
              : card;
          });

          selectedCards.forEach((selectedCard) => {
            if (!cards.some((card) => card.title === selectedCard.title)) {
              updatedCards.push({ ...selectedCard, isSelected: true });
            }
          });

          setCards(updatedCards);
          setInitialLoaded(true);
        }
      } finally {
        setLoadingData(false);
      }
    }
    if (hasFetchedData) setLoadingData(false);
  }, [strategyPricing, initialLoaded, hasFetchedData]);

  function addCard() {
    if (!newTitle.trim() || !newDescription.trim()) return;

    const newCard = { title: newTitle.trim(), description: newDescription.trim() };

    if (cards.some((card) => card.title === newCard.title)) {
      return;
    }

    setCards((prevCards) => [...prevCards, newCard]);
    setNewTitle("");
    setNewDescription("");
    setShowInputs(false);
  }

  function onCardClick(index) {
    setCards((prevCards) =>
      prevCards.map((card, i) => (i === index ? { ...card, isSelected: !card.isSelected } : card)),
    );
  }

  function updateCard(index, values) {
    setCards((prevCards) =>
      prevCards.map((card, i) =>
        i === index ? { ...card, title: values[0], description: values[1] } : card,
      ),
    );
  }

  function validate() {
    return cards.some((card) => card.isSelected);
  }

  async function onNextHandle() {
    if (!validate()) return "strategy/sales";
    await setStrategyPricing({
      pricingStrategies: cards
        .filter((card) => card.isSelected)
        .map((card) => `${card.title} (${card.description})`),
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await nextSection(
            strategyPricingObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });

    return "strategy/sales";
  }

  async function onGenerateHandle() {
    if (!validate()) return;

    await setStrategyPricing({
      pricingStrategies: cards
        .filter((card) => card.isSelected)
        .map((card) => `${card.title} (${card.description})`),
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await generateSection(
            strategyPricingObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });

    return sectionName;
  }

  async function onSave() {
    await setStrategyPricing({
      pricingStrategies: cards
        .filter((card) => card.isSelected)
        .map((card) => `${card.title} (${card.description})`),
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await saveSectionInputs(
            strategyPricingObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });
  }

  return (
    <>
      {loadingData ? (
        <CircleLoader />
      ) : (
        <QuestionWrapper
          isAnswered={cards.some((card) => card.isSelected)}
          question={"What is your Pricing strategy?"}
          description={
            "Describe the approach you use to set prices for your products and services."
          }
          hasSelectOptions
        >
          <div className="grid grid-cols-1 gap-3 auto-rows-fr">
            {cards.map((card, index) => (
              <CardSelect
                onClick={() => onCardClick(index)}
                index={index}
                key={`select-card-${index}`}
                isSelected={card.isSelected}
                onSaveEdit={updateCard}
                value={[card.title, card.description]}
              >
                <div className="flex flex-col text-center gap-1">
                  <span className="font-bold">{card.title}</span>
                  <span>{card.description}</span>
                </div>
              </CardSelect>
            ))}
            {showInputs && (
              <div className="grid grid-cols-2 gap-3 w-full">
                <Input
                  placeholder="Enter new pricing strategy title"
                  value={newTitle}
                  onChange={(e) => setNewTitle(e.target.value)}
                  onAddCard={addCard}
                  className="h-full"
                />
                <Input
                  placeholder="Enter new pricing strategy description"
                  value={newDescription}
                  onChange={(e) => setNewDescription(e.target.value)}
                  onAddCard={addCard}
                  className="h-full"
                />
              </div>
            )}
          </div>

          {!showInputs && (
            <Button outline rounded className={"w-38"} onClick={() => setShowInputs(true)}>
              Add More
            </Button>
          )}
        </QuestionWrapper>
      )}

      <SectionButtons onNext={onNextHandle} onGenerate={onGenerateHandle} />

      <FormNavigation onSave={onSave} />
    </>
  );
}

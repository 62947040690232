import { useEffect, useState } from "react";
import { Button } from "../../components/CopiedFromRefactor/Button";
import { CardSelect } from "../../components/CardSelect";
import { QuestionWrapper } from "../../components/QuestionWrapper";
import { Input } from "../../components/Input";
import { SectionButtons } from "../../components/SectionButtons";
import { usePage } from "../../contexts/PageProvider";
import { SectionMode } from "../../helpers/enums";
import { useSave } from "../../hooks/useSave";
import {
  marketProblemsAndSolutionsObjectToAiGeneratedContentDto,
  dtoOptionsToAiGeneratedContent,
} from "../../models/aiGeneratedContent/transformer";
import { fetchOptions } from "../../Services/api/Repositories/BusinessPlanGenerator";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import { useNavigate } from "react-router-dom";
import { FormNavigation } from "../../components/FormNavigation";

const MAX_SELECTIONS = 5;

export function MarketProblems() {
  const navigate = useNavigate();
  const {
    section,
    marketProblemsAndSolutions,
    setMarketProblemsAndSolutions,
    getGeneratedData,
    setLoading,
    isLoading,
    options,
    setOptions,
  } = usePage();

  const [sectionName, setSectionName] = useState({ sectionName: "" });
  const { saveSectionInputs } = useSave(sectionName);

  const [newOptions, setNewOptions] = useState([]);
  const [problemCards, setProblemCards] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [inputError, setInputError] = useState(false);
  const [isInputActive, setIsInputActive] = useState(false);
  const [loadingProblems, setLoadingProblems] = useState(false);
  const [initalRender, setInitialRender] = useState(false);

  useEffect(() => {
    if (section) {
      setSectionName({ sectionName: section.name });
    }
  }, [section]);

  const bpScenarioId = global.Modeliks.BusinessPlanScenarioInfo.ID;

  function transformToSelectable(items = [], selectedItems = []) {
    const allItems = items.map((item) => ({
      problem: item.problem,
      solution: item.solution,
      isSelected: selectedItems.some((selectedItem) => selectedItem.problem === item.problem),
    }));

    selectedItems.forEach((selectedItem) => {
      const exists = items.some((item) => item.problem === selectedItem.problem);
      if (!exists) {
        allItems.push({
          problem: selectedItem.problem,
          solution: selectedItem.solution,
          isSelected: true,
        });
      }
    });

    allItems.sort((a, b) => !!b.isSelected - (!!a.isSelected === true));

    return allItems;
  }

  async function fetchProblems() {
    try {
      setLoadingProblems(true);
      const problemResponse = await fetchOptions({
        bpScenarioId,
        optionKey: "problems",
      });
      // TODO: transfer to other repository after merge to refactor_main
      const problemsOptions = dtoOptionsToAiGeneratedContent(problemResponse.SectionData.Options);

      setOptions({
        ...options,
        problemsAndSolutions: problemsOptions.problemsAndSolutions,
      });

      setProblemCards(transformToSelectable(problemsOptions.problemsAndSolutions));
      setNewOptions(transformToSelectable(problemsOptions.problemsAndSolutions));
    } catch (error) {
      console.error("Error fetching problems:", error);
    } finally {
      setLoadingProblems(false);
    }
  }

  useEffect(() => {
    if (isLoading) return;

    if (options) {
      if (options.problemsAndSolutions.length === 0 && newOptions.length === 0) {
        fetchProblems();
      } else {
        setInitialRender(true);
        if (!initalRender)
          setProblemCards(
            transformToSelectable(
              options.problemsAndSolutions,
              marketProblemsAndSolutions.problemsAndSolutions,
            ),
          );
      }
    }
  }, [options, marketProblemsAndSolutions]);

  function handleInputChange(event) {
    setInputValue(event.target.value);
  }

  function addCard() {
    const trimmedValue = inputValue.trim();
    if (!trimmedValue) return;

    const cardExists = problemCards.some((card) => card.title === trimmedValue);
    if (cardExists) {
      setInputError(true);
      return;
    }

    const newCard = { problem: trimmedValue, solution: null, isSelected: false };
    setProblemCards((prevCards) => [...prevCards, newCard]);
    setInputValue("");
    setIsInputActive(false);
    setInputError(false);
  }

  function onCardClick(index) {
    const selectedCount = problemCards.filter((card) => card.isSelected).length;

    setProblemCards((prevCards) =>
      prevCards.map((card, i) =>
        i === index
          ? {
              ...card,
              isSelected: card.isSelected ? false : selectedCount < MAX_SELECTIONS,
            }
          : card,
      ),
    );
  }

  function handleSaveEdit(index, newTitle) {
    setProblemCards((prevCards) =>
      prevCards.map((card, i) => (i === index ? { ...card, problem: newTitle } : card)),
    );
  }

  function validate() {
    return problemCards.filter((card) => card.isSelected).length > 0;
  }

  async function onNextHandle() {
    await setMarketProblemsAndSolutions({
      problemsAndSolutions: problemCards
        .filter((card) => card.isSelected)
        .map((card) => {
          const existingEntry =
            marketProblemsAndSolutions.problemsAndSolutions &&
            !!marketProblemsAndSolutions.problemsAndSolutions &&
            marketProblemsAndSolutions.problemsAndSolutions.find(
              (item) => item.problem === card.problem,
            );
          return {
            problem: card.problem,
            solution: existingEntry ? existingEntry.solution : null,
          };
        }),
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      const generatedData = getGeneratedData();
      setTimeout(async () => {
        try {
          setLoading(true);
          await saveSectionInputs(
            marketProblemsAndSolutionsObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });

    if (validate()) {
      return "market_analysis/solutions";
    } else {
      return "market_analysis/target_market";
    }
  }

  async function onSave() {
    await setMarketProblemsAndSolutions({
      problemsAndSolutions: problemCards
        .filter((card) => card.isSelected)
        .map((card) => {
          const existingEntry =
            marketProblemsAndSolutions.problemsAndSolutions &&
            !!marketProblemsAndSolutions.problemsAndSolutions &&
            marketProblemsAndSolutions.problemsAndSolutions.find(
              (item) => item.problem === card.problem,
            );
          return {
            problem: card.problem,
            solution: existingEntry ? existingEntry.solution : null,
          };
        }),
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      const generatedData = getGeneratedData();
      setTimeout(async () => {
        try {
          setLoading(true);
          await saveSectionInputs(
            marketProblemsAndSolutionsObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });
  }

  async function onGenerateHandle() {}

  return (
    <>
      <QuestionWrapper
        isAnswered={problemCards.some((card) => card.isSelected)}
        question={"What problems do your potential customers typically face?"}
        description={
          "Identify the problems or challenges your potential customers often face. Next, you will describe how your business solves these problems."
        }
        maxSelectiveOptions={5}
        onRegenerateOptions={fetchProblems}
      >
        {loadingProblems ? (
          <CircleLoader />
        ) : (
          <div className="grid grid-cols-2 gap-3 auto-rows-fr">
            {problemCards.map((card, index) => (
              <CardSelect
                value={card.problem}
                onClick={() => onCardClick(index)}
                key={`select-card-${index}`}
                isSelected={card.isSelected}
                onSaveEdit={(i, newValue) => handleSaveEdit(i, newValue)}
                index={index}
              >
                {card.problem}
              </CardSelect>
            ))}

            {isInputActive && (
              <Input
                error={inputError}
                placeholder="Add new problem"
                value={inputValue}
                onChange={handleInputChange}
                onAddCard={addCard}
              />
            )}
          </div>
        )}

        <Button className={"w-38"} rounded outline onClick={() => setIsInputActive(true)}>
          Add More
        </Button>
      </QuestionWrapper>

      <SectionButtons onNext={onNextHandle} onGenerate={onGenerateHandle} doesntHaveGenerateBtn />

      <FormNavigation onSave={onSave} />
    </>
  );
}

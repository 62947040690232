import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import Chart from "./Chart";
import {
  ChartTypes,
  StreamTypes,
  NegativeDriverTables,
  ComparisonPeriod,
} from "../../../components/constants/charts";
import { Theme } from "../../../theme/styles/theme";
import { ReportsTypes } from "../../../data/Finance/Reports/constants";

export const WaterfallChartTypes = {
  SingleComparisonPeriod: "SingleComparisonPeriod",
  DeltaWaterfall: "DeltaWaterfall",
};

export default class WaterfallChart extends Chart {
  constructor(props) {
    super(props);
    this.chartClassName = "WaterfallChart";
    this.setClassName();
    this.subtotalIndexes = [];
    this.chartDrivers = this.getChartDrivers();
    this.indexData();
    this.buildData();

    if (props.chartFunctionsObj) {
      props.chartFunctionsObj.changeWaterfallChartType = this.changeWaterfallChartType;
    }
    if (props.chartOptionsObj) {
      props.chartOptionsObj.chartConfig = this.chartConfig;
      props.chartConfigObj.waterFallType = this.chartConfig.waterFallType;
    }
  }

  getSubtotalIndexes() {
    return this.subtotalIndexes;
  }

  addToSubtotalIndexes = (item) => {
    this.subtotalIndexes.push(item);
  };

  emptySubtotalIndexes = () => {
    this.subtotalIndexes = [];
  };

  getCompPeriod() {
    let comparisonPeriods = this.chartData.comparisonPeriod.filter((c) => !!c);
    if (this.chartConfig.waterFallType === WaterfallChartTypes.SingleComparisonPeriod) {
      return ` | ${comparisonPeriods[0]}`;
    } else {
      return (
        " | " +
        comparisonPeriods
          .reverse()
          .filter((c, index) => index < 2)
          .join(" vs ")
      );
    }
  }

  getWaterfallDatasets = () => {
    let datasets = this.getArrayDatasets();
    let newData = datasets[0].data.map((c, index) => {
      return [c, index % 2 ? (120 / 100) * c : (80 / 100) * c];
    });
    datasets[0].data = newData;
    return [datasets[0]];
  };

  getRevenueFunc(key, index) {
    //calling Revenue.getRevenueTotals()
    if (typeof key === "object" && key !== null) {
      if (this.chartConfig.waterFallType == WaterfallChartTypes.SingleComparisonPeriod) {
        return {
          DriverName: key.driverName,
          isChartSubtotal: true,
        };
      }
      return null;
    } else if (StreamTypes[key]) {
      if (StreamTypes[key].hasOwnProperty("isCashFlow")) {
        let store = global.Modeliks.ReportsStore.find(
          (d) => d.ReportType === ReportsTypes.CashFlow,
        );
        if (store) {
          return store[StreamTypes[key].containingDriver]
            .getChildDrivers()
            .filter((c) => c.isValid);
        }
      }
      if (
        StreamTypes[key].hasOwnProperty("getData") &&
        StreamTypes[key].data.hasOwnProperty(StreamTypes[key].getData)
      ) {
        let driver = StreamTypes[key].data[StreamTypes[key].getData].call();
        if (StreamTypes[key].isExpense) {
          Object.assign(driver, { isExpense: true });
        }
        return driver;
      } else if (StreamTypes[key].hasOwnProperty("function")) {
        return this[StreamTypes[key].function].call();
      } else if (StreamTypes[key].hasOwnProperty("driversStore")) {
        return StreamTypes[key]["driversStore"].flatMap((store) =>
          global.Modeliks[store]
            .filter((c) => !StreamTypes[key].except || this.compareArraysForSameObj(key, c))
            .map((c) => {
              if (c.isExpense || StreamTypes[key].isExpense) {
                Object.assign(c.Totals, { isExpense: true });
              }
              return c.Totals;
            }),
        );
      }
      return null;
    } else {
      let driver = null;
      try {
        let obj = JSON.parse(key);
        if (obj.Ref_ID && obj.Ref_ID.toString().includes("CompanyScenarios")) {
          obj.Ref_ID = obj.Ref_ID.replace(
            "CompanyScenarios-$CS_ID-",
            `CompanyScenarios-${global.Modeliks.CompanyScenarioInfo.ID}-`,
          );
        }
        if (obj.Ref_Table) {
          driver = global.Modeliks.DriversStore.find(
            (d) =>
              d.Ref_Field == obj.Ref_Field &&
              d.Ref_ID == obj.Ref_ID &&
              d.Ref_Table == obj.Ref_Table,
          );
        } else if (obj.Ref_ID) {
          driver = global.Modeliks.DriversStore.find(
            (d) => d.Ref_Field == obj.Ref_Field && d.Ref_ID == obj.Ref_ID,
          );
        } else {
          driver = global.Modeliks.DriversStore.find((d) => d.Ref_Field == obj.Ref_Field);
        }
      } catch (e) {
        driver = global.Modeliks.DriversStore.find((d) => d.DriverName === key);
      }
      if (driver) {
        if (
          NegativeDriverTables.findIndex((c) => c == driver.Ref_Table) !== -1 &&
          driver.Ref_Field !== "totals"
        ) {
          Object.assign(driver, { isExpense: true });
        }
      }
      // else {
      //     this.deleteUnusedDriverKey(index)
      // }
      return driver;
    }
  }

  getValue(item, sufix, key = "Value") {
    if (item) {
      if (item.isChartSubtotal) {
        return null;
      }
      let newItem = item.getItemByDateSufix(sufix);
      if (newItem) {
        let isExpense = 1;
        if (item.isExpense) {
          isExpense = -1;
        }
        return newItem[key] * isExpense;
      }
    }
    return null;
  }
  addTotalDrivers() {
    if (this.chartConfig.waterFallType === WaterfallChartTypes.SingleComparisonPeriod) {
      if (typeof this.chartData.driverKeys[this.chartData.driverKeys.length - 1] === "string") {
        this.chartData.driverKeys.push({
          driverName: "Total",
        });
      }
    }
  }
  buildData() {
    if (global.Modeliks.CompanyInfo) {
      let comparisonPeriods = this.chartData.comparisonPeriod.filter((c) => !!c);
      if (this.chartConfig.waterFallType === WaterfallChartTypes.SingleComparisonPeriod) {
        comparisonPeriods = [this.chartData.comparisonPeriod[0]];
      } else {
        comparisonPeriods = comparisonPeriods.reverse();
      }
      this.currentColorIndex = 0;
      this.chartLabels = [];
      this.buildReportFrequency();
      this.comparisonPeriodTotals = {};
      let driverArr = {};
      this.emptySubtotalIndexes();

      let driverSubtotalSum = 0;

      comparisonPeriods.forEach((key, index) => {
        if (!driverArr[key]) {
          driverArr[key] = {};
        }
        this.chartDates[key].forEach((date, dateIndex) => {
          date.Drivers.forEach((driver, driverIndex) => {
            if (driverIndex === 0) {
              driverSubtotalSum = 0;
            }
            if (!driverArr[key][driver.DriverName]) {
              driverArr[key][driver.DriverName] = 0;
              // if(index>0 && !driver.isChartSubtotal) {
              //     driverArr[key][driver.DriverName] += driver.DriverValue;
              // }
            }
            if (!driver.LastPeriodOnly || dateIndex === this.chartDates[key].length - 1) {
              if (!isNaN(driver.DriverValue)) {
                if (driver.isChartSubtotal) {
                  if (this.getSubtotalIndexes().indexOf(driverIndex) === -1) {
                    this.addToSubtotalIndexes(driverIndex);
                  }
                  driverArr[key][driver.DriverName] += driverSubtotalSum;
                } else {
                  driverSubtotalSum += driver.DriverValue;
                  driverArr[key][driver.DriverName] += driver.DriverValue;
                }
              }
            }
          });
        });
      });

      let firstArr = [];
      let firstElement = null;
      let mainKey = comparisonPeriods[0];
      let middleArrs = [];
      let lastArr = [];
      switch (this.chartConfig.waterFallType) {
        case WaterfallChartTypes.SingleComparisonPeriod:
          this.chartLabels.push(Object.keys(driverArr[mainKey])[0]);
          firstElement = Object.values(driverArr[mainKey])[0];
          firstArr = [0, firstElement];

          for (let i = 1; i < Object.keys(driverArr[mainKey]).length; i++) {
            if (this.getSubtotalIndexes().indexOf(i) !== -1) {
              middleArrs.push([firstElement, 0]);
            } else {
              middleArrs.push([
                firstElement,
                firstElement + driverArr[mainKey][Object.keys(driverArr[mainKey])[i]],
              ]);
              firstElement += driverArr[mainKey][Object.keys(driverArr[mainKey])[i]];
            }
            this.chartLabels.push(Object.keys(driverArr[mainKey])[i]);
          }

          this.chartDatasets = [firstArr, ...middleArrs];
          break;
        case WaterfallChartTypes.DeltaWaterfall:
          if (comparisonPeriods.length > 1) {
            firstElement = Object.values(driverArr[mainKey])[0];
            firstArr = [0, firstElement];
            let secondaryKey = comparisonPeriods[1];

            this.chartLabels.push(mainKey + " " + Object.keys(driverArr[mainKey])[0]);

            for (let i = 1; i < Object.values(driverArr[mainKey]).length; i++) {
              let mainElementKey = Object.keys(driverArr[mainKey])[i];
              if (this.chartLabels.indexOf(mainElementKey) == -1) {
                this.chartLabels.push(mainElementKey);
              }
              let dummyFirst = firstElement;
              firstElement +=
                Object.values(driverArr[secondaryKey])[i] - Object.values(driverArr[mainKey])[i];
              middleArrs.push([dummyFirst, firstElement]);
            }
            this.chartLabels.push(
              comparisonPeriods[1] + " " + Object.keys(driverArr[Object.keys(driverArr)[0]])[0],
            );
            lastArr = [firstElement, 0];
            this.chartDatasets = [firstArr, ...middleArrs, lastArr];
          }
          break;
      }
      if (this.chartDatasets.length) {
        this.addToSubtotalIndexes(this.chartDatasets.length - 1);
      }
    }
  }

  changeWaterfallChartType = (type) => {
    this.chartConfig.waterFallType = type;
    this.chartDrivers = this.getChartDrivers();
    this.indexData();
    this.buildData();
    this.chartRef.data.datasets = [{ data: this.chartDatasets }];
    this.chartRef.data.labels = this.chartLabels;
    this.chartRef.update();
    this.SaveChart();
  };

  getData() {
    if (!global.Modeliks.CompanyInfo && this.props.snapShotData) {
      return this.props.snapShotData.datasets;
    }
    return [
      {
        data: this.chartDatasets,
        elements: {
          bar: {
            backgroundColor: this.barColorCode(),
          },
        },
      },
    ];
  }

  barColorCode = () => {
    let greyColor = Theme.colors.chart.Asbestos[700];
    if (this.chartDatasets.length) {
      return (ctx) => {
        if (this.subtotalIndexes.indexOf(ctx.dataIndex) !== -1) {
          return greyColor;
        }
        const start = ctx.parsed._custom.start;
        const end = ctx.parsed._custom.end;

        return start <= end ? Theme.colors.chart.Turquoise[500] : Theme.colors.chart.Alizarin[500];
      };
    }
    return greyColor;
  };

  getDatasetStyle = () => {
    let color = this.getRandomColor();
    return {
      backgroundColor: color,
      borderColor: color,
      // barThickness: 37,
      categoryPercentage: this.chartOptions.categoryGapWidth / 10,
      barPercentage: this.chartOptions.gapWidth / 10,
      maxBarThickness: 37,
      minBarLength: 2,
      borderRadius: 5,
      fill: false,
    };
  };

  getTopPadding() {
    return super.getTopPadding() + 5;
  }
  getLabelAlignMent(context) {
    return "end";
  }

  updateChart() {
    this.forceUpdate();
  }
  render() {
    if (this.getImageSrc()) {
      return this.getChartElementAsImage();
    }

    return (
      <div
        className={"c_c_chart_container"}
        style={{ width: `${this.props.grid ? 100 : this.chartConfig.width}%` }}
        ref={this.setChartContainerRef}
      >
        {/*<div ref={this.setChartTitleRef} style={{visibility: this.chartOptions.showTitle?'visible':'hidden'}} className={'chart_title_wrapper'}><ChartTitle title={this.getChartTitle(this.chartOptions.comparisonBar)} change={this.getChartTitleChange()} unit={this.getChartTitleGrowthUnit()}/></div>*/}
        {this.getTitleElement()}
        <Bar
          key={this.state.key}
          ref={this.setChartRef}
          data={{
            labels: this.getChartLabels(),
            datasets: this.getData(),
          }}
          plugins={this.getPlugins()}
          options={this.getChartOptions()}
          type={"bar"}
        />
      </div>
    );
  }
}

import CalculatedDriver from "../CalculatedDriver";
import { DriverCategories, UnitTypes, SpecialChar } from "../CalculatedDriver/constants";
import datastructure from "../../datastructure.json";
import FinanceGeneric from "../FinanceGeneric";
import {
  DialogTypes,
  ExpenseValues,
  PersonnelEmployeeSalaries,
  PersonnelEmploymentStatus,
  PersonnelFunctionTypes,
} from "../constants";
import Revenue from "../Revenue";
import CalculatedDriver_Values from "../CalculatedDriver/CalculatedDriver_Values";
import Expense from "../Expense";
import { PERMISSIONS } from "../../Permissions/Permissions";
import { PeriodTypes } from "../../dates";
import { groupByUniversal } from "../../../pages/Secure/Dashboards/ManagementReports/components/RecentReports";

class Personnel extends FinanceGeneric {
  Name = "";
  ID_Revenue = null;
  SalaryMetric = PersonnelEmployeeSalaries.Value;
  FunctionType = PersonnelFunctionTypes.DirectLabor;
  EmploymentStatus = PersonnelEmploymentStatus.Employed;
  EmployeeNumber;
  EmployeeCost;
  EmployeeBenefits;
  PersonnelCost;
  BurdenRate = Personnel.getBurdenRate();
  NumberOfEmployeesAtStart;

  constructor(db_record = null) {
    super(db_record);
    if (db_record) {
      this.db_record = db_record;
      this.clean();
      this.NumberOfEmployeesAtStart = this.getNumberOfEmployeesAtStartID();
      this.BurdenRate = Personnel.getBurdenRate();
    }

    this.SaveRevenue = this.Save;
    this.Save = this.SavePersonnel;
  }
  static DriversDesc = {
    Total: {
      driverName: "$Name",
      fieldName: "Totals",
      driverID: "total",
    },
    EmployeeNumber: {
      driverName: `Employee Number${SpecialChar.DriverNameESCChar}$Name`,
      fieldName: "EmployeeNumber",
      driverID: "employee_number",
    },
    EmployeeCost: {
      driverName: `Employee Cost${SpecialChar.DriverNameESCChar}$Name`,
      fieldName: "EmployeeCost",
      driverID: "employee_cost",
    },
    PersonnelCost: {
      driverName: `Personnel Cost${SpecialChar.DriverNameESCChar}$Name`,
      fieldName: "PersonnelCost",
      driverID: "personnel_cost",
    },
    EmployeeBenefits: {
      driverName: `Other Employee Benefits${SpecialChar.DriverNameESCChar}$Name`,
      fieldName: "EmployeeBenefits",
      driverID: "employee_benefits",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
  };
  static TableName = datastructure.Finance_Personnel.TableName;
  getNumberOfEmployeesAtStartID = (shouldSave = true) => {
    let ID = `${datastructure.Finance_Personnel.TableName}-${this.ID}-number-of-employees-at-start`;
    let numberOfEmployees = global.Modeliks.DriverValuesStore.getItem(ID);
    if (numberOfEmployees) {
      return numberOfEmployees;
    }
    numberOfEmployees = new CalculatedDriver_Values(null, ID, null, UnitTypes.Units);

    if (shouldSave) {
      numberOfEmployees.Save();
    }

    return numberOfEmployees;
  };
  static getBurdenRateID = () => {
    return `${global.Modeliks.Tables.CompanyScenarios.TableName}-${global.Modeliks.CompanyScenarioInfo.ID}-burden_rate`;
  };
  static getBurdenRateReportDisplayID = () => {
    return `${global.Modeliks.Tables.CompanyScenarios.TableName}-${global.Modeliks.CompanyScenarioInfo.ID}-report-display-burden_rate`;
  };
  static getBurdenRate = () => {
    let burdenRateValue = global.Modeliks.DriverValuesStore.getItem(Personnel.getBurdenRateID());

    if (!burdenRateValue) {
      burdenRateValue = Personnel.createBurdenRate();
    }

    return burdenRateValue;
  };
  static createBurdenRate = () => {
    const burdenRate = global.Modeliks.DriverValuesStore.getItem(Personnel.getBurdenRateID());

    if (!burdenRate) {
      Personnel.BurdenRate = new CalculatedDriver_Values(
        null,
        Personnel.getBurdenRateID(),
        null,
        UnitTypes.Percentage,
      );
      Personnel.BurdenRate.Value = 0;
      Personnel.BurdenRate.Save();
      return Personnel.BurdenRate;
    } else {
      return global.Modeliks.DriverValuesStore.getItem(Personnel.getBurdenRateID());
    }
  };
  static getDisplayBurdenRateInReports = () => {
    let burdenRate = global.Modeliks.DriverValuesStore.getItem(
      Personnel.getBurdenRateReportDisplayID(),
    );

    if (!burdenRate) {
      burdenRate = new CalculatedDriver_Values(
        null,
        Personnel.getBurdenRateReportDisplayID(),
        null,
        UnitTypes.Integer,
      );
      burdenRate.Value = 0;
      burdenRate.Save();
    }

    return burdenRate;
  };

  periodsData = {};

  clean = (cleanDrivers = false) => {
    if (this.db_record && this.Totals) {
      this.Name = this.db_record.Name;
      this.SalaryMetric = this.db_record.SalaryMetric;
      this.FunctionType = this.db_record.FunctionType;
      this.ID_Revenue = this.db_record.ID_Revenue;
      this.EmploymentStatus = this.db_record.EmploymentStatus;
      this.ID_CompanyScenario = this.db_record.ID_CompanyScenario;
      if (cleanDrivers) {
        this.cleanDrivers();
      }
      if (!this.EmployeeCost) {
        this.EmployeeCost = CalculatedDriver.createDriverFromTable(
          this,
          Personnel.DriversDesc.EmployeeCost.driverID,
          UnitTypes.Price,
          DriverCategories.Sum,
        );
        this.changeDriversName();
        this.EmployeeCost.Save();
      }

      if (!this.EmployeeNumber) {
        this.EmployeeNumber = CalculatedDriver.createDriverFromTable(
          this,
          Personnel.DriversDesc.EmployeeNumber.driverID,
          UnitTypes.Decimal,
          DriverCategories.Average,
        );
        this.changeDriversName();
        this.EmployeeNumber.Save();
      }
      if (
        this.EmploymentStatus === PersonnelEmploymentStatus.Employed &&
        this.Totals &&
        this.Totals.Formula &&
        !this.Totals.Formula.includes(this.EmployeeBenefits.ID_f)
      ) {
        this.setPersonnel();
        this.Save();
      }

      if (
        this.EmploymentStatus !== PersonnelEmploymentStatus.Employed &&
        this.Totals &&
        this.Totals.Formula &&
        this.Totals.Formula.includes(this.EmployeeBenefits.ID_f)
      ) {
        this.setPersonnel();
        this.Save();
      }
      if (
        !this.Totals.getItemByDateSufix(global.Modeliks.DateHelper.months_before_actual[0].sufix)
          .Formula ||
        !this.Totals.getItemByDateSufix(
          global.Modeliks.DateHelper.months_before_actual[0].sufix,
        ).Formula.includes("Actual(")
      ) {
        this.setPersonnel();
        this.Save();
      }
    }
  };

  setDriverName = () => {
    if (this.PersonnelCost) {
      this.PersonnelCost.DriverName = `${this.Name}  as % of ${this.ID_Revenue ? global.Modeliks.RevenuesStore.getItem(this.ID_Revenue).RevenueName : "total revenue"}`;
    }
  };

  SavePersonnel = (callBack, saveDrivers = true) => {
    if (!this.isNew && !this.IsCreated) {
      global.Modeliks.put(this.constructor.TableName, null, this, (res) => {
        this.NumberOfEmployeesAtStart.Save();
        if (saveDrivers) {
          this.SaveDrivers(callBack);
        } else {
          callBack();
        }
      });
    } else {
      global.Modeliks.put(this.constructor.TableName, null, this, (res) => {
        this.NumberOfEmployeesAtStart.Save();
        if (saveDrivers) {
          this.setDriverName();
          this.SaveDrivers(callBack);
        } else {
          callBack();
        }
      });
    }
  };

  changeDriversName = () => {
    Object.keys(this.constructor.DriversDesc).forEach((key) => {
      const driverDesc = this.constructor.DriversDesc[key];
      if (this[driverDesc.fieldName]) {
        this[driverDesc.fieldName].DriverName = driverDesc.driverName.replace("$Name", this.Name);
      } else {
        console.log("driver not found", this, driverDesc, this[driverDesc.fieldName]);
      }
    });
  };

  getYearDatesAll = () => {
    return global.Modeliks.DateHelper.years_all;
  };

  setTotalsFormulas = (firstDriver, secondDriver) => {
    const totalYears = global.Modeliks.DateHelper.years_before;
    totalYears.forEach((year) => {
      const curYearFirstDriver = firstDriver.getItemByDateSufix(year.sufix);
      const curYearSecondDriver = secondDriver.getItemByDateSufix(year.sufix);
      const curYearTotal = this.Totals.getItemByDateSufix(year.sufix);
      if (curYearSecondDriver.Unit == UnitTypes.Percentage) {
        curYearSecondDriver.Formula = `${curYearTotal.ID_f}/${curYearFirstDriver.ID_f} * 100`;
      } else {
        curYearSecondDriver.Formula = `${curYearTotal.ID_f}/${curYearFirstDriver.ID_f}`;
      }
    });

    const years = this.getYearDatesAll();
    years.forEach((year) => {
      if (!year.Active) {
        this.Totals.getItemByDateSufix(year.sufix).Formula =
          `${year.months.map((m) => this.Totals.getItemByDateSufix(m.sufix)).join("+")}`;
        if (!this.ID_Revenue && this.SalaryMetric === PersonnelEmployeeSalaries.Value) {
          secondDriver.getItemByDateSufix(year.sufix).Formula =
            `${year.months.map((m) => secondDriver.getItemByDateSufix(m.sufix)).join("+")}`;
        } else {
          if (this.useBurdenRate) {
            secondDriver.getItemByDateSufix(year.sufix).Formula =
              `((${this.Totals.getItemByDateSufix(year.sufix)} / (1 + |${Personnel.getBurdenRateID()}|)) / ${firstDriver.getItemByDateSufix(year.sufix)}) * 100`;
          } else {
            secondDriver.getItemByDateSufix(year.sufix).Formula =
              `(${this.Totals.getItemByDateSufix(year.sufix)} / ${firstDriver.getItemByDateSufix(year.sufix)}) * 100`;
          }
        }
      }
    });
  };

  get useBurdenRate() {
    return this.EmploymentStatus === PersonnelEmploymentStatus.Employed;
  }

  get secondDriverBurdenRate() {
    if (!this.ID_Revenue && this.SalaryMetric === PersonnelEmployeeSalaries.Value) {
      return this.EmployeeCost;
    }
    return this.PersonnelCost && this.PersonnelCost;
  }

  setPersonnel = () => {
    let firstDriver = null;
    let secondDriver = null;
    if (!this.NumberOfEmployeesAtStart) {
      this.NumberOfEmployeesAtStart = this.getNumberOfEmployeesAtStartID(false);
    }

    if (!this.ID_Revenue && this.SalaryMetric === PersonnelEmployeeSalaries.Value) {
      if (!this.EmployeeNumber) {
        this.EmployeeNumber = CalculatedDriver.createDriverFromTable(
          this,
          Personnel.DriversDesc.EmployeeNumber.driverID,
          UnitTypes.Decimal,
          DriverCategories.Average,
        );
      }

      if (!this.EmployeeCost) {
        this.EmployeeCost = CalculatedDriver.createDriverFromTable(
          this,
          Personnel.DriversDesc.EmployeeCost.driverID,
          UnitTypes.Price,
          DriverCategories.Sum,
        );
      }

      firstDriver = this.EmployeeNumber;
      secondDriver = this.EmployeeCost;
    } else {
      if (!this.ID_Revenue) {
        firstDriver = Revenue.getRevenueTotals();
      } else {
        firstDriver = global.Modeliks.RevenuesStore.getItem(this.ID_Revenue).Totals;
      }
      if (!this.PersonnelCost) {
        this.PersonnelCost = CalculatedDriver.createDriverFromTable(
          this,
          Personnel.DriversDesc.PersonnelCost.driverID,
          UnitTypes.Percentage,
          DriverCategories.Average,
        );
      }
      secondDriver = this.PersonnelCost;
      if (!this.EmployeeNumber) {
        this.EmployeeNumber = CalculatedDriver.createDriverFromTable(
          this,
          Personnel.DriversDesc.EmployeeNumber.driverID,
          UnitTypes.Decimal,
          DriverCategories.Average,
        );
      }
    }

    if (this.EmploymentStatus === PersonnelEmploymentStatus.Employed) {
      if (!this.EmployeeBenefits) {
        this.EmployeeBenefits = CalculatedDriver.createDriverFromTable(
          this,
          Personnel.DriversDesc.EmployeeBenefits.driverID,
          UnitTypes.Price,
          DriverCategories.Average,
        );
      }

      if (!this.ID_Revenue && this.SalaryMetric === PersonnelEmployeeSalaries.Value) {
        this.Totals.setFormula(
          `(${firstDriver.ID_f}*${secondDriver.ID_f})+${this.EmployeeBenefits.ID_f}`,
          true,
          false,
          true,
        );
      } else {
        this.Totals.setFormula(
          `(${firstDriver.ID_f}*${secondDriver.ID_f})+${this.EmployeeBenefits.ID_f}`,
        );
        this.Totals.Values.forEach((v) => {
          if (v.Date.PeriodType === PeriodTypes.year && v.Date.Active) {
          } else {
            v.Formula = `(${firstDriver.getItemByDateSufix(v.Date.sufix).ID_f} * ${secondDriver.getItemByDateSufix(v.Date.sufix)})+${this.EmployeeBenefits.getItemByDateSufix(v.Date.sufix)} Actual((${this.EmployeeNumber.getItemByDateSufix(v.Date.sufix).ID_f_actual} * ${this.EmployeeCost.getItemByDateSufix(v.Date.sufix).ID_f_actual}) + ${this.EmployeeBenefits.getItemByDateSufix(v.Date.sufix).ID_f_actual})`;
          }
        });
        this.Totals.Formula = `(${firstDriver.ID_f}*${secondDriver.ID_f})+${this.EmployeeBenefits.ID_f}`;
      }

      this.EmployeeBenefits.Values.forEach((value) => {
        value.Formula = `(${firstDriver.getItemByDateSufix(value.Date.sufix)}*${secondDriver.getItemByDateSufix(value.Date.sufix)})*|${Personnel.getBurdenRateID()}|`;
      });
    } else {
      if (this.EmployeeBenefits) {
        this.EmployeeBenefits.removeFormula();
      }
      if (!this.ID_Revenue && this.SalaryMetric === PersonnelEmployeeSalaries.Value) {
        this.Totals.setFormula(`${firstDriver.ID_f}*${secondDriver.ID_f}`, true, false, true);
      } else {
        this.Totals.Values.forEach((v) => {
          if (v.Date.PeriodType === PeriodTypes.year && v.Date.Active) {
          } else {
            v.Formula = `${firstDriver.getItemByDateSufix(v.Date.sufix).ID_f} * ${secondDriver.getItemByDateSufix(v.Date.sufix)} Actual((${this.EmployeeNumber.getItemByDateSufix(v.Date.sufix).ID_f_actual} * ${this.EmployeeCost.getItemByDateSufix(v.Date.sufix).ID_f_actual}) + ${this.EmployeeBenefits.getItemByDateSufix(v.Date.sufix).ID_f_actual})`;
          }
        });
        this.Totals.Formula = `${firstDriver.ID_f}*${secondDriver.ID_f}`;
      }
    }

    this.setTotalsFormulas(firstDriver, secondDriver);
  };

  static getEmptyPersonnel = (callBack) => {
    const cost = Personnel.createNewEmpty();
    cost.SaveNew((record) => {
      return callBack(record);
    });
  };

  SaveNew = (callBack) => {
    let limit =
      global.Modeliks.PersonnelStore.length >=
      PERMISSIONS.Financials.restrictions.MaxStreamsCount.TotalEmployees;
    if (limit) {
      global.Modeliks.showDialog(
        `
            You have reached the maximum number of ${PERMISSIONS.Financials.restrictions.MaxStreamsCount.TotalEmployees} streams in Employees. 
            Please reorganize your streams in Employees. \n Click outside of the box to continue with your work.`,
        DialogTypes.WarningSimpleText,
        () => {
          callBack(null);
        },
      );
    } else {
      global.Modeliks.post(this.constructor.TableName, this, (res) => {
        this.ID = res.id;
        this.Totals = CalculatedDriver.createDriverFromTable(
          this,
          Personnel.DriversDesc.Total.driverID,
          UnitTypes.Price,
          DriverCategories.Sum,
        );
        this.PersonnelCost = CalculatedDriver.createDriverFromTable(
          this,
          Personnel.DriversDesc.PersonnelCost.driverID,
          UnitTypes.Percentage,
          DriverCategories.Average,
        );
        this.EmployeeBenefits = CalculatedDriver.createDriverFromTable(
          this,
          Personnel.DriversDesc.EmployeeBenefits.driverID,
          UnitTypes.Price,
          DriverCategories.Average,
        );
        this.EmployeeNumber = CalculatedDriver.createDriverFromTable(
          this,
          Personnel.DriversDesc.EmployeeNumber.driverID,
          UnitTypes.Decimal,
          DriverCategories.Average,
        );
        this.EmployeeCost = CalculatedDriver.createDriverFromTable(
          this,
          Personnel.DriversDesc.EmployeeCost.driverID,
          UnitTypes.Price,
          DriverCategories.Sum,
        );

        callBack(this);
      });
    }
  };

  static createNewEmpty = () => {
    const personnel = new Personnel();
    personnel.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
    personnel.Name = "";
    personnel.SalaryMetric = PersonnelEmployeeSalaries.Value;
    personnel.FunctionType = PersonnelFunctionTypes.SalesAndMarketing;
    personnel.EmploymentStatus = PersonnelEmploymentStatus.Employed;
    return personnel;
  };

  static createNew = (personnel = null) => {
    const newPersonnel = new Personnel();
    newPersonnel.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
    newPersonnel.ID = personnel.ID;
    if (personnel) {
      newPersonnel.Name = personnel.Name;
      newPersonnel.FunctionType = personnel.FunctionType;
      newPersonnel.ID_Revenue = personnel.ID_Revenue;
      newPersonnel.SalaryMetric = personnel.SalaryMetric;
      newPersonnel.EmploymentStatus = personnel.EmploymentStatus;
      newPersonnel.NumberOfEmployeesAtStart = newPersonnel.getNumberOfEmployeesAtStartID(false);
    }
    newPersonnel.Totals = CalculatedDriver.createDriverFromTable(
      newPersonnel,
      Personnel.DriversDesc.Total.driverID,
      UnitTypes.Price,
      DriverCategories.Sum,
    );

    return newPersonnel;
  };

  static getPersonnelTotals = () => {
    const driverID = 0;
    let driver = global.Modeliks.DriversStore.getItem(
      Personnel.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Personnel.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Personnel Totals",
        true,
        true,
        false,
      );
      if (global.Modeliks.PersonnelStore.length > 0) {
        const formula = global.Modeliks.PersonnelStore.map((c) => c.Totals);
        if (driver.Formula !== formula.map((d) => d.ID_f).join("+")) {
          driver.setFormula_Sum(formula);
        }
      }
    }
    return driver;
  };

  static getPersonnelTotalsBurdenRate = () => {
    const driverID = "burden_rate-0";
    let driver = global.Modeliks.DriversStore.getItem(`${Personnel.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Personnel.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Total Employee Cost",
        true,
        false,
        false,
      );
      driver.setFormula_Sum(Personnel.getPersonnelCategoriesTotalsBurdenRate());
    }

    return driver;
  };

  static getPersonnelTotalsBurdenEmpCostRate = () => {
    const driverID = "burden_rate-emp-cost-0";
    let driver = global.Modeliks.DriversStore.getItem(`${Personnel.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Personnel.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Total Employee Cost",
        true,
        false,
        false,
      );
    }
    return driver;
  };

  static geTotalEmployeeCostTotals = () => {
    const driverID = "total_emp_cost_divided";
    let driver = global.Modeliks.DriversStore.getItem(
      Personnel.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Personnel.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Total Average Salary per Employee",
        true,
        true,
        false,
      );
      const totalCost = Personnel.getPersonnelTotals();
      const totalNumber = Personnel.getPersonnelEmployeeTotals();
      driver.Values.forEach((v) => {
        v.Formula = `${totalCost.getItemByDateSufix(v.Date.sufix)} / ${totalNumber.getItemByDateSufix(v.Date.sufix)}`;
      });
    }

    return driver;
  };

  static geTotalEmployeeCostTotalsBurdenRate = () => {
    const driverID = "total_emp_cost_burden_rate_divided";
    let driver = global.Modeliks.DriversStore.getItem(
      Personnel.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Personnel.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Total Average Salary per Employee",
        true,
        true,
        false,
      );
      const totalCost = Personnel.getPersonnelTotals();
      const totalNumber = Personnel.getPersonnelEmployeeTotals();
      driver.Values.forEach((v) => {
        v.Formula = `${totalCost.getItemByDateSufix(v.Date.sufix)} / ${totalNumber.getItemByDateSufix(v.Date.sufix)}`;
      });
    }

    return driver;
  };

  static getNumberOfNewEmployees = () => {
    const driverID = "number_of_new_employees";
    let driver = global.Modeliks.DriversStore.getItem(`${Personnel.TableName}-${driverID}-value`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Personnel.TableName,
        driverID,
        "value",
        UnitTypes.Units,
        DriverCategories.Sum,
        "Number Of New Employees (delta)",
        false,
        true,
        false,
      );
      driver.IsFormulaEditable = false;
      driver.Save();
    }
    driver.DriverName = "Number Of New Employees (delta)";
    driver.IsFormulaEditable = false;
    const months = [
      ...global.Modeliks.DateHelper.months_before_actual,
      ...global.Modeliks.DateHelper.months,
    ];
    const years = [
      ...global.Modeliks.DateHelper.years_before_actual,
      ...global.Modeliks.DateHelper.years_all,
    ];
    const employeeTotals = Personnel.getPersonnelEmployeeTotals();
    const NumberOfNewEmployeesDriver = Personnel.getNumberOfNewEmployeesDriver();
    months.forEach((month) => {
      const prevMonthDate = months.find((c) => c.Order == month.Order - 1);
      const curMonth = driver.getItemByDateSufix(month.sufix);
      if (global.Modeliks.DateHelper.months[0].sufix === month.sufix) {
        curMonth.Formula = `${employeeTotals.getItemByDateSufix(month.sufix)} - MxMath.Sum([${global.Modeliks.PersonnelStore.map((d) => d.NumberOfEmployeesAtStart).join(",")}])`;
        NumberOfNewEmployeesDriver.getItemByDateSufix(month.sufix).Formula =
          `MxMath.IFELSE(${curMonth} > 0, ${curMonth}, 0)`;
      } else if (prevMonthDate) {
        curMonth.Formula = `${employeeTotals.getItemByDateSufix(month.sufix)} - ${employeeTotals.getItemByDateSufix(prevMonthDate.sufix)}`;
        NumberOfNewEmployeesDriver.getItemByDateSufix(month.sufix).Formula =
          `MxMath.IFELSE(${curMonth} > 0, ${curMonth}, 0)`;
      } else {
        curMonth.Formula = `${employeeTotals.getItemByDateSufix(month.sufix)}`;
        NumberOfNewEmployeesDriver.getItemByDateSufix(month.sufix).Formula =
          `MxMath.IFELSE(${curMonth} > 0, ${curMonth}, 0)`;
      }
    });
    years.forEach((year) => {
      const prevYearDate = years.find((c) => c.Order === year.Order - 1);
      const curYear = driver.getItemByDateSufix(year.sufix);
      if (global.Modeliks.DateHelper.years_all[0].sufix === year.sufix) {
        curYear.Formula = `${employeeTotals.getItemByDateSufix(year.sufix)} - MxMath.Sum([${global.Modeliks.PersonnelStore.map((d) => d.NumberOfEmployeesAtStart).join(",")}])`;
      } else if (prevYearDate) {
        curYear.Formula = `${employeeTotals.getItemByDateSufix(year.sufix)} - ${employeeTotals.getItemByDateSufix(prevYearDate.sufix)}`;
        NumberOfNewEmployeesDriver.getItemByDateSufix(year.sufix).Formula =
          `MxMath.IFELSE(${curYear} > 0, ${curYear}, 0)`;
      } else {
        curYear.Formula = `${employeeTotals.getItemByDateSufix(year.sufix)}`;
        NumberOfNewEmployeesDriver.getItemByDateSufix(year.sufix).Formula =
          `MxMath.IFELSE(${curYear} > 0, ${curYear}, 0)`;
      }
    });
    return driver;
  };
  static getNumberOfNewEmployeesDriver = () => {
    const driverID = "number_of_new_employees_driver";
    let driver = global.Modeliks.DriversStore.getItem(`${Personnel.TableName}-${driverID}-value`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Personnel.TableName,
        driverID,
        "value",
        UnitTypes.Units,
        DriverCategories.Sum,
        "Number Of New Employees",
        false,
        true,
        false,
      );
      driver.IsFormulaEditable = false;
      driver.Save();
    }
    driver.IsFormulaEditable = false;

    return driver;
  };

  static getPersonnelOnlyTotals = () => {
    const driverID = "employees_only_total";
    let driver = global.Modeliks.DriversStore.getItem(
      Personnel.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Personnel.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Personnel",
        true,
      );
    }

    const formula = global.Modeliks.PersonnelStore.filter(
      (c) => c.FunctionType === PersonnelFunctionTypes.DirectLabor,
    )
      .map((c) => c.Totals.ID_f)
      .join("+");

    driver.setFormula(formula);
    return driver;
  };

  static getPersonnelDirectLaborTotals = () => {
    const driverID = "personnel_direct_labor_d";
    let driver = global.Modeliks.DriversStore.getItem(
      Personnel.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Personnel.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Direct Labor",
        false,
        false,
        false,
      );
      driver.Save();
    }

    if (driver.DriverName !== "Direct Labor") {
      driver.DriverName = "Direct Labor";
    }

    driver.isExpense = true;
    if (global.Modeliks.PersonnelStore.length > 0) {
      const directLaborDrivers = global.Modeliks.PersonnelStore.filter(
        (c) => c.FunctionType === PersonnelFunctionTypes.DirectLabor,
      );
      if (directLaborDrivers.length > 0) {
        const directLaborDriversTotals = directLaborDrivers.map((c) => c.Totals);
        driver.Values.forEach((value) => {
          value.Formula = `${directLaborDriversTotals.map((c) => c.getItemByDateSufix(value.Date.sufix)).join("+")} Actual(${directLaborDriversTotals.map((c) => global.Modeliks.DriversStore.getItem(`${Personnel.TableName}-${c.Ref_ID}-burden-changeable-driver`).getItemByDateSufix(value.Date.sufix).ID_f_actual).join("+")})`;
        });
        driver.Formula = directLaborDriversTotals.map((c) => c.ID_f).join("+");
      }
    }

    return driver;
  };

  static getPersonnelEmployeeTotals = () => {
    const driverID = "total_number_of_emp";
    let driver = global.Modeliks.DriversStore.getItem(
      Personnel.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Personnel.TableName,
        driverID,
        "totals",
        UnitTypes.Units,
        DriverCategories.Average,
        "Total Number of Employees",
        true,
        true,
        false,
      );
    }

    driver.LastPeriodOnly = true;
    const drivers = global.Modeliks.DriversStore.filter(
      (c) => c.Ref_Field === Personnel.DriversDesc.EmployeeNumber.driverID,
    );
    if (drivers.length > 0) {
      driver.setFormula_Sum(drivers);
    } else {
      if (driver.Formula !== null) {
        driver.removeFormula();
      }
    }

    return driver;
  };

  static getPersonnelCategoriesTotals = () => {
    const personnelIndexes = {};
    const Drivers = [];

    Object.keys(PersonnelFunctionTypes).forEach(function (key) {
      personnelIndexes[PersonnelFunctionTypes[key]] = [];
    });
    global.Modeliks.PersonnelStore.forEach((item) =>
      personnelIndexes[item.FunctionType].push(item),
    );

    Object.entries(PersonnelFunctionTypes).forEach(([key, value]) => {
      const driverID = Personnel.TableName + `-${value}-totals`;
      let driver = global.Modeliks.DriversStore.getItem(driverID);
      if (driver == null) {
        driver = CalculatedDriver.createDriver(
          Personnel.TableName,
          value,
          "totals",
          UnitTypes.Price,
          DriverCategories.Sum,
          key.replace(/[A-Z]/g, " $&").replace("And", "&").trim() + " Personnel Cost",
          true,
          true,
          false,
        );
      }

      if (personnelIndexes[value].length > 0) {
        const formula = personnelIndexes[value].map((c) => c.Totals);
        driver.setFormula_Sum(formula);
      }

      Drivers.push(driver);
    });

    return Drivers;
  };

  static getPersonnelCategoriesTotalsBurdenRate = () => {
    const AllDrivers = [];
    const data =
      global.Modeliks.PersonnelStore.length > 0
        ? groupByUniversal(global.Modeliks.PersonnelStore, "FunctionType")
        : [];
    const driversID = "burden-changeable-rate";
    const tmpTotalID = "burden-changeable-driver";

    Object.entries(PersonnelFunctionTypes).forEach(([key, catValue]) => {
      const functionTotalDriverID = `${Personnel.TableName}-${catValue}-${driversID}`;
      let driver = global.Modeliks.DriversStore.getItem(functionTotalDriverID);
      if (driver == null) {
        driver = CalculatedDriver.createDriver(
          Personnel.TableName,
          catValue,
          driversID,
          UnitTypes.Price,
          DriverCategories.Sum,
          key.replace(/[A-Z]/g, " $&").replace("And", "&").trim(),
          true,
          false,
          false,
        );
      }

      if (data[catValue] && data[catValue].length > 0) {
        let formula = [];

        data[catValue].forEach((personnel) => {
          let burdenDriver = global.Modeliks.DriversStore.getItem(
            `${Personnel.TableName}-${personnel.Totals.Ref_ID}-${tmpTotalID}`,
          );
          if (burdenDriver === null) {
            burdenDriver = CalculatedDriver.createDriver(
              Personnel.TableName,
              personnel.Totals.Ref_ID,
              tmpTotalID,
              personnel.Totals.UnitType,
              personnel.Totals.DriverCategory,
              personnel.Totals.DriverName,
              true,
              false,
              false,
            );
          }
          burdenDriver.Values.forEach((value) => {
            value.Formula = `(${personnel.Totals.getItemByDateSufix(value.Date.sufix)} - ${personnel.EmployeeBenefits.getItemByDateSufix(value.Date.sufix)}) + MxMath.IFELSE(${Personnel.getDisplayBurdenRateInReports().ID_f} > 0 && ${personnel.useBurdenRate}, ${personnel.EmployeeBenefits.getItemByDateSufix(value.Date.sufix).ID_f},0) Actual((${personnel.EmployeeCost.getItemByDateSufix(value.Date.sufix).ID_f_actual} * ${personnel.EmployeeNumber.getItemByDateSufix(value.Date.sufix).ID_f_actual}) + (${personnel.EmployeeNumber.getItemByDateSufix(value.Date.sufix).ID_f_actual} * ${personnel.EmployeeBenefits.getItemByDateSufix(value.Date.sufix).ID_f_actual}))`;
          });
          if (personnel.Totals.Formula.includes("personnel_cost")) {
            burdenDriver.Formula = `${personnel.Totals.Formula}`;
          } else {
            burdenDriver.Formula = `${personnel.EmployeeNumber.ID_f}+${personnel.EmployeeCost.ID_f}+${personnel.EmployeeBenefits.ID_f}`;
          }
          formula.push(burdenDriver);
        });
        driver.setFormula_Sum(formula);
      }
      AllDrivers.push(driver);
    });

    return AllDrivers;
  };

  static getPersonnelCategoriesEmployeesCostTotals = () => {
    const personnelIndexes = {};
    const Drivers = [];

    Object.keys(PersonnelFunctionTypes).forEach(function (key) {
      personnelIndexes[PersonnelFunctionTypes[key]] = [];
    });
    global.Modeliks.PersonnelStore.forEach((item) =>
      personnelIndexes[item.FunctionType].push(item),
    );

    Object.entries(PersonnelFunctionTypes).forEach(([key, value]) => {
      const driverID = Personnel.TableName + `-${value}-cost-totals`;
      let driver = global.Modeliks.DriversStore.getItem(driverID);
      if (driver == null) {
        driver = CalculatedDriver.createDriver(
          Personnel.TableName,
          value,
          "cost-totals",
          UnitTypes.Price,
          DriverCategories.Average,
          key.replace(/[A-Z]/g, " $&").replace("And", "&").trim() + "",
          true,
          true,
          false,
        );
      }
      let formula = "";
      const totalCategoryDriver = global.Modeliks.DriversStore.getItem(
        `${Personnel.TableName}-${value}-totals`,
      );
      const totalEmployeeDriver = global.Modeliks.DriversStore.getItem(
        `${Personnel.TableName}-${value}-number-totals`,
      );
      if (personnelIndexes[value].length > 0) {
        const validEmployeeCost = personnelIndexes[value].filter((c) => c.EmployeeCost);
        const validTmpDrivers = validEmployeeCost.map((d) => {
          const driverID = Personnel.TableName + `-${d.EmployeeCost.ID}-tmp-salary-totals`;
          let driver = global.Modeliks.DriversStore.getItem(driverID);
          if (driver == null) {
            driver = CalculatedDriver.createDriver(
              Personnel.TableName,
              d.EmployeeCost.ID,
              "tmp-salary-totals",
              UnitTypes.Price,
              DriverCategories.Sum,
              d.EmployeeCost.DriverName,
              true,
              true,
              false,
            );
            driver.Values.forEach((v) => {
              v.Formula = `MxMath.IFELSE(${d.EmployeeNumber.getItemByDateSufix(v.Date.sufix)} > 0, ${d.EmployeeCost.getItemByDateSufix(v.Date.sufix)}, ${d.Totals.getItemByDateSufix(v.Date.sufix)}) Actual(${d.EmployeeCost.getItemByDateSufix(v.Date.sufix).ID_f_actual})`;
            });
          }

          return driver;
        });
        const notValidEmployeeCost = personnelIndexes[value].filter((c) => c.PersonnelCost);
        formula = validTmpDrivers
          .map((d) => d.ID_f)
          .join("+")
          .concat("+" + validEmployeeCost.map((c) => c.EmployeeCost.ID_f).join("+"))
          .concat(
            "+" +
              personnelIndexes[value]
                .filter((c) => c.PersonnelCost)
                .map((c) => c.Totals.ID_f)
                .join("+"),
          );
        const getFormula = (formula, sufix) => {
          if (validEmployeeCost && validEmployeeCost.length > 0) {
            formula = validEmployeeCost
              .map(
                (c) =>
                  `${c.EmployeeCost.getItemByDateSufix(sufix).ID_f_actual} * ${c.EmployeeNumber.getItemByDateSufix(sufix).ID_f_actual}`,
              )
              .join("+");
          }

          if (notValidEmployeeCost && notValidEmployeeCost.length > 0) {
            if (validEmployeeCost && validEmployeeCost.length > 0) {
              formula =
                formula +
                "+" +
                notValidEmployeeCost
                  .map(
                    (c) =>
                      `${c.Totals.getItemByDateSufix(sufix).ID_f_actual} * ${c.EmployeeNumber ? c.EmployeeNumber.getItemByDateSufix(sufix).ID_f_actual : 1}`,
                  )
                  .join("+");
            } else {
              formula = notValidEmployeeCost
                .map(
                  (c) =>
                    `${c.Totals.getItemByDateSufix(sufix).ID_f_actual} * ${c.EmployeeNumber ? c.EmployeeNumber.getItemByDateSufix(sufix).ID_f_actual : 1}`,
                )
                .join("+");
            }
          }

          return formula;
        };

        driver.Values.forEach(
          (v) =>
            (v.Formula = `MxMath.IFELSE(${totalEmployeeDriver.getItemByDateSufix(v.Date.sufix)} > 0, ${totalCategoryDriver.getItemByDateSufix(v.Date.sufix).ID_f} / ${totalEmployeeDriver.getItemByDateSufix(v.Date.sufix).ID_f}, ${totalCategoryDriver.getItemByDateSufix(v.Date.sufix).ID_f}) Actual( (${getFormula("", v.Date.sufix)} ) / ${totalEmployeeDriver.getItemByDateSufix(v.Date.sufix).ID_f_actual})`),
        );
        driver.Formula = formula;
      }

      Drivers.push(driver);
    });

    return Drivers;
  };

  static getPersonnelCategoriesEmployeesCostTotalsBurdenRate = () => {
    const AllDrivers = [];
    const data =
      global.Modeliks.PersonnelStore.length > 0
        ? groupByUniversal(global.Modeliks.PersonnelStore, "FunctionType")
        : [];
    const driversID = "burden-employee-totals-cost";
    const tmpTotalID = "burden-stream-total-driver";
    const tmpEmployeeCost = "burden-employee-cost-driver";
    const tmpEmployeeRealCost = "burden-employee-real-cost-driver";

    Object.entries(PersonnelFunctionTypes).forEach(([key, value]) => {
      const functionTotalDriverID = `${Personnel.TableName}-${value}-${driversID}`;
      let driver = global.Modeliks.DriversStore.getItem(functionTotalDriverID);
      if (driver == null) {
        driver = CalculatedDriver.createDriver(
          Personnel.TableName,
          value,
          driversID,
          UnitTypes.Price,
          DriverCategories.Sum,
          key.replace(/[A-Z]/g, " $&").replace("And", "&").trim(),
          true,
          false,
          false,
        );
      }
      if (data[value] && data[value].length > 0) {
        let formula = [];
        let categoryFormulaDrivers = [];
        let categoryFormulaAnnualDrivers = [];

        data[value].forEach((personnel) => {
          let drivers = [personnel.EmployeeCost, personnel.PersonnelCost];
          let dr = drivers[0];
          let drTotal = personnel.Totals;
          let driver = global.Modeliks.DriversStore.getItem(
            `${Personnel.TableName}-${dr.Ref_ID}-${tmpEmployeeCost}`,
          );

          if (driver === null) {
            driver = CalculatedDriver.createDriver(
              Personnel.TableName,
              dr.Ref_ID,
              tmpEmployeeCost,
              dr.UnitType,
              dr.DriverCategory,
              dr.DriverName,
              true,
              false,
              false,
            );
            const EmployeeCost = CalculatedDriver.createDriver(
              Personnel.TableName,
              dr.Ref_ID,
              tmpEmployeeRealCost,
              dr.UnitType,
              dr.DriverCategory,
              dr.DriverName,
              true,
              false,
              false,
            );
            EmployeeCost.Formula = dr.Formula;
            EmployeeCost.Values.forEach((value) => {
              if (value.Date.PeriodType !== PeriodTypes.year && !value.Date.Active) {
                value.Formula = dr.getItemByDateSufix(value.Date.sufix).Formula;
              }
              if (
                dr.getItemByDateSufix(value.Date.sufix).Formula &&
                dr
                  .getItemByDateSufix(value.Date.sufix)
                  .Formula.includes(Personnel.DriversDesc.EmployeeBenefits.driverID)
              ) {
                value.Formula = dr
                  .getItemByDateSufix(value.Date.sufix)
                  .Formula.replace(
                    personnel.EmployeeBenefits.getItemByDateSufix(value.Date.sufix).ID_f,
                    `MxMath.IFELSE(${Personnel.getDisplayBurdenRateInReports().ID_f} > 0 && ${personnel.useBurdenRate}, ${personnel.EmployeeBenefits.getItemByDateSufix(value.Date.sufix).ID_f},0)`,
                  );
              } else {
                if (!value.Formula) {
                  value.Formula = dr.getItemByDateSufix(value.Date.sufix).ID_f;
                }
              }
            });

            const Totals = CalculatedDriver.createDriver(
              Personnel.TableName,
              drTotal.Ref_ID,
              tmpTotalID,
              drTotal.UnitType,
              drTotal.DriverCategory,
              drTotal.DriverName,
              true,
              false,
              false,
            );
            Totals.Formula = drTotal.Formula;
            Totals.Values.forEach((value) => {
              if (value.Date.PeriodType !== PeriodTypes.year && !value.Date.Active) {
                value.Formula = drTotal.getItemByDateSufix(value.Date.sufix).Formula;
              }
              if (
                drTotal.getItemByDateSufix(value.Date.sufix).Formula &&
                drTotal
                  .getItemByDateSufix(value.Date.sufix)
                  .Formula.includes(Personnel.DriversDesc.EmployeeBenefits.driverID)
              ) {
                value.Formula = drTotal
                  .getItemByDateSufix(value.Date.sufix)
                  .Formula.replace(
                    personnel.EmployeeBenefits.getItemByDateSufix(value.Date.sufix).ID_f,
                    `MxMath.IFELSE(${Personnel.getDisplayBurdenRateInReports().ID_f} > 0 && ${personnel.useBurdenRate}, ${personnel.EmployeeBenefits.getItemByDateSufix(value.Date.sufix).ID_f},null)`,
                  );
              } else {
                if (!value.Formula) {
                  value.Formula = drTotal.getItemByDateSufix(value.Date.sufix).ID_f;
                }
              }
            });

            driver.Values.forEach((v) => {
              v.Formula = `MxMath.IFELSE(${personnel.EmployeeNumber.getItemByDateSufix(v.Date.sufix)} > 0, ((${EmployeeCost.getItemByDateSufix(v.Date.sufix)}*${`MxMath.IFELSE(${Personnel.getDisplayBurdenRateInReports().ID_f} > 0 && ${personnel.useBurdenRate}, (1 + |${Personnel.getBurdenRateID()}|),1)`})), null)`;
            });
          }
          formula.push(driver);
          categoryFormulaDrivers.push(`(${driver.ID_f} * ${personnel.EmployeeNumber.ID_f})`);
          categoryFormulaAnnualDrivers.push(
            `(${driver.getItemByDateSufix(global.Modeliks.DateHelper.years_before[0].sufix).ID_f} * ${personnel.EmployeeNumber.getItemByDateSufix(global.Modeliks.DateHelper.years_before[0].sufix).ID_f})`,
          );
        });
        driver.setFormula(
          `MxMath.Sum([${categoryFormulaDrivers.map((d) => d).join("+")}]) / |${Personnel.TableName}-${value}-number-totals|`,
        );
        driver.getItemByDateSufix(global.Modeliks.DateHelper.years_before[0].sufix).Formula =
          `MxMath.Sum([${categoryFormulaAnnualDrivers.map((d) => d).join("+")}]) / ${global.Modeliks.DriversStore.getItem(`${Personnel.TableName}-${value}-number-totals`).getItemByDateSufix(global.Modeliks.DateHelper.years_before[0].sufix).ID_f}`;
        driver.Formula = formula.map((d) => d.ID_f).join("+");
        AllDrivers.push(driver);
      }
    });

    return AllDrivers;
  };

  static getPersonnelTotalCostPerEmployee = () => {
    const driverID = "total_cost_per_employees";
    let driver = global.Modeliks.DriversStore.getItem(
      Personnel.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Personnel.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Average,
        "Average Salary per Employee",
        true,
        true,
        false,
      );
      const firstTotals = Personnel.getPersonnelEmployeeTotals();
      const secondTotals = Personnel.getPersonnelTotalsBurdenRate();
      driver.setFormula(`${secondTotals} / ${firstTotals}`);
    }

    return driver;
  };

  static getPersonnelCategoriesEmployeesCostTotalsBurdenRateActuals = () => {
    const AllDrivers = [];
    const data =
      global.Modeliks.PersonnelStore.length > 0
        ? groupByUniversal(global.Modeliks.PersonnelStore, "FunctionType")
        : [];
    const driversID = "burden-employee-totals-cost-actuals";
    const tmpEmployeeCost = "burden-employee-cost-driver-actuals";

    Object.entries(PersonnelFunctionTypes).forEach(([key, value]) => {
      const functionTotalDriverID = `${Personnel.TableName}-${value}-${driversID}`;
      let driver = global.Modeliks.DriversStore.getItem(functionTotalDriverID);
      if (driver == null) {
        driver = CalculatedDriver.createDriver(
          Personnel.TableName,
          value,
          driversID,
          UnitTypes.Price,
          DriverCategories.Sum,
          key.replace(/[A-Z]/g, " $&").replace("And", "&").trim(),
          true,
          false,
          false,
        );
      }
      if (data[value] && data[value].length > 0) {
        let formula = [];
        let categoryFormulaDrivers = [];

        data[value].forEach((personnel) => {
          let drivers = [personnel.Totals];
          let dr = drivers[0];
          let driver = global.Modeliks.DriversStore.getItem(
            `${Personnel.TableName}-${dr.Ref_ID}-${tmpEmployeeCost}`,
          );

          if (driver === null) {
            driver = CalculatedDriver.createDriver(
              Personnel.TableName,
              dr.Ref_ID,
              tmpEmployeeCost,
              dr.UnitType,
              dr.DriverCategory,
              dr.DriverName,
              true,
              false,
              false,
            );
            driver.Formula = `${personnel.EmployeeCost.ID_f}+${personnel.EmployeeBenefits.ID_f}`;
            driver.Values.forEach((value) => {
              if (value.Date.PeriodType !== PeriodTypes.year && !value.Date.Active) {
              } else {
                value.Formula = `Actual(${personnel.EmployeeCost.getItemByDateSufix(value.Date.sufix).ID_f_actual} + ${personnel.EmployeeBenefits.getItemByDateSufix(value.Date.sufix).ID_f_actual})`;
              }
            });
          }
          formula.push(driver);
          categoryFormulaDrivers.push(`(${driver.ID_f} * ${personnel.EmployeeNumber.ID_f})`);
        });
        let totalCostDriverID = `${Personnel.TableName}-${value}-burden-changeable-rate`;
        let totalNumberEmpDriverID = `${Personnel.TableName}-${value}-number-totals`;
        driver.setFormula(
          `${global.Modeliks.DriversStore.getItem(totalCostDriverID)} / ${global.Modeliks.DriversStore.getItem(totalNumberEmpDriverID)}`,
        );
        driver.Formula = `${formula.map((d) => d.ID_f).join("+")}`;
        AllDrivers.push(driver);
      }
    });

    return AllDrivers;
  };

  static getPersonnelCategoriesTotalsNumbersOfEmployees = () => {
    const personnelIndexes = {};
    const Drivers = [];

    Object.keys(PersonnelFunctionTypes).forEach(function (key) {
      personnelIndexes[PersonnelFunctionTypes[key]] = [];
    });
    global.Modeliks.PersonnelStore.forEach((item) =>
      personnelIndexes[item.FunctionType].push(item),
    );

    Object.entries(PersonnelFunctionTypes).forEach(([key, value]) => {
      const driverID = Personnel.TableName + `-${value}-number-totals`;
      let driver = global.Modeliks.DriversStore.getItem(driverID);
      if (driver == null) {
        driver = CalculatedDriver.createDriver(
          Personnel.TableName,
          value,
          "number-totals",
          UnitTypes.Units,
          DriverCategories.Average,
          key.replace(/[A-Z]/g, " $&").replace("And", "&").trim() + "",
          true,
          true,
          false,
        );
      }

      if (personnelIndexes[value].length > 0) {
        // const validEmployeeDrivers = personnelIndexes[value].filter(d => !d.ID_Revenue && d.SalaryMetric === PersonnelEmployeeSalaries.Value);
        // const otherEmployeeDrivers = personnelIndexes[value].filter(d => d.SalaryMetric !== PersonnelEmployeeSalaries.Value && d.EmployeeNumber);

        // let formula = '';
        // let formula2 = '';
        //
        // if(validEmployeeDrivers && validEmployeeDrivers.length > 0){
        //     formula = validEmployeeDrivers.map(c => c.EmployeeNumber.ID_f).join('+');
        //     formula2 = validEmployeeDrivers.map(c => c.Totals.ID_f).join('+');
        // }
        //
        // if(otherEmployeeDrivers && otherEmployeeDrivers.length > 0) {
        //     if(formula.length > 0 && formula2.length > 0){
        //         formula = formula + '+' + otherEmployeeDrivers.map(c => c.EmployeeNumber.ID_f).join('+');
        //         formula2 = formula2 + '+' + otherEmployeeDrivers.map(c => c.EmployeeNumber.ID_f).join('+');
        //
        //     }else {
        //         formula = formula + otherEmployeeDrivers.map(c => c.EmployeeNumber.ID_f).join('+');
        //         formula2 = formula2 + otherEmployeeDrivers.map(c => c.EmployeeNumber.ID_f).join('+');
        //     }
        // }

        const formula = personnelIndexes[value].map((c) => c.EmployeeNumber.ID_f).join("+");
        driver.setFormula(formula);
        driver.Formula = formula;

        Drivers.push(driver);
      }
    });

    return Drivers;
  };

  static getSalesAndMarketingEmployeesTotals = () => {
    const driverID = "employee_sales_and_marketing_totals";
    let driver = global.Modeliks.DriversStore.getItem(
      Expense.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Expense.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Employees Sales & Marketing Totals",
        true,
        false,
        false,
      );
      driver.isExpense = true;
    }

    if (global.Modeliks.PersonnelStore.length > 0) {
      const lastCheck = global.Modeliks.PersonnelStore.filter(
        (c) => c.FunctionType === PersonnelFunctionTypes.SalesAndMarketing,
      );
      if (lastCheck && lastCheck.length > 0) {
        const formula = lastCheck.map((c) => c.Totals);
        driver.setFormula_Sum(formula);
      }
    }

    return driver;
  };
}

export default Personnel;

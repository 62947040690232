import { AiGeneratedContent } from "./AiGeneratedContent";
import { AiGeneratedContentDto } from "./AiGeneratedContentDto";

export function dtoOptionsToAiGeneratedContent(options) {
  return {
    ...options,
    problemsAndSolutions: options.problemsAndSolutions?.map((e) => {
      return { problem: e.Problem, solution: e.Solution };
    }),
  };
}

export function dtoToAiGeneratedContent(dto) {
  const json = JSON.parse(dto);
  const jsonInputs = json.Inputs;
  const options = json.Options;

  const businessDescription = {
    companyName: jsonInputs.companyName,
    industryName: jsonInputs.industryName,
    city: jsonInputs.city,
    state: jsonInputs.state,
    country: jsonInputs.country,
    monthEstablished: jsonInputs.monthEstablished,
    yearEstablished: jsonInputs.yearEstablished,
  };

  const businessValues = {
    companyValues: jsonInputs.companyValues,
  };

  const businessOwnership = {
    shareholders: jsonInputs.shareholders,
    companyStructureType: jsonInputs.companyStructureType,
  };

  const businessProductsAndServices = {
    products: jsonInputs.products,
    services: jsonInputs.services,
  };

  const businessIntellectualProperty = {
    intellectualProperties: jsonInputs.intellectualProperties,
  };

  const marketProblemsAndSolutions = {
    problemsAndSolutions: jsonInputs.problemsAndSolutions?.map((e) => {
      return { problem: e.Problem, solution: e.Solution };
    }),
  };

  const marketTargets = {
    targetMarketGeography: jsonInputs.targetMarketGeography,
    targetMarketType: jsonInputs.targetMarketType,
  };

  const marketTrends = {
    marketTrends: jsonInputs.marketTrends,
  };

  const marketCustomers = {
    buyerPersonas: jsonInputs.buyerPersonas,
  };

  const marketCompetitors = {
    competitors: jsonInputs.competitors,
  };

  const marketCriticalSuccessFactor = {
    criticalSuccessFactors: jsonInputs.criticalSuccessFactors,
    successFactorCompetitors: jsonInputs.successFactorCompetitors,
  };

  const marketSellingPropositions = {
    keySellingPoints: jsonInputs.keySellingPoints,
  };

  const strategyMarketChannels = {
    marketingChannels: jsonInputs.marketingChannels,
  };

  const strategyCustomerJourney = {
    customerJourneyStages: jsonInputs.customerJourneyStages,
  };

  const strategyPricing = {
    pricingStrategies: jsonInputs.pricingStrategies,
  };

  const strategySales = {
    salesChannels: jsonInputs.salesChannels,
  };

  const strategyOperations = {
    facilities: jsonInputs.facilities,
  };

  const strategyEquipment = {
    equipments: jsonInputs.equipments,
  };

  const strategyManagementTeam = {
    topManagers: jsonInputs.topManagers,
  };

  const strategyPersonnelTeam = {
    keyPersonnel: jsonInputs.keyPersonnel,
  };

  return AiGeneratedContent({
    businessDescription,
    businessValues,
    businessOwnership,
    businessProductsAndServices,
    businessIntellectualProperty,
    marketProblemsAndSolutions,
    marketTargets,
    marketTrends,
    marketCustomers,
    marketCompetitors,
    marketCriticalSuccessFactor,
    marketSellingPropositions,
    strategyMarketChannels,
    strategyCustomerJourney,
    strategyPricing,
    strategySales,
    strategyOperations,
    strategyEquipment,
    strategyManagementTeam,
    strategyPersonnelTeam,
    options,
  });
}

function getGlobalIdentifiers() {
  return {
    account: global.Modeliks.Account.ID,
    company: global.Modeliks.CompanyInfo.ID,
    user: global.Modeliks.User.ID,
    bpScenarioId: global.Modeliks.BusinessPlanScenarioInfo.ID,
  };
}

export function businessDescriptionObjectToAiGeneratedContentDto(generatedData, options) {
  const { bpScenarioId } = getGlobalIdentifiers();
  const { businessDescription } = generatedData;
  const { industryName, companyName, city, state, country, monthEstablished, yearEstablished } =
    businessDescription;

  const inputs = {
    industryName,
    companyName,
    city,
    state,
    country,
    monthEstablished,
    yearEstablished,
  };
  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function businessValuesObjectToAiGeneratedContentDto(generatedData, options) {
  const { bpScenarioId } = getGlobalIdentifiers();
  const { businessValues } = generatedData;
  const { companyValues } = businessValues;

  const inputs = {
    companyValues,
  };
  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function businessOwnershipObjectToAiGeneratedContentDto(generatedData, options) {
  const { bpScenarioId } = getGlobalIdentifiers();
  const { businessOwnership } = generatedData;
  const { shareholders, companyStructureType } = businessOwnership;

  const inputs = {
    shareholders,
    companyStructureType,
  };
  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function businessProductsAndServicesObjectToAiGeneratedContentDto(generatedData, options) {
  const { bpScenarioId } = getGlobalIdentifiers();
  const { businessProductsAndServices } = generatedData;
  const { products, services } = businessProductsAndServices;

  const inputs = {
    products,
    services,
  };

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function businessIntellectualPropertyObjectToAiGeneratedContentDto(generatedData, options) {
  const { bpScenarioId } = getGlobalIdentifiers();
  const { businessIntellectualProperty } = generatedData;
  const { intellectualProperties } = businessIntellectualProperty;

  const inputs = { intellectualProperties };

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function marketProblemsAndSolutionsObjectToAiGeneratedContentDto(generatedData, options) {
  const { bpScenarioId } = getGlobalIdentifiers();

  const { marketProblemsAndSolutions } = generatedData;
  const { problemsAndSolutions } = marketProblemsAndSolutions;

  const inputs = {
    problemsAndSolutions: problemsAndSolutions.map(({ problem, solution }) => ({
      Problem: problem,
      Solution: solution,
    })),
  };

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function marketTargetsObjectToAiGeneratedContentDto(generatedData, options) {
  const { bpScenarioId } = getGlobalIdentifiers();
  const { marketTargets } = generatedData;
  const { targetMarketGeography, targetMarketType } = marketTargets;

  const inputs = { targetMarketGeography, targetMarketType };

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function marketTrendsObjectToAiGeneratedContentDto(generatedData, options) {
  const { bpScenarioId } = getGlobalIdentifiers();
  const { marketTrends } = generatedData;

  const inputs = { marketTrends: marketTrends.marketTrends };

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function marketCustomersObjectToAiGeneratedContentDto(generatedData, options) {
  const { bpScenarioId } = getGlobalIdentifiers();
  const { marketCustomers } = generatedData;
  const { buyerPersonas } = marketCustomers;

  const inputs = { buyerPersonas };

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function marketCompetitorsObjectToAiGeneratedContentDto(generatedData, options) {
  const { bpScenarioId } = getGlobalIdentifiers();
  const { marketCompetitors } = generatedData;
  const { competitors } = marketCompetitors;

  const inputs = { competitors };

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function marketCriticalSuccessFactorsObjectToAiGeneratedContentDto(generatedData, options) {
  const { bpScenarioId } = getGlobalIdentifiers();
  const { marketCriticalSuccessFactor } = generatedData;
  const { criticalSuccessFactors, successFactorCompetitors } = marketCriticalSuccessFactor;

  const inputs = { criticalSuccessFactors, successFactorCompetitors };

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function marketSellingPorpositionsObjectToAiGeneratedContentDto(generatedData, options) {
  const { bpScenarioId } = getGlobalIdentifiers();
  const { marketSellingPropositions } = generatedData;
  const { keySellingPoints } = marketSellingPropositions;

  const inputs = { keySellingPoints };

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function strategyMarketChannelsObjectToAiGeneratedContentDto(generatedData, options) {
  const { bpScenarioId } = getGlobalIdentifiers();
  const { strategyMarketChannels } = generatedData;
  const { marketingChannels } = strategyMarketChannels;

  const inputs = { marketingChannels };

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function strategyCustomerJourneyObjectToAiGeneratedContentDto(generatedData, options) {
  const { bpScenarioId } = getGlobalIdentifiers();
  const { strategyCustomerJourney } = generatedData;
  const { customerJourneyStages } = strategyCustomerJourney;

  const inputs = { customerJourneyStages };

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function strategyPricingObjectToAiGeneratedContentDto(generatedData, options) {
  const { bpScenarioId } = getGlobalIdentifiers();
  const { strategyPricing } = generatedData;
  const { pricingStrategies } = strategyPricing;

  const inputs = { pricingStrategies };

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function strategySalesObjectToAiGeneratedContentDto(generatedData, options) {
  const { bpScenarioId } = getGlobalIdentifiers();
  const { strategySales } = generatedData;
  const { salesChannels } = strategySales;

  const inputs = { salesChannels };

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function strategyOperationsObjectToAiGeneratedContentDto(generatedData, options) {
  const { bpScenarioId } = getGlobalIdentifiers();
  const { strategyOperations } = generatedData;
  const { facilities } = strategyOperations;

  const inputs = { facilities };

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function strategyEquipmentObjectToAiGeneratedContentDto(generatedData, options) {
  const { bpScenarioId } = getGlobalIdentifiers();
  const { strategyEquipment } = generatedData;
  const { equipments } = strategyEquipment;

  const inputs = { equipments };

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function strategyManagementTeamObjectToAiGeneratedContentDto(generatedData, options) {
  const { bpScenarioId } = getGlobalIdentifiers();
  const { strategyManagementTeam } = generatedData;
  const { topManagers } = strategyManagementTeam;

  const inputs = { topManagers };

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function strategyPersonellTeamObjectToAiGeneratedContentDto(generatedData, options) {
  const { bpScenarioId } = getGlobalIdentifiers();
  const { strategyPersonnelTeam } = generatedData;
  const { keyPersonnel } = strategyPersonnelTeam;

  const inputs = { keyPersonnel };

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function executiveStrategyObjectToAiGeneratedContentDto(generatedData, options) {
  const { bpScenarioId } = getGlobalIdentifiers();
  const inputs = { generatedData };

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import { CollapsedTable } from "./CollapsedTable";
import FooterContent from "../../../../pages/Secure/Financials/components/Footer";
import ShowHideTitle from "../../../../pages/Secure/Financials/components/ShowHideTitle";

export default function CollapseTableComponent(props) {
  const [isCollapsed, setIsCollapsed] = React.useState(props.collapsed ? props.collapsed : false);

  const { className, header } = props;

  const toggleIsCollapsed = () => {
    setIsCollapsed(props.toggleActualsObj.toggled);
    props.toggleActualsObj.callBack && props.toggleActualsObj.callBack();
  };

  if (props.toggleActualsObj) {
    props.toggleActualsObj.toggleFunction = toggleIsCollapsed;
  }

  if (isCollapsed) {
    return (
      <ShowHideTitle
        showIconHover={props.showIconHover}
        hoverMessage={props.hoverMessage}
        showActualTitle={props.showActualTitle}
        button={false}
        title={props.title}
        onChange={() => setIsCollapsed(!isCollapsed)}
      />
    );
  }

  return (
    <React.Fragment>
      <FooterContent
        showBurdenRate={props.showBurdenRate}
        BurdenRateValue={props.BurdenRateValue}
        changeBurdenRate={props.changeBurdenRate}
        showActualMessage={props.showActualMessage}
        ActualMessage={props.ActualMessage}
        EditStream={props.EditStream}
        disableTruncateNumbers={props.disableTruncateNumbers}
        toggleActuals={props.toggleActuals}
        showLineOfCreditWarning={props.showLineOfCreditWarning}
        showCashDialog={props.showCashDialog}
        showMonthly={props.showMonthly}
        itemChanged={props.itemChanged}
        showMonthlyOnChange={props.showMonthlyOnChange}
        useCustomizableTable={props.useCustomizableTable}
        updateTable={props.updateTable}
        IsReport={props.IsReport}
        showHideCurrency={props.showHideCurrency}
        hideCheckBox={props.hideCheckBox}
        disabledEdit={props.disabledEdit}
        title={props.title}
        showActualTitle={props.showActualTitle}
        showCollapseButton={true}
        showIconHover={props.showIconHover}
        hoverMessage={props.hoverMessage}
        onChange={() => setIsCollapsed(!isCollapsed)}
        showCheckBox={props.showCheckBox ? props.showCheckBox : true}
        showSectionExcelExport={props.showSectionExcelExport}
        exportSectionReport={props.exportSectionReport}
      />
      {props.children}
    </React.Fragment>
  );
}

export const SectionMode = Object.freeze({
  Default: "default",
  Edit: "edit",
});

export const SuccessFactorCompetitorsEnum = Object.freeze({
  Best: "best",
  Average: "average",
  Poor: "poor",
});

export const BusinessPlanType = Object.freeze({
  LegacyPlan: "legacyPlan",
  AiPlan: "aiPlan",
});

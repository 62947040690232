import { useState, useEffect } from "react";
import { Button } from "../../components/CopiedFromRefactor/Button";
import { QuestionWrapper } from "../../components/QuestionWrapper";
import { Input } from "../../components/Input";
import { ArrowDown } from "../../../../components/icons/svgIcons";
import { Menu, MenuItem } from "@mui/material";
import { SectionButtons } from "../../components/SectionButtons";
import { useSave } from "../../hooks/useSave";
import { usePage } from "../../contexts/PageProvider";
import { strategyOperationsObjectToAiGeneratedContentDto } from "../../models/aiGeneratedContent/transformer";
import { SectionMode } from "../../helpers/enums";
import { useNavigate } from "react-router-dom";
import { iconClose } from "../../icons";
import { FormNavigation } from "../../components/FormNavigation";

export function Operations() {
  const navigate = useNavigate();
  const { section, strategyOperations, setStrategyOperations, getGeneratedData, setLoading } =
    usePage();

  const [sectionName, setSectionName] = useState({ sectionName: "" });
  const { nextSection, generateSection, saveSectionInputs } = useSave(sectionName);

  const [locations, setLocations] = useState([
    {
      physicalLocationName: "",
      physicalLocationShortDescription: "",
      physicalLocationSize: "",
      physicalLocationLocation: "",
      physicalLocationStatus: "Existing",
      error: false,
    },
  ]);

  const [anchorEls, setAnchorEls] = useState([null]);

  useEffect(() => {
    if (section) {
      setSectionName({ sectionName: section.name });
    }
  }, [section]);

  const handleDropdownClick = (index, event) => {
    const newAnchorEls = [...anchorEls];
    newAnchorEls[index] = event.currentTarget;
    setAnchorEls(newAnchorEls);
  };

  const handleDropdownClose = (index) => {
    const newAnchorEls = [...anchorEls];
    newAnchorEls[index] = null;
    setAnchorEls(newAnchorEls);
  };

  const handleStatusChange = (index, status) => {
    const updatedLocations = [...locations];
    updatedLocations[index].physicalLocationStatus = status;
    setLocations(updatedLocations);
    handleDropdownClose(index);
  };

  const handleInputChange = (index, field, value) => {
    const updatedLocations = [...locations];
    updatedLocations[index][field] = value;
    setLocations(updatedLocations);
  };

  const addLocation = () => {
    setLocations((prevLocations) => [
      ...prevLocations,
      {
        physicalLocationName: "",
        physicalLocationShortDescription: "",
        physicalLocationSize: "",
        physicalLocationLocation: "",
        physicalLocationStatus: "Existing",
        error: false,
      },
    ]);
    setAnchorEls((prev) => [...prev, null]);
  };

  const deleteLocation = (index) => {
    setLocations((prevLocations) => prevLocations.filter((_, i) => i !== index));
    setAnchorEls((prev) => prev.filter((_, i) => i !== index));
  };

  const checkErrors = () => {
    let hasErrors = false;
    const updatedLocations = locations.map((loc) => {
      if (
        !loc.physicalLocationName ||
        !loc.physicalLocationShortDescription ||
        !loc.physicalLocationSize ||
        !loc.physicalLocationLocation
      ) {
        hasErrors = true;
        return { ...loc, error: true };
      }
      return { ...loc, error: false };
    });

    if (hasErrors) {
      setLocations(updatedLocations);
    }

    return !hasErrors;
  };

  const validate = () => {
    return locations.every(
      (loc) =>
        loc.physicalLocationName &&
        loc.physicalLocationShortDescription &&
        loc.physicalLocationSize &&
        loc.physicalLocationLocation,
    );
  };

  async function onNextHandle() {
    if (!validate()) return "strategy/equipment";

    await setStrategyOperations({
      facilities: locations.map(({ error, ...rest }) => rest),
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await nextSection(
            strategyOperationsObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });

    return "strategy/equipment";
  }

  async function onGenerateHandle() {
    checkErrors();
    if (!validate()) return;

    await setStrategyOperations({
      facilities: locations.map(({ error, ...rest }) => rest),
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await generateSection(
            strategyOperationsObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });

    return sectionName;
  }

  async function onSave() {
    await setStrategyOperations({
      facilities: locations.map(({ error, ...rest }) => rest),
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await saveSectionInputs(
            strategyOperationsObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });
  }

  useEffect(() => {
    if (strategyOperations && strategyOperations.facilities) {
      setLocations(strategyOperations.facilities);
    }
  }, [strategyOperations]);

  return (
    <>
      <QuestionWrapper
        isAnswered={validate()}
        question={"List your company’s physical locations."}
        description={
          "This might be your office, store locations, manufacturing plants, storage facilities, etc."
        }
      >
        <div className="flex flex-col gap-8 2xl:gap-2">
          <div className="hidden 2xl:grid grid-cols-9 gap-3 auto-rows-fr">
            <span className="text-18 font-bold col-span-2">Name</span>
            <span className="text-18 font-bold col-span-2">Short Description</span>
            <span className="text-18 font-bold col-span-2">Size</span>
            <span className="text-18 font-bold col-span-2">Location</span>
          </div>
          {locations.map((location, index) => (
            <div
              className="grid grid-cols-2 2xl:grid-cols-9 gap-2 auto-rows-fr min-h-16 relative pr-32 2xl:pr-4"
              key={index}
            >
              <div className="flex flex-col col-span-1 2xl:col-span-2">
                <span className="block 2xl:hidden text-18 font-bold">Name</span>
                <Input
                  placeholder="Type here"
                  className={`h-full ${location.error && !location.physicalLocationName ? "border-red-500" : ""}`}
                  value={location.physicalLocationName}
                  onChange={(e) => handleInputChange(index, "physicalLocationName", e.target.value)}
                />
              </div>
              <div className="flex flex-col col-span-1 2xl:col-span-2">
                <span className="block 2xl:hidden text-18 font-bold">Description</span>
                <Input
                  placeholder="Type here"
                  className={`h-full ${location.error && !location.physicalLocationShortDescription ? "border-red-500" : ""}`}
                  value={location.physicalLocationShortDescription}
                  onChange={(e) =>
                    handleInputChange(index, "physicalLocationShortDescription", e.target.value)
                  }
                />
              </div>
              <div className="flex flex-col col-span-1 2xl:col-span-2">
                <span className="block 2xl:hidden text-18 font-bold">Size</span>
                <Input
                  placeholder="Type here"
                  className={`h-full ${location.error && !location.physicalLocationSize ? "border-red-500" : ""}`}
                  value={location.physicalLocationSize}
                  onChange={(e) => handleInputChange(index, "physicalLocationSize", e.target.value)}
                />
              </div>
              <div className="flex flex-col col-span-1 2xl:col-span-2">
                <span className="block 2xl:hidden text-18 font-bold">Location</span>
                <Input
                  placeholder="Type here"
                  className={`h-full ${location.error && !location.physicalLocationLocation ? "border-red-500" : ""}`}
                  value={location.physicalLocationLocation}
                  onChange={(e) =>
                    handleInputChange(index, "physicalLocationLocation", e.target.value)
                  }
                />
              </div>
              <div className="absolute right-4 top-7 2xl:top-0 2xl:right-0 2xl:relative flex flex-col">
                <Button className="h-fit w-full" onClick={(e) => handleDropdownClick(index, e)}>
                  <div className="flex items-center gap-2">
                    <span>{location.physicalLocationStatus}</span> <ArrowDown />
                  </div>
                </Button>
                <Menu
                  anchorEl={anchorEls[index]}
                  open={Boolean(anchorEls[index])}
                  onClose={() => handleDropdownClose(index)}
                >
                  <MenuItem
                    className="user_item"
                    onClick={() => handleStatusChange(index, "Existing")}
                  >
                    Existing
                  </MenuItem>
                  <MenuItem
                    className="user_item"
                    onClick={() => handleStatusChange(index, "Planned")}
                  >
                    Planned
                  </MenuItem>
                </Menu>
              </div>
              {locations.length > 1 && (
                <div
                  className="absolute top-1 right-0 cursor-pointer"
                  onClick={() => deleteLocation(index)}
                >
                  {iconClose}
                </div>
              )}
            </div>
          ))}
        </div>

        <Button className={"w-38"} rounded outline onClick={addLocation}>
          Add More
        </Button>
      </QuestionWrapper>

      <SectionButtons onNext={onNextHandle} onGenerate={onGenerateHandle} />

      <FormNavigation onSave={onSave} />
    </>
  );
}

import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";

const bgVariantClasses = {
  primary:
    "text-white border border-primary bg-primary hover:bg-white hover:text-black-primary hover:border-primary hover:shadow-light",
  secondary:
    "text-white border border-blue-dark-2 bg-blue-dark-2 hover:bg-white hover:text-black-primary hover:border-blue-dark-2 hover:shadow-light",
  tertiary: "bg-transparent text-black-primary border-0 hover:bg-grey-light",
  quaternary:
    "text-white border border-blue-dark-2 bg-blue-dark-2 hover:bg-white hover:text-black-primary hover:shadow-light",
  warning:
    "bg-red-primary text-white border border-red-primary hover:border-red-primary hover:text-black-primary hover:bg-white",
  secondaryWarning:
    "text-red-primary bg-white border border-red-primary hover:bg-red-primary hover:text-white",
  basic: "text-grey-dark-3",
  link: "bg-transparent text-primary underline hover:no-underline",
};

const outlineVariantClasses = {
  primary: "border-primary hover:bg-primary hover:text-white",
  secondary: "border-blue-dark-2 hover:bg-blue-dark-2 hover:text-white",
  tertiary: "border-tertiary",
  quaternary: "border-blue-dark2 hover:bg-blue-dark-2 hover:text-white",
  warning: "border-red-primary",
  secondaryWarning: "",
  basic: "text-grey-dark-3",
  link: "border-none",
};

const textVariantClasses = {
  primary: "text-primary",
  secondary: "text-grey-dark-2",
  tertiary: "text-black-primary",
  warning: "text-black",
  secondaryWarning: "",
  basic: "text-grey-dark-3",
  link: "text-primary underline cursor-pointer hover:no-underline",
};

const sizeClasses = {
  small: "px-2 py-0.5",
  medium: "px-2.5 py-1.5",
  large: "px-2.5 py-1.5",
};

const textSizeClasses = {
  small: "text-12",
  medium: "text-14",
  large: "text-16",
};

const disabledClasses = ["pointer-events-none bg-grey-dark-1 border-grey-dark-1"];

export const Button = forwardRef(
  (
    {
      children,
      className,
      variant = "primary",
      size = "medium",
      rounded = false,
      outline = false,
      centerText = false,
      ...rest
    },
    ref,
  ) => {
    const { leftslot, rightslot, onClick, disabled } = rest;

    const hasSlots = !!leftslot || !!rightslot;

    const buttonStyle = twMerge(
      `${bgVariantClasses[variant]} ${disabled ? disabledClasses : ""} font-primary inline-flex ${
        hasSlots ? "justify-between" : "justify-center"
      } items-center gap-2.5 ${sizeClasses[size]} font-medium leading-tight  ${
        rounded ? "rounded-full" : "rounded-md"
      } ${
        outline
          ? `bg-transparent ${outlineVariantClasses[variant]} ${textVariantClasses[variant]}`
          : ""
      } transition-colors duration-150 ease-in-out`,
      className,
    );

    return (
      <button ref={ref} className={buttonStyle} {...rest} type="button" onClick={onClick}>
        <>{leftslot}</>
        <span className={`${textSizeClasses[size]} flex`}>{children}</span>
        <>{rightslot}</>
      </button>
    );
  },
);

import request from "superagent";
import {BusinessPlanType} from "../helpers/enums";

export function handleScenarioChange(ID) {
  if (ID === global.Modeliks.BusinessPlanScenarioInfo.ID) {
    return 0;
  } else {
    window.localStorage.setItem("BusinessPlanScenarioID", ID);
    window.location.reload();
  }
}

export async function postBusinessPlanScenario(scenarioName, isCloned, clonedScenarioId) {
  if (isCloned) {
    global.Modeliks.post(
      "businessplanscenarios",
      {
        Company_ID: global.Modeliks.CompanyInfo.ID,
        Name: scenarioName,
        IndustryID: null,
        Type: BusinessPlanType.AiPlan
      },
      (businessplanscenario) => {
        request
          .post("/api/BusinessPlanScenarios")
          .set("authorization", "Bearer " + window.localStorage.getItem("token"))
          .query({
            business_plan_scenario_id: businessplanscenario.id,
            company_id: global.Modeliks.CompanyInfo.ID,
            industry_id: null,
          })
          .then((data) => {
            handleScenarioChange(businessplanscenario.id);
          });
      },
      (err) => {
        console.error("err", err);
      },
    );
  } else {
    request
      .post("/api/cloneBusinessPlanScenario")
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .query({
        BusinessPlanScenario_ID: clonedScenarioId,
        ScenarioName: scenarioName,
      })
      .then((data) => {
        global.Modeliks.GetCompanyBusinessPlanScenarios(() => {
          handleScenarioChange(data.body.id);
        });
      });
  }
}

import httpService from "../../../../../services/api/httpService";

const resource = "/api/aiBusinessPlan";

export function saveInputs(data) {
  return httpService().post(`${resource}/inputs`, data);
}

export function saveSection(data) {
  return httpService().post(`${resource}/section`, data);
}

export function fetchSection(data) {
  const url = `${resource}/section`;

  return httpService().get(url, data);
}

// data receives {BusinessPlanScenarioID, Options} - similar to saveInputs
export function saveOptions(data) {
  return httpService().post(`${resource}/options`, data);
}

export function fetchBusinessPlanScenario(params) {
  const { bpScenarioId } = params;
  const url = `${resource}/inputs/${bpScenarioId}`;

  return httpService().get(url);
}

export function fetchAiInputs(params) {
  const { bpScenarioId } = params;
  const url = `${resource}/inputs/${bpScenarioId}`;

  return httpService().get(url);
}

export function fetchOptions(params) {
  const { bpScenarioId, optionKey } = params;

  return httpService().put(
    `${resource}/options`,
    {},
    {
      BusinessPlanScenarioID: bpScenarioId,
      optionKey,
    },
  );
}

import React from "react";
import { Menu, MenuItem } from "@szhsin/react-menu";
import Button from "@mui/material/Button";
import { default as ButtonJ } from "../../../../../components/actions/Button";
import { ArrowRight, SmallPlusIcon, TrashCan } from "../../../../../components/icons/svgIcons";
import { Theme } from "../../../../../theme/styles/theme";
import { AddDialogTitle } from "../../../../../components/dialogs/financials/AddDialog/AddDialogTitle";
import AddDialog from "../../../../../components/dialogs/financials/AddDialog/AddDialog";
import { AddDialogContent } from "../../../../../components/dialogs/financials/AddDialog/AddDialogContent";
import Input from "../../../../../components/actions/Input";
import CircleCheckboxM from "../../../../../components/actions/CircleCheckboxM";
import MaterialSelect from "../../../../../components/actions/SelectM";
import { DialogActions } from "@mui/material";
import CancelDialog from "../../../../../components/dialogs/settings/CancelDialog";
import PitchScenarioLimitDialog from "../../Dialog/PitchScenarioLimitDialog";
import IconButton from "@mui/material/IconButton";
import { EditIcon } from "../../menus/LeftMenu/components/Icons/LeftMenuIcons";
import request from "superagent";
import "./BusinessPlanScenarioMenu.scss";
import NoAccessDashboards from "../../../../../pages/Secure/Dashboards/dashboard_noaccess.png";
import { AccessControl } from "../../../../../data/Permissions/AccessControl";
import Tooltip from "@mui/material/Tooltip";
import CircleLoader from "../../../../../components/Loaders/CircleLoader/CircleLoader";
import { BusinessPlanType } from "../../../../../BusinessPlan/AiGenerator/helpers/enums";
const Dialog = (props) => {
  return (
    <AddDialog onClose={props.onClose} open>
      <AddDialogTitle title={props.title} onClose={props.onClose} />
      <AddDialogContent>
        <div className="scenario_add_content">
          <div className="sad_heading">{props.dialogPurpose}</div>
          {props.children}
        </div>
      </AddDialogContent>
      <DialogActions>
        <ButtonJ
          color={"primary"}
          backgroundColor={"primary"}
          width="91px"
          padding="0 0 0 0"
          label={props.actionButtonLabel}
          onClick={props.onActionClick}
        />
      </DialogActions>
    </AddDialog>
  );
};

class BusinessPlanScenarioMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editedScenarioName: "",
      scenarioName: "",
      dialogOpen: false,
      editDialogOpen: false,
      scenarioNameError: "",
      isLoading: false,
      cancelDialog: false,
      openScenarioLimit: false,
      newScenario: true,
      industryScenario: false,
      selectedIndustry: global.Modeliks.BusinessPlanScenarios.length
        ? global.Modeliks.BusinessPlanScenarios[0].ID
        : null,
      scenarioForDuplicate: global.Modeliks.BusinessPlanScenarios[0].ID,
    };

    this.scenarioForDeletion = null;
    this.scenarioForEdit = null;
  }

  setScenarioForEdit = (scenario) => {
    this.scenarioForEdit = scenario;
    this.setState({ editDialogOpen: true, editedScenarioName: scenario.Name });
  };

  componentDidUpdate(prevProps) {
    if (this.props.shouldCreateNewBp && !prevProps.shouldCreateNewBp) {
      if (global.Modeliks.BusinessPlanScenarios.length < 3) {
        this.setState({ dialogOpen: true });
      } else {
        this.setState({ openScenarioLimit: true });
      }

      this.props.setShouldCreateNewBp(false);
    }
  }

  addNewScenario = () => {
    if (this.validate()) {
      {
        if (this.state.isLoading) return;
        if (this.props.postBusinessPlanScenario) {
          if (global.Modeliks.BusinessPlanScenarios.length < 3) {
            this.setState({ isLoading: true });
            this.props.postBusinessPlanScenario(
              this.state.scenarioName,
              this.state.newScenario || this.state.industryScenario,
              this.state.scenarioForDuplicate,
            );
            return;
          } else {
            this.setState({ openScenarioLimit: true });
          }
        }
        this.props.saveSlideContent(() => {
          this.setState({ dialogOpen: false, scenarioName: "" });
          this.props.setSaving(true);
          if (global.Modeliks.BusinessPlanScenarios.length < 3) {
            if (this.state.newScenario || this.state.industryScenario) {
              global.Modeliks.post(
                "businessplanscenarios",
                {
                  Company_ID: global.Modeliks.CompanyInfo.ID,
                  Name: this.state.scenarioName,
                  IndustryID: this.state.industryScenario ? this.state.selectedIndustry : null,
                  Type: BusinessPlanType.AiPlan,
                },
                (businessplanscenario) => {
                  request
                    .post("/api/BusinessPlanScenarios")
                    .set("authorization", "Bearer " + window.localStorage.getItem("token"))
                    .query({
                      business_plan_scenario_id: businessplanscenario.id,
                      company_id: global.Modeliks.CompanyInfo.ID,
                      industry_id: this.state.industryScenario ? this.state.selectedIndustry : null,
                    })
                    .then((data) => {
                      global.Modeliks.GetCompanyBusinessPlanScenarios(() => {
                        this.handleScenarioChange(businessplanscenario.id);
                      });
                    });
                },
                (err) => {
                  this.props.setSaving(false);
                  console.error("err", err);
                },
              );
            } else {
              request
                .post("/api/cloneBusinessPlanScenario")
                .set("authorization", "Bearer " + window.localStorage.getItem("token"))
                .query({
                  BusinessPlanScenario_ID: this.state.scenarioForDuplicate,
                  ScenarioName: this.state.scenarioName,
                })
                .then((data) => {
                  global.Modeliks.GetCompanyBusinessPlanScenarios(() => {
                    this.handleScenarioChange(data.body.id);
                  });
                });
            }
          } else {
            this.props.setSaving(false);
            this.setState({ openScenarioLimit: true });
          }
        });
      }
    }
  };

  deletePitchScenario = (ID, scenario) => {
    this.scenarioForDeletion = scenario;
    this.setState({ cancelDialog: true });
  };

  handleDeletePitchScenario = (callBack) => {
    if (global.Modeliks.BusinessPlanScenarios.length > 1) {
      let scenIndex = global.Modeliks.BusinessPlanScenarios.findIndex(
        (c) => c == this.scenarioForDeletion,
      );

      if (scenIndex == -1) {
        return;
      }

      global.Modeliks.del(
        "businessplanscenarios",
        {
          ID: this.scenarioForDeletion.ID,
          Company_ID: global.Modeliks.CompanyInfo.ID,
        },
        (data) => {
          global.Modeliks.BusinessPlanScenarios.splice(scenIndex, 1);
          if (this.scenarioForDeletion.ID == global.Modeliks.BusinessPlanScenarioInfo.ID) {
            this.props.changeScenario
              ? this.props.changeScenario(global.Modeliks.BusinessPlanScenarios[0].ID)
              : this.handleScenarioChange(global.Modeliks.BusinessPlanScenarios[0].ID);
          }
          // global.Modeliks.GetCompanyPitchScenarios();
          callBack();
        },
        (err) => {
          console.error("err", err);
        },
      );
    }
  };

  saveEditedScenario = () => {
    if (this.validate()) {
      this.scenarioForEdit.Name = this.state.editedScenarioName;

      if (this.props.onChangeName) {
        this.props.onChangeName(this.state.editedScenarioName);
      }

      this.scenarioForEdit.Save((success, error) => {
        this.scenarioForEdit = null;
        this.state.editedScenarioName = "";
        this.setState({ editDialogOpen: false, editedScenarioName: "" });
      });
    }
  };

  validate = () => {
    let key = "scenarioName";
    if (this.state.editDialogOpen) {
      key = ["editedScenarioName"];
    }
    if (this.state[key]) {
      return true;
    } else {
      this.setState({ scenarioNameError: "please type a scenario name" });
      return false;
    }
  };

  handleScenarioChange = (ID) => {
    if (ID === global.Modeliks.BusinessPlanScenarioInfo.ID) {
      return 0;
    } else {
      let oldID = global.Modeliks.BusinessPlanScenarioInfo.ID;
      const selectedScenario = global.Modeliks.BusinessPlanScenarios.find((el) => el.ID === ID);
      global.Modeliks.BusinessPlanScenarioInfo = selectedScenario;

      window.localStorage.setItem("BusinessPlanScenarioID", ID);
      this.props.onScenarioChange(
        () => {
          if (selectedScenario.Type === BusinessPlanType.AiPlan)
            window.location.href = "/business_plan/ai_generator";
        },
        true,
        oldID,
      );
    }
  };

  getColorObj = () => {
    if (global.Modeliks.PERMISSIONS.Pitch.restrictions.edit.active) {
      return {
        color: Theme.colors.primary.blue[150],
      };
    }
    return {};
  };

  getBusinessPlanCheckBoxes = () => {
    return (
      <>
        <CircleCheckboxM
          checked={this.state.newScenario && !this.state.industryScenario}
          onChange={() => this.setState({ newScenario: true, industryScenario: false })}
          size="medium"
          label="Create a new business plan"
        />
        <CircleCheckboxM
          checked={!this.state.newScenario && !this.state.industryScenario}
          onChange={() => this.setState({ newScenario: false, industryScenario: false })}
          size="medium"
          label="Duplicate an existing business plan"
        />
      </>
    );
  };

  render() {
    return (
      <>
        <Menu
          className={"categories_menu pitch_scenario_menu"}
          direction={"right"}
          menuButton={
            this.props.menuButton ? (
              this.props.menuButton
            ) : (
              <Tooltip title={global.Modeliks.BusinessPlanScenarioInfo.Name} arrow>
                <Button
                  variant="text"
                  className="section_name button"
                  endIcon={<ArrowRight color={"#000"} />}
                  disableElevation
                >
                  <span>{global.Modeliks.BusinessPlanScenarioInfo.Name}</span>
                </Button>
              </Tooltip>
            )
          }
          transition
        >
          {global.Modeliks.BusinessPlanScenarios &&
            global.Modeliks.BusinessPlanScenarios.map((scenario, index) => {
              return (
                <MenuItem
                  disabled={
                    this.props.isInAiGenerator && scenario.Type === BusinessPlanType.LegacyPlan
                  }
                  key={"left_menu_scenarios_" + index}
                  className={
                    global.Modeliks.BusinessPlanScenarioInfo.ID === scenario.ID ? "selected" : ""
                  }
                  onClick={() =>
                    this.props.changeScenario
                      ? this.props.changeScenario(scenario.ID)
                      : this.handleScenarioChange(scenario.ID)
                  }
                >
                  {scenario.Name}
                  <span>
                    <IconButton
                      className={"edit_icon"}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.setScenarioForEdit(scenario);
                      }}
                      disabled={
                        !global.Modeliks.PERMISSIONS.Pitch.restrictions.edit.active ||
                        (this.props.isInAiGenerator &&
                          scenario.Type === BusinessPlanType.LegacyPlan)
                      }
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      className={"trash_can"}
                      disabled={!global.Modeliks.PERMISSIONS.Pitch.restrictions.edit.active}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.deletePitchScenario(scenario.ID, scenario);
                      }}
                    >
                      <TrashCan />
                    </IconButton>
                  </span>
                </MenuItem>
              );
            })}
          <MenuItem
            disabled={!global.Modeliks.PERMISSIONS.Pitch.restrictions.edit.active}
            onClick={() => {
              if (global.Modeliks.BusinessPlanScenarios.length < 3) {
                this.setState({ dialogOpen: true });
              } else {
                this.setState({ openScenarioLimit: true });
              }
            }}
            className={"add_btn"}
            style={{ fontWeight: 600, ...this.getColorObj() }}
          >
            Add New Business Plan
            <SmallPlusIcon />
          </MenuItem>
        </Menu>

        {this.state.dialogOpen && (
          <Dialog
            onClose={() => this.setState({ dialogOpen: false, scenarioName: "" })}
            title={"Add New Business Plan"}
            actionButtonLabel={
              this.state.isLoading ? (
                <div className="overflow-hidden max-w-14">
                  <CircleLoader className={"h-5 w-full"} />
                </div>
              ) : (
                "Create"
              )
            }
            onActionClick={() => this.addNewScenario()}
            dialogPurpose={"Name of Business Plan"}
          >
            <div
              className="sad_input"
              onPaste={(e) => {
                e.stopPropagation();
              }}
            >
              <Input
                placeholder="Please enter..."
                value={this.state.scenarioName}
                error={!!this.state.scenarioNameError}
                errorMessage={this.state.scenarioNameError}
                onChange={(event) => {
                  this.setState({ scenarioName: event.target.value });
                }}
              />
            </div>
            <div className="sad_heading">Please select one of the following:</div>

            <div style={{ width: "60%" }}>
              <AccessControl
                allowedPermissions={[
                  {
                    [global.Modeliks.PERMISSIONS.BusinessPlan.key]:
                      global.Modeliks.PERMISSIONS.BusinessPlan.restrictions.edit.key,
                  },
                ]}
                renderNoAccess={<>{this.getBusinessPlanCheckBoxes()}</>}
              >
                {this.getBusinessPlanCheckBoxes()}
              </AccessControl>
            </div>
            <div className={""}>
              {this.state.newScenario ? "" : "Which business plan do you want to duplicate?"}
            </div>
            {!this.state.newScenario && (
              <MaterialSelect
                onChange={(e) => this.setState({ scenarioForDuplicate: e.target.value })}
                value={this.state.scenarioForDuplicate}
                options={global.Modeliks.BusinessPlanScenarios.filter(
                  (scneario) => scneario.Type === BusinessPlanType.AiPlan,
                ).map((scenario) => {
                  return {
                    value: scenario.ID,
                    description: scenario.Name,
                  };
                })}
              />
            )}
            <div className={""}>
              {!this.state.industryScenario ? "" : "Select business plan industry:"}
            </div>
            {this.state.industryScenario && (
              <MaterialSelect
                onChange={(e) => this.setState({ selectedIndustry: e.target.value })}
                value={this.state.selectedIndustry}
                options={
                  global.Modeliks.BusinessPlanIndustries
                    ? global.Modeliks.BusinessPlanIndustries.map((industry) => {
                        return {
                          value: industry.ID,
                          description: industry.Name,
                        };
                      })
                    : []
                }
              />
            )}
          </Dialog>
        )}

        {this.state.editDialogOpen && (
          <Dialog
            onClose={() => this.setState({ editDialogOpen: false, editedScenarioName: "" })}
            title={"Edit Deck"}
            actionButtonLabel={"Save"}
            onActionClick={() => this.saveEditedScenario()}
            dialogPurpose={"Change Name of Deck"}
          >
            <div className="scenario_add_content">
              <div className="sad_input">
                <Input
                  placeholder="Please enter..."
                  value={this.state.editedScenarioName}
                  error={!!this.state.scenarioNameError}
                  errorMessage={this.state.scenarioNameError}
                  onChange={(event) => {
                    this.setState({ editedScenarioName: event.target.value });
                  }}
                />
              </div>
            </div>
          </Dialog>
        )}
        {this.state.cancelDialog && (
          <CancelDialog
            firstBtnName={"Go Back"}
            btnName={"Delete Scenario"}
            onExit={() => this.setState({ cancelDialog: false })}
            onDelete={() =>
              this.handleDeletePitchScenario(() => this.setState({ cancelDialog: false }))
            }
            title={`Are you sure you want to delete ${this.scenarioForDeletion.Name}?`}
            open={true}
          >
            This action is irreversable. All added data will be lost.
          </CancelDialog>
        )}
        {this.state.openScenarioLimit && (
          <PitchScenarioLimitDialog
            onClose={() => this.setState({ openScenarioLimit: false })}
            label={"business plan scenarios"}
          />
        )}
      </>
    );
  }
}

export default BusinessPlanScenarioMenu;

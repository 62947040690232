import React from "react";
import { Footer } from "../../../../../components/footer/Footer";
import CheckBoxMaterial from "../../../../../components/actions/CheckBoxMaterial";
import "./routerLink.scss";
import PropTypes from "prop-types";
import "./Footer.scss";
import ButtonMui from "../../../../../components/buttons/buttonMui/buttonMui";
import {
  ArrowLeft,
  ArrowRight,
  IconPlus,
  TablePlusIcon,
  TableMinusIcon,
  ArrowDown,
  TruncateNumbers,
  ShowFullNumbers,
  ActualToggle,
  DecreaseDecimals,
  IncreaseDecimals,
  MobileMenu,
  ShowCurrency,
  HideCurrency,
  ExportIcon,
  DownloadIcon,
} from "../../../../../components/icons/svgIcons";
import CashBalanceDialog from "../../../../../components/dialogs/financials/CashBalanceDialog";
import LineOfCreditDialog from "../../../../../components/dialogs/financials/LineOfCreditDialog";
import LocalStorage from "../../../../../helpers/LocalStorage";
import {
  TableFontSizes,
  LimitCellWidth,
} from "../../../../../components/tables/FinanceTable/components/constants";
import Mx_Tooltip_Hover from "../../../../../components/icons/Mx_Tooltip_Hover";
import CashFlowMessage from "../../../Dashboards/Actuals/components/CashFlowMessage";
import ShowHideTitle from "../ShowHideTitle";
import { NumberFormatTypes } from "../../../../../components/constants/charts";
import MaterialSelect from "../../../../../components/actions/SelectM";
import { Label } from "../../../../../components/typography/label/Label";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { FinancialStatementsTypes } from "../../../../../data/Finance/Dashboards/constants";
import ShowHideMenu from "../../../Dashboards/FinancialStatements/components/SowHideMenu";
import IconButton from "@mui/material/IconButton";
import {
  tableConfig,
  tableObject,
  tableConfigTypes,
} from "./../../../../../components/tables/FinanceTable/components/constants";
import ActualMessage from "../../../Dashboards/Actuals/components/ActualMessage";
import { Menu, MenuItem } from "@szhsin/react-menu";
const ShowMonthlyAnnualTypes = {
  Monthly: 1,
  Annual: 2,
};

if (LocalStorage.getByKeyFromLocalStorageObjects(tableObject, tableConfig) === null) {
  LocalStorage.appendObjectsToLocalStorage(
    tableObject,
    Object.assign({
      [tableConfig]: {
        fontSize: TableFontSizes[100].fontSize,
        limitCellWidth: LimitCellWidth.On,
        showFullNumbers: true,
        displayOfDecimals: 0,
        displayOfDecimalsReport: 0,
        showCurrency: false,
        unitDisplayReportFormat: "fullNumbers",
      },
    }),
  );
}

class FooterContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableSize: TableFontSizes[100].fontSize,
      fontSize: TableFontSizes[100].fontSize,
      limitCellWidth: LimitCellWidth.On,
      showFullNumbers: true,
      displayOfDecimals: 0,
      displayOfDecimalsReport: 0,
      unitDisplayReportFormat: "fullNumbers",
      displayOfDecimalsEditStream: 0,
      showCurrency: true,
    };

    if (LocalStorage.getByKeyFromLocalStorageObjects(tableObject, tableConfig) !== null) {
      const tableObjectLocal = LocalStorage.getByKeyFromLocalStorageObjects(
        tableObject,
        tableConfig,
      );
      const tableSize = Object.values(TableFontSizes).find(
        (d) => d.fontSize === tableObjectLocal.fontSize,
      ).value;
      this.state = {
        tableSize,
        limitCellWidth: tableObjectLocal.limitCellWidth,
        showFullNumbers: tableObjectLocal.showFullNumbers,
        displayOfDecimals: tableObjectLocal.displayOfDecimals,
        displayOfDecimalsReport: tableObjectLocal.displayOfDecimalsReport,
        unitDisplayReportFormat: tableObjectLocal.unitDisplayReportFormat,
        showCurrency: tableObjectLocal.showCurrency,
        displayOfDecimalsEditStream: 0,
      };
    }
  }

  handleStorageChange = () => {
    const newTableSize = localStorage.getItem("table_object");
    const tableObject = JSON.parse(newTableSize);
    const newFontSize = tableObject.table_config.fontSize;
    if (newFontSize === 10) {
      this.setState({ tableSize: 0 });
    } else if (newFontSize === 12) {
      this.setState({ tableSize: 50 });
    } else if (newFontSize === 14) {
      this.setState({ tableSize: 100 });
    } else if (newFontSize === 16) {
      this.setState({ tableSize: 150 });
    } else if (newFontSize === 18) {
      this.setState({ tableSize: 200 });
    }
  };

  componentDidMount() {
    window.addEventListener("storage", this.handleStorageChange);
  }

  componentWillUnmount() {
    window.removeEventListener("storage", this.handleStorageChange);
  }

  ChangeSize = (increase = true) => {
    if (increase) {
      if (this.state.tableSize < 200) {
        LocalStorage.updateValueFromObject(
          tableObject,
          tableConfig,
          tableConfigTypes.fontSize,
          TableFontSizes[this.state.tableSize + 50].fontSize,
        );
        this.setState(
          { tableSize: this.state.tableSize + 50 },
          () => this.props.updateTable && this.props.updateTable(),
        );
      }
    } else {
      if (this.state.tableSize > 0) {
        LocalStorage.updateValueFromObject(
          tableObject,
          tableConfig,
          tableConfigTypes.fontSize,
          TableFontSizes[this.state.tableSize - 50].fontSize,
        );
        this.setState(
          { tableSize: this.state.tableSize - 50 },
          () => this.props.updateTable && this.props.updateTable(),
        );
      }
    }
    window.dispatchEvent(new Event("storage"));
  };

  ChangeDecimalNumber = (increase = true) => {
    if (increase) {
      if (this.props.IsReport) {
        const displayOfDecimalsReport = LocalStorage.get(
          tableObject,
          tableConfig,
          tableConfigTypes.displayOfDecimalsReport,
        );
        if (displayOfDecimalsReport < 2) {
          LocalStorage.updateValueFromObject(
            tableObject,
            tableConfig,
            tableConfigTypes.displayOfDecimalsReport,
            displayOfDecimalsReport + 1,
          );
          this.setState(
            { displayOfDecimalsReport: displayOfDecimalsReport + 1 },
            () => this.props.updateTable && this.props.updateTable(),
          );
        }
      } else if (this.props.EditStream) {
        const displayOfDecimalsEditStream = this.state.displayOfDecimalsEditStream;
        if (displayOfDecimalsEditStream < 2) {
          this.setState(
            { displayOfDecimalsEditStream: displayOfDecimalsEditStream + 1 },
            () =>
              this.props.updateTable &&
              this.props.updateTable(false, displayOfDecimalsEditStream + 1),
          );
        }
      } else {
        const displayOfDecimals = LocalStorage.get(
          tableObject,
          tableConfig,
          tableConfigTypes.displayOfDecimals,
        );
        if (displayOfDecimals < 2) {
          LocalStorage.updateValueFromObject(
            tableObject,
            tableConfig,
            tableConfigTypes.displayOfDecimals,
            displayOfDecimals + 1,
          );
          this.setState(
            { displayOfDecimals: displayOfDecimals + 1 },
            () => this.props.updateTable && this.props.updateTable(),
          );
        }
      }
    } else {
      if (this.props.IsReport) {
        const displayOfDecimalsReport = LocalStorage.get(
          tableObject,
          tableConfig,
          tableConfigTypes.displayOfDecimalsReport,
        );
        if (displayOfDecimalsReport > 0) {
          LocalStorage.updateValueFromObject(
            tableObject,
            tableConfig,
            tableConfigTypes.displayOfDecimalsReport,
            displayOfDecimalsReport - 1,
          );
          this.setState(
            { displayOfDecimalsReport: displayOfDecimalsReport - 1 },
            () => this.props.updateTable && this.props.updateTable(),
          );
        }
      } else if (this.props.EditStream) {
        const displayOfDecimalsEditStream = this.state.displayOfDecimalsEditStream;
        if (displayOfDecimalsEditStream > 0) {
          this.setState(
            { displayOfDecimalsEditStream: displayOfDecimalsEditStream - 1 },
            () =>
              this.props.updateTable &&
              this.props.updateTable(false, displayOfDecimalsEditStream - 1),
          );
        }
      } else {
        const displayOfDecimals = LocalStorage.get(
          tableObject,
          tableConfig,
          tableConfigTypes.displayOfDecimals,
        );
        if (displayOfDecimals > 0) {
          LocalStorage.updateValueFromObject(
            tableObject,
            tableConfig,
            tableConfigTypes.displayOfDecimals,
            displayOfDecimals - 1,
          );
          this.setState(
            { displayOfDecimals: displayOfDecimals - 1 },
            () => this.props.updateTable && this.props.updateTable(),
          );
        }
      }
    }
  };

  changeFullNumbers = () => {
    LocalStorage.updateValueFromObject(
      tableObject,
      tableConfig,
      tableConfigTypes.showFullNumbers,
      !this.state.showFullNumbers,
    );
    this.setState(
      { showFullNumbers: !this.state.showFullNumbers },
      () => this.props.updateTable && this.props.updateTable(),
    );
  };

  changeShowCurrency = () => {
    LocalStorage.updateValueFromObject(
      tableObject,
      tableConfig,
      tableConfigTypes.showCurrency,
      !this.state.showCurrency,
    );
    this.setState(
      { showCurrency: !this.state.showCurrency },
      () => this.props.updateTable && this.props.updateTable(),
    );
  };

  SaveNumberFormatType = (callBack) => {
    global.Modeliks.put(
      "settings_client",
      { Key: "DashboardSettings", CompanyID: global.Modeliks.CompanyInfo.ID },
      { Value: JSON.stringify(global.Modeliks.DashboardSettings.Value) },
      (success, err) => {
        if (!success) {
          console.error("error", err);
        }
        callBack && callBack();
      },
    );
  };

  changeUnitDisplay = (e) => {
    if (this.props.IsReport) {
      LocalStorage.updateValueFromObject(
        tableObject,
        tableConfig,
        tableConfigTypes.unitDisplayReportFormat,
        e.target.value,
      );
      LocalStorage.updateValueFromObject(
        tableObject,
        tableConfig,
        tableConfigTypes.displayOfDecimalsReport,
        NumberFormatTypes[e.target.value].numDecimals,
      );
      this.setState(
        { unitDisplayReportFormat: e.target.value },
        () => this.props.updateTable && this.props.updateTable(),
      );
    } else {
      global.Modeliks.DashboardSettings.Value.financialDates.numberFormatType = e.target.value;
      LocalStorage.updateValueFromObject(
        tableObject,
        tableConfig,
        tableConfigTypes.displayOfDecimals,
        NumberFormatTypes[e.target.value].numDecimals,
      );
      this.SaveNumberFormatType();
      this.setState({ updated: true }, () => this.props.updateTable && this.props.updateTable());
    }
  };

  render() {
    if (this.props.showCheckBox) {
      let icon = <TruncateNumbers />;
      let title = "Truncate numbers";

      if (!this.state.showFullNumbers) {
        icon = <ShowFullNumbers />;
        title = "Show full numbers";
      }

      return (
        <div className="spsffcf_warning_div">
          <div className="spsfcfi_messages_div">
            {this.props.showCollapseButton && (
              <ShowHideTitle
                showIconHover={this.props.showIconHover}
                hoverMessage={this.props.hoverMessage}
                button={true}
                showNumberOfEmployeeTitle={this.props.showNumberOfEmployeeTitle}
                showActualTitle={this.props.showActualTitle}
                title={this.props.title}
                onChange={this.props.onChange}
              />
            )}
            {this.props.showCashDialog && <CashBalanceDialog />}
            {this.props.showLineOfCreditWarning && (
              <LineOfCreditDialog itemChanged={this.props.itemChanged} rows={this.props.rows} />
            )}
            {this.props.showCashFlowMessage && (
              <CashFlowMessage selectedReport={this.props.showCashFlowMessage} />
            )}
            {this.props.showActualMessage && <ActualMessage message={this.props.ActualMessage} />}
          </div>

          <span className="spsfcf_table_resize_icons">
            {this.props.useCustomizableTable && (
              <>
                {this.props.showBurdenRate && (
                  <>
                    <Label
                      style={{ whiteSpace: "nowrap" }}
                      color={"black"}
                      size="label3"
                      label={"Include burden rate"}
                    />
                    <Mx_Tooltip_Hover
                      icon={
                        <CheckBoxMaterial
                          checked={this.props.BurdenRateValue}
                          onChange={this.props.changeBurdenRate}
                          size="small"
                        />
                      }
                      title={"Include burden rate"}
                    />
                  </>
                )}

                {!this.props.hideCheckBox && (
                  <Tabs
                    value={this.props.showMonthly ? 1 : 2}
                    textColor="secondary"
                    className="psdf_navigation_menu_buttons apply_color "
                    onChange={() => this.props.showMonthlyOnChange()}
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                  >
                    {Object.entries(ShowMonthlyAnnualTypes).map(([key, value]) => {
                      return (
                        <Tab
                          key={`${key} ${value}`}
                          label={key.replace(/([A-Z])/g, " $1").trim()}
                          value={value}
                          className="psdf_navigation_menu_tab"
                        />
                      );
                    })}
                  </Tabs>
                )}

                {this.props.customDashboard && (
                  <Tabs
                    value={this.props.selectedType}
                    textColor="secondary"
                    className="psdf_navigation_menu_buttons apply_color"
                    onChange={this.props.changeType}
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                  >
                    {Object.entries(FinancialStatementsTypes).map(([key, value]) => {
                      return (
                        <Tab
                          key={`${key} ${value}`}
                          label={key.replace(/([A-Z])/g, " $1").trim()}
                          value={value}
                          className="psdf_navigation_menu_tab"
                        />
                      );
                    })}
                  </Tabs>
                )}

                {!this.props.hideCheckBox && (
                  <>
                    <Label
                      style={{ whiteSpace: "nowrap" }}
                      color={"black"}
                      size="label3"
                      label={"Unit display"}
                    />
                    <MaterialSelect
                      name="default_level"
                      style={{ width: "110px", minWidth: "110px" }}
                      value={
                        this.props.IsReport
                          ? LocalStorage.get(
                              tableObject,
                              tableConfig,
                              tableConfigTypes.unitDisplayReportFormat,
                            )
                          : global.Modeliks.DashboardSettings.Value.financialDates.numberFormatType
                      }
                      onChange={this.changeUnitDisplay}
                      width="110px"
                      minWidth="110px"
                      options={Object.values(NumberFormatTypes).map((c) => {
                        return {
                          description: `${c.name.length < 2 ? global.Modeliks.CompanyInfo.Currency.value : ""} ${c.name}`,
                          value: c.key,
                        };
                      })}
                    />
                  </>
                )}
                {this.props.showHideCurrency && (
                  <Mx_Tooltip_Hover
                    icon={
                      LocalStorage.get(tableObject, tableConfig, tableConfigTypes.showCurrency) ? (
                        <HideCurrency />
                      ) : (
                        <ShowCurrency />
                      )
                    }
                    title="Display currency symbol"
                    onClick={this.changeShowCurrency}
                  />
                )}
                {this.props.toggleActuals && (
                  <Mx_Tooltip_Hover
                    icon={
                      <ActualToggle
                        active={this.props.toggleActuals ? this.props.toggleActuals.toggled : false}
                      />
                    }
                    title={"Toggle Actuals"}
                    onClick={() => {
                      if (this.props.toggleActuals && this.props.toggleActuals.toggleFunction) {
                        this.props.toggleActuals.toggleFunction();
                      }
                    }}
                  />
                )}
                {!this.props.disableTruncateNumbers && (
                  <Mx_Tooltip_Hover icon={icon} title={title} onClick={this.changeFullNumbers} />
                )}
                <Mx_Tooltip_Hover
                  icon={<DecreaseDecimals />}
                  title="Decrease display of decimals"
                  onClick={(e) => this.ChangeDecimalNumber(false)}
                />
                <Mx_Tooltip_Hover
                  icon={<IncreaseDecimals />}
                  title="Increase display of decimals"
                  onClick={(e) => this.ChangeDecimalNumber(true)}
                />
                <Mx_Tooltip_Hover
                  icon={<TableMinusIcon />}
                  title="Decrease table font"
                  onClick={(e) => this.ChangeSize(false)}
                />
                <Mx_Tooltip_Hover
                  icon={<TablePlusIcon />}
                  title="Increase table font"
                  onClick={(e) => this.ChangeSize(true)}
                />
                <span className="spsfcf_table_display_size">{this.state.tableSize}%</span>

                {!this.props.disableFilter &&
                  this.props.selectedType === FinancialStatementsTypes.Comparative && (
                    <ShowHideMenu
                      menuButton={
                        <IconButton>
                          <MobileMenu />
                        </IconButton>
                      }
                      isValuation={false}
                      isCompany={false}
                      changeColumns={this.props.changeColumns}
                      getHeaders={this.props.getHeaders}
                    />
                  )}

                {this.props.showSectionExcelExport && (
                  <Menu
                    arrow
                    menuClassName="psdf_menu"
                    menuButton={
                      <IconButton sx={{ padding: "4px" }}>
                        <DownloadIcon />
                      </IconButton>
                    }
                  >
                    <MenuItem key={"dash_excel"} onClick={this.props.exportSectionReport}>
                      <span
                        style={{
                          display: "inline-flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <span>Export to Excel</span>
                        <span style={{ marginLeft: "10px" }}>
                          <ExportIcon />
                        </span>
                      </span>
                    </MenuItem>
                  </Menu>
                )}
              </>
            )}

            {/*{!this.props.hideCheckBox && <CheckBoxMaterial label={'Show Monthly'} checked={this.props.showMonthly} onChange={this.props.showMonthlyOnChange} size='medium' elevation={false}/>}*/}
          </span>
        </div>
      );
    }

    return (
      <>
        <Footer style={this.props.style}>
          {/*<span>test</span>*/}
          <div
            className={`spsfcf_footer_action_buttons ${this.props.showButton ? "start" : "center"}`}
          >
            {this.props.prevLink && (
              <ButtonMui
                width={150}
                linkTo={this.props.prevLink ? this.props.prevLink : ""}
                disabled={this.props.disablePrev}
                label={"Previous"}
                startIcon={<ArrowLeft />}
                variant={"contained"}
                style={{ height: "36px" }}
              />
            )}
            {this.props.nextLink && (
              <ButtonMui
                width={150}
                linkTo={this.props.nextLink ? this.props.nextLink : ""}
                disabled={this.props.disableNext}
                label={"Next"}
                endIcon={<ArrowRight />}
                variant={"contained"}
                style={{ height: "36px" }}
              />
            )}
            {this.props.goToOtherLink && (
              <ButtonMui
                width={150}
                linkTo={this.props.goToOtherLink}
                label={this.props.otherLinkLabel}
                variant={"contained"}
                color={"secondary"}
              />
            )}
          </div>
          {this.props.showButton && (
            <div className="spsfcf_footer_div">
              <CheckBoxMaterial
                label={"Show Monthly"}
                checked={this.props.showMonthly}
                onChange={this.props.showMonthlyOnChange}
                size="medium"
                elevation={false}
              />
            </div>
          )}
        </Footer>
      </>
    );
  }
}

FooterContent.propTypes = {
  showButton: PropTypes.bool,
  showCheckBox: PropTypes.bool,
  IsReport: PropTypes.bool,
  EditStream: PropTypes.bool,
};

FooterContent.defaultProps = {
  showButton: true,
  showCheckBox: false,
  IsReport: false,
  EditStream: false,
};
export default FooterContent;

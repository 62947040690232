import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from "react";
import request from "superagent";
import ButtonMui from "../../../../../components/buttons/buttonMui/buttonMui";
import ConfirmDeleteDialog from "../../../../../components/dialogs/ConfirmDeleteDialog";
import AddNewFileDialog from "../../Dialog/AddNewFileDialog";
import ChartDialog from "../../Dialog/ChartDialog";
import SectionDialog from "../../Dialog/SectionDialog";
import { LeftArrow } from "../../IconButtons/SubMenuIcons";
import BusinessPlanScenarioMenu from "../BusinessPlanScenarioMenu/BusinessPlanScenarioMenu";
import PitchScenarioMenu from "../PitchScenarioMenu/PitchScenarioMenu";
import "./LeftMenu.scss";
import LeftMenuResponsiveBox from "./components/LeftMenuResponsiveBox";
import SectionTab from "./components/SectionTab";
import { array_move } from "../../../../../helpers/utils/array";
import { PageContext } from "../../../../../BusinessPlan/AiGenerator/contexts/PageProvider";

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    iconPosition: "top",
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

let draggingEle;
let placeholder;
let isDraggingStarted = false;
let x = 0;
let y = 0;

const CSSClass = "se_menu_container";

class LeftMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addSectionModal: false,
      expandedMenuClass: "",
      key: new Date().getTime(),
      leftMenuKey: new Date().getTime(),
    };

    this.tabFunctions = { closeTabFunctions: [] };

    props.scale.resizeLeft = this.toggleMenu;
    props.scale.refreshLeft = this.refresh;
    this.draggingIndex = null;
    this.droppingIndex = null;
    this.draggingElement = null;

    this.mouseDown = false;
  }

  componentDidMount() {
    if (this.props.sectionId) {
      this.handleChange(event, this.props.sectionId);
    }
  }

  refresh = () => {
    this.setState({ leftMenuKey: new Date().getTime() });
  };

  updateOrder = (index, callBack) => {
    if (index < this.props.tabs.length) {
      if (!this.props.isAdmin) {
        if (this.props.tabs[index].hasOwnProperty("Save")) {
          this.props.tabs[index].Save((success) => {
            if (success) {
              this.updateOrder(index + 1, callBack);
            }
          });
        } else {
          if (!this.props.businessPlan) {
            request
              .put("/api/" + this.SlideSectionsTableName)
              .set("authorization", "Bearer " + window.localStorage.getItem("token"))
              .query({ ID: this.props.tabs[index].ID })
              .send({ order: this.props.tabs[index].order })
              .then((data) => this.updateOrder(index + 1, callBack));
          } else {
            request
              .put("/api/businessplanpages_client")
              .set("authorization", "Bearer " + window.localStorage.getItem("token"))
              .query({ ID: this.props.tabs[index].ID })
              .send({ order: this.props.tabs[index].order })
              .then((data) => this.updateOrder(index + 1, callBack));
          }
        }
      } else {
        if (!this.props.businessPlan) {
          request
            .put("/api/master/slidesections")
            .set("authorization", "Bearer " + window.localStorage.getItem("token"))
            .query({ ID: this.props.tabs[index].ID })
            .send({ order: this.props.tabs[index].order })
            .then((data) => this.updateOrder(index + 1, callBack));
        } else {
          request
            .put("/api/master/businessplanpages")
            .set("authorization", "Bearer " + window.localStorage.getItem("token"))
            .query({ ID: this.props.tabs[index].ID })
            .send({ order: this.props.tabs[index].order })
            .then((data) => this.updateOrder(index + 1, callBack));
        }
      }
    } else {
      callBack();
    }
  };

  mouseDownHandler = (e, id, section) => {
    // Attach the listeners to `document`
    this.mouseDown = true;
    document.addEventListener("mouseup", this.mouseUpHandler);
    document.addEventListener("mousemove", this.mouseMoveHandler);

    draggingEle = document.getElementById("vertical-tab-" + id);
    this.draggingIndex = Number.parseInt(
      draggingEle.getAttribute("aria-controls").replace("vertical-tabpanel-", ""),
    );
    this.draggingElement = section;

    // Calculate the mouse position
    const rect = draggingEle.getBoundingClientRect();
    const scroll = draggingEle.parentElement.parentElement.scrollTop;
    x = e.pageX - rect.left;
    const offset = this.props.leftMenuTopOffset
      ? this.props.leftMenuTopOffset
      : this.props.isAdmin
        ? 190
        : !this.props.ManagementReports
          ? 190
          : 497;
    y = e.pageY - rect.top - scroll + offset;
  };

  mouseMoveHandler = (e) => {
    if (!this.mouseDown) {
      this.mouseUpHandler();
      document.removeEventListener("mousemove", this.mouseMoveHandler);
      document.removeEventListener("mouseup", this.mouseUpHandler);
      return;
    }
    const draggingRect = draggingEle.getBoundingClientRect();

    if (!isDraggingStarted) {
      // Update the flag
      isDraggingStarted = true;

      // Let the placeholder take the height of dragging element
      // So the next element won't move up
      placeholder = document.createElement("div");
      placeholder.classList.add("placeholder");
      draggingEle.parentNode.insertBefore(placeholder, draggingEle.nextSibling);

      // Set the placeholder's height
      placeholder.style.height = `${draggingRect.height}px`;
    }

    // Set position for dragging element
    draggingEle.style.position = "absolute";
    draggingEle.style.top = `${e.pageY - y}px`;
    draggingEle.style.left = `${e.pageX - x}px`;

    const prevEle = draggingEle.previousElementSibling;
    const nextEle = placeholder.nextElementSibling;

    if (prevEle && this.isAbove(draggingEle, prevEle)) {
      // The current order    -> The new order
      // prevEle              -> placeholder
      // draggingEle          -> draggingEle
      // placeholder          -> prevEle
      this.swap(placeholder, draggingEle);
      this.swap(placeholder, prevEle);
      return;
    }

    if (nextEle && this.isAbove(nextEle, draggingEle)) {
      // The current order    -> The new order
      // draggingEle          -> nextEle
      // placeholder          -> placeholder
      // nextEle              -> draggingEle
      this.swap(nextEle, placeholder, true);
      this.swap(nextEle, draggingEle, true);
    }
  };

  swap = (nodeA, nodeB, isDownwards) => {
    const parentA = nodeA.parentNode;
    const siblingA = nodeA.nextSibling === nodeB ? nodeA : nodeA.nextSibling;

    let elem = nodeB;
    if (isDownwards) {
      elem = nodeA;
    }

    if (elem) {
      if (elem.hasAttribute("aria-controls")) {
        let val = Number.parseInt(
          elem.getAttribute("aria-controls").replace("vertical-tabpanel-", ""),
        );
        if (Number.isInteger(val) && val !== this.draggingIndex) {
          this.droppingIndex = val;
        }
      }
    }

    // Move `nodeA` to before the `nodeB`
    nodeB.parentNode.insertBefore(nodeA, nodeB);

    // Move `nodeB` to before the sibling of `nodeA`
    parentA.insertBefore(nodeB, siblingA);
  };

  mouseUpHandler = () => {
    this.mouseDown = false;

    document.removeEventListener("mousemove", this.mouseMoveHandler);
    document.removeEventListener("mouseup", this.mouseUpHandler);
    // Remove the placeholder
    placeholder && placeholder.parentNode.removeChild(placeholder);
    // Reset the flag
    isDraggingStarted = false;
    // Remove the position styles
    draggingEle.style.removeProperty("top");
    draggingEle.style.removeProperty("left");
    draggingEle.style.removeProperty("position");

    x = null;
    y = null;

    // Remove the handlers of `mousemove` and `mouseup`
    // this.props.tabs.splice(this.draggingIndex, 1);
    // this.props.tabs.splice(this.droppingIndex, 0, section);
    if (this.draggingIndex !== null && this.droppingIndex !== null) {
      array_move(this.props.tabs, this.draggingIndex, this.droppingIndex);
      this.props.updateOrder(false, () => {
        this.setState({ leftMenuKey: new Date().getTime() });
      });
    }

    this.draggingIndex = null;
    this.droppingIndex = null;
    // this.forceUpdate();

    // if(this.droppingIndex < this.draggingIndex){
    //
    // }
    // else if(this.droppingIndex > this.draggingIndex){
    //
    // }

    // if (this.droppingIndex < this.draggingIndex) {
    //     this.props.tabs[this.draggingIndex].order = this.props.tabs[this.droppingIndex].order
    //     for (let i = this.droppingIndex; i < this.draggingIndex; i++) {
    //         this.props.tabs[i].order += 1;
    //         if (currentCategory) {
    //             currentCategory.Sections[i].order = this.props.tabs[i].order;
    //         }
    //     }
    //     this.updateOrder(this.droppingIndex, () => {
    //         this.state.key = new Date().getTime()
    //         this.forceUpdate(() => this.props.onSectionMove())
    //     })
    // } else if (this.droppingIndex > this.draggingIndex) {
    //     this.props.tabs[this.draggingIndex].order = this.props.tabs[this.droppingIndex].order
    //     for (let i = this.draggingIndex + 1; i < this.droppingIndex + 1; i++) {
    //         this.props.tabs[i].order -= 1
    //         if (currentCategory) {
    //             currentCategory.Sections[i].order = this.props.tabs[i].order;
    //         }
    //     }
    //     this.updateOrder(this.draggingIndex, () => {
    //         this.state.key = new Date().getTime()
    //         this.forceUpdate(() => this.props.onSectionMove())
    //     })
    // } else {
    //
    // }
    draggingEle = null;
  };

  isAbove = (nodeA, nodeB) => {
    // Get the bounding rectangle of nodes
    const rectA = nodeA.getBoundingClientRect();
    const rectB = nodeB.getBoundingClientRect();

    return rectA.top + rectA.height / 2 < rectB.top + rectB.height / 2;
  };

  handleResize = (value) => {
    setTimeout(this.props.update, 150);
  };

  toggleMenu = (callBack, preview = false) => {
    if (preview) {
      if (this.props.scale.enabled) {
        this.setState({ expandedMenuClass: " extended" }, callBack && callBack());
      } else {
        this.setState({ expandedMenuClass: " hidden" }, callBack && callBack());
      }
    } else {
      if (this.state.expandedMenuClass === " hidden") {
        this.setState({ expandedMenuClass: " extended" }, callBack && callBack());
      } else {
        this.setState({ expandedMenuClass: " hidden" }, callBack && callBack());
      }
    }
  };

  beforeDeleteSection = (section, index) => {
    this.setState({ openConfirmDelete: true, deleteSection: section, deleteIndex: index });
  };

  deleteSection = (section, index, callBack) => {
    if (!this.props.CurrentScreen) {
      if (this.props.isAdmin) {
        this.props.deleteSection(section.ID, () => {
          this.props.tabs.splice(index, 1);
          this.forceUpdate();
        });
      } else {
        if (this.props.handleDeleteSection) {
          this.props.handleDeleteSection(section, index, callBack);
        } else {
          this.props.deleteSectionFlow(section, index);
        }
      }
    } else {
      this.props.deleteSection(section.ID, () => {
        this.props.tabs.splice(index, 1);
        this.forceUpdate();
      });
    }

    callBack && callBack();
  };

  hideSection = (section, callBack) => {
    if (!this.props.businessPlan) {
      section.Hidden = !section.Hidden;
      section.Save((success, error) => {
        if (success) {
          callBack();
          this.props.updateIndex();
        }
      });
    } else {
      section.Hidden = !section.Hidden;
      global.Modeliks.put(
        "businessplanpages_client",
        { ID: section.ID },
        { Hidden: section.Hidden },
        () => {
          this.forceUpdate();
          this.props.updateIndex();
        },
      );
    }
  };

  changeSectionName = (section, name, callBack) => {
    if (this.props.avoidNameChangeRequest) {
      section[this.props.tabKey] = name;
      section.Save && section.Save();
      section.Save(() => {
        this.props.update && this.props.update();
        this.forceUpdate();
        callBack();
      });
      return;
    }
    if (!this.props.CurrentScreen) {
      if (!this.props.isAdmin) {
        // this.props.categories[this.props.categories.findIndex(c => c.ID === section.CategoryID)].Sections[this.props.categories[this.props.categories.findIndex(c => c.ID === section.CategoryID)].Sections.findIndex(c => c.SectionName === section.SectionName)].SectionName = name;

        if (!this.props.businessPlan) {
          section[this.props.tabKey] = name;
          if (section.Save) {
            section.Save(() => {
              this.forceUpdate();
              callBack();
            });
          } else {
            global.Modeliks.put(
              this.props.SlideSectionsTableName,
              { ID: section.ID },
              { [this.props.tabKey]: name },
              () => {
                this.forceUpdate();
                callBack();
              },
            );
          }
        } else {
          section.PageName = name;
          global.Modeliks.put(
            "businessplanpages_client",
            { ID: section.ID },
            { PageName: name },
            () => {
              this.props.changeName(section.ID, name);
              callBack();
            },
          );
        }
      } else {
        request
          .put("/api/master/slidesections")
          .set("authorization", "Bearer " + window.localStorage.getItem("token"))
          .query({ ID: section.ID })
          .send({ SectionName: name })
          .then((data) => {
            this.forceUpdate();
            callBack();
          });
      }
    } else {
      request
        .put(`/api/master/dashboardcharts`)
        .set("authorization", "Bearer " + window.localStorage.getItem("token"))
        .query({ ID: section.ID })
        .send({ Name: name })
        .then((res) => callBack && callBack());
    }
  };

  saveBunchOfObjects = (objs, index, callBack) => {
    if (index < objs.length) {
      if (objs[index].Save) {
        objs[index].Save(() => {
          this.saveBunchOfObjects(objs, index + 1, callBack);
        });
      } else {
        this.saveBunchOfObjects(objs, index + 1, callBack);
      }
    } else {
      callBack && callBack();
    }
  };

  addSection = (tab, callBack) => {
    // let currIndex = this.props.tabs.indexOf(this.props.selectedSection)
    // for (let i = currIndex + 1; i < this.props.tabs.length; i++) {
    //     this.props.tabs[i].order += 1;
    //     if (this.props.tabs[i].Save) {
    //         this.props.tabs[i].Save();
    //     }
    // }
    // this.saveBunchOfObjects(this.props.tabs, currIndex + 1, ()=>{window.alert(' saved')});

    this.props.addSection(tab, callBack);
  };

  addBlankSection = (callBack) => {
    // if (!this.props.isAdmin) {
    //     let currIndex = this.props.tabs.indexOf(this.props.selectedSection)
    //     for (let i = currIndex + 1; i < this.props.tabs.length; i++) {
    //         this.props.tabs[i].order += 1;
    //         if (this.props.tabs[i].Save) {
    //             this.props.tabs[i].Save();
    //         }
    //     }
    // }

    this.props.addBlankSection(callBack);
  };

  handleChange = (e, val) => {
    if (this.props.sectionIndexes) {
      this.setState({ selectedSection: this.props.sectionIndexes[val] });
    }
    this.props.handleChange(e, val, () => {
      this.setState({
        selectedSection: undefined,
      });
    });
    this.tabFunctions.closeTabFunctions.forEach((c) => c());
  };

  getTabName = (tab, tabKey) => {
    let newTabName = null;

    if (Array.isArray(tabKey)) {
      newTabName = tab;

      tabKey.forEach((key) => {
        newTabName = newTabName[key];
      });
    } else {
      newTabName = tab[tabKey];
    }

    return newTabName;
  };

  handleCloseConfirmDelete = () => {
    this.setState({ openConfirmDelete: false });
  };

  getSelectedSection = () => {
    if (this.state.selectedSection) {
      return this.state.selectedSection;
    }
    if (this.props.selectedSection) {
      if (typeof this.props.selectedSection === "function") {
        return this.props.selectedSection();
      }
      return this.props.selectedSection;
    }
    return null;
  };

  render() {
    return (
      <PageContext.Consumer>
        {({ shouldCreateNewBp, setShouldCreateNewBp }) => (
          <div className={CSSClass + this.state.expandedMenuClass} id={"se_menu_container"}>
            <div className="left_menu">
              {!this.props.ManagementReports && (
                <div
                  style={{
                    height: 24,
                    width: 24,
                    backgroundColor: "white",
                    borderRadius: "50%",
                    zIndex: 9,
                    top: 10,
                  }}
                  className={"closing_btn"}
                >
                  <ButtonMui
                    label={<LeftArrow color={"#585858"} />}
                    isIconButton
                    onClick={() => this.toggleMenu(() => this.handleResize())}
                    variant={"text"}
                    color={"black"}
                    style={{
                      borderRadius: "50%",
                      zIndex: 999,
                      height: 24,
                      width: 24,
                      padding: "4px",
                    }}
                  />
                </div>
              )}
              {!this.props.hideTopButton &&
                !this.props.ManagementReports &&
                !this.props.isAdmin && (
                  <>
                    {!this.props.businessPlan ? (
                      <PitchScenarioMenu
                        onScenarioChange={this.props.onScenarioChange}
                        setSaving={this.props.setSaving}
                        saveCurrentSection={this.props.saveCurrentSection}
                      />
                    ) : (
                      <BusinessPlanScenarioMenu
                        shouldCreateNewBp={shouldCreateNewBp}
                        setShouldCreateNewBp={setShouldCreateNewBp}
                        onScenarioChange={this.props.onScenarioChange}
                        setSaving={this.props.setSaving}
                        saveSlideContent={this.props.saveSlideContent}
                      />
                    )}
                  </>
                )}

              {(this.props.topButtonText ||
                (this.props.ManagementReports && !this.props.isAdmin)) && (
                <p
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "#252525",
                    paddingLeft: "15px",
                  }}
                >
                  {this.props.topButtonText ?? "Reports Folder"}
                </p>
              )}
              {/*{this.props.isAdmin && <Menu className={'categories_menu'} direction={'right'}*/}
              {/*menuButton={<Button variant='text' className='section_name button'*/}
              {/*disableElevation>{this.props.EditingScreens[this.props.CurrentScreen].ScreenName}</Button>}*/}
              {/*transition>*/}
              {/*{this.props.EditingScreens && this.props.EditingScreens.map((screen, index) => {*/}
              {/*return <MenuItem*/}
              {/*key={'left_menu_screens_' + index}*/}
              {/*className={this.props.CurrentScreen === index ? 'selected' : ''}*/}
              {/*onClick={() => this.props.changeScreen(index)}>{screen.ScreenName}</MenuItem>*/}
              {/*})}*/}
              {/*</Menu>}*/}
              {/*{this.props.isAdmin && this.props.CurrentScreen == 0 &&*/}
              {/*    <Menu className={'categories_menu'} direction={'right'}*/}
              {/*          menuButton={<Button variant='text' className='section_name button'*/}
              {/*                              disableElevation>{this.props.selectedCategory}</Button>}*/}
              {/*          transition>*/}
              {/*        {this.props.categories && this.props.categories.map((category, index) => {*/}
              {/*            return <MenuItem*/}
              {/*                key={'left_menu_categories_' + index}*/}
              {/*                className={this.props.selectedCategory === category.CategoryName ? 'selected' : ''}*/}
              {/*                onClick={() => this.props.changeCategory(category.CategoryName)}>{category.CategoryName}</MenuItem>*/}
              {/*        })}*/}
              {/*    </Menu>}*/}
              <LeftMenuResponsiveBox
                offsetHeight={this.props.offsetHeight}
                admin={this.props.isAdmin}
              >
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  visibleScrollbar={this.props.visibleScrollbar}
                  value={this.getSelectedSection() && this.getSelectedSection().ID}
                  onChange={this.handleChange}
                  aria-label="Vertical tabs example"
                  scrollButtons={false}
                  sx={{ width: "100%", minHeight: 0 }}
                  key={this.state.leftMenuKey + "l_m"}
                >
                  {this.props.tabs.map((tab, index) => {
                    return (
                      <Tab
                        key={this.state.key + "left_menu" + index}
                        id={"tab_" + index}
                        value={tab.ID}
                        className="tab_btn draggable"
                        disableFocusRipple
                        label={
                          <SectionTab
                            hideHiddenProperty={this.props.hideHiddenProperty}
                            hideDuplicate={this.props.hideDuplicate}
                            updateSection={(name, callBack) =>
                              this.changeSectionName(tab, name, callBack)
                            }
                            className={tab.Hidden ? " hidden" : ""}
                            isHidden={tab.Hidden}
                            tabFunctions={this.tabFunctions}
                            disableEdit={this.props.disableEdit}
                            hideSection={() => this.hideSection(tab, () => this.forceUpdate())}
                            id={tab.ID}
                            enableDuplicate={this.props.enableDuplicate}
                            disableDelete={
                              (!this.props.CurrentScreen && this.props.isAdmin) ||
                              this.props.hideDelete
                            }
                            businessPlan={this.props.businessPlan}
                            isAdmin={this.props.isAdmin}
                            handleDuplicate={() =>
                              this.props.handleDuplicate(
                                tab,
                                () => {
                                  this.setState({ leftMenuKey: new Date().getTime() });
                                },
                                true,
                              )
                            }
                            addSection={() => this.addSection(tab, () => this.forceUpdate())}
                            deleteSection={() => this.beforeDeleteSection(tab, index)}
                            key={"left_menu_section" + index}
                            sectionName={this.getTabName(tab, this.props.tabKey)}
                            mouseDownHandler={(e) => this.mouseDownHandler(e, index, tab)}
                          >
                            {tab[this.props.tabKey]}
                          </SectionTab>
                        }
                        {...a11yProps(index)}
                      />
                    );
                  })}
                </Tabs>
                <div
                  className="left_side_button_container"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    paddingTop:
                      this.props.CurrentScreen == 0 && !this.props.ManagementReports
                        ? "20px"
                        : "0px",
                  }}
                >
                  {this.props.CurrentScreen == 0 && !this.props.ManagementReports && (
                    <SectionDialog
                      selectedCategory={this.props.selectedCategory}
                      disableEdit={this.props.disableEdit}
                      updateSection={this.props.editSection}
                      isAdmin={this.props.isAdmin}
                      tabKey={this.props.tabKey}
                      addNewObjectFuncs={this.props.addNewObjectFuncs}
                      label={this.props.label ? this.props.label : ""}
                      businessPlan={this.props.businessPlan}
                      deleteComplete={this.props.deleteComplete}
                      addNewSection={this.props.addNewSection}
                      deleteSections={this.props.deleteSections}
                      selectedSection={this.props.selectedSection}
                      sections={this.props.tabs}
                      categories={this.props.categories}
                      addSection={this.addSection}
                      addBlankSection={this.addBlankSection}
                    />
                  )}
                  {this.props.CurrentScreen > 0 && (
                    <ChartDialog handleAddChart={this.props.handleAddChart} />
                  )}
                </div>
                {this.props.CurrentScreen === 0 && this.props.ManagementReports && (
                  <AddNewFileDialog addNewSection={this.props.addNewSection} />
                )}
              </LeftMenuResponsiveBox>
            </div>
            {
              <ConfirmDeleteDialog
                open={this.state.openConfirmDelete}
                handleClose={this.handleCloseConfirmDelete}
                text={this.props.text}
                handleDeleteConfirm={() =>
                  this.deleteSection(
                    this.state.deleteSection,
                    this.state.deleteIndex,
                    this.handleCloseConfirmDelete,
                  )
                }
              />
            }
          </div>
        )}
      </PageContext.Consumer>
    );
  }
}

export default LeftMenu;

import { Link, useNavigate } from "react-router-dom";
import { Button } from "./CopiedFromRefactor/Button";
import { usePage } from "../contexts/PageProvider";
import CircleLoader from "../../../components/Loaders/CircleLoader/CircleLoader";

export function FormNavigation(props) {
  const navigate = useNavigate();
  const { onSave, noSaveBtn } = props;
  const { isLoading } = usePage();

  function handleNavigate(path) {
    if (!isLoading) navigate("/business_plan/ai_generator");
  }

  return (
    <div className="w-full absolute bottom-0 left-0 bg-white flex justify-end gap-6 border-t-2 border-grey-light p-4 mt-auto">
      <Button
        onClick={() => handleNavigate()}
        className="w-38 overflow-hidden"
        size="large"
        rounded
        outline
      >
        {isLoading ? <CircleLoader className="h-5 max-w-full" /> : "Back"}
      </Button>

      {!noSaveBtn && (
        <Button className="w-38 overflow-hidden" size="large" rounded onClick={onSave}>
          {isLoading ? <CircleLoader className="h-5 max-w-full" /> : "Save"}
        </Button>
      )}
    </div>
  );
}

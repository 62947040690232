import { useEffect, useState } from "react";
import { Button } from "../../components/CopiedFromRefactor/Button";
import { CardSelect } from "../../components/CardSelect";
import { QuestionWrapper } from "../../components/QuestionWrapper";
import { Input } from "../../components/Input";
import { SectionButtons } from "../../components/SectionButtons";
import { useSave } from "../../hooks/useSave";
import { marketCompetitorsObjectToAiGeneratedContentDto } from "../../models/aiGeneratedContent/transformer";
import { SectionMode } from "../../helpers/enums";
import { usePage } from "../../contexts/PageProvider";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import { fetchOptions } from "../../Services/api/Repositories/BusinessPlanGenerator";
import { useNavigate } from "react-router-dom";
import { FormNavigation } from "../../components/FormNavigation";

const MAX_CARDS = 4;

export function MarketCompetitors() {
  const navigate = useNavigate();
  const {
    section,
    marketCompetitors,
    setMarketCompetitors,
    getGeneratedData,
    setLoading,
    isLoading,
    options,
    setOptions,
  } = usePage();

  const [sectionName, setSectionName] = useState({ sectionName: "" });
  const { nextSection, generateSection, saveSectionInputs } = useSave(sectionName);

  const [cards, setCards] = useState([]);
  const [newCompetitorName, setNewCompetitorName] = useState("");
  const [newCompetitorWebsite, setNewCompetitorWebsite] = useState("");
  const [showInputs, setShowInputs] = useState(false);
  const [loadingCompetitors, setLoadingCompetitors] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  const [newCompetitorOptions, setNewCompetitorOptions] = useState([]);

  useEffect(() => {
    if (options)
      setOptions({
        ...options,
        competitors: [
          ...options.competitors,
          ...newCompetitorOptions.filter((card) => !options.competitors.includes(card)),
        ],
      });
  }, [newCompetitorOptions]);

  useEffect(() => {
    if (section) {
      setSectionName({ sectionName: section.name });
    }
  }, [section]);

  const bpScenarioId = global.Modeliks.BusinessPlanScenarioInfo.ID;

  async function fetchCompetitors() {
    try {
      setLoadingCompetitors(true);
      const competitorsResponse = await fetchOptions({
        bpScenarioId,
        optionKey: "competitors",
      });

      setOptions({ ...options, competitors: competitorsResponse.SectionData.Options.competitors });
      setCards(
        transformToSelectable(
          competitorsResponse.SectionData.Options.competitors,
          marketCompetitors.competitors,
        ),
      );
    } catch (error) {
      console.error("Error fetching competitors:", error);
    } finally {
      setLoadingCompetitors(false);
    }
  }

  function transformToSelectable(items = [], selectedItems = []) {
    const allItems = items.map((item) => ({
      competitorName: item.competitorName,
      competitorWebsite: item.competitorWebsite,
      isSelected: selectedItems.some(
        (selectedItem) => selectedItem.competitorName === item.competitorName,
      ),
    }));

    selectedItems.forEach((selectedItem) => {
      if (!items.some((item) => item.competitorName === selectedItem.competitorName)) {
        allItems.push({
          competitorName: selectedItem.competitorName,
          competitorWebsite: selectedItem.competitorWebsite,
          isSelected: true,
        });
      }
    });

    return allItems;
  }

  useEffect(() => {
    if (options) {
      setInitialRender(false);
      if (options.competitors.length === 0) {
        fetchCompetitors();
      } else {
        if (initialRender)
          setCards((prevCards) =>
            transformToSelectable(options.competitors, marketCompetitors.competitors),
          );
      }
    }
  }, [options, marketCompetitors, isLoading]);

  function addCard() {
    const trimmedCompetitorName = newCompetitorName.trim();
    const trimmedCompetitorWebsite = newCompetitorWebsite.trim();

    if (
      !trimmedCompetitorName ||
      !trimmedCompetitorWebsite ||
      cards.some((card) => card.competitorName === trimmedCompetitorName)
    )
      return;

    const newCard = {
      competitorName: trimmedCompetitorName,
      competitorWebsite: trimmedCompetitorWebsite,
      isSelected: false,
    };

    setCards((prevCards) => [...prevCards, newCard]);
    setNewCompetitorOptions((prevCards) => [...prevCards, newCard]);
    setNewCompetitorName("");
    setNewCompetitorWebsite("");
    setShowInputs(false);
  }

  function onCardClick(index) {
    const selectedCardsCount = cards.filter((card) => card.isSelected).length;

    setCards((prevCards) =>
      prevCards.map((card, i) => {
        if (i === index) {
          const canDeselect = card.isSelected || selectedCardsCount < MAX_CARDS;
          return {
            ...card,
            isSelected: canDeselect ? !card.isSelected : card.isSelected,
          };
        }
        return card;
      }),
    );
  }

  function updateCard(index, values) {
    const [competitorName, competitorWebsite] = values;
    setCards((prevCards) =>
      prevCards.map((card, i) =>
        i === index ? { ...card, competitorName, competitorWebsite } : card,
      ),
    );
  }

  function validate() {
    return cards.filter((card) => card.isSelected).length > 0;
  }

  async function onNextHandle() {
    if (!validate()) {
      return "market_analysis/unique_selling_proposition";
    }

    const competitors = cards
      .filter((card) => card.isSelected)
      .map(({ isSelected, ...rest }) => rest);

    await setMarketCompetitors({
      competitors: competitors,
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await nextSection(
            marketCompetitorsObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });
    return "market_analysis/critical_success_factors";
  }

  async function onGenerateHandle() {
    if (!validate()) return;

    const competitors = cards
      .filter((card) => card.isSelected)
      .map(({ isSelected, ...rest }) => rest);

    await setMarketCompetitors({
      competitors: competitors,
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await generateSection(
            marketCompetitorsObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });

    return sectionName;
  }

  async function onSave() {
    const competitors = cards
      .filter((card) => card.isSelected)
      .map(({ isSelected, ...rest }) => rest);

    await setMarketCompetitors({
      competitors: competitors,
      mode: SectionMode.Edit,
    });

    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setLoading(true);
          const generatedData = getGeneratedData();

          await saveSectionInputs(
            marketCompetitorsObjectToAiGeneratedContentDto(generatedData, sectionName),
          );
        } catch (error) {
          console.error("Error during business plan generation:", error);
        } finally {
          setLoading(false);
        }

        resolve();
      }, 0);
    });
  }

  function regenerateOptions() {
    fetchCompetitors();
  }

  return (
    <>
      <QuestionWrapper
        isAnswered={validate()}
        question={"Who are your main competitors?"}
        description={
          "Identify your main competitors. Later on you will identify their strengths and weaknesses and evaluate how your business compares to them."
        }
        maxSelectiveOptions={MAX_CARDS}
        onRegenerateOptions={regenerateOptions}
      >
        {loadingCompetitors ? (
          <CircleLoader />
        ) : (
          <div className="grid grid-cols-1 gap-3 auto-rows-fr">
            {cards.map((card, index) => (
              <CardSelect
                key={index}
                onClick={() => onCardClick(index)}
                isSelected={card.isSelected}
                value={[card.competitorName, card.competitorWebsite]}
                onSaveEdit={updateCard}
                index={index}
              >
                {card.competitorName}
              </CardSelect>
            ))}
            {showInputs && (
              <div className="grid grid-cols-2 gap-3 w-full">
                <Input
                  placeholder="Enter new competitor name"
                  value={newCompetitorName}
                  onChange={(e) => setNewCompetitorName(e.target.value)}
                  onAddCard={addCard}
                />
                <Input
                  placeholder="Enter new competitor website"
                  value={newCompetitorWebsite}
                  onChange={(e) => setNewCompetitorWebsite(e.target.value)}
                  onAddCard={addCard}
                />
              </div>
            )}
          </div>
        )}
      </QuestionWrapper>

      <Button onClick={() => setShowInputs(true)} className="w-38" rounded outline>
        Add More
      </Button>

      <SectionButtons onNext={onNextHandle} onGenerate={onGenerateHandle} />

      <FormNavigation onSave={onSave} />
    </>
  );
}

import { Button } from "./CopiedFromRefactor/Button";
import CircleLoader from "../../../components/Loaders/CircleLoader/CircleLoader";
import { usePage } from "../contexts/PageProvider";
import { useNavigate } from "react-router-dom";
import { getSection } from "../hooks/getSection";
import { setNewOptions } from "../hooks/saveNewOptions";
import { SectionName } from "../config";

export function SectionButtons(props) {
  const { onNext, onGenerate, doesntHaveGenerateBtn, disableGenerateBtn, disableBtns } = props;
  const { isLoading, options, setLoading } = usePage();
  const navigate = useNavigate();
  const bpScenarioId = global.Modeliks.BusinessPlanScenarioInfo.ID;

  const handleGenerateClick = async () => {
    if (onGenerate && !isLoading) {
      const shouldGenerate = await onGenerate();

      if (shouldGenerate) {
        const section = await getSection(shouldGenerate.sectionName, bpScenarioId);
        const { problemsAndSolutions, ...optionsWithoutProblems } = options || {};

        if (shouldGenerate.sectionName != SectionName.MarketSolutions)
          if (options) await setNewOptions(bpScenarioId, optionsWithoutProblems);
        if (section && section.PageID) {
          navigate("/business_plan", { state: { sectionId: section.PageID } });
        } else {
          console.error("Failed to retrieve section or PageId is missing.");
        }
      }
    }
  };

  const handeOnNextClick = async () => {
    if (isLoading) return;
    setLoading(true);
    const nextSectionUrl = await onNext();
    if (!nextSectionUrl) {
      setLoading(false);
      return;
    }
    if (options) {
      const { problemsAndSolutions, ...optionsWithoutProblems } = options || {};
      if (nextSectionUrl !== "market_analysis/solutions") {
        await setNewOptions(bpScenarioId, options);
      } else {
        await setNewOptions(bpScenarioId, optionsWithoutProblems);
      }
    }

    navigate(`/business_plan/ai_generator/${nextSectionUrl}`);
  };

  return (
    <div className="flex justify-center gap-11">
      {!doesntHaveGenerateBtn ? (
        <Button
          disabled={disableGenerateBtn || disableBtns}
          outline={!disableGenerateBtn && !disableBtns}
          rounded
          onClick={handleGenerateClick}
          className={"w-full max-w-96 py-4"}
        >
          {isLoading ? (
            <CircleLoader className={"h-8"} />
          ) : (
            <span className="text-24 font-bold min-h-8">Generate & Review Section</span>
          )}
        </Button>
      ) : (
        <div className="w-96"></div>
      )}
      <Button
        rounded
        onClick={handeOnNextClick}
        disabled={disableBtns}
        className={"w-full max-w-96"}
      >
        {isLoading ? (
          <CircleLoader className={"h-8"} />
        ) : (
          <span className="text-24 font-bold">Next Section</span>
        )}
      </Button>
    </div>
  );
}

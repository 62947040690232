import { createContext, useContext, useState, useRef, useEffect } from "react";
import { fetchAiInputs } from "../Services/api/Repositories/BusinessPlanGenerator";
import { dtoToAiGeneratedContent } from "../models/aiGeneratedContent";
import { dtoOptionsToAiGeneratedContent } from "../models/aiGeneratedContent/transformer";
import { getSection } from "../hooks/getSection";
import { SectionName } from "../config";

export const PageContext = createContext();

export function PageProvider({ children, pageProp }) {
  const [page, setPage] = useState(pageProp);
  const [section, setSection] = useState(pageProp);

  const [isLoading, setLoading] = useState(false);
  const [businessDescription, setBusinessDescription] = useState(null);
  const [businessValues, setBusinessValues] = useState(null);
  const [businessOwnership, setBusinessOwnership] = useState(null);
  const [businessProductsAndServices, setBusinessProductsAndServices] = useState(null);
  const [businessIntellectualProperty, setBusinessIntellectualProperty] = useState(null);

  const [marketProblemsAndSolutions, setMarketProblemsAndSolutions] = useState(null);
  const [marketTargets, setMarketTargets] = useState(null);
  const [marketTrends, setMarketTrends] = useState(null);
  const [marketCustomers, setMarketCustomers] = useState(null);
  const [marketCompetitors, setMarketCompetitors] = useState(null);
  const [marketCriticalSuccessFactor, setMarketCriticalSuccessFactor] = useState(null);
  const [marketSellingPropositions, setMarketSellingPropositions] = useState(null);

  const [strategyMarketChannels, setStrategyMarketChannels] = useState(null);
  const [strategyCustomerJourney, setStrategyCustomerJourney] = useState(null);
  const [strategyPricing, setStrategyPricing] = useState(null);
  const [strategySales, setStrategySales] = useState(null);
  const [strategyOperations, setStrategyOperations] = useState(null);
  const [strategyEquipment, setStrategyEquipment] = useState(null);
  const [strategyManagementTeam, setStrategyManagementTeam] = useState(null);
  const [strategyPersonnelTeam, setStrategyPersonnelTeam] = useState(null);

  const [executiveSummary, setExecutiveSummary] = useState(null);
  const [hasFetchedData, setHasFetchedData] = useState(false);

  const [inputs, setInputs] = useState([]);
  const [options, setOptions] = useState(null);

  const [shouldCreateNewBp, setShouldCreateNewBp] = useState(false);

  const bpScenarioId = global.Modeliks.BusinessPlanScenarioInfo.ID;
  async function fetchData() {
    try {
      setLoading(true);
      const response = await fetchAiInputs({
        bpScenarioId,
      });
      setHasFetchedData(true);
      if (!response) return;

      const dto = dtoToAiGeneratedContent(response.SectionData);
      const executiveSummaryResponse = await getSection(SectionName.ExecutiveSummary, bpScenarioId);

      setInputs({
        ...dto,
        executiveSummary: {
          executiveSummary: executiveSummaryResponse?.Completed
            ? executiveSummaryResponse.Completed
            : null,
        },
      });

      setOptions(
        dtoOptionsToAiGeneratedContent({
          ...dto.options,
          products: dto.options.products || [],
          services: dto.options.services || [],
          problems: dto.options.problems || [],
          solutions: dto.options.solutions || [],
          marketTrends: dto.options.marketTrends || [],
          buyerPersonas: dto.options.buyerPersonas || [],
          competitors: dto.options.competitors || [],
          criticalSuccessFactors: dto.options.criticalSuccessFactors || [],
          keySellingPoints: dto.options.keySellingPoints || [],
          marketingChannels: dto.options.marketingChannels || [],
          customerJourneyStages: dto.options.customerJourneyStages || [],
          salesChannels: dto.options.salesChannels || [],
          equipments: dto.options.equipments || [],
          keyPersonnel: dto.options.keyPersonnel || [],
          problemsAndSolutions: dto.options.problemsAndSolutions || [],
        }),
      );

      setBusinessDescription(dto.businessDescription);
      setBusinessValues(dto.businessValues);
      setBusinessOwnership(dto.businessOwnership);
      setBusinessProductsAndServices(dto.businessProductsAndServices);
      setBusinessIntellectualProperty(dto.businessIntellectualProperty);

      setMarketProblemsAndSolutions(dto.marketProblemsAndSolutions);
      setMarketTargets(dto.marketTargets);
      setMarketTrends(dto.marketTrends);
      setMarketCustomers(dto.marketCustomers);
      setMarketCompetitors(dto.marketCompetitors);
      setMarketCriticalSuccessFactor(dto.marketCriticalSuccessFactor);
      setMarketSellingPropositions(dto.marketSellingPropositions);

      setStrategyMarketChannels(dto.strategyMarketChannels);
      setStrategyCustomerJourney(dto.strategyCustomerJourney);
      setStrategyPricing(dto.strategyPricing);
      setStrategySales(dto.strategySales);
      setStrategyOperations(dto.strategyOperations);
      setStrategyEquipment(dto.strategyEquipment);
      setStrategyManagementTeam(dto.strategyManagementTeam);
      setStrategyPersonnelTeam(dto.strategyPersonnelTeam);
    } catch (error) {
      console.error("Error fetching business plan scenario:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const stateRef = useRef();

  stateRef.current = {
    businessDescription,
    businessValues,
    businessOwnership,
    businessProductsAndServices,
    businessIntellectualProperty,
    marketProblemsAndSolutions,
    marketTargets,
    marketTrends,
    marketCustomers,
    marketCompetitors,
    marketCriticalSuccessFactor,
    marketSellingPropositions,
    strategyMarketChannels,
    strategyCustomerJourney,
    strategyPricing,
    strategySales,
    strategyOperations,
    strategyEquipment,
    strategyManagementTeam,
    strategyPersonnelTeam,
  };

  function getGeneratedData() {
    return stateRef.current;
  }

  const contextValue = {
    inputs,
    page,
    setPage,
    businessDescription,
    setBusinessDescription,
    businessValues,
    setBusinessValues,
    businessOwnership,
    setBusinessOwnership,
    businessProductsAndServices,
    setBusinessProductsAndServices,
    businessIntellectualProperty,
    setBusinessIntellectualProperty,
    marketProblemsAndSolutions,
    setMarketProblemsAndSolutions,
    marketTargets,
    setMarketTargets,
    marketTrends,
    setMarketTrends,
    marketCustomers,
    setMarketCustomers,
    marketCompetitors,
    setMarketCompetitors,
    marketCriticalSuccessFactor,
    setMarketCriticalSuccessFactor,
    marketSellingPropositions,
    setMarketSellingPropositions,
    strategyMarketChannels,
    setStrategyMarketChannels,
    strategyCustomerJourney,
    setStrategyCustomerJourney,
    strategyPricing,
    setStrategyPricing,
    strategySales,
    setStrategySales,
    strategyOperations,
    setStrategyOperations,
    strategyEquipment,
    setStrategyEquipment,
    strategyManagementTeam,
    setStrategyManagementTeam,
    strategyPersonnelTeam,
    setStrategyPersonnelTeam,
    hasFetchedData,
    options,
    setOptions,
    getGeneratedData,
    isLoading,
    setLoading,
    section,
    setSection,
    shouldCreateNewBp,
    setShouldCreateNewBp,
  };

  return <PageContext.Provider value={contextValue}>{children}</PageContext.Provider>;
}

export function usePage() {
  const context = useContext(PageContext);
  if (!context) {
    throw new Error("usePage must be used within a PageProvider");
  }
  return context;
}

import { iconCheck } from "../icons";

export function CheckedBox(props) {
  const { isChecked } = props;

  return (
    <div
      className={`min-h-10 min-w-10 h-10 w-10 rounded-full shadow-sm flex items-center justify-center ${isChecked ? "bg-primary" : "bg-grey-light-2"}`}
    >
      {isChecked && iconCheck}
    </div>
  );
}

// import HeaderPortal from './HeaderPortal'
import React, { Fragment, useState } from "react";
import "../HeaderTextEditor.scss";
import "../HeaderDefault.scss";
import { Charts } from "../../components/IconButtons/SubMenuIcons";
import {
  BubbleChart,
  ClusteredHorizontalBar,
  ClusteredVerticalBar,
  Combo,
  DoughnutChart,
  FloatingBarChart,
  HorizontalBar,
  LineChart,
  PieChart,
  ScatterChart,
  StackedBarChart,
  StackedBarLine,
  VerticalBar,
} from "../../components/IconButtons/SubMenuIcons";
import { SlideObject, SlideTypes } from "../../SlideObjects/SlideStructures";
import { Menu, MenuItem, SubMenu, MenuButton } from "@szhsin/react-menu";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

function InsertChartComponent(props) {
  const handleNewChartObj = (chartType, top, left, width, height, e) => {
    let showTitle = false;
    let showSubtitle = false;
    let titleAlign = "start";
    let displayLegend = false;
    let legendPosition = "bottom";
    let xGrid = false;
    let yGrid = false;

    props.setInsertMode(
      SlideTypes.chartObject,
      (top, left) => {
        const props1 = {
          chartType,
          top,
          left,
          width,
          height,
          showTitle,
          showSubtitle,
          titleAlign,
          displayLegend,
          legendPosition,
          xGrid,
          yGrid,
        };
        const nObj = new SlideObject(SlideTypes.chartObject, null, props1);
        props.onNewObject(nObj);
      },
      e,
    );
  };

  //<Tooltip disableHoverListener={props.disableEdit}  arrow title={'Insert Chart'}><span>
  // </span></Tooltip>
  const ChartInsert = () => {
    return (
      <SubMenu
        className="header_menu"
        label={({ open }) => (
          <span>
            <div style={{ display: "flex", columnGap: "15px", alignItems: "center" }}>
              <Charts active={open} />
              <div style={{ color: open ? "#01AFFD" : "unset" }}>Chart</div>
            </div>
          </span>
        )}
      >
        <MenuItem onClick={(e) => handleNewChartObj(6, 100, 20, 600, 300, e)}>
          {({ hover }) => (
            <>
              <HorizontalBar active={hover} />
              <span className="item_name" style={{ color: hover ? "#01AFFD" : "unset" }}>
                Horizontal Bar
              </span>
            </>
          )}
        </MenuItem>
        <MenuItem onClick={(e) => handleNewChartObj(3, 100, 20, 600, 300, e)}>
          {({ hover }) => (
            <>
              <ClusteredHorizontalBar active={hover} />
              <span className="item_name" style={{ color: hover ? "#01AFFD" : "unset" }}>
                Clustered Horizontal Bar
              </span>
            </>
          )}
        </MenuItem>
        <MenuItem onClick={(e) => handleNewChartObj(4, 100, 20, 600, 300, e)}>
          {({ hover }) => (
            <>
              <VerticalBar active={hover} />
              <span className="item_name" style={{ color: hover ? "#01AFFD" : "unset" }}>
                Vertical Bar
              </span>
            </>
          )}
        </MenuItem>
        <MenuItem onClick={(e) => handleNewChartObj(2, 100, 20, 600, 300, e)}>
          {({ hover }) => (
            <>
              <ClusteredVerticalBar active={hover} />
              <span className="item_name" style={{ color: hover ? "#01AFFD" : "unset" }}>
                Clustered Vertical Bar
              </span>
            </>
          )}
        </MenuItem>
        <MenuItem onClick={(e) => handleNewChartObj(1, 100, 20, 600, 300, e)}>
          {({ hover }) => (
            <>
              <StackedBarChart active={hover} />
              <span className="item_name" style={{ color: hover ? "#01AFFD" : "unset" }}>
                Stacked Bar Chart
              </span>
            </>
          )}
        </MenuItem>
        <MenuItem onClick={(e) => handleNewChartObj(14, 100, 20, 600, 300, e)}>
          {({ hover }) => (
            <>
              <FloatingBarChart active={hover} />
              <span className="item_name" style={{ color: hover ? "#01AFFD" : "unset" }}>
                Waterfall Chart
              </span>
            </>
          )}
        </MenuItem>
        <MenuItem onClick={(e) => handleNewChartObj(13, 100, 20, 600, 300, e)}>
          {({ hover }) => (
            <>
              <LineChart active={hover} />
              <span className="item_name" style={{ color: hover ? "#01AFFD" : "unset" }}>
                Line Chart
              </span>
            </>
          )}
        </MenuItem>
        <MenuItem onClick={(e) => handleNewChartObj(7, 100, 20, 600, 300, e)}>
          {({ hover }) => (
            <>
              <BubbleChart active={hover} />
              <span className="item_name" style={{ color: hover ? "#01AFFD" : "unset" }}>
                Bubble Chart
              </span>
            </>
          )}
        </MenuItem>
        <MenuItem onClick={(e) => handleNewChartObj(8, 100, 20, 600, 300, e)}>
          {({ hover }) => (
            <>
              <Combo active={hover} />
              <span className="item_name" style={{ color: hover ? "#01AFFD" : "unset" }}>
                Combo (bar/line)
              </span>
            </>
          )}
        </MenuItem>
        <MenuItem onClick={(e) => handleNewChartObj(9, 100, 20, 600, 300, e)}>
          {({ hover }) => (
            <>
              <DoughnutChart active={hover} />
              <span className="item_name" style={{ color: hover ? "#01AFFD" : "unset" }}>
                Doughnut Chart
              </span>
            </>
          )}
        </MenuItem>
        <MenuItem onClick={(e) => handleNewChartObj(10, 100, 20, 600, 300, e)}>
          {({ hover }) => (
            <>
              <PieChart active={hover} />
              <span className="item_name" style={{ color: hover ? "#01AFFD" : "unset" }}>
                Pie Chart
              </span>
            </>
          )}
        </MenuItem>
        <MenuItem onClick={(e) => handleNewChartObj(11, 100, 20, 600, 300, e)}>
          {({ hover }) => (
            <>
              <ScatterChart active={hover} />
              <span className="item_name" style={{ color: hover ? "#01AFFD" : "unset" }}>
                Scatter Chart
              </span>
            </>
          )}
        </MenuItem>
        <MenuItem onClick={(e) => handleNewChartObj(12, 100, 20, 600, 300, e)}>
          {({ hover }) => (
            <>
              <StackedBarLine active={hover} />
              <span className="item_name" style={{ color: hover ? "#01AFFD" : "unset" }}>
                Stacked Bar/Line
              </span>
            </>
          )}
        </MenuItem>
      </SubMenu>
    );
  };

  return <>{ChartInsert()}</>;
}

export default InsertChartComponent;
